import { useEffect } from 'react';

// Trigger an alert if the user tries to reload or close the tab
const usePreventLeaveRoute = () =>
  useEffect(() => {
    window.onbeforeunload = function() {
      return true;
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

export default usePreventLeaveRoute;
