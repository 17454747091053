import React from 'react';

const viewMoreIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-1192 -627)">
        <g transform="translate(208 80)">
          <g transform="translate(0 86)">
            <g transform="translate(0 437)">
              <g transform="translate(24 24)">
                <g transform="translate(932)">
                  <g transform="rotate(-180 26 12)">
                    <rect
                      width="24"
                      height="24"
                      x="0"
                      y="0"
                      opacity="0.054"
                      rx="2"
                    ></rect>
                    <circle
                      cx="12"
                      cy="6.826"
                      r="1.565"
                      fill="#575766"
                    ></circle>
                    <circle cx="12" cy="12" r="1.565" fill="#575766"></circle>
                    <circle
                      cx="12"
                      cy="17.174"
                      r="1.565"
                      fill="#575766"
                    ></circle>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default viewMoreIcon;
