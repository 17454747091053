import React, { createContext, useReducer } from 'react';
import { actionTypes } from 'state/assessmentContext/actionTypes';

const initialState = {
  assessmentList: [],
  selectedAssessment: null,
  selectedAssessmentAttempts: null
};

export const AssessmentContext = createContext(initialState);

export const reducer = (state, action) => {
  const {
    SET_ASSESSMENTS,
    SET_SELECTED_ASSESSMENT,
    SET_SELECTED_ASSESSMENT_ATTEMPTS,
    CLEAR_ASSESSMENTS
  } = actionTypes;

  switch (action.type) {
    case SET_ASSESSMENTS:
      return { ...state, assessmentList: action.payload };
    case SET_SELECTED_ASSESSMENT:
      return { ...state, selectedAssessment: action.payload };
    case SET_SELECTED_ASSESSMENT_ATTEMPTS:
      return { ...state, selectedAssessmentAttempts: action.payload };
    case CLEAR_ASSESSMENTS:
      return {
        ...state,
        assessmentList: [],
        selectedAssessment: null,
        selectedAssessmentAttempts: null
      };
    default:
      return state;
  }
};

const AssessmentProvider = ({ children }) => {
  const [assessmentState, assessmentDispatch] = useReducer(
    reducer,
    initialState
  );

  return (
    <AssessmentContext.Provider value={{ assessmentState, assessmentDispatch }}>
      {children}
    </AssessmentContext.Provider>
  );
};

export default AssessmentProvider;
