import React from 'react';
import { Svg } from 'components/Blocks/Styled/Media';

const inactiveFocusIcon = (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-24 -139)">
        <g transform="translate(8 68)">
          <g transform="translate(8 60)">
            <g transform="translate(8 11)">
              <circle cx="13" cy="13" r="13" fill="currentColor"></circle>
              <g
                fill="#FFFFFF"
                fillRule="nonzero"
                stroke="#FFFFFF"
                strokeWidth="0.2"
                transform="translate(10 7)"
              >
                <path d="M6.34.253A.411.411 0 005.96 0H.411a.411.411 0 00-.32.67L.946 1.72v8.033A2.249 2.249 0 003.192 12 2.25 2.25 0 005.44 9.753v-8.03a97.16 97.16 0 00.7-.872c.19-.239.294-.37.2-.598zM4.717 1.305a.411.411 0 00-.101.27v8.178c0 .786-.64 1.425-1.425 1.425a1.426 1.426 0 01-1.424-1.425V1.575a.411.411 0 00-.092-.259L1.274.822H5.11c-.185.23-.357.442-.392.483z"></path>
                <path d="M4.864 3.397H3.822a.41.41 0 100 .822h1.042a.41.41 0 100-.822zM5.028 5.589H3.822a.41.41 0 100 .822h1.206a.41.41 0 100-.822zM5.028 7.795H3.822a.41.41 0 100 .821h1.206a.41.41 0 100-.821z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </Svg>
);

export default inactiveFocusIcon;
