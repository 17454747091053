import React from 'react';

const leakedIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-1062 -326)">
        <g transform="translate(208 152)">
          <g transform="translate(0 86)">
            <g transform="translate(24 23)">
              <g transform="translate(821 1)">
                <g transform="translate(0 28)">
                  <g transform="translate(1 32)">
                    <g transform="translate(8 4)">
                      <rect
                        width="24"
                        height="24"
                        x="0"
                        y="0"
                        opacity="0.048"
                        rx="2"
                      ></rect>
                      <path
                        fill="#575766"
                        fillRule="nonzero"
                        d="M17.04 12.306h-.68l-2.134-5.994a.47.47 0 00-.773-.175 2.387 2.387 0 01-1.7.704c-.641 0-1.244-.25-1.698-.704a.469.469 0 00-.773.175l-2.134 5.994h-.68a.469.469 0 100 .938H17.04a.469.469 0 100-.938zm-8.897 0l1.8-5.059a3.318 3.318 0 001.81.532c.653 0 1.276-.186 1.811-.532l1.8 5.06H8.144zm6.05 1.55c-.982 0-1.806.686-2.019 1.603h-.84a2.076 2.076 0 00-2.019-1.603c-1.142 0-2.072.93-2.072 2.072S8.173 18 9.315 18c.981 0 1.806-.686 2.019-1.603h.84A2.076 2.076 0 0014.193 18c1.142 0 2.072-.93 2.072-2.072 0-1.143-.93-2.072-2.072-2.072zm-4.878 3.206a1.136 1.136 0 01-1.134-1.134c0-.626.508-1.135 1.134-1.135a1.136 1.136 0 010 2.269zm4.878 0a1.136 1.136 0 01-1.135-1.134 1.136 1.136 0 012.269 0c0 .626-.509 1.134-1.134 1.134z"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default leakedIcon;
