export const VALID_STATES = Object.freeze({
  ALL: 'all',
  ACTIVE: 'active',
  ARCHIVED: 'archived',
  EDIT: 'edit',
  DELETE: 'deleted',
  DRAFT: 'draft',
  INACTIVE: 'inactive',
  LEAKED: 'leaked',
  LIVE: 'live',
  REVIEW: 'review',
  RETIRED: 'retired'
});

export const ACTIVE_QUESTIONS = Object.freeze([
  VALID_STATES.ACTIVE,
  VALID_STATES.DRAFT,
  VALID_STATES.RETIRED
]);

export const INACTIVE_QUESTIONS = Object.freeze([
  VALID_STATES.DRAFT,
  VALID_STATES.RETIRED
]);

export const QUESTION_STATES = Object.freeze([
  VALID_STATES.ACTIVE,
  VALID_STATES.DRAFT,
  VALID_STATES.RETIRED,
  VALID_STATES.DELETE
]);

export const ACTIVE_ASSESSMENTS = Object.freeze([
  VALID_STATES.ACTIVE,
  VALID_STATES.REVIEW,
  VALID_STATES.ARCHIVED
]);

export const INACTIVE_ASSESSMENTS = Object.freeze([
  VALID_STATES.ARCHIVED,
  VALID_STATES.REVIEW
]);

export const VALID_ASSESSMENT_STATE_CHANGES = Object.freeze({
  [VALID_STATES.LIVE]: [
    VALID_STATES.EDIT,
    VALID_STATES.ARCHIVED,
    VALID_STATES.REVIEW
  ],
  [VALID_STATES.REVIEW]: [VALID_STATES.EDIT, VALID_STATES.LIVE],
  [VALID_STATES.ARCHIVED]: [
    VALID_STATES.EDIT,
    VALID_STATES.DELETE,
    VALID_STATES.LIVE
  ]
});

export const VALID_QUESTION_STATE_CHANGES = Object.freeze({
  [VALID_STATES.ACTIVE]: [
    VALID_STATES.DRAFT,
    VALID_STATES.RETIRED,
    VALID_STATES.EDIT
  ],
  [VALID_STATES.DRAFT]: [
    VALID_STATES.ACTIVE,
    VALID_STATES.DELETE,
    VALID_STATES.EDIT
  ],
  [VALID_STATES.RETIRED]: [
    VALID_STATES.DRAFT,
    VALID_STATES.ACTIVE,
    VALID_STATES.DELETE,
    VALID_STATES.EDIT
  ]
});

export const MAP_ASSESSMENT_STATE_TO_LABEL = Object.freeze({
  [VALID_STATES.ACTIVE]: VALID_STATES.LIVE,
  [VALID_STATES.ARCHIVED]: VALID_STATES.ARCHIVED,
  [VALID_STATES.DRAFT]: VALID_STATES.REVIEW,
  [VALID_STATES.REVIEW]: VALID_STATES.REVIEW
});
