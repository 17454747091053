import React from 'react';

const freeResponseQuestionSquareIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="15"
    viewBox="0 0 26 15"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g stroke="#6A7F87" transform="translate(-289 -146)">
        <g transform="translate(91 90)">
          <g transform="translate(21.5)">
            <g transform="translate(166.5 39)">
              <g transform="translate(10 17)">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M23.833 12.167L23.167 12.833"
                ></path>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M24 10L21 13"
                ></path>
                <rect
                  width="25"
                  height="14"
                  x="0.5"
                  y="0.5"
                  fill="#6A7F87"
                  fillOpacity="0.16"
                  rx="2"
                ></rect>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 2.227L3 6.773"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default freeResponseQuestionSquareIcon;
