import endpoints from 'utils/requests/endpoints.js';

// Generates an object where the keys and values are equal
const endpointNames = Object.freeze(
  Object.keys(endpoints).reduce(
    (acc, value) => ({ ...acc, [value]: value }),
    {}
  )
);

export default endpointNames;
