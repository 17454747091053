import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import DistributionTable from 'pages/PanelPage/components/selectedAssessment/assessmentResults/DistributionTable';
import OverallPortfolioTimeline from 'pages/PanelPage/components/selectedAssessment/assessmentResults/takerResults/OverallPortfolioTimeline';
import OverallSectionSummaryTable from 'pages/PanelPage/components/selectedAssessment/assessmentResults/takerResults/OverallSectionSummaryTable';
import OverallSkillsBreakdownTable from 'pages/PanelPage/components/selectedAssessment/assessmentResults/takerResults/OverallSkillsBreakdownTable';
import SectionSummaryTable from 'pages/PanelPage/components/selectedAssessment/assessmentResults/takerResults/SectionSummaryTable';
import SkillsBreakdownTable from 'pages/PanelPage/components/selectedAssessment/assessmentResults/takerResults/SkillsBreakdownTable';
import QuestionsTable from 'pages/PanelPage/components/selectedAssessment/assessmentResults/takerResults/questionsTable/QuestionsTable';
import TakerAtAGlance from 'pages/PanelPage/components/selectedAssessment/assessmentResults/takerResults/TakerAtAGlance';
import leftArrowIcon from 'assets/leftArrowIcon';
import { FlexContainer } from 'components/Atoms/Containers';
import { Body1 } from 'components/Atoms/Text';
import Loader from 'components/Systems/Loader';
import { useUserContext } from 'state/userContext/useUserContext';
import { useAssessmentContext } from 'state/assessmentContext/useAssessmentContext';
import { requestHelper } from 'utils/requests/requestHelper';
import { requests } from 'utils/requests/requests';
import { ATTEMPT_STATES } from 'pages/ManagementPage/Constants';
import { paths } from 'pages/App/helpers/paths';
import { Separator } from 'pages/PanelPage/styles/StyledComponents/StyledComponents';
import CandidateDetails from 'pages/PanelPage/components/selectedAssessment/assessmentResults/takerResults/CandidateDetails';
import { takerFieldTypes } from 'pages/PanelPage/Constants';

const TakerResults = ({ chartData, setChartData }) => {
  const history = useHistory();
  const currentUuid = history.location.pathname.split('/').pop();
  const { isAnonymous } = useUserContext();
  const {
    assessmentState: {
      selectedAssessment: { sections, taker_fields }
    }
  } = useAssessmentContext();
  let [takerData, setTakerData] = useState();
  let [isLoading, setIsLoading] = useState(true);
  let { assessment } = useParams();

  useEffect(() => {
    requestHelper(
      requests({
        slug: assessment,
        attempt_state: ATTEMPT_STATES.COMPLETE
      }).getHistogramData,
      {},
      response => {
        const { chart_data } = response.data.data;
        setChartData(chart_data);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const fetchAssessmentAttempt = async () => {
      await requestHelper(
        requests({ uuid: currentUuid }).getAssessmentAttempt,
        {},
        response => {
          setTakerData(response.data.data);
        },
        () => {
          setTakerData('invalid');
        }
      );
      setIsLoading(false);
    };
    fetchAssessmentAttempt();
  }, [currentUuid]);

  const renderOverallResults = () => {
    return (
      sections.length > 1 && (
        <FlexContainer margin="20px 0 0" justify="space-between">
          {takerData?.section_attempts && (
            <>
              <OverallSectionSummaryTable
                sectionAttempts={takerData.section_attempts}
              />
              <OverallSkillsBreakdownTable
                sectionAttempts={takerData.section_attempts}
              />
            </>
          )}
        </FlexContainer>
      )
    );
  };

  const renderSectionResults = () =>
    takerData?.section_attempts?.map(renderSection);

  const renderSection = section => {
    return (
      <FlexContainer direction="column" key={section.id}>
        <SectionSummaryTable section={section} />
        <SkillsBreakdownTable categoryScores={section.category_scores} />
        {section.section_type !== 'section_stock' && (
          <QuestionsTable slotAttempts={section.slot_attempts} />
        )}
      </FlexContainer>
    );
  };

  // Redirect back to all attempts if the path does not include an existing taker uuid
  if (takerData === 'invalid') {
    return <Redirect to={`${paths.ADMIN}/${assessment}`} />;
  }

  const handlePortfolioTimeline = () => {
    if (
      takerData.section_attempts.some(x => x.section_type === 'section_stock')
    ) {
      return <OverallPortfolioTimeline currentUuid={currentUuid} />;
    }
  };

  const combinedData = new Map();

  const getCandidateDetailsArray = () => {
    taker_fields.forEach(function(takerField) {
      combinedData.set(takerField.id, takerField);
    });

    return takerData?.taker_field_responses
      .filter(candidateDetails => {
        // this handles cases where we have a response, but no associated field
        // for example, when a field existed and is later deleted or edited
        const takerField = combinedData.get(candidateDetails.taker_field_id);

        return takerField;
      })
      .map(function(response) {
        const takerField = combinedData.get(response.taker_field_id);

        if (takerField?.field_type === takerFieldTypes.SELECT) {
          response.options.forEach(option => {
            takerField.options.forEach(takerFieldOption => {
              if (option.id === takerFieldOption.id) {
                takerField.result = takerFieldOption.content[0].text_display;
              }
            });
          });
        } else if (takerField?.field_type === takerFieldTypes.TEXT) {
          takerField.result = response.result;
        }

        return takerField;
      });
  };

  const candidateDetailsArray = getCandidateDetailsArray();

  const filterForName = () =>
    candidateDetailsArray?.filter(
      candidateDetails =>
        candidateDetails.content[0].title === 'Full Name' &&
        candidateDetails.result
    );

  const nameData = filterForName();

  const filterCandidateDetailsArray = () =>
    candidateDetailsArray?.filter(
      candidateDetails =>
        !(
          candidateDetails.content[0].title === 'Full Name' ||
          !candidateDetails.result
        )
    );

  const filteredData = filterCandidateDetailsArray();

  const renderTakerFields = () => {
    return (
      <>
        <FlexContainer>
          <CandidateDetails
            candidateDetailsData={filteredData}
          ></CandidateDetails>
        </FlexContainer>
        <Separator />
      </>
    );
  };

  return isLoading ? (
    <Loader />
  ) : (
    <>
      {takerData && (
        <FlexContainer direction="column">
          {!isAnonymous && (
            <FlexContainer
              margin="0 0 32px"
              className="pointer"
              onClick={() => history.goBack()}
              width="fit-content"
              align="center"
            >
              {leftArrowIcon}
              <Body1 margin="3px 0 0" textTransform="capitalize">
                back
              </Body1>
            </FlexContainer>
          )}
          <TakerAtAGlance takerData={takerData} candidateName={nameData} />
          <Separator />
          {renderTakerFields()}
          <DistributionTable
            attemptScore={takerData.weighted_score}
            chartData={chartData}
          />
          {handlePortfolioTimeline()}
          {renderOverallResults()}
          {renderSectionResults()}
        </FlexContainer>
      )}
    </>
  );
};

TakerResults.propTypes = {
  chartData: PropTypes.object.isRequired,
  setChartData: PropTypes.func.isRequired
};

export default TakerResults;
