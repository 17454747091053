import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FlexContainer } from 'components/Atoms/Containers';
import { H6 } from 'components/Atoms/Text';
import closeIcon from 'assets/closeIcon';

const Modal = ({ children, className, onClose, title, showCloseIcon }) => {
  const modalRef = useRef(null);
  return (
    <Overlay
      data-testid="modal-overlay"
      className={className}
      onClick={() => onClose()}
    >
      <PopUp
        data-testid="modal-content"
        ref={modalRef}
        onClick={ev => ev.stopPropagation()}
      >
        <FlexContainer align="center" justify="space-between">
          <Title>{title}</Title>
          {showCloseIcon && (
            <CloseBtn
              className="close-btn"
              data-testid="modal-close-icon"
              onClick={onClose}
            >
              {closeIcon}
            </CloseBtn>
          )}
        </FlexContainer>
        {children}
      </PopUp>
    </Overlay>
  );
};

export default Modal;

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  showCloseIcon: PropTypes.bool
};

Modal.defaultProps = {
  className: '',
  title: '',
  showCloseIcon: true
};

const Overlay = styled.div`
  background: rgba(189, 197, 200, 0.8);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 100;
`;

const PopUp = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  margin: 50px auto;
  max-width: 784px;
  min-height: 280px;
  max-height: 600px;
  opacity: 1;
  overflow: auto;
  padding: 40px;
`;

const Title = styled(H6)`
  font-weight: normal;
`;

const CloseBtn = styled.button`
  background: ${({ theme }) => theme.colors.white};
  border: 0;
  cursor: pointer;
  margin: 0;
  outline: 0;
  padding: 0;
  > svg {
    fill: #6a7f87;
    height: 14px;
    width: 14px;
  }
`;
