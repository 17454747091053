import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import styled from 'styled-components';
import searchIcon from 'assets/searchIcon';
import { useClickedOutsideEvent } from 'hooks/useClickedOutsideEvent';
import { useInputFocus } from 'pages/PanelPage/hooks/useInputFocus';
import {
  Input,
  InputLabel,
  InputDescriptor,
  InputUnderlineInner,
  InputUnderlineOuter,
  InputWrapper,
  InputHolder,
  SearchImage
} from 'pages/PanelPage/styles/StyledComponents/StyledComponents';

const Search = ({
  defaultValue,
  description,
  disabled,
  label,
  name,
  placeholder,
  setSearchInputValue
}) => {
  const { focus, onFocus, onBlur } = useInputFocus();
  const searchRef = useRef(null);
  useClickedOutsideEvent(searchRef, onBlur);

  return (
    <SearchWrapper className={disabled && 'disabled'} ref={searchRef}>
      {label && <InputLabel>{label}</InputLabel>}
      {description && <InputDescriptor>{description}</InputDescriptor>}
      <InputHolder height="32px" width="329px">
        <SearchImage>{searchIcon}</SearchImage>
        <SearchInput
          defaultValue={defaultValue}
          disabled={disabled}
          name={name}
          onFocus={onFocus}
          placeholder={placeholder}
          onKeyDown={e =>
            e.key === 'Enter' && setSearchInputValue(e.target.value)
          }
        />
        <InputUnderlineOuter>
          <InputUnderlineInner
            className={focus ? 'focus' : 'unfocus'}
          ></InputUnderlineInner>
        </InputUnderlineOuter>
      </InputHolder>
    </SearchWrapper>
  );
};

export default Search;

Search.propTypes = {
  defaultValue: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  setSearchInputValue: PropTypes.func.isRequired
};

Search.defaultProps = {
  defaultValue: '',
  description: '',
  disabled: false,
  label: '',
  placeholder: 'Search'
};

const SearchWrapper = styled(InputWrapper)`
  margin-bottom: 0;
`;

const SearchInput = styled(Input)`
  border: none;
  height: 100%;
  padding: 9px 16px 9px 38px;
`;
