import styled from 'styled-components';
import { handleButtonHover } from 'utils/helpers/handleButtonHover';

const StyledButton = styled.button`
  align-items: center;
  background: ${({ background, theme }) => background || theme.tenantAccent};
  border: none;
  border-radius: 2px;
  color: ${({ color, theme }) => color || theme.colors.white};
  cursor: pointer;
  display: flex;
  font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
  font-size: 14px;
  font-weight: 900;
  height: 32px;
  justify-content: center;
  letter-spacing: 1.25px;
  outline: none;
  padding: 3px 32px 0;
  text-transform: ${({ textTransform }) => textTransform || 'uppercase'};
  transition: 300ms all ease-out;
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : 'fit-content')};
  &:hover {
    background: ${({ background, theme }) =>
      handleButtonHover(background || theme.tenantAccent)};
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    background: ${({ theme }) => theme.colors.black};
    cursor: not-allowed;
  }
  &.silent {
    &:disabled {
      background: ${({ background, theme }) =>
        background || theme.tenantAccent};
      cursor: default;
    }
  }
`;

// Export Container
export { StyledButton };
