import indexToLetter from './indexToLetter';

const getCorrectAnswerOption = options => {
  return options.reduce(
    (correct, option, i) =>
      option.value > 0
        ? [{ ...option, correctLetter: indexToLetter(i) }]
        : correct,
    [{}]
  );
};

export default getCorrectAnswerOption;
