import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { FlexContainer, MaxWidthContainer } from 'components/Atoms/Containers';

const ChartSkeletonLoader = () => (
  <MaxWidthContainer>
    <FlexContainer>
      <DonutSkeleton>
        <Skeleton />
      </DonutSkeleton>
      <SkeletonPiece>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </SkeletonPiece>
      <SkeletonPiece>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </SkeletonPiece>
    </FlexContainer>
  </MaxWidthContainer>
);

export default ChartSkeletonLoader;

const SkeletonPiece = styled.div`
  height: 30px;
  margin: 15px;
  max-width: 500px;
  width: 33.33%;

  span {
    height: 100%;
    margin-bottom: 5px;
  }
`;

const DonutSkeleton = styled(SkeletonPiece)`
  bottom: 25px;
  border-radius: 50%;
  height: 200px;
  left: 18px;
  margin-right: 60px;
  position: relative;
  width: 200px;
  span {
    height: 200px;
    border-radius: 50%;
    width: 200px;
  }
  > span::before {
    background: ${({ theme }) => theme.colors.white};
    border-radius: 50%;
    content: '';
    height: 100px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    z-index: 10;
  }
`;
