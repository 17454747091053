const endpoints = Object.freeze({
  login: {
    endpoint: 'authentication/login',
    method: 'post'
  },
  publicAccess: {
    endpoint: 'authentication/public_access',
    method: 'post'
  },
  getAssessments: {
    endpoint: `assessment`,
    method: 'get'
  },
  getAssessmentBySlug: slug => ({
    endpoint: `assessment/slug/${slug}`,
    method: 'get'
  }),
  getAssessmentPreviewData: previewUuid => ({
    endpoint: `assessment/preview/${previewUuid}`,
    method: 'get'
  }),
  getPoolsByAssessment: slug => ({
    endpoint: `assessment/slug/${slug}/pools`,
    method: 'get'
  }),
  getQuestionList: {
    endpoint: 'question',
    method: 'get'
  },
  createAssessment: {
    endpoint: 'assessment',
    method: 'post'
  },
  uploadLogo: {
    endpoint: 'configuration/logo',
    method: 'post'
  },
  updateAssessmentConfig: {
    endpoint: 'configuration/update_assessment_config',
    method: 'put'
  },
  updateAssessment: assessmentId => ({
    endpoint: `assessment/${assessmentId}`,
    method: 'put'
  }),
  updateAssessmentMetadata: assessmentId => ({
    endpoint: `assessment/${assessmentId}/update-metadata`,
    method: 'post'
  }),
  checkAssessmentBySlug: slug => ({
    endpoint: `proctoring/${slug}`,
    method: 'get'
  }),
  getAssessmentsCount: {
    endpoint: `assessment/count`,
    method: 'get'
  },
  getTenants: {
    endpoint: 'identity/tenant',
    method: 'get'
  },
  getUserData: {
    endpoint: 'identity/tenant-user',
    method: 'get'
  },
  requestResetPassword: {
    endpoint: 'identity/tenant-user/password/request-reset',
    method: 'post'
  },
  resetPassword: {
    endpoint: 'identity/tenant-user/password/reset',
    method: 'post'
  },
  getAssessmentCategoryStatistics: assessmentId => ({
    endpoint: `assessment/${assessmentId}/statistics/category`,
    method: 'get'
  }),
  getAssessmentPreviewUuid: assessmentId => ({
    endpoint: `assessment/${assessmentId}/get-preview`,
    method: 'get'
  }),
  getAssessmentTemplate: slug => ({
    endpoint: `template/${slug}`,
    method: 'get'
  }),
  createAssessmentTemplate: slug => ({
    endpoint: `template/${slug}`,
    method: 'post'
  }),
  createInvitations: slug => ({
    endpoint: `proctoring/invite/${slug}`,
    method: 'post'
  })
});

export default endpoints;
