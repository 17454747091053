import { useCallback, useEffect } from 'react';
import { requestHelper } from 'utils/requests/requestHelper';
import { requests } from 'utils/requests/requests';

const cache = {};

const useFetchTenants = setTenants => {
  const fetchTenants = useCallback(async () => {
    if (cache?.data) {
      setTenants(cache.data);
      return;
    }
    try {
      await requestHelper(
        requests().getTenants,
        {},
        response => {
          const tenantWithValue = response.data.data.map(tenant => ({
            id: tenant.id,
            value: tenant.name
          }));
          setTenants(tenantWithValue);
          cache.data = tenantWithValue;
        },
        errorResponse => console.error(errorResponse)
      );
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchTenants();
  }, [fetchTenants]);
};

export default useFetchTenants;
