import axios from 'axios';

const tokenInstance = axios.create();

tokenInstance.interceptors.request.use(
  config => {
    const access = localStorage.getItem('access');

    if (access) {
      config.headers['Authorization'] = 'Bearer ' + access;
    }
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  error => Promise.reject(error)
);

tokenInstance.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refresh = localStorage.getItem('refresh');
      localStorage.removeItem('access');
      if (refresh) {
        return axios
          .post(
            `${process.env.REACT_APP_API_URL}/api/v1/authentication/refresh`,
            {},
            { headers: { Authorization: `Bearer ${refresh}` } }
          )
          .then(response => {
            if (response.status === 200 || response.status === 201) {
              localStorage.setItem('access', response.data.data.access_token);

              return tokenInstance(originalRequest);
            }
          });
      }
    }
    return Promise.reject(error);
  }
);

export const requestHelper = async (
  config,
  body = null,
  onSuccess = null,
  onError = () => {
    throw new Error('Something went wrong');
  }
) => {
  const url = `${process.env.REACT_APP_API_URL}/api/v1/${config.endpoint}`;
  const { method, params, headers } = config;
  try {
    let response;
    if (method === 'get') {
      response = await tokenInstance[method](url, params ? { params } : null);
    } else {
      response = await tokenInstance[method](
        url,
        body,
        headers && { ...headers }
      );
    }
    return onSuccess && onSuccess(response);
  } catch (error) {
    return onError && onError(error);
  }
};
