import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useClickedOutsideEvent } from 'hooks/useClickedOutsideEvent';
import { Container, FlexContainer } from 'components/Atoms/Containers';
import { Body1 } from 'components/Atoms/Text';

const ProfileOptionsMenu = ({ label, options }) => {
  const [toggle, setToggle] = useState(false);
  const dropdownRef = useRef(null);

  useClickedOutsideEvent(dropdownRef, () => setToggle(false));

  const renderChildren = () => {
    return options.map(({ text, onClick }) => {
      return (
        <Option id={text} key={text} onClick={onClick}>
          {text}
        </Option>
      );
    });
  };

  return (
    <FlexContainer
      align="center"
      className="pointer"
      onClick={() => setToggle(true)}
      ref={dropdownRef}
    >
      <DropdownLabel>{label}</DropdownLabel>
      <ImageContainer />
      {toggle && <DropdownOptions>{renderChildren()}</DropdownOptions>}
    </FlexContainer>
  );
};

ProfileOptionsMenu.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired
};

const DropdownLabel = styled(Body1)`
  color: ${({ theme }) => theme.colors.white};
  padding: 2px 0 0;
`;

const Option = styled.li`
  appearance: none;
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.4px;
  list-style: none;
  padding: 8px;
  transition: all ease-in-out 200ms;
  word-wrap: break-word;
  &:hover {
    background: #f3f2f2;
  }
`;

const DropdownOptions = styled.ul`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  box-shadow: 1px 2px 6px 0px rgba(0, 0, 0, 0.28);
  padding: 8px;
  position: absolute;
  right: 0;
  top: 16px;
  transition: all ease-in-out 200ms;
  width: 130px;
  z-index: 10;
`;

const ImageContainer = styled(Container)`
  background: rgba(255, 255, 255, 0.27);
  border-radius: 100%;
  height: 24px;
  margin-left: 8px;
  width: 24px;
`;

export default ProfileOptionsMenu;
