import styled from 'styled-components';

const BaseText = styled.div`
  border-bottom: ${({ borderBottom }) => borderBottom};
  color: ${({ color }) => color};
  margin: ${({ margin }) => margin || 0};
  padding: ${({ padding }) => padding || 0};
  text-align: ${({ textAlign }) => textAlign};
  text-transform: ${({ textTransform }) => textTransform};
  &.bold {
    font-weight: 900;
  }
  &.pointer {
    cursor: pointer;
  }
`;

const H3 = styled(BaseText).attrs({
  as: 'h3'
})`
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.4px;
`;

const H4 = styled(BaseText).attrs({
  as: 'h4'
})`
  font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0.21px;
  line-height: normal;
`;

const H6 = styled(BaseText).attrs({
  as: 'h6'
})`
  font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 0.15px;
  line-height: normal;
`;

const Body1 = styled(BaseText).attrs({
  as: 'p'
})`
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.25px;
  line-height: normal;
`;

const Body2 = styled(BaseText).attrs({
  as: 'p'
})`
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: normal;
`;

const Body3 = styled(BaseText).attrs({
  as: 'p'
})`
  font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
  font-size: 10px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.29px;
`;

const Overline = styled(BaseText).attrs({
  as: 'p'
})`
  font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1.5px;
  line-height: 16px;
  text-decoration: underline;
  text-decoration-color: ${({ color }) => color};
  text-transform: uppercase;
`;

const Caption = styled(BaseText).attrs({
  as: 'p'
})`
  font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.4px;
  line-height: 16px;
`;

const Subtitle = styled(BaseText).attrs({
  as: 'p'
})`
  font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: normal;
`;

const Subtitle2 = styled(BaseText).attrs({
  as: 'p'
})`
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: normal;
`;
const Label = styled.div`
  color: ${({ color }) => color || 'rgb(147, 147, 147)'};
  font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
  font-size: ${({ fontSize }) => fontSize || '10px'};
  font-weight: ${({ fontWeight }) => fontWeight || '900'};
  letter-spacing: ${({ letterSpacing }) => letterSpacing || '0.89px'};
  line-height: ${({ lineHeight }) => lineHeight};
  text-transform: ${({ textTransform }) => textTransform};
`;

const TruncateTextByLines = styled.span`
  /* stylelint-disable */
  display: -webkit-box;
  -webkit-line-clamp: ${({ lines }) => lines};
  -webkit-box-orient: vertical;
  /* stylelint-enable */
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: ${({ isSingleWord }) => isSingleWord && 'break-all'};
`;

// Export text
export {
  H3,
  H4,
  H6,
  Body1,
  Body2,
  Body3,
  Overline,
  Caption,
  Subtitle,
  Subtitle2,
  Label,
  TruncateTextByLines
};
