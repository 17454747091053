import React from 'react';
import { questionTypes } from 'pages/App/helpers/questionTypes';
import Question from 'pages/PanelPage/components/selectedAssessment/assessmentResults/takerResults/questionsTable/Question';
import { Cell, BorderTable, BorderRow } from 'components/Blocks/Styled/Tables';
import { Body1 } from 'components/Atoms/Text';

const QuestionsTable = ({ slotAttempts }) => {
  const renderQuestions = () => {
    // Filter out question_stock
    const filteredAttempts = slotAttempts.filter(
      attempt => attempt.question_type !== questionTypes.STOCK
    );

    return filteredAttempts.map((attempt, index) => {
      const formattedTimeTaken =
        !isNaN(attempt.time_taken_seconds) &&
        `${new Date(attempt.time_taken_seconds * 1000)
          .toISOString()
          .substr(14, 2)}m${new Date(attempt.time_taken_seconds * 1000)
          .toISOString()
          .substr(17, 2)}s
      `;
      return (
        <Question
          attempt={attempt}
          formattedTimeTaken={formattedTimeTaken}
          index={index}
          key={attempt.id}
        />
      );
    });
  };

  return (
    <BorderTable borderCollapse="collapse">
      <thead>
        <BorderRow>
          <Cell padding="12px 27px 8px">
            <Body1 className="bold" textTransform="capitalize" color="#212629">
              questions
            </Body1>
          </Cell>
          <Cell padding="12px 27px 8px" textAlign="right">
            <Body1 className="bold" textTransform="capitalize" color="#212629">
              all questions
            </Body1>
          </Cell>
        </BorderRow>
      </thead>
      {renderQuestions()}
    </BorderTable>
  );
};

export default QuestionsTable;
