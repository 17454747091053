import React, { useState, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import Navbar from 'pages/PanelPage/components/Navbar';
import QuestionList from 'pages/QuestionPage/QuestionList';
import SelectQuestionType from 'pages/QuestionPage/SelectQuestionType';
import { PageWrapper } from 'components/Blocks/Styled/Wrappers';
import { PageContentContainer } from 'components/Atoms/Containers';
import {
  VALID_STATES as VS,
  QUESTION_STATES
} from 'utils/constants/stateTypes';
import {
  FREE_RESPONSE,
  MULTIPLE_CHOICE,
  questionLibraryPath
} from 'pages/QuestionPage/Constants';

const QuestionLibrary = () => {
  const { pathname } = useLocation();
  const [stateList, setStateList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedQuestionType, setSelectedQuestionType] = useState(null);
  const [editingQuestionId, setEditingQuestionId] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState('');

  useEffect(() => {
    const handleFilters = () => {
      switch (pathname) {
        case `${questionLibraryPath}/${VS.ALL}`:
          setStateList(QUESTION_STATES);
          setSelectedFilter(VS.ALL);
          break;
        case `${questionLibraryPath}/${VS.ALL}/${VS.ACTIVE}`:
          setStateList([VS.ACTIVE]);
          setSelectedFilter(VS.ALL);
          break;
        case `${questionLibraryPath}/${VS.ALL}/${VS.DRAFT}`:
          setStateList([VS.DRAFT]);
          setSelectedFilter(VS.ALL);
          break;
        case `${questionLibraryPath}/${VS.ALL}/${VS.RETIRED}`:
          setStateList([VS.RETIRED]);
          setSelectedFilter(VS.ALL);
          break;
        case `${questionLibraryPath}/${VS.INACTIVE}`:
          setStateList([VS.DRAFT, VS.RETIRED]);
          setSelectedFilter(VS.INACTIVE);
          break;
        case `${questionLibraryPath}/${VS.INACTIVE}/${VS.DRAFT}`:
          setStateList([VS.DRAFT]);
          setSelectedFilter(VS.INACTIVE);
          break;
        case `${questionLibraryPath}/${VS.INACTIVE}/${VS.RETIRED}`:
          setStateList([VS.RETIRED]);
          setSelectedFilter(VS.INACTIVE);
          break;
        case `${questionLibraryPath}/${VS.ACTIVE}`:
          setStateList([VS.ACTIVE]);
          setSelectedFilter(VS.ACTIVE);
          break;
        default:
          break;
      }
    };
    handleFilters();
  }, [pathname]);

  const getCreationForm = action => {
    const redirectProps = {
      pathname: `${questionLibraryPath}/${action}-question${
        editingQuestionId ? `/${editingQuestionId}` : ''
      }`,
      questionType: selectedQuestionType
    };
    return <Redirect push to={redirectProps} />;
  };

  return (
    <PageWrapper background="#f0f2f4">
      <Navbar
        isQuestionLibraryPage
        selectedFilter={selectedFilter}
        setStateList={setStateList}
        setSelectedFilter={setSelectedFilter}
      />
      <PageContentContainer>
        <QuestionList
          showSelectQuestionType={() => setShowModal(true)}
          setEditingQuestionId={setEditingQuestionId}
          stateList={stateList}
          setStateList={setStateList}
          selectedFilter={selectedFilter}
          shouldFetchAssessmentsByQuestion
        />
        {showModal && (
          <SelectQuestionType
            freeResponseClickHandler={() =>
              setSelectedQuestionType(FREE_RESPONSE)
            }
            multipleChoiceQuestionHandler={() =>
              setSelectedQuestionType(MULTIPLE_CHOICE)
            }
            onClose={() => setShowModal(false)}
          />
        )}
        {selectedQuestionType && getCreationForm('create')}
        {editingQuestionId && getCreationForm(VS.EDIT)}
      </PageContentContainer>
    </PageWrapper>
  );
};

export default QuestionLibrary;
