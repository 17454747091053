import React from 'react';

const cancelIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g stroke="#FFF" strokeWidth="2" transform="translate(-91 -589)">
        <g transform="translate(91 90)">
          <g transform="translate(0 374)">
            <g transform="translate(0 36)">
              <g transform="translate(0 89)">
                <circle cx="8" cy="8" r="7" fill="#D64E4C"></circle>
                <path
                  fill="#C4C4C4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.667 8L10.667 8"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default cancelIcon;
