import React from 'react';

const getLinkIcon = (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-985.000000, -216.000000)">
        <g transform="translate(985.000000, 211.000000)">
          <g transform="translate(0.000000, 5.000000)">
            <circle fill="#575766" cx="8" cy="8" r="8"></circle>
            <g
              transform="translate(4.000000, 4.000000)"
              fill="#FFFFFF"
              fillRule="nonzero"
            >
              <path
                d="M2.96167187,2.01401562 C2.01945312,2.988875 2.2250625,4.6066875 
                3.30435937,5.32335937 C3.33992187,5.34698437 
                3.38723437,5.34229687 3.41778125,5.31245312 C3.645,5.0904375 
                3.83721875,4.87539062 4.00553125,4.60189062 C4.03128125,4.56004687 
                4.01526562,4.50575 3.9720625,4.48234375 C3.8074375,4.39315625 3.643625,4.22592187 
                3.55140625,4.04926562 L3.55129687,4.04932812 C3.44082812,3.82935937 
                3.40321875,3.58278125 3.46170312,3.328125 C3.46176562,3.32814063 
                3.46182812,3.32815625 3.46189062,3.32815625 C3.5291875,3.002125 
                3.87920312,2.69884375 4.14653125,2.41835938 C4.14596875,2.41817188 
                4.14542187,2.41796875 4.14485937,2.41778125 L5.14646875,1.3955 
                C5.545625,0.988109375 6.2021875,0.98475 6.60548437,1.38804688 
                C7.01285937,1.7871875 7.01959375,2.4470625 6.62045312,2.8544375 
                L6.01376562,3.4783125 C5.9856875,3.5071875 5.97657812,3.5493125 
                5.98971875,3.58739062 C6.12940625,3.99246875 6.16376562,4.56364062 
                6.07015625,4.99517187 C6.06753125,5.00725 6.0824375,5.01515625 
                6.09107812,5.00632812 L7.38229687,3.68845313 C8.20715625,2.84657813 
                8.20015625,1.47589063 7.36675,0.642484375 C6.51623437,-0.20803125 
                5.131625,-0.200953125 4.28984375,0.6581875 L2.96684375,2.00848438 
                C2.96509375,2.01032813 2.9634375,2.01220313 2.96167187,2.01401563 
                L2.96167187,2.01401562 Z"
              ></path>
              <path
                d="M5.37559375,5.509 C5.37557813,5.50904687 5.37554688,5.50909375 
                5.37553125,5.50914063 C5.37635937,5.50879688 5.377125,5.50846875 
                5.37795312,5.50810938 C5.64146875,5.02625 5.69334375,4.47360938 
                5.56984375,3.934875 L5.56928125,3.93545313 L5.56867187,3.9351875 
                C5.45140625,3.45535938 5.12964062,2.97889063 4.69264062,2.68553125 
                C4.65504687,2.66029688 4.595,2.66321875 4.55979687,2.6916875 
                C4.3384375,2.87067188 4.12176562,3.1001875 3.97879687,3.39307813 
                C3.95634375,3.4390625 3.97315625,3.49429688 4.01740625,3.51998438 
                C4.18332812,3.6163125 4.33317187,3.75734375 4.4335,3.94457813 
                L4.43365625,3.94446875 C4.51184375,4.07673438 4.58889062,4.32770313 
                4.53898437,4.59735938 C4.53895312,4.59735938 4.53890625,4.59735938 
                4.538875,4.59735938 C4.4923125,4.954875 4.13128125,5.2828125 3.84428125,5.5783125 
                L3.84442187,5.57845313 C3.62595312,5.80185938 3.07059375,6.36801563 
                2.84821875,6.59520313 C2.44907812,7.00257813 1.78920312,7.0093125 
                1.38182812,6.61017188 C0.974453125,6.21103125 0.96771875,5.55115625 
                1.36685937,5.14378125 L1.97534375,4.51803125 C2.00292187,4.48967188 
                2.01229687,4.44845313 2.00003125,4.41084375 C1.86492187,3.99629688 
                1.82790625,3.438 1.9131875,3.00695313 C1.9155625,2.9949375 
                1.90078125,2.98726563 1.89220312,2.99601563 L0.62040625,4.2940625 
                C-0.21290625,5.14457813 -0.20584375,6.52932813 0.636125,7.37128125 
                C1.48659375,8.2045625 2.86420312,8.19048438 3.69748437,7.34001563 
                C3.98696875,7.01620313 5.22615625,5.868875 5.37559375,5.509 
                L5.37559375,5.509 Z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default getLinkIcon;
