import {
  MULTIPLE_CHOICE,
  QUESTION_BACKEND_TYPES
} from 'pages/QuestionPage/Constants';

const getQuestionMaxScore = question =>
  question.question_type === QUESTION_BACKEND_TYPES[MULTIPLE_CHOICE]
    ? Math.max(...question.options.map(({ value }) => value))
    : Math.max(...question.bands.map(({ score }) => score));

export default getQuestionMaxScore;
