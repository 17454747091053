import makeRequest from 'utils/requests/makeRequest';
import endpointNames from 'utils/constants/endpointNames';

export const getAssessmentsCountMW = async ({ queryParams }) => {
  try {
    return await makeRequest({
      endpoint: endpointNames.getAssessmentsCount,
      queryParams
    });
  } catch (err) {
    console.error(err);
  }
};

export const getTenantsMW = async () => {
  try {
    const response = await makeRequest({
      endpoint: endpointNames.getTenants
    });
    return response.data.map(tenant => ({
      id: tenant.id,
      value: tenant.name
    }));
  } catch (err) {
    console.error(err);
  }
};

export const getAssessmentsMW = async ({ queryParams }) => {
  try {
    return await makeRequest({
      endpoint: endpointNames.getAssessments,
      queryParams
    });
  } catch (err) {
    console.error(err);
  }
};

export const getAssessmentBySlugMW = async ({ urlParams }) => {
  try {
    const result = await makeRequest({
      endpoint: endpointNames.getAssessmentBySlug,
      urlParams
    });
    return result;
  } catch (err) {
    console.error(err);
  }
};

export const getAssessmentCategoryStatisticsMW = async ({ urlParams }) => {
  try {
    const result = await makeRequest({
      endpoint: endpointNames.getAssessmentCategoryStatistics,
      urlParams
    });
    return result;
  } catch (err) {
    console.error(err);
  }
};

export const getAssessmentPreviewUuidMW = async ({ urlParams }) => {
  try {
    const result = await makeRequest({
      endpoint: endpointNames.getAssessmentPreviewUuid,
      urlParams
    });
    return result;
  } catch (err) {
    console.error(err);
  }
};

export const getInviteTemplateMW = async ({ urlParams }) => {
  try {
    const result = await makeRequest({
      endpoint: endpointNames.getAssessmentTemplate,
      urlParams
    });
    return result;
  } catch (err) {
    console.error(err);
  }
};

export const saveDefaultTemplateMW = async ({ urlParams, data }) => {
  try {
    const result = await makeRequest({
      endpoint: endpointNames.createAssessmentTemplate,
      urlParams,
      data
    });
    return result;
  } catch (err) {
    console.error(err);
  }
};

export const sendInvitationsMW = async ({ urlParams, data }) => {
  try {
    const result = await makeRequest({
      endpoint: endpointNames.createInvitations,
      urlParams,
      data
    });
    if (result?.status === 200) {
      return result;
    }
  } catch (err) {
    console.error(err);
  }
};
