import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FlexContainer } from 'components/Atoms/Containers';
import { CandidateDetailTitle } from 'pages/ManagementPage/ProctorComponents';
import FieldItem from 'pages/ManagementPage/candiateInfoPreview/FieldItem';

const CandidateInfoPreview = ({ assessmentColorTheme, fields }) => (
  <PreviewContainer align="flex-start" direction="column" justify="center">
    <CandidateDetailTitle data-testid="main-title">
      Candidate Details
    </CandidateDetailTitle>
    <FieldsContainer
      data-testid="fields-container"
      align="flex-start"
      justify="space-between"
      wrap="wrap"
    >
      {fields.map(({ itemName, isMandatory, configuration = {} }, index) => {
        const { fieldOptions } = configuration;
        const isDropdown = Array.isArray(fieldOptions);
        const isOptional = isMandatory === false;
        return (
          <FieldItem
            key={itemName + index}
            assessmentColorTheme={assessmentColorTheme}
            isDropdown={isDropdown}
            isOptional={isOptional}
            itemName={itemName}
            fieldOptions={fieldOptions}
          />
        );
      })}
    </FieldsContainer>
  </PreviewContainer>
);

export default CandidateInfoPreview;

CandidateInfoPreview.propTypes = {
  assessmentColorTheme: PropTypes.string.isRequired,
  fields: PropTypes.array
};

CandidateInfoPreview.defaultProps = {
  fields: []
};

const PreviewContainer = styled(FlexContainer)`
  padding: 32px 48px;
`;

const FieldsContainer = styled(FlexContainer)`
  width: 100%;
`;
