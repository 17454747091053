import PropTypes from 'prop-types';
import React from 'react';
import TakerSummaryPie from 'pages/PanelPage/components/charts/TakerSummaryPie';
import { FlexContainer } from 'components/Atoms/Containers';
import { Row, BorderTable, BorderCell } from 'components/Blocks/Styled/Tables';
import { Body1 } from 'components/Atoms/Text';

const OverallSectionSummaryTable = ({ sectionAttempts }) => {
  const reducer = (accumulator, currentValue) => {
    return {
      weighted_max_score:
        accumulator.weighted_max_score + currentValue.weighted_max_score,
      blank: accumulator.blank + currentValue.blank,
      weighted_score: accumulator.weighted_score + currentValue.weighted_score
    };
  };

  const { weighted_max_score, blank, weighted_score } = sectionAttempts
    .map(attempt => {
      return Object.values(attempt.category_scores).reduce(reducer, {
        weighted_max_score: 0,
        blank: 0,
        weighted_score: 0
      });
    })
    .reduce(reducer, { weighted_max_score: 0, blank: 0, weighted_score: 0 });

  return (
    <BorderTable borderCollapse="collapse">
      <thead>
        <Row>
          <BorderCell padding="12px 27px 8px">
            <Body1 className="bold" color="#212629" textTransform="capitalize">
              summary
            </Body1>
          </BorderCell>
        </Row>
      </thead>
      <tbody>
        <Row>
          <BorderCell>
            <FlexContainer height="474px" width="350px">
              <TakerSummaryPie
                weightMaxScore={weighted_max_score}
                unanswered={blank}
                obtained={weighted_score}
                vertical
              />
            </FlexContainer>
          </BorderCell>
        </Row>
      </tbody>
    </BorderTable>
  );
};

export default OverallSectionSummaryTable;

OverallSectionSummaryTable.propTypes = {
  sectionAttempts: PropTypes.array.isRequired
};
