import PropTypes from 'prop-types';
import React from 'react';
import TakerSkillsBreakdownBar from 'pages/PanelPage/components/charts/TakerSkillsBreakdownBar';
import { FlexContainer } from 'components/Atoms/Containers';
import { Row, BorderCell, BorderTable } from 'components/Blocks/Styled/Tables';
import { Body1 } from 'components/Atoms/Text';

const OverallSkillsBreakdownTable = ({ sectionAttempts }) => {
  const allCategoryScores = sectionAttempts
    .map(attempt => Object.values(attempt.category_scores))
    .flat();

  return (
    <BorderTable borderCollapse="collapse">
      <thead>
        <Row>
          <BorderCell padding="12px 27px 8px">
            <Body1 className="bold" textTransform="capitalize" color="#212629">
              skills breakdown
            </Body1>
          </BorderCell>
        </Row>
      </thead>
      <tbody>
        <Row>
          <BorderCell>
            <FlexContainer height="474px" width="720px">
              <TakerSkillsBreakdownBar categoryScores={allCategoryScores} />
            </FlexContainer>
          </BorderCell>
        </Row>
      </tbody>
    </BorderTable>
  );
};

export default OverallSkillsBreakdownTable;

OverallSkillsBreakdownTable.propTypes = {
  sectionAttempts: PropTypes.arrayOf(PropTypes.object).isRequired
};
