import React from 'react';

const personIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="11"
    viewBox="0 0 11 11"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#939393" fillRule="nonzero" transform="translate(-71 -46)">
        <g transform="translate(71.5 46)">
          <path d="M7.167 6.667a2.722 2.722 0 012.717 2.556l.005.166V10.5a.5.5 0 01-.992.09l-.008-.09V9.389c0-.901-.692-1.64-1.574-1.716l-.148-.006H2.722c-.9 0-1.64.692-1.716 1.573L1 9.39V10.5a.5.5 0 01-.992.09L0 10.5V9.389a2.722 2.722 0 012.556-2.717l.166-.005h4.445z"></path>
          <path d="M4.944 0a2.722 2.722 0 100 5.444 2.722 2.722 0 000-5.444zm0 1a1.722 1.722 0 110 3.444 1.722 1.722 0 010-3.444z"></path>
        </g>
      </g>
    </g>
  </svg>
);

export default personIcon;
