import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const FormErrors = React.memo(({ errors }) => {
  const renderCardsErrors = () => {
    const cardErrors = Object.keys(errors?.CARDS || []);
    return cardErrors.map(error => (
      <StyledItem key={error}>{errors.CARDS[error]}</StyledItem>
    ));
  };

  const renderLandingPagerErrors = () => {
    const landingPageErrors = Object.keys(errors?.LANDING_PAGE || []);
    return landingPageErrors.map(error => (
      <StyledItem key={error}>{errors.LANDING_PAGE[error]}</StyledItem>
    ));
  };

  const renderSectionErrors = () => {
    const sectionErrors = Object.keys(errors?.SECTIONS?.INVALID || []);
    return sectionErrors.map(error => (
      <StyledItem key={error}>{errors?.SECTIONS.INVALID?.[error]}</StyledItem>
    ));
  };

  const renderSlotRandomErrors = () => {
    const slotRandomErrors = Object.keys(errors?.SLOT_RANDOM || []);
    return slotRandomErrors.map(error => (
      <StyledItem key={error}>{errors?.SLOT_RANDOM?.[error]}</StyledItem>
    ));
  };

  const renderSettingsError = () =>
    errors?.SETTINGS && (
      <StyledItem key={errors.SETTINGS}>{errors.SETTINGS}</StyledItem>
    );

  return (
    <StyledList>
      {renderCardsErrors()}
      {renderLandingPagerErrors()}
      {renderSectionErrors()}
      {renderSlotRandomErrors()}
      {renderSettingsError()}
    </StyledList>
  );
});

export default FormErrors;

const StyledList = styled.ul`
  border: ${({ theme }) => `2px solid ${theme.colors.invalidRed}`};
  list-style-type: disclosure-closed;
  list-style-position: inside;
  margin: 8px 0;
  padding: 16px;
`;

const StyledItem = styled.li`
  font-family: ${({ theme }) => theme.fonts.avenirProBlack};
  margin-bottom: 16px;
  text-align: center;
`;

FormErrors.propTypes = {
  errors: PropTypes.object
};

FormErrors.defaultProps = {
  errors: {}
};
