import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import styled from 'styled-components';
import Button from 'components/Blocks/Forms/Button';
import { Container } from 'components/Atoms/Containers';
import 'react-quill/dist/quill.snow.css';
import { dialogBoxArrow } from 'utils/mixins/Mixins';

const InviteBody = ({
  template,
  setBody,
  body,
  sending,
  setBodyHasChanged,
  isValidSaveDefault,
  setShowEmailChanges,
  showBodyChanges,
  handleSaveDefaultTemplate
}) => {
  let quillBodyRef = useRef(null);

  useEffect(() => {
    if (template) {
      const header = document.createElement('div');
      header.setAttribute('class', 'header');
      header.innerHTML = template.formatted_header;
      header.innerHTML += '<div class=divider></div>';

      const detail = document.createElement('div');
      detail.setAttribute('class', 'detail');
      detail.innerHTML = template.formatted_detail;

      const footer = document.createElement('div');
      footer.setAttribute('class', 'footer');
      footer.innerHTML = template.formatted_footer;
      const textEditor = document.getElementsByClassName('ql-container')[0];

      textEditor.prepend(header);
      textEditor.appendChild(detail);
      textEditor.appendChild(footer);

      return () => {
        textEditor.removeChild(header);
        textEditor.removeChild(detail);
        textEditor.removeChild(footer);
      };
    }
  });

  const updateBody = (text, _, source) => {
    if (!quillBodyRef?.getEditor) return;
    const editor = quillBodyRef.getEditor();
    const html = quillBodyRef.makeUnprivilegedEditor(editor).getHTML();
    setBody(html);
    if (source === 'user') {
      const initialBody = template?.formatted_body.replace(/\s+/g, ' ').trim();
      setBodyHasChanged(initialBody !== text);
    }
  };

  // Set toolbar modules
  const modules = {
    toolbar: [
      [
        'bold',
        'italic',
        'underline',
        'strike',
        { list: 'ordered' },
        { list: 'bullet' },
        'link'
      ]
    ]
  };

  // Set toolbar formats
  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'link'
  ];

  return (
    <BodyContainer showChanges={showBodyChanges} width="100%">
      <ReactQuill
        theme={'snow'}
        formats={formats}
        modules={modules}
        value={body || template?.formatted_body || ''}
        ref={element => (quillBodyRef = element)}
        onChange={updateBody}
      />
      <SendButtonContainer>
        <Button
          className="silent"
          disabled={sending || !template}
          loading={sending}
          onClick={updateBody}
          text="SEND"
          type="submit"
        />
      </SendButtonContainer>

      <SettingsContainer>
        <Container>
          <SaveDefaultTextButton
            disabled={!isValidSaveDefault}
            onMouseOver={() => setShowEmailChanges(true)}
            onMouseOut={() => setShowEmailChanges(false)}
            onClick={handleSaveDefaultTemplate}
          >
            Save default text
          </SaveDefaultTextButton>
          <InformationTooltip>
            Make your custom text the default for this email
          </InformationTooltip>
        </Container>
      </SettingsContainer>
    </BodyContainer>
  );
};

export default InviteBody;

InviteBody.propTypes = {
  template: PropTypes.object,
  setBody: PropTypes.func.isRequired,
  body: PropTypes.string,
  sending: PropTypes.bool.isRequired,
  setBodyHasChanged: PropTypes.func.isRequired,
  isValidSaveDefault: PropTypes.bool.isRequired,
  setShowEmailChanges: PropTypes.func.isRequired,
  showBodyChanges: PropTypes.bool.isRequired,
  handleSaveDefaultTemplate: PropTypes.func.isRequired
};

InviteBody.defaultProps = {
  body: '',
  template: {}
};

const SendButtonContainer = styled(Container)`
  bottom: 16px;
  left: 16px;
  position: absolute;
`;

const SettingsContainer = styled(Container)`
  bottom: 16px;
  position: absolute;
  right: 16px;
`;

const SaveDefaultTextButton = styled.button`
  background: 0;
  border: 0;
  color: ${({ disabled }) => (disabled ? '#bfbfbf' : '#367ebe')};
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 24px;
  &:hover {
    + div {
      display: ${({ disabled }) => !disabled && 'block'};
    }
  }
`;

const InformationTooltip = styled.div`
  background: ${({ theme }) => theme.colors.black};
  bottom: calc(100% + 5px);
  color: ${({ theme }) => theme.colors.white};
  display: none;
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 14px;
  left: 50%;
  letter-spacing: 0.4px;
  line-height: 18px;
  padding: 12px 15px 18px;
  position: absolute;
  transform: translateX(-50%);
  width: 205px;
  ${dialogBoxArrow}
`;

const BodyContainer = styled(Container)`
  padding: 8px 16px 0;
  .quill {
    background: ${({ theme }) => theme.colors.white};
    display: flex;
    flex-direction: column-reverse;
    text-align: left;
  }
  .ql-container.ql-snow {
    border: none;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .ql-toolbar.ql-snow {
    border: none;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 16px;
    margin-left: 150px;
    padding: 0;
    padding-bottom: 2px;
  }
  .header {
    font-family: ${({ theme }) => theme.fonts.roboto};
    padding: 12px 15px 0;
    .divider {
      background: #969696;
      height: 2px;
      margin: 15px 0 20px;
      width: 100%;
    }
  }
  .detail {
    font-family: ${({ theme }) => theme.fonts.roboto};
    padding: 0 15px 12px;
    text-align: center;
  }
  .footer {
    background: ${({ theme }) => theme.colors.lightGrey};
    font-family: ${({ theme }) => theme.fonts.roboto};
    font-size: 12px;
    letter-spacing: 0.4px;
    padding: 12px;
    text-align: center;
  }
  .ql-editor {
    background: ${({ showChanges, theme }) =>
      showChanges && theme.colors.skyBlue};
    font-family: ${({ theme }) => theme.fonts.roboto};
    min-height: 50px;
  }
`;
