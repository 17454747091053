import React from 'react';
import styled from 'styled-components';
import { Row, Cell, BorderTable } from 'components/Blocks/Styled/Tables';
import { Body1, Subtitle2 } from 'components/Atoms/Text';

const CandidateDetails = ({ candidateDetailsData }) => {
  return (
    candidateDetailsData.length > 0 && (
      <BorderCollapseTable>
        <thead>
          <Row>
            <HeadlineCell colSpan="2">
              <Body1Headline className="bold">candidate details</Body1Headline>
            </HeadlineCell>
          </Row>
        </thead>
        <tbody>
          {candidateDetailsData.map((detail, i, array) => {
            const isEven = i % 2 === 0;
            const isLast = i === array.length - 1;

            if (isEven && !isLast) {
              return (
                <Row key={detail.id}>
                  <DetailCell>
                    <Subtitle2>{detail.result}</Subtitle2>
                    <Body1CandidateDetail>
                      {detail.content[0].title}
                    </Body1CandidateDetail>
                  </DetailCell>
                  <DetailCell>
                    <Subtitle2>{array[i + 1].result}</Subtitle2>
                    <Body1CandidateDetail>
                      {array[i + 1].content[0].title}
                    </Body1CandidateDetail>
                  </DetailCell>
                </Row>
              );
            } else if (isEven && isLast) {
              return (
                <Row key={detail.id}>
                  <LastDetailCell colSpan="2">
                    <Subtitle2>{detail.result}</Subtitle2>
                    <Body1CandidateDetail>
                      {detail.content[0].title}
                    </Body1CandidateDetail>
                  </LastDetailCell>
                </Row>
              );
            } else {
              return null;
            }
          })}
        </tbody>
      </BorderCollapseTable>
    )
  );
};

export default CandidateDetails;

const Body1CandidateDetail = styled(Body1)`
  color: ${({ theme }) => `1px solid ${theme.colors.slateGrey}`};
  padding: 6px 0 0;
  text-transform: capitalize;
`;

const Body1Headline = styled(Body1)`
  color: ${({ theme }) => `1px solid ${theme.colors.gunMetal}`};
  text-transform: capitalize;
  width: 50%;
`;

const BorderCollapseTable = styled(BorderTable)`
  border-collapse: collapse;
`;

const DetailCell = styled(Cell)`
  border: ${({ theme }) => `1px solid ${theme.colors.iron}`};
  padding: 16px 24px;
  text-align: left;
  width: 50%;
`;

const HeadlineCell = styled(Cell)`
  border-top: ${({ theme }) => `1px solid ${theme.colors.iron}`};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.iron}`};
  padding: 16px 24px;
`;

const LastDetailCell = styled(Cell)`
  border: ${({ theme }) => `1px solid ${theme.colors.iron}`};
  padding: 16px 24px;
  text-align: left;
`;
