import React, { useState, useRef } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Dropdown from 'components/Blocks/Dropdown';
import { FlexContainer } from 'components/Atoms/Containers';
import { FormInput } from 'components/Blocks/ManagementForm';
import Modal from 'components/Systems/Modal';
import ToggleSwitch from 'pages/ManagementPage/questionSection/ToggleSwitch';
import OptionTooltip from 'components/Blocks/OptionTooltip';
import RadioButton from 'components/Atoms/RadioButton';
import CalendarPicker from 'components/Blocks/CalendarPicker';
import CategoryWeightsForm from 'pages/ManagementPage/CategoryWeightsForm';
import HoursDropdown from 'components/Blocks/HoursDropdown';
import { handleButtonHover } from 'utils/helpers/handleButtonHover';
import validateSlugUniqueness from 'pages/ManagementPage/utils/validateSlugUniqueness';
import { removeNumericInputSpinners } from 'utils/mixins/Mixins';
import {
  ASSESSMENT_TYPES,
  EXPIRATION_TYPES,
  EXPIRATION_UNITS
} from 'pages/ManagementPage/Constants';
import { EMAIL_REGEX } from 'pages/App/constants/constants';
import { useClickedOutsideEvent } from 'hooks/useClickedOutsideEvent';

const EMAIL_SENDER_DOMAIN = '@correlation1.com';

const SettingsModal = ({
  defaultSettings,
  onCancel,
  onSave,
  categoriesWeightData,
  isEditing,
  isValidSlug,
  setWasWeightsCustomized
}) => {
  const {
    assessmentName,
    assessmentSlug,
    selectedType: assessmentType,
    emailSender,
    expirationNumber,
    expirationUnit,
    notificationSender,
    assessmentColorTheme,
    isQuestionPoolEnabled,
    expirationType: expType,
    expirationDate,
    expirationHour: expHour,
    attemptLimit
  } = defaultSettings;

  const { defaultCategoriesWeight, reconciledWeights } = categoriesWeightData;
  const [selectedType, setSelectedType] = useState(assessmentType);
  const [editWeighting, setEditWeighting] = useState(false);
  const [expirationType, setExpirationType] = useState(expType);
  const [selectedExpirationDate, setSelectedExpirationDate] = useState(
    expirationDate
  );
  const [selectedExpirationHour, setSelectedExpirationHour] = useState(expHour);
  const [shouldCloseCalendar, setshouldCloseCalendar] = useState(false);
  const [selectedExpirationUnit, setSelectedExpirationUnit] = useState(
    expirationUnit
  );
  const [showEmailToNotify, setShowshowEmailToNotify] = useState(
    Boolean(notificationSender)
  );
  const [questionPoolIsEnabled, setquestionPoolIsEnabled] = useState(
    Boolean(isQuestionPoolEnabled)
  );
  const [isAttemptLimited, setIsAttemptLimited] = useState(
    Boolean(attemptLimit)
  );
  const [colorTheme, setColorTheme] = useState(assessmentColorTheme);

  const pickerRef = useRef(null);

  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit'
  });

  const { handleSubmit, setValue } = methods;

  useClickedOutsideEvent(pickerRef, () => setshouldCloseCalendar(true));

  const handleResetValues = event => {
    event.preventDefault();
    const defaultValues = Object.keys(defaultCategoriesWeight).map(id => ({
      [defaultCategoriesWeight[id].id]: defaultCategoriesWeight[id].weight
    }));
    setValue(defaultValues);
  };

  const handleTypeChange = (type, event) => {
    event.preventDefault();
    setSelectedType(type);
  };

  const formID = 'settings-form';

  const customEmailValidation = email => {
    const re = EMAIL_REGEX;
    return re.test(email.toLowerCase());
  };

  const customExpirationDateValidation = () => {
    const date = new Date(selectedExpirationDate);
    const today = new Date();
    const hours = parseInt(selectedExpirationHour.substring(0, 2));
    date.setHours(hours, 0, 0);
    const isValidExpirationDate = date > today;
    return isValidExpirationDate;
  };

  const hexColorValidation = color =>
    !(color?.[0] !== '#' || color.length !== 7);

  const positiveValidation = value => parseInt(value) > 0;

  const renderAssessmentNameField = () => (
    <Field data-testid="assessment-name">
      <FieldLabel>Assessment name</FieldLabel>
      <FieldInput
        name="assessment-settings-name"
        defaultValue={assessmentName}
        required
      />
    </Field>
  );

  const renderAssessmentSlugField = () => (
    <SlugField data-testid="assessment-slug">
      <FieldLabel>Slug name</FieldLabel>
      <FieldInput
        name="assessment-settings-slug"
        defaultValue={assessmentSlug}
        required
        validations={{
          // Just allow letters, numbers and hyphens
          pattern: /^[a-zA-Z0-9-]*$/,
          validate: isEditing ? true : validateSlugUniqueness
        }}
        isValid={isValidSlug}
      />
    </SlugField>
  );

  const renderAssessmentTypeField = () => (
    <Field data-testid="assessment-type">
      <FieldLabel>Assessment type</FieldLabel>
      <FieldInput
        name="assessment-settings-type"
        defaultValue={selectedType}
        value={selectedType}
        isVisible={false}
      />

      <FlexContainer>
        <AssessmentTypeBtn
          onClick={ev => {
            handleTypeChange(ASSESSMENT_TYPES.PRIVATE, ev);
          }}
          active={selectedType === ASSESSMENT_TYPES.PRIVATE}
        >
          Private
        </AssessmentTypeBtn>
        <AssessmentTypeBtn
          onClick={ev => handleTypeChange(ASSESSMENT_TYPES.PUBLIC, ev)}
          active={selectedType === ASSESSMENT_TYPES.PUBLIC}
        >
          Public
        </AssessmentTypeBtn>
      </FlexContainer>
    </Field>
  );

  const renderAssessmentExpirationField = () => (
    <ExpirationField>
      <FlexContainer align="center" justify="flex-start">
        <FieldLabel>Expires on a</FieldLabel>
        <RadioGroup>
          <RadioButton
            label="date"
            groupName="assessment-settings-expiration-type"
            id="expiration-type-date"
            defaultChecked={expirationType === EXPIRATION_TYPES.DATE}
            onSelect={() => {
              setExpirationType(EXPIRATION_TYPES.DATE);
              setshouldCloseCalendar(false);
            }}
          />
          <RadioButton
            label="duration"
            groupName="assessment-settings-expiration-type"
            id="expiration-type-duration"
            defaultChecked={expirationType === EXPIRATION_TYPES.DURATION}
            onSelect={() => {
              setExpirationType(EXPIRATION_TYPES.DURATION);
              setshouldCloseCalendar(false);
            }}
          />
        </RadioGroup>
      </FlexContainer>
      {expirationType === EXPIRATION_TYPES.DURATION && (
        <FlexContainer justify="flex-start">
          <FlexFieldInput
            name="assessment-settings-expiration-number"
            testId="assessment-settings-expiration-number"
            type="number"
            placeholder="Number of days or weeks"
            defaultValue={expirationNumber}
            required
            validations={{ validate: positiveValidation }}
          />
          <DurationDropdownContainer>
            <DurationDropdown
              name="assessment-settings-expiration-unit"
              selectedOption={selectedExpirationUnit}
              setSelectedOption={value => {
                setSelectedExpirationUnit(value);
              }}
              options={[
                { id: EXPIRATION_UNITS.DAYS },
                { id: EXPIRATION_UNITS.WEEKS }
              ]}
              showUnderline
            />
          </DurationDropdownContainer>
        </FlexContainer>
      )}
      {expirationType === EXPIRATION_TYPES.DATE && (
        <FlexContainer justify="flex-Sstart" ref={pickerRef}>
          <div onClick={() => setshouldCloseCalendar(false)}>
            <StyledCalendarPicker
              shouldCloseCalendar={shouldCloseCalendar}
              dateFilter={{
                startDate: selectedExpirationDate,
                endDate: selectedExpirationDate
              }}
              handleDatePicker={date => setSelectedExpirationDate(date)}
              showLabels={false}
              endInputName="assessment-settings-expiration-date"
              endInputvalidations={{
                validate: () => customExpirationDateValidation()
              }}
            />
          </div>
          <HoursDropdownContainer onClick={() => setshouldCloseCalendar(true)}>
            <StyledHoursDropdown
              selectedHour={selectedExpirationHour}
              onSelect={setSelectedExpirationHour}
              inputName="assessment-settings-expiration-hour"
              isValid={
                !methods?.errors?.['assessment-settings-expiration-date']
              }
            />
          </HoursDropdownContainer>
        </FlexContainer>
      )}
    </ExpirationField>
  );

  const renderEmailSenderField = () => (
    <EmailField data-testid="assessment-email-sender">
      <FieldLabel>Email sender</FieldLabel>
      <FlexContainer>
        <FieldInput
          name="assessment-settings-email-sender"
          defaultValue={emailSender}
          required
          validations={{
            validate: email =>
              customEmailValidation(`${email}${EMAIL_SENDER_DOMAIN}`)
          }}
        />
        <DisabledEmail>@correlation1.com</DisabledEmail>
      </FlexContainer>
    </EmailField>
  );

  const renderColorThemeField = () => (
    <Field data-testid="assessment-color-theme">
      <FlexContainer align="center">
        <ColorThemeLabel>
          Color theme
          <CustomTooltip content="Applies new color to text headings, links, and buttons in all assessments" />
        </ColorThemeLabel>
      </FlexContainer>
      <FlexContainer>
        <FieldInput
          name="assessment-settings-color-theme"
          defaultValue={assessmentColorTheme}
          onChange={ev => setColorTheme(ev.target.value)}
          placeholder="######"
          required
          isValidHex={hexColorValidation(colorTheme)}
          validations={{
            validate: hexColorValidation
          }}
        />
        <ColorPickerDisplay
          color={hexColorValidation(colorTheme) ? colorTheme : ''}
        />
      </FlexContainer>
    </Field>
  );

  const renderNotificationField = () => (
    <Field data-testid="assessment-complete-notification">
      <NotificationFieldContainer align="center">
        <SwitchContainer>
          <ToggleSwitch
            inputName="assessment-settings-complete-notification-toggle"
            isOn={showEmailToNotify}
            handleSwitch={() => setShowshowEmailToNotify(!showEmailToNotify)}
          />
        </SwitchContainer>
        <ToggleFieldLabel>Notify me of completed assessment </ToggleFieldLabel>
        {showEmailToNotify && (
          <EmailInputContainer>
            <FieldInput
              name="assessment-settings-complete-notification-sender"
              required
              placeholder="Notification email"
              defaultValue={notificationSender}
              validations={{
                validate: customEmailValidation
              }}
            />
          </EmailInputContainer>
        )}
      </NotificationFieldContainer>
    </Field>
  );

  const renderQuestionPoolToggleField = () => (
    <Field data-testid="assessment-question-pool-toggle">
      <FlexContainer align="center">
        <SwitchContainer>
          <ToggleSwitch
            inputName="assessment-settings-question-pool-toggle"
            isOn={questionPoolIsEnabled}
            handleSwitch={() =>
              setquestionPoolIsEnabled(!questionPoolIsEnabled)
            }
          />
        </SwitchContainer>
        <ToggleFieldLabel>Enable question pools</ToggleFieldLabel>
      </FlexContainer>
    </Field>
  );

  const renderAttemptLimit = () => (
    <Field data-testid="assessment-attempt-limit">
      <FlexContainer align="center">
        <SwitchContainer>
          <ToggleSwitch
            inputName="assessment-settings-attempt-limit-toggle"
            isOn={isAttemptLimited}
            handleSwitch={() => setIsAttemptLimited(!isAttemptLimited)}
          />
        </SwitchContainer>
        <ToggleLimitFieldLabel>
          Limit how many attempts allowed
        </ToggleLimitFieldLabel>
        <LimitToolTip content="Attempt limit will only apply to public links" />
        {isAttemptLimited && (
          <AttemptLimitContainer>
            <FieldInput
              name="assessment-settings-attempt-limit"
              required
              defaultValue={defaultSettings.attemptLimit || 1}
              validations={{ validate: positiveValidation }}
              type="number"
            />
          </AttemptLimitContainer>
        )}
      </FlexContainer>
    </Field>
  );

  const renderEditWeightBtn = () =>
    Object.keys(reconciledWeights)?.length > 0 && (
      <ActionBtnContainer>
        <ActionBtn
          data-testid="edit-weight-btn"
          onClick={() => setEditWeighting(true)}
        >
          Edit category weighting
        </ActionBtn>
      </ActionBtnContainer>
    );

  const renderActions = () => (
    <NavActions data-testid="action-buttons">
      <ActionButton data-testid="save-btn" className="save" form={formID}>
        Save
      </ActionButton>
      <ActionButton
        data-testid="cancel-btn"
        className="cancel"
        onClick={ev => {
          ev.preventDefault();
          onCancel();
        }}
      >
        Cancel
      </ActionButton>
    </NavActions>
  );

  return (
    <StyledModal title="Settings" showCloseIcon={false} onClose={onCancel}>
      <form id={formID} onSubmit={handleSubmit(onSave)}>
        <FormContext {...methods}>
          <Settings>
            {renderAssessmentNameField()}
            <FlexContainer align="center" justify="space-between">
              {renderAssessmentSlugField()}
              {renderColorThemeField()}
            </FlexContainer>
            {renderAssessmentTypeField()}
            <FlexContainer align="center" justify="space-between">
              {renderEmailSenderField()}
              {renderAssessmentExpirationField()}
            </FlexContainer>

            <ToggleConfigurations>
              {renderAttemptLimit()}
              {renderNotificationField()}
              {renderQuestionPoolToggleField()}
            </ToggleConfigurations>
            {!editWeighting ? (
              renderEditWeightBtn()
            ) : (
              <CategoryWeightsForm
                reconciledWeights={reconciledWeights}
                handleResetValues={handleResetValues}
                setWasWeightsCustomized={setWasWeightsCustomized}
              />
            )}
            {renderActions()}
          </Settings>
        </FormContext>
      </form>
    </StyledModal>
  );
};

export default SettingsModal;

SettingsModal.propTypes = {
  defaultSettings: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  categoriesWeightData: PropTypes.shape({
    defaultCategoriesWeight: PropTypes.object,
    reconciledWeights: PropTypes.object
  }).isRequired,
  isEditing: PropTypes.bool.isRequired,
  isValidSlug: PropTypes.bool.isRequired,
  setWasWeightsCustomized: PropTypes.func
};

SettingsModal.defaultProps = {
  setWasWeightsCustomized: () => null
};

const StyledModal = styled(Modal)`
  > div {
    min-height: 85%;
    padding: 34px 31px 32px;
  }
`;

const Settings = styled.div`
  margin-top: 24px;
`;

const Field = styled.div`
  margin-top: 25px;
  text-align: left;
`;

const FieldLabel = styled.label`
  color: ${({ theme }) => theme.colors.black};
  display: block;
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 20px;
  margin-bottom: 8px;
`;

const ColorPickerDisplay = styled.div`
  background: ${({ color, theme }) => color || theme.colors.iron};
  height: 34px;
  width: 34px;
`;

const ColorThemeLabel = styled(FieldLabel)`
  align-items: center;
  display: flex;
`;

const CustomTooltip = styled(OptionTooltip)`
  line-height: 12px;
  margin-left: 6px;
`;

const LimitToolTip = styled(CustomTooltip)`
  bottom: 2px;
  margin-right: 11px;
  position: relative;
`;

const DisabledEmail = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGrey};
  color: #858585;
  height: 34px;
  padding: 8px;
  user-select: none;
`;

const FieldInput = styled(FormInput)`
  height: 32px;
  color: ${({ isValidHex }) => isValidHex === false && '#6a7f87'};
  ${removeNumericInputSpinners}
`;

const FlexFieldInput = styled(FieldInput)`
  flex: 1;
`;

const AssessmentTypeBtn = styled.button`
  background: ${({ active, theme }) =>
    active ? `${theme.tenantAccent}17` : '#f5f5f5'};
  border: ${({ active, theme }) =>
    `1px solid ${active ? theme.tenantAccent : 'rgb(208, 208, 208)'}`};
  color: ${({ active, theme }) => (active ? theme.tenantAccent : '#858585')};
  cursor: pointer;
  flex: 1;
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 14px;
  font-weight: ${({ active }) => (active ? '900' : '500')};
  letter-spacing: 0.4px;
  line-height: 20px;
  outline: 0;
  padding: 8px 0;
  text-align: center;
  &:first-child {
    border-radius: 4px 0px 0px 4px;
  }
  &:last-child {
    border-radius: 0px 4px 4px 0px;
  }
`;

const NavActions = styled.div`
  display: flex;
`;

const ActionBtnContainer = styled.div`
  margin-top: 25px;
`;

const ActionButton = styled.button`
  border-radius: 2px;
  border: 1px solid rgb(41, 41, 69);
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.avenirProBlack};
  font-size: 14px;
  letter-spacing: 1.25px;
  margin-top: 32px;
  min-width: 94px;
  outline: 0;
  text-align: center;
  text-transform: uppercase;
  transition: 200ms all ease-out;
  padding: 7px 14px;
  &.cancel {
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid rgb(208, 208, 208);
    color: ${({ theme }) => theme.colors.darkBlue};
    margin-left: 8px;
    &:hover {
      background: ${({ theme }) => handleButtonHover(theme.colors.white)};
      color: ${({ theme }) => theme.colors.white};
    }
  }
  &.save {
    background: ${({ theme }) => theme.colors.darkBlue};
    border-color: transparent;
    color: ${({ theme }) => theme.colors.white};
    &:hover {
      background: ${({ theme }) => handleButtonHover(theme.colors.darkBlue)};
    }
    &:disabled {
      background: #9c9c9c;
      cursor: auto;
    }
  }
`;

const DurationDropdownContainer = styled.div`
  margin-left: 20px;
`;

const DurationDropdown = styled(Dropdown)`
  background: ${({ theme }) => theme.colors.lightGrey};
  flex: 1;
  height: 32px;
`;

const ActionBtn = styled.button`
  background: transparent;
  border: 0;
  color: ${({ theme }) => theme.tenantAccent};
  cursor: pointer;
  display: block;
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 20px;
  padding: 0;
  outline: 0;
  text-decoration: underline;
`;

const NotificationFieldContainer = styled(FlexContainer)`
  min-height: 38px;
`;

const SwitchContainer = styled.div`
  min-width: 65px;
`;

const EmailInputContainer = styled.div`
  width: 40%;
`;

const AttemptLimitContainer = styled.div`
  max-width: 100px;
`;

const RadioGroup = styled(FlexContainer)`
  margin: 0 0 8px 12px;
  > div {
    margin-right: 12px;
  }
`;

const StyledCalendarPicker = styled(CalendarPicker)`
  margin-right: 20px;
  padding: 0;
  + .react-calendar {
    position: absolute;
  }
`;

const StyledHoursDropdown = styled(HoursDropdown)`
  height: 100%;
  min-height: ${({ isValid }) => !isValid && '45px'};
`;

const HoursDropdownContainer = styled.div`
  height: 100%;
  > div {
    height: 41px;
  }
`;

const SlugField = styled(Field)`
  flex: 0.9;
`;

const EmailField = styled(Field)`
  flex: 1;
`;

const ExpirationField = styled(Field)`
  flex: 1;
`;

const ToggleFieldLabel = styled(FieldLabel)`
  margin: 0 16px 0 20px;
`;

const ToggleLimitFieldLabel = styled(ToggleFieldLabel)`
  margin-right: 0;
`;

const ToggleConfigurations = styled.div`
  margin-top: 40px;
`;
