import React from 'react';
import styled from 'styled-components';
import { checkMarkIcon } from 'components/Atoms/Icons/checkMarkIcon';
import { redXIcon } from 'components/Atoms/Icons/redXIcon';
import withinOptimalRangeDashIcon from 'assets/withinOptimalRangeDashIcon';
import { FlexContainer } from 'components/Atoms/Containers';
import { Body1 } from 'components/Atoms/Text';

const BandedResponse = ({
  attempt: {
    question: { bands },
    answer
  },
  formattedTimeTaken
}) => {
  const lessThanOrEqualTo = String.fromCharCode(8804);
  const { score, answer_value } = answer || { score: null, answer_value: null };

  const handleCorrectBanded = () => {
    let correctAnswerBand = {};

    if (bands.length) {
      correctAnswerBand = bands.reduce((highestScoringBand, currentBand) => {
        return highestScoringBand.score > currentBand.score
          ? highestScoringBand
          : currentBand;
      });
    }

    return (
      <CorrectContainer
        align="center"
        justify="space-between"
        background={score ? '#e6f1de' : '#faddd6'}
      >
        <FlexContainer align="center">
          <Body1 color="#df7c48" margin="0 13px 0 0">
            {score
              ? score === correctAnswerBand.score
                ? checkMarkIcon
                : withinOptimalRangeDashIcon
              : redXIcon}
          </Body1>
          <Body1>
            Optimal answer:{' '}
            <AnswerText>
              {handleBandedOptimalAnswerText(correctAnswerBand)}
            </AnswerText>
          </Body1>
        </FlexContainer>
        <FlexContainer>
          {score && score !== correctAnswerBand.score ? (
            <Body1>(Partial credit received)</Body1>
          ) : null}
          {formattedTimeTaken && (
            <Body1>
              {' '}
              Time spent: <AnswerText>{formattedTimeTaken}</AnswerText>
            </Body1>
          )}
        </FlexContainer>
      </CorrectContainer>
    );
  };

  const handleBandedOptimalAnswerText = ({ min_value, max_value }) => {
    if (max_value === min_value) {
      return max_value;
    } else {
      return (
        <AnswerText>
          {min_value} {lessThanOrEqualTo} x {lessThanOrEqualTo} {max_value}
        </AnswerText>
      );
    }
  };

  return (
    <>
      <BandedInputMockContainer align="center">
        <Body1>{answer_value}</Body1>
      </BandedInputMockContainer>
      {handleCorrectBanded()}
    </>
  );
};

export default BandedResponse;

const AnswerText = styled.span`
  font-weight: bold;
`;

const BandedInputMockContainer = styled(FlexContainer)`
  background: #f5f5f5;
  border-bottom: ${({ theme }) => `solid 2px ${theme.tenantAccent}`};
  height: 48px;
  margin: 8px 0 0;
  padding: 5px 0 0 16px;
  width: 332px;
`;

const CorrectContainer = styled(FlexContainer)`
  border-radius: 4px;
  height: 40px;
  margin: 20px 24px 20px 0;
  padding: 5px 28px 0 32px;
`;
