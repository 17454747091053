import React from 'react';

const questionSectionIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="12"
    viewBox="0 0 16 12"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#FFF" transform="translate(-513 -141)">
        <g transform="translate(164 59)">
          <g transform="translate(300 70)">
            <g transform="translate(39)">
              <g transform="translate(10 12)">
                <rect width="16" height="4.8" x="0" y="0" rx="2"></rect>
                <rect width="16" height="4.8" x="0" y="6.4" rx="2"></rect>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default questionSectionIcon;
