import React from 'react';

const checkListIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    viewBox="0 0 13 13"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g
        fill="#939393"
        fillRule="nonzero"
        stroke="#979797"
        strokeWidth="0.3"
        transform="translate(-901 -233)"
      >
        <g transform="translate(208 80)">
          <g transform="translate(0 86)">
            <g transform="translate(694 63)">
              <g transform="translate(0 5)">
                <path d="M3.655 3.323H1.822a.344.344 0 000 .687h1.833a.344.344 0 000-.687zM3.655 5.156H1.822a.344.344 0 000 .688h1.833a.344.344 0 000-.688zM3.655 6.99H1.822a.344.344 0 000 .687h1.833a.344.344 0 000-.687z"></path>
                <path d="M8.582 6.3V3.162c0-.453-.174-.88-.492-1.204L6.696.516 6.694.514A1.73 1.73 0 005.468 0H1.043C.474 0 .01.463.01 1.031v8.915c0 .568.463 1.031 1.032 1.031h5.313c.49.468 1.153.756 1.883.756 1.516 0 2.75-1.223 2.75-2.727 0-1.388-1.052-2.537-2.407-2.706zM.7 9.946V1.03c0-.19.154-.343.344-.343h4.425c.274 0 .542.112.735.307l1.394 1.442.002.002c.19.194.296.45.296.723v3.139A2.731 2.731 0 005.51 9.006c0 .464.117.9.322 1.284h-4.79a.344.344 0 01-.344-.344zm7.54 1.1a2.042 2.042 0 01-2.04-2.04c0-1.124.915-2.04 2.04-2.04 1.137 0 2.062.916 2.062 2.04 0 1.125-.925 2.04-2.062 2.04z"></path>
                <path d="M9.032 8.192a.344.344 0 00-.485.044l-.58.696-.131-.263a.344.344 0 10-.615.308l.366.733a.344.344 0 00.572.066l.917-1.1a.344.344 0 00-.044-.484h0zM5.489 4.01h.366a.344.344 0 000-.687H5.49a.344.344 0 000 .687zM6.199 5.5a.344.344 0 00-.344-.344H5.49a.344.344 0 000 .688h.366c.19 0 .344-.154.344-.344z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default checkListIcon;
