import React from 'react';

const trashCanRedIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-1062 -357)">
        <g transform="translate(208 152)">
          <g transform="translate(0 86)">
            <g transform="translate(24 23)">
              <g transform="translate(821 1)">
                <g transform="translate(0 28)">
                  <g transform="translate(1 64)">
                    <g transform="translate(8 3)">
                      <rect
                        width="24"
                        height="24"
                        x="0"
                        y="0"
                        opacity="0.048"
                        rx="2"
                      ></rect>
                      <g
                        fill="#D64E4C"
                        fillRule="nonzero"
                        stroke="#FFF"
                        strokeWidth="0.2"
                        transform="translate(7 6)"
                      >
                        <g>
                          <path d="M5.906 0H3.844c-.569 0-1.031.463-1.031 1.031v.844H1.5c-.827 0-1.5.673-1.5 1.5 0 .764.574 1.395 1.313 1.488V10.5c0 .827.672 1.5 1.5 1.5h4.124c.828 0 1.5-.673 1.5-1.5V4.863A1.502 1.502 0 009.75 3.375c0-.827-.673-1.5-1.5-1.5H6.937v-.844C6.938.463 6.476 0 5.907 0zM3.938 1.125h1.874v.75H3.938v-.75zM7.312 10.5a.375.375 0 01-.375.375H2.813a.375.375 0 01-.374-.375V4.875h4.874V10.5zm1.313-7.125a.375.375 0 01-.375.375H1.5a.375.375 0 010-.75h6.75c.207 0 .375.168.375.375z"></path>
                          <path d="M3.75 9.375c.31 0 .563-.252.563-.563v-2.25a.562.562 0 10-1.125 0v2.25c0 .311.251.563.562.563zM6 9.375c.31 0 .563-.252.563-.563v-2.25a.562.562 0 10-1.125 0v2.25c0 .311.251.563.562.563z"></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default trashCanRedIcon;
