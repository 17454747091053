import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { FlexContainer, PositionContainer } from 'components/Atoms/Containers';
import { Body2 } from 'components/Atoms/Text';
import { StyledButton } from 'components/Atoms/Buttons';
import Underline from 'components/Atoms/Underline';
import { requestHelper } from 'utils/requests/requestHelper';
import { requests } from 'utils/requests/requests';
import { useClickedOutsideEvent } from 'hooks/useClickedOutsideEvent';

const AssessmentShareLink = () => {
  const [linkModalShowing, setLinkModalShowing] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [loadingLink, setLoadingLink] = useState(false);
  const [generatedLink, setGeneratedLink] = useState('');
  const modalRef = useRef(null);
  const linkRef = useRef(null);
  const { taker: uuid, assessment: slug } = useParams();
  const {
    REACT_APP_PANEL_URI,
    REACT_APP_TALENTSTAT_DOMAIN,
    REACT_APP_CORRELATIONONE_DOMAIN
  } = process.env;

  useClickedOutsideEvent(modalRef, () => setLinkModalShowing(false));

  const copyLink = () => {
    if (!linkRef) return;
    linkRef.current.select();
    document.execCommand('copy');
    setCopySuccess(true);
    setTimeout(() => setCopySuccess(false), 2000);
  };

  const fetchShareLink = async () => {
    setLoadingLink(true);
    await requestHelper(
      requests({
        uuid: uuid
      }).createAssessmentAttemptLink,
      {},
      response => {
        const { public_uuid } = response.data.data;
        const domain = window.location.href.includes(
          REACT_APP_TALENTSTAT_DOMAIN
        )
          ? REACT_APP_TALENTSTAT_DOMAIN
          : REACT_APP_CORRELATIONONE_DOMAIN;
        const shareLinkComponents = [
          `${REACT_APP_PANEL_URI}.${domain}`,
          `admin`,
          `${slug}`,
          `results`,
          `${uuid}?public_uuid=${public_uuid}`
        ];
        const shareLink = shareLinkComponents.join('/');
        setGeneratedLink(shareLink);
        setLoadingLink(false);
      }
    );
  };

  return (
    <FlexContainer
      ref={modalRef}
      align="center"
      className="pointer"
      onClick={() => setLinkModalShowing(true)}
    >
      <Button>SHARE RESULTS</Button>
      {linkModalShowing && (
        <ShareModal>
          <FlexContainer justify="space-between">
            <ColoredBody2>Get shareable link of this report</ColoredBody2>
          </FlexContainer>
          {generatedLink && (
            <>
              <LinkText ref={linkRef} value={generatedLink} readOnly />
              <Underline />
            </>
          )}

          {
            <FlexContainer>
              <Button
                onClick={() => {
                  !loadingLink && !generatedLink
                    ? fetchShareLink()
                    : copyLink();
                }}
              >
                {generatedLink ? 'COPY LINK' : 'CREATE LINK'}
              </Button>
            </FlexContainer>
          }
          {copySuccess && <SuccessContainer>Copied!</SuccessContainer>}
        </ShareModal>
      )}
    </FlexContainer>
  );
};

export default AssessmentShareLink;

const ColoredBody2 = styled(Body2)`
  color: ${({ theme }) => theme.colors.smoky};
`;

const ShareModal = styled(PositionContainer)`
  background: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.mercury}`};
  border-radius: 4px;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.07);
  cursor: default;
  min-width: 480px;
  padding: 16px;
  right: 0px;
  top: 42px;
  z-index: 101;
`;

const SuccessContainer = styled(PositionContainer)`
  background: ${({ theme }) => theme.colors.black};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 12px;
  padding: 7px 4px 4px;
  z-index: 102;
`;

const LinkText = styled.input`
  border: none;
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 14px;
  letter-spacing: 0.4px;
  margin-top: 16px;
  outline: none;
  width: 100%;
`;

const Button = styled(StyledButton)`
  align-items: center;
  background: ${({ theme }) => theme.tenantAccent};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  height: 40px;
  justify-content: center;
  width: 212px;
`;
