import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FlexContainer } from 'components/Atoms/Containers';

const RadioButton = ({ defaultChecked, groupName, id, label, onSelect }) => {
  return (
    <FlexContainer direction="row" align="center">
      <RadioContainer>
        <RadioLabel htmlFor={id}>{label}</RadioLabel>
        <RadioInput
          defaultChecked={defaultChecked}
          type="radio"
          name={groupName}
          id={id}
          onChange={onSelect}
        />
        <FakeInput />
      </RadioContainer>
    </FlexContainer>
  );
};

export default RadioButton;

RadioButton.propTypes = {
  defaultChecked: PropTypes.bool,
  groupName: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  onSelect: PropTypes.func
};

RadioButton.defaultProps = {
  defaultChecked: false,
  groupName: '',
  id: '',
  label: '',
  onSelect: () => null
};

const RadioContainer = styled.label`
  cursor: pointer;
`;

const RadioLabel = styled.label`
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.avenirNextMedium};
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 20px;
  margin-right: 8px;
`;

const RadioInput = styled.input`
  display: none;
  &:checked + span::before {
    background: ${({ theme }) => theme.tenantAccent};
  }
`;

const FakeInput = styled.span`
  &::before {
    border: solid 1px #979797;
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 16px;
    margin-right: 0.3rem;
    vertical-align: middle;
    width: 16px;
  }
`;
