import React from 'react';

export const gridIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="11"
    viewBox="0 0 11 11"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#939393" fillRule="nonzero" transform="translate(-902 -259)">
        <g transform="translate(208 80)">
          <g transform="translate(0 86)">
            <g transform="translate(694 63)">
              <g transform="translate(0 24)">
                <g transform="translate(0 6)">
                  <path d="M3.798 0H1.272C.571 0 0 .57 0 1.272v2.526C0 4.5.57 5.07 1.272 5.07h2.526c.702 0 1.272-.57 1.272-1.272V1.272C5.07.571 4.5 0 3.798 0zm.413 3.798a.413.413 0 01-.413.413H1.272a.413.413 0 01-.413-.413V1.272c0-.227.186-.413.413-.413h2.526c.228 0 .413.186.413.413v2.526zM9.218 0H6.726c-.71 0-1.289.578-1.289 1.29V3.78c0 .711.578 1.29 1.289 1.29h2.492c.711 0 1.29-.579 1.29-1.29V1.29C10.507.58 9.928 0 9.217 0zm.43 3.781a.43.43 0 01-.43.43H6.726a.43.43 0 01-.43-.43V1.29a.43.43 0 01.43-.43h2.492a.43.43 0 01.43.43v2.492zM3.798 5.437H1.272C.571 5.437 0 6.007 0 6.709v2.526c0 .702.57 1.272 1.272 1.272h2.526c.702 0 1.272-.57 1.272-1.272V6.71c0-.701-.57-1.272-1.272-1.272zm.413 3.798a.413.413 0 01-.413.413H1.272a.413.413 0 01-.413-.413V6.71c0-.228.186-.413.413-.413h2.526c.228 0 .413.185.413.413v2.526zM9.218 5.437H6.726c-.71 0-1.289.578-1.289 1.289v2.492c0 .711.578 1.29 1.289 1.29h2.492c.711 0 1.29-.579 1.29-1.29V6.726c0-.71-.579-1.289-1.29-1.289zm.43 3.781a.43.43 0 01-.43.43H6.726a.43.43 0 01-.43-.43V6.726a.43.43 0 01.43-.43h2.492a.43.43 0 01.43.43v2.492z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default gridIcon;
