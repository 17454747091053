import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FlexContainer } from 'components/Atoms/Containers';
import DistributionBar from 'pages/PanelPage/components/charts/DistributionBar';
import DateFilterWindow from 'pages/PanelPage/components/selectedAssessment/assessmentResults/DateFilterWindow';
import { filterTypes } from 'pages/App/helpers/filterTypes';
import { Body1 } from 'components/Atoms/Text';
import RemoveFilter from 'components/Blocks/RemoveFilter';
import { useClickedOutsideEvent } from 'hooks/useClickedOutsideEvent';
import calendarBlackIcon from 'assets/calendarBlackIcon';

const DistributionTable = ({
  attemptScore,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  chartData,
  searchEmail,
  setSearchEmail
}) => {
  const [dateFilter, setDateFilter] = useState({
    showWindow: false,
    startDate: null,
    endDate: null
  });

  const filterDropdownRef = useRef(null);

  useClickedOutsideEvent(
    filterDropdownRef,
    () => {
      setDateFilter({ ...dateFilter, showWindow: false });
    },
    '[class^="react-calendar"]'
  );

  const closeAllFilterWindows = () => {
    setDateFilter({ ...dateFilter, showWindow: false });
  };

  const clearDateFilter = () => {
    setStartDate(null);
    setEndDate(null);
    setDateFilter({ ...dateFilter, startDate: null, endDate: null });
  };

  const renderFilterIndicator = filterType => {
    switch (filterType) {
      case filterTypes.EMAIL:
        return (
          <RemoveFilter
            filterLabel={`Filter by searched phrase "${searchEmail}"`}
            onRemove={() => setSearchEmail('')}
          />
        );
      case filterTypes.DATE:
        return (
          <RemoveFilter
            filterLabel={`Filter by assessments submitted between ${startDate} and ${endDate}`}
            onRemove={clearDateFilter}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <FlexContainer justify="space-between" align="center">
        <ScoreDistributionBody className="bold">
          Score Distribution
        </ScoreDistributionBody>
        {!attemptScore && (
          <FlexContainer ref={filterDropdownRef}>
            <FilterButton
              align="center"
              background={dateFilter.showWindow && '#f5f5f5'}
              className="pointer"
              onClick={() =>
                setDateFilter({
                  ...dateFilter,
                  showWindow: !dateFilter.showWindow
                })
              }
            >
              Date
              <Icon>{calendarBlackIcon}</Icon>
            </FilterButton>
            {dateFilter.showWindow && (
              <DateFilterWindow
                closeAllFilterWindows={closeAllFilterWindows}
                dateFilter={dateFilter}
                setDateFilter={setDateFilter}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            )}
          </FlexContainer>
        )}
      </FlexContainer>
      {startDate && renderFilterIndicator('date')}
      {searchEmail && renderFilterIndicator('email')}
      <FlexContainer height="266px" width="100%" margin="16px 0 50px">
        <DistributionBar attemptScore={attemptScore} chartData={chartData} />
      </FlexContainer>
    </>
  );
};

DistributionTable.propTypes = {
  attemptScore: PropTypes.number,
  startDate: PropTypes.string,
  setStartDate: PropTypes.func,
  endDate: PropTypes.string,
  setEndDate: PropTypes.func,
  chartData: PropTypes.object.isRequired,
  searchEmail: PropTypes.string,
  setSearchEmail: PropTypes.func
};

DistributionTable.defaultProps = {
  attemptScore: null,
  startDate: null,
  setStartDate: () => {},
  endDate: null,
  setEndDate: () => {},
  searchEmail: '',
  setSearchEmail: () => {}
};

export default DistributionTable;

const FilterButton = styled(FlexContainer)`
  border: 1px solid #dadada;
  border-radius: 4px;
  margin: 0 8px;
  padding: 7px 16px;
`;

const Icon = styled.div`
  cursor: pointer;
  margin-bottom: -2px;
  padding-left: 8px;
`;

const ScoreDistributionBody = styled(Body1)`
  color: ${({ theme }) => theme.colors.smoky};
`;
