import { useContext } from 'react';
import { UserContext } from 'state/userContext/userContext';
import { dispatchActions } from 'state/userContext/dispatchActions';

export const useUserContext = () => {
  const { userState, userDispatch } = useContext(UserContext);

  return {
    userState,
    userDispatch: dispatchActions(userDispatch),
    isAnonymous: !userState?.userData
  };
};
