import PropTypes from 'prop-types';
import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import styled from 'styled-components';
import rightArrowIcon from 'assets/rightArrowIcon';
import { Row, Cell } from 'components/Blocks/Styled/Tables';
import { Body1 } from 'components/Atoms/Text';

const AssessmentTaker = ({ takerData, uuid }) => {
  const history = useHistory();
  const renderTakerCells = () => {
    return Object.values(takerData).map((datum, index) => (
      // NOTE: I think this actually falls under the rare use case for when an index is acceptable as a key
      <Cell key={index}>
        <CellText>{datum}</CellText>
      </Cell>
    ));
  };

  return (
    <TakerRow>
      {renderTakerCells()}
      <Cell textAlign="center">
        <Body1 className="pointer">
          <Link
            style={{ textDecoration: 'none' }}
            to={`${history.location.pathname}/${uuid}`}
          >
            {rightArrowIcon}
          </Link>
        </Body1>
      </Cell>
    </TakerRow>
  );
};

AssessmentTaker.propTypes = {
  takerData: PropTypes.object.isRequired,
  uuid: PropTypes.string.isRequired
};

export default AssessmentTaker;

const TakerRow = styled(Row)`
  border-bottom: 1px solid #e0e0e0;
  height: 48px;
  &:hover {
    background: ${({ theme }) => theme.colors.lightGrey};
  }
`;

const CellText = styled(Body1)`
  color: ${({ theme }) => theme.colors.darkBlue};
`;
