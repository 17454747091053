import React from 'react';

const blackXIcon = (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 26 26"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(-316.000000, -2844.000000)"
        stroke="#000000"
        strokeWidth="3"
      >
        <g transform="translate(0.000000, 160.000000)">
          <g transform="translate(209.000000, 1319.000000)">
            <g transform="translate(0.000000, 704.000000)">
              <g transform="translate(24.000000, 80.000000)">
                <g transform="translate(3.018109, 51.250409)">
                  <g transform="translate(61.368209, 522.553191)">
                    <g transform="translate(20.169014, 4.019640)">
                      <g transform="translate(0.444668, 6.029460)">
                        <g transform="translate(10.878022, 10.912563) rotate(-315.000000) translate(-10.878022, -10.912563) translate(3.378022, 3.412563)">
                          <line
                            x1="0"
                            y1="7.11167065"
                            x2="14.7551979"
                            y2="7.11167065"
                          ></line>
                          <line
                            x1="7.37759893"
                            y1="14.2233413"
                            x2="7.37759893"
                            y2="0"
                          ></line>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default blackXIcon;
