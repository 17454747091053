import React, { useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { FlexContainer, Container } from 'components/Atoms/Containers';
import PreviewNav from 'pages/PreviewPage/PreviewNav';
import PreviewQuestion from 'pages/PreviewPage/PreviewQuestion';
import PreviewSectionHead from 'pages/PreviewPage/PreviewSectionHead';
import Loader from 'components/Systems/Loader';
import { MaxWidthContainer } from 'components/Atoms/Containers';
import CandidateInfoPreview from 'pages/ManagementPage/candiateInfoPreview/CandidateInfoPreview';
import LandingPagePreview from 'pages/ManagementPage/LandingPagePreview';
import PreviewNavPools from 'pages/PreviewPage/PreviewNavPools';
import { useThemeContext } from 'state/themeContext/useThemeContext';
import usePrevious from 'pages/PanelPage/hooks/usePrevious';
import mapTakerFields from 'utils/helpers/mapTakerFields';
import calculateDuration from 'utils/helpers/calculateDuration';
import { VIEW_TYPES } from 'pages/PreviewPage/Constants';
import { getAssessmentPreviewDataMW } from './middleware';

const PreviewPage = () => {
  const [assessmentPreview, setAssessmentPreview] = useState({});
  const [sectionsData, setSectionsData] = useState([]);
  const [questionPoolsData, setQuestionPoolsData] = useState(null);
  const [currentView, setCurrentView] = useState(VIEW_TYPES.assessment);
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState(null);
  const [selectedAssessmentRef, setSelectedAssessmentRef] = useState(null);
  const [selectedPoolRef, setSelectedPoolRef] = useState(null);
  const { previewUuid } = useParams();
  const { tenantAccent } = useThemeContext();
  const previousView = usePrevious(currentView);
  let questionRefs = {};

  const mapSectionsData = sectionsData =>
    sectionsData.map(({ slots, is_timed, time_allowed_seconds, title }) => ({
      questionCount: slots.length,
      durationInSeconds: is_timed
        ? time_allowed_seconds
        : calculateDuration({ section: slots, mode: 'preview-page' }),
      title: title
    }));

  const handleScrollToItem = (e, target, view, behavior = 'smooth') => {
    e && e.stopPropagation();
    questionRefs[target].current.scrollIntoView({ behavior });

    if (view === VIEW_TYPES.assessment) {
      setSelectedAssessmentRef(target);
    }
  };

  const fetchAssessmentData = useCallback(async () => {
    const response = await getAssessmentPreviewDataMW({
      urlParams: [previewUuid]
    });

    const { assessment, categories = [], pools = [] } = response.data;

    setAssessmentPreview(assessment);
    setSectionsData(mapSectionsData(assessment.sections));
    setCategories(categories);
    setQuestionPoolsData(pools);
    setIsLoading(false);
  }, [previewUuid]);

  useEffect(() => {
    if (
      selectedAssessmentRef &&
      currentView === VIEW_TYPES.assessment &&
      previousView === VIEW_TYPES.pools
    ) {
      handleScrollToItem(
        null,
        selectedAssessmentRef,
        VIEW_TYPES.assessment,
        'auto'
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentView, selectedAssessmentRef, previousView]);

  useEffect(() => {
    if (
      currentView === VIEW_TYPES.pools &&
      previousView === VIEW_TYPES.assessment
    ) {
      if (selectedPoolRef) {
        handleScrollToItem(null, selectedPoolRef, VIEW_TYPES.pools, 'auto');
      } else {
        window.scrollTo(0, 0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentView, selectedPoolRef, previousView]);

  useEffect(() => {
    fetchAssessmentData();
  }, [fetchAssessmentData]);

  const {
    sections,
    name,
    taker_fields,
    configuration,
    assessment_detail
  } = assessmentPreview;

  const renderSections = sections =>
    sections.map((section, i) => {
      const questionRef = React.createRef();
      questionRefs[`S${i}`] = questionRef;

      return (
        <PreviewSection key={section.id} ref={questionRef}>
          <PreviewSectionHead section={section} />
          <PreviewSectionContent>
            {renderQuestions(section, i)}
          </PreviewSectionContent>
        </PreviewSection>
      );
    });

  const renderQuestions = (section, sectionIndex) =>
    section.slots.map((slot, i) => {
      const questionRef = React.createRef();
      questionRefs[`S${sectionIndex}-Q${i}`] = questionRef;

      const poolQuestions = questionPoolsData?.[slot.category_id] || [];

      return (
        <PreviewQuestion
          slot={slot}
          key={slot.id}
          index={i}
          sectionIndex={sectionIndex}
          questionRef={questionRef}
          handleGoToPool={() => {
            setSelectedAssessmentRef(`S${sectionIndex}-Q${i}`);
            setSelectedPoolRef(slot?.category_id);
            setCurrentView(VIEW_TYPES.pools);
          }}
          poolQuestions={poolQuestions}
        />
      );
    });

  const renderPoolQuestions = () =>
    Object.keys(questionPoolsData).map((poolId, poolIndex) => {
      const poolRef = React.createRef();
      questionRefs[poolId] = poolRef;
      return (
        <div key={poolId}>
          <QuestionPoolHeader
            align="center"
            justify="space-between"
            ref={poolRef}
          >
            <QuestionPoolHeaderCategoryName>
              {categories.find(({ id }) => +id === +poolId).name}
            </QuestionPoolHeaderCategoryName>
            <QuestionPoolHeaderQuestionCount>
              {questionPoolsData[poolId].length} questions
            </QuestionPoolHeaderQuestionCount>
          </QuestionPoolHeader>
          {questionPoolsData[poolId].map((question, i) => {
            const slot = {
              id: question.id,
              question,
              categories: [question.default_category],
              category: question.default_category
            };
            const questionRef = React.createRef();
            questionRefs[`SR${poolId}-${i}`] = questionRef;
            return (
              <PreviewQuestion
                key={slot.id}
                slot={slot}
                index={i}
                sectionIndex={poolIndex}
                questionRef={questionRef}
              />
            );
          })}
        </div>
      );
    });

  const renderLoader = () => (
    <PreviewPageWrapper>
      <FlexContainer direction="column">
        <Loader />
      </FlexContainer>
      <MaxWidthContainer>
        <Loader />
      </MaxWidthContainer>
    </PreviewPageWrapper>
  );

  const renderLandingPage = () => {
    return (
      <PreviewSection>
        <LandingPagePreview
          sectionsData={sectionsData}
          logoUrl={configuration.logo_url}
          assessmentColorTheme={tenantAccent}
          instructions={
            assessment_detail.assessment_detail_content.instructions
          }
          title={name}
          isAssessmentPreview
        />
      </PreviewSection>
    );
  };

  const renderTakerFields = taker_fields => {
    const fields = mapTakerFields(taker_fields);

    return (
      <PreviewSection>
        <CandidateInfoWrap>
          <CandidateInfoPreview
            fields={fields}
            assessmentColorTheme={tenantAccent}
          />
        </CandidateInfoWrap>
      </PreviewSection>
    );
  };

  const renderQuestionPreviewPageNav = () => (
    <AnimatedQuestionContainer currentView={currentView}>
      <PreviewNav
        sections={sections}
        name={name}
        questionRefs={questionRefs}
        hasQuestionPools={Boolean(Object.keys(questionPoolsData).length)}
        handleGoToPoolsView={() => {
          setCurrentView(VIEW_TYPES.pools);
          setSelectedPoolRef(null);
        }}
        handleScrollToItem={(ev, target) =>
          handleScrollToItem(ev, target, VIEW_TYPES.assessment)
        }
      />
    </AnimatedQuestionContainer>
  );

  const renderQuestionPreviewPageContent = () => (
    <PreviewContent>
      {renderLandingPage()}
      {renderTakerFields(taker_fields)}
      {renderSections(sections)}
    </PreviewContent>
  );

  const renderPoolsPreviewPageNav = () => (
    <AnimatedPoolContainer currentView={currentView}>
      <PreviewNavPools
        setViewType={setCurrentView}
        questionPoolsData={questionPoolsData}
        categories={categories}
        questionRefs={questionRefs}
        handleScrollToItem={handleScrollToItem}
        defaultSelectedItem={selectedPoolRef}
      />
    </AnimatedPoolContainer>
  );

  const renderPoolsPreviewPageContent = () => (
    <PreviewContent>{renderPoolQuestions()}</PreviewContent>
  );

  const renderPreviewPage = () => (
    <PreviewPageWrapper>
      <FlexContainer direction="column">
        <FixedNavContainer>
          {renderPoolsPreviewPageNav()}
          {renderQuestionPreviewPageNav()}
        </FixedNavContainer>
      </FlexContainer>
      {currentView === VIEW_TYPES.assessment &&
        renderQuestionPreviewPageContent()}
      {currentView === VIEW_TYPES.pools && renderPoolsPreviewPageContent()}
    </PreviewPageWrapper>
  );

  return isLoading ? renderLoader() : renderPreviewPage();
};

export default PreviewPage;

const PreviewPageWrapper = styled.main`
  display: grid;
  grid-template-columns: 350px 5fr;
`;

const PreviewContent = styled(Container)`
  background-color: ${({ theme }) => theme.colors.grey};
  padding: 64px 0;
`;

const PreviewSection = styled(Container)`
  max-width: 800px;
  margin: auto;
`;

const CandidateInfoWrap = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  margin: 8px 0;
`;

const PreviewSectionContent = styled(Container)`
  margin: auto;
  margin-bottom: 8px;
`;

const FixedNavContainer = styled.section`
  height: 100vh;
  position: fixed;
  width: 350px;
`;

const AnimatedPoolContainer = styled.section`
  transition: transform 0.8s;
  transform: translate3d(
    ${props => (props.currentView === VIEW_TYPES.pools ? '0%' : '100%')},
    0,
    0
  );
`;

const AnimatedQuestionContainer = styled.section`
  transition: transform 0.8s;
  transform: translate3d(
    ${props => (props.currentView === VIEW_TYPES.assessment ? '0%' : '-100%')},
    0,
    0
  );
`;

const QuestionPoolHeader = styled(FlexContainer)`
  border-top: 1px solid #d0d0d0;
  padding-top: 24px;
  margin: 48px auto 24px;
  max-width: 800px;
  &:first-of-type {
    margin-top: -9px;
  }
`;

const QuestionPoolHeaderCategoryName = styled.span`
  color: #373440;
  font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
  font-size: 20px;
  white-space: nowrap;
  width: 105px;
`;

const QuestionPoolHeaderQuestionCount = styled.span`
  color: #5a5a5a;
  font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
  font-size: 12px;
  letter-spacing: 1.8px;
  line-height: 16px;
  text-transform: uppercase;
`;
