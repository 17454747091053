import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import styled from 'styled-components';
import { PageWrapper } from 'components/Blocks/Styled/Wrappers';

const Loader = () => {
  const renderSkeletonHeader = () => {
    const headerHeight = 40;
    return (
      <SkeletonHeader>
        <SkeletonSearch>
          <Skeleton height={headerHeight} />
        </SkeletonSearch>
        <SkeletonDistributionTable>
          <Skeleton height={headerHeight} />
        </SkeletonDistributionTable>
      </SkeletonHeader>
    );
  };

  const renderSkeletonGraph = () => {
    const graphHeight = 250;
    return (
      <>
        <Skeleton />
        <SkeletonSpacer />
        <Skeleton height={graphHeight} />
      </>
    );
  };

  const renderSkeletonTable = () => {
    const rows = 10;
    return [...Array(rows)].map((el, index) => (
      <div key={`skeleton-row-${index}`}>
        <Skeleton />
        <SkeletonSpacer />
      </div>
    ));
  };

  return (
    <PageWrapper>
      <SkeletonTheme color="#e9ebf0" highlightColor="#f6f7fa">
        {renderSkeletonHeader()}
        <SkeletonSectionSpacer />
        {renderSkeletonGraph()}
        <SkeletonSectionSpacer />
        {renderSkeletonTable()}
        <SkeletonSectionSpacer />
        {renderSkeletonTable()}
        <SkeletonSectionSpacer />
        {renderSkeletonTable()}
      </SkeletonTheme>
    </PageWrapper>
  );
};

export default Loader;

const SkeletonSpacer = styled.div`
  height: 10px;
`;

const SkeletonSectionSpacer = styled.div`
  margin-top: 40px;
`;

const SkeletonHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const SkeletonSearch = styled.div`
  flex-basis: 30%;
`;

const SkeletonDistributionTable = styled.div`
  flex-basis: 60%;
`;
