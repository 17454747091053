import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import OptionsNav from 'pages/ManagementPage/OptionsNav';
import AddQuestionModal from 'pages/ManagementPage/questionSection/AddQuestionModal';
import ConfirmDeleteModal from 'pages/ManagementPage/questionSection/ConfirmDeleteModal/ConfirmDeleteModal';
import EditableCards from 'pages/ManagementPage/EditableCards';
import candidateInfoIcon from 'assets/candidateInfoIcon';
import landingPageIcon from 'assets/landingPageIcon';
import { messageIcon } from 'assets/messageIcon';
import questionSectionIcon from 'assets/questionSectionIcon';
import { OPTION_TYPES, OPTION_STATES } from 'pages/ManagementPage/Constants';
import getNumericPart from 'pages/ManagementPage/utils/getNumericPart';

const DEFAULT_QUESTION_SECTION_TEXT = 'Question section';

const OPTION_CONFIGURATIONS = [
  {
    id: OPTION_TYPES.LANDING_PAGE,
    background: '#3dbb60',
    svg: landingPageIcon,
    text: 'Landing page',
    cardState: OPTION_STATES.HIDDEN,
    isCardCreated: false,
    isActive: true
  },
  {
    id: OPTION_TYPES.CANDIDATE_INFO,
    background: '#a167b4',
    svg: candidateInfoIcon,
    text: 'Candidate info',
    cardState: OPTION_STATES.HIDDEN,
    isCardCreated: false,
    isActive: true
  },
  {
    id: OPTION_TYPES.MESSAGE,
    background: '#68c9cb',
    svg: messageIcon,
    text: 'Message',
    cardState: OPTION_STATES.HIDDEN,
    isCardCreated: false,
    isDeletable: true,
    isActive: false
  },
  {
    id: `${OPTION_TYPES.QUESTION_SECTION}-0`,
    background: '#5a5a5a',
    svg: questionSectionIcon,
    text: DEFAULT_QUESTION_SECTION_TEXT,
    cardState: OPTION_STATES.HIDDEN,
    isSectionCreated: false,
    isCardCreated: false,
    isDeletable: true,
    sectionId: 0,
    isActive: true
  }
];

const AssessmentCreationForm = ({
  formData,
  sectionsData,
  setSectionsData,
  setSelectedOption,
  isEditing,
  setCreatedCards,
  formErrors,
  isSubmitting,
  isQuestionPoolEnabled,
  questionPools,
  handleAddQuestionPool,
  handleRemoveQuestionPool,
  handleUpdateQuestionPool
}) => {
  const [optionsData, setOptionsData] = useState(
    OPTION_CONFIGURATIONS.filter(({ isActive }) => isActive)
  );
  const [showAddQuestionModalId, setShowAddQuestionModalId] = useState(null);
  const [showQuestionPoolEditor, setShowQuestionPoolEditor] = useState(false);
  const [deleteConfirmationId, setDeleteConfirmationId] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  const closeCards = useCallback(
    () =>
      optionsData.map(option => ({
        ...option,
        cardState:
          option.cardState === OPTION_STATES.EXPANDED
            ? OPTION_STATES.COLLAPSED
            : option.cardState
      })),
    [optionsData]
  );

  useEffect(() => {
    isSubmitting && setOptionsData(closeCards());
  }, [isSubmitting, closeCards]);

  useEffect(() => {
    const cards = {};
    optionsData.forEach(({ isCardCreated, id }) => (cards[id] = isCardCreated));
    setCreatedCards(cards);
  }, [optionsData, setCreatedCards]);

  const createCards = () => {
    let newOptionsData = [...optionsData];
    newOptionsData.pop();

    const reconciledOptionsWithSections = [
      ...newOptionsData,
      ...sectionsData.map(({ sectionId, sectionTitle }, idx) => ({
        id: `${OPTION_TYPES.QUESTION_SECTION}-${sectionId}`,
        background: '#5a5a5a',
        svg: questionSectionIcon,
        text: sectionTitle || DEFAULT_QUESTION_SECTION_TEXT,
        isDeletable: true,
        sectionId: idx
      }))
    ];

    return reconciledOptionsWithSections.map(option => {
      const section = sectionsData.find(
        ({ sectionId }) => sectionId === option.sectionId
      );
      const sectionData = section && {
        isSectionCreated: true,
        text: section.sectionTitle
      };

      return {
        ...option,
        cardState: OPTION_STATES.COLLAPSED,
        isCardCreated: true,
        ...sectionData
      };
    });
  };

  useEffect(() => {
    isEditing && sectionsData.length && setOptionsData(createCards());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  const handleAddSection = () => {
    const modifiedOptions = closeCards();
    const currentSection = modifiedOptions[modifiedOptions.length - 1];
    if (!currentSection.isCardCreated) {
      handleNavItemClick(currentSection.id);
    } else {
      const newId = getNumericPart(currentSection.id) + 1;
      modifiedOptions.push({
        id: `${OPTION_TYPES.QUESTION_SECTION}-${newId}`,
        background: '#5a5a5a',
        svg: questionSectionIcon,
        text: 'Question section',
        cardState: OPTION_STATES.EXPANDED,
        isSectionCreated: false,
        isCardCreated: true,
        isDeletable: true,
        sectionId: newId
      });
      const newSectionsData = [...sectionsData];
      newSectionsData.push({
        sectionId: newId,
        sectionTitle: '',
        sectionSeconds: null,
        sectionDescription: '',
        sectionIsLinear: false,
        sectionDuration: ''
      });
      setOptionsData(modifiedOptions);
      setSectionsData(newSectionsData);
      setSelectedOption(`${OPTION_TYPES.QUESTION_SECTION}-${newId}`);
    }
  };

  const handleNavItemClick = id => {
    if (isDragging) return;
    const isCardOpen =
      optionsData.find(option => option.id === id).cardState ===
      OPTION_STATES.EXPANDED;
    const modifiedOptions = closeCards();
    const modifiedItem = modifiedOptions.find(option => option.id === id);
    if (!isCardOpen) modifiedItem.cardState = OPTION_STATES.EXPANDED;
    if (modifiedItem?.isCardCreated === false)
      modifiedItem.isCardCreated = true;

    setOptionsData(modifiedOptions);
    setSelectedOption(id);
  };

  const handleSaveSection = (id, sectionName) => {
    const modifiedOptions = closeCards();
    const modifiedSection = modifiedOptions.find(
      ({ id: itemId }) => itemId === id
    );
    modifiedSection.text = `${sectionName} section`;
    modifiedSection.isSectionCreated = true;
    setOptionsData(modifiedOptions);
  };

  const handleUpdateSection = (id, newData) => {
    const modifiedSectionsData = sectionsData.map(item => {
      if (parseInt(item.sectionId) === getNumericPart(id)) {
        return { ...item, ...newData };
      }
      return item;
    });
    setSectionsData(modifiedSectionsData);
  };

  const handleDeleteConfirm = () => {
    handleCardRemove(deleteConfirmationId);
    setDeleteConfirmationId(null);
  };

  const handleCardRemove = id => {
    const { data, setData } = formData;
    const newData = { ...data };
    const removedSection = id.includes(OPTION_TYPES.QUESTION_SECTION);
    let updatedOptionsData = [...optionsData];
    const removedOption = updatedOptionsData.find(
      ({ id: itemId }) => itemId === id
    );
    removedOption.cardState = OPTION_STATES.HIDDEN;
    removedOption.isCardCreated = false;

    if (removedSection) {
      const deletedSectionId = getNumericPart(id);
      const updatedSectionData = sectionsData.filter(
        ({ sectionId }) => sectionId !== deletedSectionId
      );
      if (updatedSectionData.length) {
        updatedOptionsData = updatedOptionsData.filter(
          ({ id: itemId }) => itemId !== id
        );
      } else {
        removedOption.text = DEFAULT_QUESTION_SECTION_TEXT;
      }
      const emptySectionData = {
        sectionId: 0,
        sectionTitle: '',
        sectionDuration: '',
        sectionSeconds: null,
        sectionDescription: '',
        sectionIsLinear: true
      };

      setSectionsData(
        updatedSectionData.length ? updatedSectionData : [emptySectionData]
      );
    } else {
      newData.messageHeader = '';
      newData.messageParagraph = '';
    }
    setData(newData);
    setOptionsData(updatedOptionsData);
  };

  const getNavOptions = () => {
    const filteredSection = optionsData.filter(
      ({ id }) => !id.includes(OPTION_TYPES.QUESTION_SECTION)
    );
    return [
      ...filteredSection,
      {
        id: OPTION_TYPES.QUESTION_SECTION,
        background: '#5a5a5a',
        svg: questionSectionIcon,
        text: DEFAULT_QUESTION_SECTION_TEXT
      }
    ];
  };

  const createPool = poolIdx => ({
    id: `question-pool-${poolIdx}`,
    isQuestionPool: true
  });

  const handleAddQuestionToSection = (
    question,
    sectionId = null,
    isQuestionPool = false
  ) => {
    let sectionToEdit = showAddQuestionModalId;
    let questionToAdd = question;

    if (isQuestionPool) {
      sectionToEdit = sectionId;
    }
    const newSectionsData = [...sectionsData];

    const editedSectionId = optionsData.find(({ id }) => id === sectionToEdit)
      .sectionId;

    const editedSection = newSectionsData.find(
      ({ sectionId }) => editedSectionId === sectionId
    );
    if (!editedSection?.questions) {
      editedSection.questions = [];
    }

    if (isQuestionPool) {
      questionToAdd = createPool(editedSection?.questions?.length);
    }

    editedSection.questions.push(questionToAdd);

    setSectionsData(newSectionsData);
    setShowAddQuestionModalId(null);
  };

  const getQuestions = () => {
    const usedQuestionsInRegularSlots = sectionsData
      .map(({ questions = [] }) => questions)
      .flat()
      .filter(({ isQuestionPool }) => !isQuestionPool)
      .map(({ id }) => id);

    const usedQuestionsInRandomSlots = [
      ...questionPools
        .map(({ questions = [] }) => questions)
        .flat()
        .map(({ id }) => id)
    ];

    return [...usedQuestionsInRegularSlots, ...usedQuestionsInRandomSlots];
  };

  const handleRemoveQuestion = (questionId, affectedSectionId) => {
    const newSectionsData = [...sectionsData];
    const affectedSection = newSectionsData.find(
      ({ sectionId }) => sectionId === affectedSectionId
    );
    const newQuestions = affectedSection.questions.filter(
      ({ id }) => id !== questionId
    );
    affectedSection.questions = newQuestions;
    setSectionsData(newSectionsData);
  };

  const getAddQuestionModalProps = () => {
    if (showAddQuestionModalId) {
      return {
        onClose: () => setShowAddQuestionModalId(false),
        setQuestions: handleAddQuestionToSection,
        selectedQuestions: getQuestions(),
        isQuestionPool: false
      };
    } else if (showQuestionPoolEditor) {
      return {
        onClose: () => setShowQuestionPoolEditor(false),
        selectedQuestions: getQuestions(),
        isQuestionPool: true,
        questionPools: questionPools,
        handleAddQuestionPool: handleAddQuestionPool,
        handleUpdateQuestionPool: handleUpdateQuestionPool,
        handleRemoveQuestionPool: handleRemoveQuestionPool
      };
    }
    return;
  };

  return (
    <>
      {(showAddQuestionModalId || showQuestionPoolEditor) && (
        <AddQuestionModal {...getAddQuestionModalProps()} />
      )}
      {deleteConfirmationId !== null && (
        <ConfirmDeleteModal
          onClose={() => setDeleteConfirmationId(null)}
          onConfirm={handleDeleteConfirm}
        />
      )}
      <EditableCards
        setDeleteConfirmationId={setDeleteConfirmationId}
        handleCardRemove={handleCardRemove}
        handleAddQuestion={setShowAddQuestionModalId}
        handleAddPool={id => handleAddQuestionToSection(null, id, true)}
        handleOpenQuestionPoolEditor={() => setShowQuestionPoolEditor(true)}
        handleClickOption={handleNavItemClick}
        formData={formData}
        handleSaveSection={handleSaveSection}
        handleUpdateSection={handleUpdateSection}
        optionsData={optionsData}
        sectionsData={sectionsData}
        handleRemoveQuestion={handleRemoveQuestion}
        setIsDragging={setIsDragging}
        formErrors={formErrors}
        isQuestionPoolEnabled={isQuestionPoolEnabled}
        questionPools={questionPools}
      />
      <OptionsNav
        handleAddSection={handleAddSection}
        handleNavItemClick={handleNavItemClick}
        isShowingText={
          !optionsData.some(option => option.cardState !== OPTION_STATES.HIDDEN)
        }
        optionsData={getNavOptions()}
      />
    </>
  );
};

export default AssessmentCreationForm;

AssessmentCreationForm.propTypes = {
  formData: PropTypes.object.isRequired,
  sectionsData: PropTypes.array.isRequired,
  setSectionsData: PropTypes.func.isRequired,
  setSelectedOption: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  setCreatedCards: PropTypes.func.isRequired,
  formErrors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isQuestionPoolEnabled: PropTypes.bool.isRequired,
  questionPools: PropTypes.array.isRequired,
  handleAddQuestionPool: PropTypes.func.isRequired,
  handleUpdateQuestionPool: PropTypes.func.isRequired,
  handleRemoveQuestionPool: PropTypes.func.isRequired
};
