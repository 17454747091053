import multipleChoiceQuestionIcon from 'assets/multipleChoiceQuestionIcon';
import freeResponseQuestionSquareIcon from 'assets/freeResponseQuestionSquareIcon';
import { paths } from 'pages/App/helpers/paths';

export const MULTIPLE_CHOICE = 'Multiple Choice';
export const FREE_RESPONSE = 'Free Response';
export const STOCK_TRADING = 'Stock Trading (non-editable)';
export const QUESTION_ICONS = Object.freeze({
  [MULTIPLE_CHOICE]: multipleChoiceQuestionIcon,
  [FREE_RESPONSE]: freeResponseQuestionSquareIcon
});

export const MAP_BACKEND_QUESTION_TYPES = Object.freeze({
  question_select: MULTIPLE_CHOICE,
  question_banded: FREE_RESPONSE,
  question_stock: STOCK_TRADING
});

export const QUESTION_BACKEND_TYPES = Object.freeze({
  [MULTIPLE_CHOICE]: 'question_select',
  [FREE_RESPONSE]: 'question_banded'
});
export const FORM_ID = 'CREATE_QUESTION_FORM';
export const MIN_ANSWERS_OPTIONS = 1;
export const DEFAULT_ANSWER_OPTIONS = 2;
export const MAX_ANSWERS_OPTIONS = 12;
export const questionLibraryPath = `${paths.ADMIN}/question-library`;
