import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Label } from 'components/Atoms/Text';
import upArrowVIcon from 'assets/upArrowVIcon';
import downArrowV from 'assets/downArrowVIcon';

const SortList = ({ handleSort, isCaretUp, text, isUppercase }) => (
  <Filter data-testid="question-sorter" onClick={handleSort} assessment>
    <SortLabel isUppercase={isUppercase}>{text}</SortLabel>
    <Caret data-testid={`caret-${isCaretUp ? 'up' : 'down'}`}>
      {isCaretUp ? upArrowVIcon : downArrowV}
    </Caret>
  </Filter>
);

SortList.propTypes = {
  handleSort: PropTypes.func.isRequired,
  isCaretUp: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  isUppercase: PropTypes.bool
};

SortList.defaultProps = {
  isUppercase: true
};

export default SortList;

const Filter = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
`;

const Caret = styled.span`
  margin-left: 8px;
`;

const SortLabel = styled(Label)`
  color: ${({ theme }) => theme.colors.steel};
  font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
  font-size: 12px;
  font-weight: normal;
  text-transform: ${({ isUppercase }) => isUppercase && 'uppercase'};
`;
