import getQuestionMaxScore from 'pages/ManagementPage/utils/getQuestionMaxScore';

const validateQuestionPoolSameScore = questionPool => {
  const poolQuestions = questionPool.questions;
  const possibleScore = getQuestionMaxScore(poolQuestions[0]);
  const everyQuestionInThePoolHasTheSameScore = poolQuestions.every(
    question => getQuestionMaxScore(question) === possibleScore
  );
  return everyQuestionInThePoolHasTheSameScore && possibleScore;
};

export default validateQuestionPoolSameScore;
