import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tooltipIcon from 'assets/tooltipIcon';
import { dialogBoxArrow } from 'utils/mixins/Mixins';

const OptionTooltip = ({ className, content }) => {
  return (
    <TooltipContainer className={className} data-testid="tooltip-container">
      {tooltipIcon}
      <Info data-testid="tooltip-info">{content}</Info>
    </TooltipContainer>
  );
};

export default OptionTooltip;

OptionTooltip.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string
};

OptionTooltip.defaultProps = {
  className: '',
  content: ''
};

const TooltipContainer = styled.div`
  cursor: pointer;
  position: relative;
  &:hover {
    & > div {
      display: flex;
    }
  }
`;

const Info = styled.div`
  background: ${({ theme }) => theme.colors.black};
  bottom: 25px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  display: none;
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 14px;
  left: -146px;
  letter-spacing: 0.4px;
  line-height: 18px;
  max-width: 305px;
  padding: 18px 8px;
  position: absolute;
  width: 100vw;
  z-index: 10;
  ${dialogBoxArrow}
`;
