import styled from 'styled-components';
import { H6 } from 'components/Atoms/Text';
import { Container } from 'components/Atoms/Containers';

const AuthFormTitle = styled(H6)`
  margin-bottom: 40px;
  color: ${({ theme }) => theme.colors.white};
`;

const SubmitBtnContainer = styled(Container)`
  margin-top: 16px;
`;

const SuccessMessage = styled.div`
  color: #7ebf5a;
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 14px;
  letter-spacing: 0.4px;
  margin-top: 20px;
  visibility: ${({ isHidden }) => isHidden && 'hidden'};
`;

// Export styled components
export { AuthFormTitle, SubmitBtnContainer, SuccessMessage };
