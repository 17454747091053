import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FlexContainer } from 'components/Atoms/Containers';
import QuillEditor from 'components/Systems/QuillEditor';
import { FormSection, FormTitle } from 'components/Blocks/ManagementForm';

const MessageForm = ({ defaultHeader, defaultParagraph, setData }) => (
  <FormContainer direction="column">
    <FormSection data-testid="message-header">
      <FormTitle>Header</FormTitle>
      <QuillEditor
        initialBody={defaultHeader}
        grayTheme
        inputName="assessment-candidate-info-description"
        isLatexRestricted
        onUpdatedBody={body => setData({ messageHeader: body })}
        placeholder="Header Text"
        isRequired={false}
      />
    </FormSection>
    <FormSection data-testid="message-paragprah">
      <FormTitle>Paragraph</FormTitle>
      <QuillEditor
        initialBody={defaultParagraph}
        grayTheme
        inputName="assessment-candidate-info-description"
        isLatexRestricted
        onUpdatedBody={body => setData({ messageParagraph: body })}
        placeholder="Input message here"
      />
    </FormSection>
  </FormContainer>
);

export default MessageForm;

MessageForm.propTypes = {
  defaultHeader: PropTypes.string.isRequired,
  defaultParagraph: PropTypes.string.isRequired,
  setData: PropTypes.func.isRequired
};

const FormContainer = styled(FlexContainer)`
  width: 100%;
`;
