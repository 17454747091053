export const paths = Object.freeze({
  BASE: '/',
  ADMIN: '/admin',
  LOGIN: '/login',
  PREVIEW: '/preview',
  REQUEST_RESET_PASSWORD: '/request-reset-password',
  RESET_PASSWORD: '/reset-password',
  CREATE_ACCOUNT: '/create-account',
  UNAUTHORIZED: '/unauthorized'
});
