import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  OuterBar,
  SectionLandingBodyContainer,
  TableCell,
  TableHeader,
  TableContainer,
  SectionLandingDivider,
  Description,
  TableData,
  BlankSlot
} from 'pages/ManagementPage/ProctorComponents';
import { FlexContainer } from 'components/Atoms/Containers';
import { parseHtml } from 'pages/PanelPage/helpers/parseHtml';
import secondsToTimeStamp from 'utils/helpers/secondsToTimeStamp';
import { EACH_QUESTION } from 'pages/ManagementPage/Constants';

const QuestionSectionPreview = ({ assessmentColorTheme, sectionData }) => {
  const {
    sectionTitle,
    sectionDescription,
    sectionSeconds,
    sectionIsLinear,
    sectionDuration,
    questions
  } = sectionData;

  const getTime = () => {
    if (sectionDuration === EACH_QUESTION) return 'Individual Question Timing';
    if (isNaN(sectionSeconds)) return;
    return secondsToTimeStamp(sectionSeconds);
  };

  return (
    <PreviewContainer>
      <BarContainer
        data-testid={sectionIsLinear ? 'continuous' : 'noncontinuous'}
      >
        {sectionIsLinear ? (
          <OuterBar />
        ) : (
          <FlexContainer>
            {[...Array(5)].map((el, indx) => (
              <BlankSlot key={`${el}-${indx}`} />
            ))}
          </FlexContainer>
        )}
      </BarContainer>
      <SectionLandingBodyContainer>
        <TableContainer>
          <TableCell>
            <TableHeader>Section:</TableHeader>
            <TableData color={assessmentColorTheme}>{sectionTitle}</TableData>
          </TableCell>
          <TableCell>
            <TableHeader>Duration:</TableHeader>
            <TableData color={assessmentColorTheme}>{getTime()}</TableData>
          </TableCell>
          <TableCell>
            <TableHeader>Number of questions:</TableHeader>
            <TableData color={assessmentColorTheme}>
              {questions?.length}
            </TableData>
          </TableCell>
        </TableContainer>
        <SectionLandingDivider />
        <TableHeader>Description</TableHeader>
        <Description>{parseHtml(sectionDescription)}</Description>
      </SectionLandingBodyContainer>
    </PreviewContainer>
  );
};

export default QuestionSectionPreview;

QuestionSectionPreview.propTypes = {
  assessmentColorTheme: PropTypes.string.isRequired,
  sectionData: PropTypes.shape({
    sectionTitle: PropTypes.string.isRequired,
    sectionDescription: PropTypes.string,
    sectionSeconds: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sectionDuration: PropTypes.string,
    sectionIsLinear: PropTypes.bool,
    questions: PropTypes.array
  })
};

QuestionSectionPreview.defaultProps = {
  sectionData: {
    sectionTitle: '',
    sectionDescription: '',
    sectionSeconds: null,
    sectionDuration: '',
    sectionIsLinear: false,
    questions: []
  }
};

const PreviewContainer = styled.div`
  margin: 90px 0 0;
`;

const BarContainer = styled.div`
  margin-bottom: 64px;
`;
