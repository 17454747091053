import PropTypes from 'prop-types';
import React from 'react';
import TakerSkillsBreakdownBar from 'pages/PanelPage/components/charts/TakerSkillsBreakdownBar';
import { FlexContainer } from 'components/Atoms/Containers';
import { Row, BorderTable, BorderCell } from 'components/Blocks/Styled/Tables';
import { Body1 } from 'components/Atoms/Text';

const SkillsBreakdownTable = ({ categoryScores }) => {
  return (
    <BorderTable borderCollapse="collapse" margin="16px 0">
      <thead>
        <Row>
          <BorderCell padding="12px 27px 8px">
            <Body1 className="bold" textTransform="capitalize" color="#212629">
              skills breakdown
            </Body1>
          </BorderCell>
        </Row>
      </thead>
      <tbody>
        <Row>
          <BorderCell>
            <FlexContainer height="500px" width="100%">
              <TakerSkillsBreakdownBar
                categoryScores={Object.values(categoryScores)}
              />
            </FlexContainer>
          </BorderCell>
        </Row>
      </tbody>
    </BorderTable>
  );
};

SkillsBreakdownTable.propTypes = {
  categoryScores: PropTypes.object.isRequired
};

export default SkillsBreakdownTable;
