import axios from 'axios';
import endpoints from 'utils/requests/endpoints';

const baseURL = process.env.REACT_APP_API_URL;
const extendedURL = `${baseURL}/api/v1`;

const instance = axios.create({ baseURL: extendedURL });

// handles sending token automatically
instance.interceptors.request.use(
  config => {
    const access = localStorage.getItem('access');

    if (access) {
      config.headers['Authorization'] = 'Bearer ' + access;
    }
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  error => Promise.reject(error)
);

// handles token refreshes
instance.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config;

    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refresh = localStorage.getItem('refresh');
      localStorage.removeItem('access');
      if (refresh) {
        return axios
          .post(
            `${process.env.REACT_APP_API_URL}/api/v1/authentication/refresh`,
            {},
            { headers: { Authorization: `Bearer ${refresh}` } }
          )
          .then(response => {
            if (response.status === 200 || response.status === 201) {
              localStorage.setItem('access', response.data.data.access_token);

              return instance(originalRequest);
            }
          });
      }
    }
    return Promise.reject(error);
  }
);

/**
 *
 * @param {string} endpoint - name of key from endpoints.js
 * @param {object} headers - HTTP headers
 * @param {array} urlParams - if the endpoint has any params in it, pass in an array of values in the order they appear in the URL
 * @param {object} queryParams - object of query parameters. will be serialized
 * @param {object} data - object to send as request body. will be converted to json
 *
 * @returns {promise}
 */

const makeRequest = async ({
  endpoint,
  urlParams = [],
  data = undefined,
  headers = {},
  queryParams = {}
}) => {
  if (!endpoint) {
    throw new Error('endpoint is a required option');
  }
  if (!endpoints[endpoint]) {
    throw new Error(
      'Invalid endpoint. Check your spelling or consult endpoints.js'
    );
  }
  if (!Array.isArray(urlParams)) {
    throw new Error('urlParams must be an array of strings');
  }

  const hasParam = typeof endpoints[endpoint] === 'function';
  const url = hasParam
    ? `${extendedURL}/${endpoints[endpoint](...urlParams).endpoint}`
    : `${extendedURL}/${endpoints[endpoint].endpoint}`;
  const method =
    (hasParam ? endpoints[endpoint]().method : endpoints[endpoint].method) ||
    'get';

  try {
    let response = await instance({
      url,
      headers,
      method,
      params: queryParams,
      data
    });

    const { status, statusText } = response;
    const responseLength = Object.keys(response.data.data).length;
    const responseData = responseLength ? response.data.data : null;
    const responseObj = {
      data: responseData,
      status,
      statusText
    };
    return responseObj;
  } catch (error) {
    // log to GCP here ideally
    console.error(error);
  }
};

export default makeRequest;
