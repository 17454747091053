import { ResponsiveBar } from '@nivo/bar';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { FlexContainer, Container } from 'components/Atoms/Containers';
import { Body1 } from 'components/Atoms/Text';

const TakerSkillsBreakdownBar = ({ categoryScores }) => {
  const colors = {
    weighted_score_color: '#6eba44',
    missed_color: '#e55531',
    blank_color: '#cbcbcb'
  };

  const categoryScoresWithMissed = categoryScores.map(category => {
    return {
      ...category,
      missed:
        category.weighted_max_score - category.weighted_score - category.blank
    };
  });

  // Find like objects in categoryScoresWithMissed and add the values
  // Prob not the most sophistocated way of doing this? Maybe a reduce?
  const data = {};

  categoryScoresWithMissed.forEach(obj => {
    data[obj.name]
      ? (data[obj.name] = {
          ...data[obj.name],
          weighted_score: data[obj.name].weighted_score + obj.weighted_score,
          missed: data[obj.name].missed + obj.missed,
          blank: data[obj.name].blank + obj.blank,
          weighted_max_score:
            data[obj.name].weighted_max_score + obj.weighted_max_score
        })
      : (data[obj.name] = obj);
  });

  /**
   * Returns a tick element that wraps text for the given number of lines and adds an ellipsis if the text can't fit. This can be passed to the renderTick method.
   */
  const HorizontalTick = ({
    textAnchor,
    textBaseline,
    value,
    x,
    y,
    maxLineLength = 25,
    maxLines = 2
  }) => {
    const LENGTH_OF_ELLIPSIS = 3;
    const TRIM_LENGTH = maxLineLength * maxLines - LENGTH_OF_ELLIPSIS;
    const trimWordsOverLength = new RegExp(`^(.{${TRIM_LENGTH}}[^\\w]*).*`);
    const groupWordsByLength = new RegExp(
      `([^\\s].{0,${maxLineLength}}(?=[\\s\\W]|$))`,
      'gm'
    );
    const splitValues = value
      .replace(trimWordsOverLength, '$1...')
      .match(groupWordsByLength)
      .slice(0, 2)
      .map((val, i) => (
        <tspan
          key={val}
          dy={12 * i}
          x={-10}
          style={{ fontFamily: 'sans-serif', fontSize: '11px' }}
        >
          {val}
        </tspan>
      ));

    return (
      <g transform={`translate(${x},${y})`}>
        <text alignmentBaseline={textBaseline} textAnchor={textAnchor}>
          {splitValues}
        </text>
      </g>
    );
  };

  const tooltip = ({ data: { name, weighted_score, missed, blank } }) => {
    return (
      <>
        <NameContainer>
          <Body1 className="bold" margin="0 auto">
            {name}
          </Body1>
        </NameContainer>
        <StatsContainer direction="column">
          <FlexContainer align="baseline" padding="4px 0">
            <Dot background={colors.missed_color} />
            <TooltipText>Missed: {missed.toFixed(2)}</TooltipText>
          </FlexContainer>
          <FlexContainer align="baseline" padding="4px 0">
            <Dot background={colors.blank_color} />
            <TooltipText>Unanswered: {blank.toFixed(2)}</TooltipText>
          </FlexContainer>
          <FlexContainer align="baseline" padding="4px 0">
            <Dot background={colors.weighted_score_color} />
            <TooltipText>Obtained: {weighted_score.toFixed(2)}</TooltipText>
          </FlexContainer>
        </StatsContainer>
      </>
    );
  };

  const MyResponsiveBar = () => (
    <ResponsiveBar
      animate={true}
      axisBottom={{
        tickSize: 0,
        tickPadding: 8,
        tickRotation: 0,
        legend: 'Points',
        legendPosition: 'end',
        legendOffset: 36
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 8,
        tickRotation: 0,
        legend: '',
        legendPosition: 'end',
        legendOffset: 14,
        renderTick: HorizontalTick
      }}
      axisRight={null}
      axisTop={null}
      colors={({ id }) => colors[`${id}_color`]}
      data={Object.values(data)}
      enableGridX={true}
      enableGridY={false}
      enableLabel={false}
      indexBy="name"
      keys={['weighted_score', 'missed', 'blank']}
      layout="horizontal"
      labelSkipHeight={12}
      labelSkipWidth={12}
      legends={[]}
      margin={{ top: 20, right: 30, bottom: 50, left: 160 }}
      motionDamping={15}
      motionStiffness={90}
      padding={0.25}
      theme={{
        tooltip: {
          container: { padding: 0, borderRadius: '4px' }
        },
        axis: {
          ticks: {
            text: { fill: '#6a7f87' }
          }
        }
      }}
      tooltip={tooltip}
    />
  );

  return MyResponsiveBar();
};

TakerSkillsBreakdownBar.propTypes = {
  categoryScores: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default TakerSkillsBreakdownBar;

const StatsContainer = styled(FlexContainer)`
  border: ${({ theme }) => `1px solid ${theme.colors.iron}`};
  border-radius: 0 0 4px 4px;
  border-top: none;
  padding: 8px 12px;
`;

const TooltipText = styled(Body1)`
  font-size: 16px;
`;

const NameContainer = styled(FlexContainer)`
  border: ${({ theme }) => `1px solid ${theme.colors.iron}`};
  padding: 12px 12px 8px;
  border-radius: 4px 4px 0 0;
`;

const Dot = styled(Container)`
  border-radius: 12px;
  height: 8px;
  margin: 0 8px 0 0;
  width: 8px;
`;
