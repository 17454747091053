import React from 'react';
import { Svg } from 'components/Blocks/Styled/Media';

const downloadIcon = (
  <Svg
    width="12px"
    height="12px"
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    dynamic
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-1150.000000, -454.000000)"
        fill="currentColor"
        fillRule="nonzero"
      >
        <g transform="translate(930.000000, 381.000000)">
          <g transform="translate(220.000000, 69.000000)">
            <g transform="translate(0.000000, 4.000000)">
              <path
                d="M9.60433959,6.52734375 L6,10.1316833 L2.39566041,6.52734375 
                L3.05859375,5.86441041 L5.53125,8.33706666 L5.53125,-1.33226763e-15 
                L6.46875,-1.33226763e-15 L6.46875,8.33706666 L8.94140625,5.86441041 
                L9.60433959,6.52734375 Z M12,11.0625 L0,11.0625 L0,12 L12,12 L12,11.0625 Z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </Svg>
);

export default downloadIcon;
