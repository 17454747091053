import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tickIcon from 'assets/tickIcon';
import { FlexContainer } from 'components/Atoms/Containers';
import { handleButtonHover } from 'utils/helpers/handleButtonHover';

const Checkbox = ({ isChecked, isDisabled, onClick }) => (
  <CheckboxContainer
    data-testid="checkbox"
    isChecked={isChecked}
    isDisabled={isDisabled}
    onClick={!isDisabled ? onClick : () => null}
  >
    {isChecked && tickIcon}
  </CheckboxContainer>
);

export default Checkbox;

Checkbox.propTypes = {
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func
};

Checkbox.defaultProps = {
  isDisabled: false,
  isChecked: false,
  onClick: () => null
};

const CheckboxContainer = styled(FlexContainer)`
  background: ${({ isDisabled, isChecked, theme }) =>
    isDisabled
      ? '#a0a0a0'
      : isChecked
      ? theme.colors.darkBlue
      : theme.colors.white};
  border-radius: 2px;
  border: ${({ isDisabled, isChecked }) =>
    !isChecked && !isDisabled && '1px solid rgb(147, 147, 147)'};
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  display: flex;
  height: 16px;
  transition: 300ms ease-out;
  width: 16px;
  &:hover {
    background: ${({ isDisabled, isChecked, theme }) =>
      !isDisabled &&
      handleButtonHover(
        isChecked ? theme.colors.darkBlue : theme.colors.white
      )};
  }
`;
