import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Input from 'components/Blocks/Forms/Input';
import { FlexContainer } from 'components/Atoms/Containers';

const ToggleSwitch = ({ handleSwitch, inputName, isOn }) => (
  <FlexContainer align="center" justify="center">
    <ToggleSwitchContainer isOn={isOn}>
      <SwitchInput
        name={inputName}
        data-testid="switch-input"
        type="checkbox"
        checked={isOn}
        onChange={handleSwitch}
        showUnderLine={false}
      />
      <Span className="slider" />
    </ToggleSwitchContainer>
    <ToggleDescription isOn={isOn}>{isOn ? 'On' : 'Off'}</ToggleDescription>
  </FlexContainer>
);

export default ToggleSwitch;

ToggleSwitch.propTypes = {
  handleSwitch: PropTypes.func.isRequired,
  inputName: PropTypes.string.isRequired,
  isOn: PropTypes.bool.isRequired
};

const ToggleDescription = styled.span`
  color: ${({ isOn }) => (isOn ? '#3dbb60' : '#ccc')};
  font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 1.5px;
  line-height: 16px;
  text-transform: uppercase;
  transition: 0.2s;
`;

const ToggleSwitchContainer = styled.label`
  display: inline-block;
  height: 12px;
  margin-right: 8px;
  position: relative;
  width: 32px;
  ${({ isOn }) =>
    isOn &&
    `span {
    background-color: #3dbb60;
    &:before {
      margin-left: calc(100% - 8px);
    }
  }`}
`;

const SwitchInput = styled(Input)`
  height: 0;
  opacity: 0;
  width: 0;
`;
const Span = styled.span`
  align-items: center;
  background-color: #ccc;
  border-radius: 6px;
  border: 0;
  bottom: 0;
  cursor: pointer;
  display: flex;
  height: 100%;
  left: 0;
  padding: 2px;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
  width: 100%;
  &:before {
    content: '';
    background: rgb(255, 255, 255);
    border-radius: 6px;
    border: 4px solid white;
    border: ${({ theme }) => `4px solid $`};
    transition: 0.4s;
  }
`;
