import React from 'react';

const eyeLineIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-1164 -627)">
        <g transform="translate(208 80)">
          <g transform="translate(0 86)">
            <g transform="translate(0 437)">
              <g transform="translate(24 24)">
                <g transform="translate(932)">
                  <rect
                    width="24"
                    height="24"
                    x="0"
                    y="0"
                    opacity="0.048"
                    rx="2"
                  ></rect>
                  <g stroke="#575766" transform="translate(6 9)">
                    <circle cx="6" cy="4.13" r="2.283"></circle>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M0 3.478C2 1.16 4 0 6 0s4 1.16 6 3.478"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default eyeLineIcon;
