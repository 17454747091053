import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FlexContainer } from 'components/Atoms/Containers';
import { Caption } from 'components/Atoms/Text';
import Underline from 'components/Atoms/Underline';
import { paths } from 'pages/App/helpers/paths';
import { questionLibraryPath } from 'pages/QuestionPage/Constants';
import {
  VALID_STATES as VS,
  ACTIVE_ASSESSMENTS,
  ACTIVE_QUESTIONS,
  INACTIVE_ASSESSMENTS,
  INACTIVE_QUESTIONS
} from 'utils/constants/stateTypes.js';
import { listTypes } from 'pages/App/helpers/listTypes';

const getLinks = isInactive => {
  return {
    assessments: [
      {
        text: VS.ALL,
        url: isInactive
          ? `${paths.ADMIN}/${VS.INACTIVE}`
          : `${paths.ADMIN}/${VS.ALL}`,
        filterList: isInactive ? INACTIVE_ASSESSMENTS : ACTIVE_ASSESSMENTS,
        groups: [VS.ALL, VS.INACTIVE]
      },
      {
        text: VS.ACTIVE,
        url: `${paths.ADMIN}/${VS.ALL}/${VS.ACTIVE}`,
        filterList: [VS.ACTIVE],
        groups: [VS.ALL]
      },
      {
        text: VS.REVIEW,
        url: isInactive
          ? `${paths.ADMIN}/${VS.INACTIVE}/${VS.REVIEW}`
          : `${paths.ADMIN}/${VS.ALL}/${VS.REVIEW}`,
        filterList: [VS.REVIEW],
        groups: [VS.ALL, VS.INACTIVE]
      },
      {
        text: VS.ARCHIVED,
        url: isInactive
          ? `${paths.ADMIN}/${VS.INACTIVE}/${VS.ARCHIVED}`
          : `${paths.ADMIN}/${VS.ALL}/${VS.ARCHIVED}`,
        filterList: [VS.ARCHIVED],
        groups: [VS.ALL, VS.INACTIVE]
      }
    ],
    questions: [
      {
        text: VS.ALL,
        url: isInactive
          ? `${questionLibraryPath}/${VS.INACTIVE}`
          : `${questionLibraryPath}/${VS.ALL}`,
        filterList: isInactive ? INACTIVE_QUESTIONS : ACTIVE_QUESTIONS,
        groups: [VS.ALL, VS.INACTIVE]
      },
      {
        text: VS.ACTIVE,
        url: `${questionLibraryPath}/${VS.ALL}/${VS.ACTIVE}`,
        filterList: [VS.ACTIVE],
        groups: [VS.ALL]
      },
      {
        text: VS.DRAFT,
        url: isInactive
          ? `${questionLibraryPath}/${VS.INACTIVE}/${VS.DRAFT}`
          : `${questionLibraryPath}/${VS.ALL}/${VS.DRAFT}`,
        filterList: [VS.DRAFT],
        groups: [VS.ALL, VS.INACTIVE]
      },
      {
        text: VS.RETIRED,
        url: isInactive
          ? `${questionLibraryPath}/${VS.INACTIVE}/${VS.RETIRED}`
          : `${questionLibraryPath}/${VS.ALL}/${VS.RETIRED}`,
        filterList: [VS.RETIRED],
        groups: [VS.ALL, VS.INACTIVE]
      }
    ]
  };
};

const FilterList = ({ type, onFilterClick, selectedFilter }) => {
  const history = useHistory();
  const isInactive = selectedFilter === VS.INACTIVE;

  const renderOptions = () => {
    return getLinks(isInactive)[type].map(option => {
      const isHighlighted = history.location.pathname === option.url;

      if (option.groups.includes(selectedFilter)) {
        return (
          <OptionContainer
            key={option.text}
            align="center"
            direction="column"
            onClick={() => onFilterClick(option.filterList)}
          >
            <NavOption isHighlighted={isHighlighted}>
              <Link to={option.url} data-testid="filter-link">
                {option.text}
              </Link>
            </NavOption>
            {isHighlighted && <Underline type="filter-list" />}
          </OptionContainer>
        );
      } else {
        return null;
      }
    });
  };

  return <FilterContainer align="center">{renderOptions()}</FilterContainer>;
};

FilterList.propTypes = {
  type: PropTypes.oneOf(Object.values(listTypes)).isRequired,
  onFilterClick: PropTypes.func.isRequired,
  selectedFilter: PropTypes.string.isRequired
};

export default FilterList;

const FilterContainer = styled(FlexContainer)`
  height: 100%;
`;

const OptionContainer = styled(FlexContainer)`
  margin-right: 16px;
  width: 'fit-content';
`;

const NavOption = styled(Caption)`
  color: ${({ theme }) => theme.colors.steel};
  letter-spacing: 0.89px;
  text-transform: uppercase;
  a {
    color: ${({ isHighlighted, theme }) =>
      isHighlighted ? theme.colors.darkBlue : theme.colors.steel};
    text-decoration: none;
  }
`;
