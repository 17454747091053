import React from 'react';

const tickIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
  >
    <defs>
      <filter
        id="filter-1"
        width="107.1%"
        height="112.4%"
        x="-3.5%"
        y="-6.2%"
        filterUnits="objectBoundingBox"
      >
        <feOffset
          dy="2"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="3"
        ></feGaussianBlur>
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.100879589 0"
        ></feColorMatrix>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
          <feMergeNode in="SourceGraphic"></feMergeNode>
        </feMerge>
      </filter>
    </defs>
    <g
      fill="none"
      fillRule="evenodd"
      stroke="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
    >
      <g stroke="#FFF" strokeWidth="1.6" transform="translate(-159 -732)">
        <g filter="url(#filter-1)" transform="translate(145 556)">
          <g transform="translate(15.143 16)">
            <path
              d="M4.03579418 8L7.06005341 11 12.1073826 5"
              transform="translate(1 160)"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default tickIcon;
