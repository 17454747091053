import React from 'react';

const downArrowIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 24 24"
    fill="#ffffff"
  >
    <path d="M12 21l-12-18h24z" />
  </svg>
);

export default downArrowIcon;
