import React from 'react';
import styled from 'styled-components';
import { FlexContainer } from 'components/Atoms/Containers';
import { Body1 } from 'components/Atoms/Text';

const NoResults = () => {
  return (
    <FlexContainer align="center">
      <NoResultsMessage>
        Your applied filters returned no results
      </NoResultsMessage>
    </FlexContainer>
  );
};

export default NoResults;

const NoResultsMessage = styled(Body1)`
  color: ${({ theme }) => theme.colors.darkBlue};
  margin: 14px 0;
`;
