import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useClickedOutsideEvent } from 'hooks/useClickedOutsideEvent';
import { listTypes } from 'pages/App/helpers/listTypes';
import { paths } from 'pages/App/helpers/paths';
import { questionLibraryPath } from 'pages/QuestionPage/Constants';
import {
  VALID_STATES as VS,
  INACTIVE_ASSESSMENTS
} from 'utils/constants/stateTypes';
import activeBlurIcon from 'assets/activeBlurIcon';
import activeFocusIcon from 'assets/activeFocusIcon';
import allBlurIcon from 'assets/allBlurIcon';
import allFocusIcon from 'assets/allFocusIcon';
import inactiveBlurIcon from 'assets/inactiveBlurIcon';
import inactiveFocusIcon from 'assets/inactiveFocusIcon';
import downArrow from 'assets/downArrowIcon';
import upArrowIcon from 'assets/upArrowIcon';
import {
  FlexContainer,
  Container,
  PositionContainer
} from 'components/Atoms/Containers';
import { H4, Body2, Body3 } from 'components/Atoms/Text';

export const options = {
  assessments: [
    {
      label: VS.ACTIVE,
      text: 'Active Assessments',
      url: `${paths.ADMIN}/${VS.ACTIVE}`,
      selected: activeFocusIcon,
      unselected: activeBlurIcon,
      stateList: [VS.ACTIVE]
    },
    {
      label: VS.INACTIVE,
      text: 'Inactive Assessments',
      url: `${paths.ADMIN}/${VS.INACTIVE}`,
      selected: inactiveFocusIcon,
      unselected: inactiveBlurIcon,
      stateList: INACTIVE_ASSESSMENTS
    },
    {
      label: VS.ALL,
      text: 'All Assessments',
      url: `${paths.ADMIN}/${VS.ALL}`,
      selected: allFocusIcon,
      unselected: allBlurIcon,
      stateList: [VS.ACTIVE, VS.ARCHIVED, VS.REVIEW]
    }
  ],
  questions: [
    {
      label: VS.ALL,
      text: 'All Questions',
      url: `${questionLibraryPath}/${VS.ALL}`,
      selected: allFocusIcon,
      unselected: allBlurIcon,
      stateList: [VS.ACTIVE, VS.DRAFT, VS.RETIRED]
    },
    {
      label: VS.ACTIVE,
      text: 'Active Questions',
      url: `${questionLibraryPath}/${VS.ACTIVE}`,
      selected: activeFocusIcon,
      unselected: activeBlurIcon,
      stateList: [VS.ACTIVE]
    },
    {
      label: VS.INACTIVE,
      text: 'Inactive Questions',
      url: `${questionLibraryPath}/${VS.INACTIVE}`,
      selected: inactiveFocusIcon,
      unselected: inactiveBlurIcon,
      stateList: [VS.DRAFT, VS.RETIRED]
    }
  ]
};

const TypeToggle = ({
  type,
  countObj,
  setStateList,
  setSelectedFilter,
  selectedFilter
}) => {
  const [dropdownState, setDropdownState] = useState(false);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    const filterInUrl = options[type].find(
      option => option.label === selectedFilter
    );
    setSelected(filterInUrl?.text || options[type][0].text);
  }, [selectedFilter, type]);

  const dropdownRef = useRef(null);
  useClickedOutsideEvent(dropdownRef, () => setDropdownState(false));

  const renderOptions = () => {
    return options[type].map(option => {
      const isChecked = selected === option.text;
      return (
        <DropdownOptionContainer key={option.label}>
          <Link
            to={option.url}
            onClick={() => {
              setDropdownState(false);
              setSelected(option.text);
              setSelectedFilter(option.label);
              setStateList(option.stateList);
            }}
          >
            <DropdownOption
              align="center"
              justify="space-between"
              data-testid="type-toggle-option"
            >
              <FlexContainer>
                <AssetContainer>
                  {isChecked ? option.selected : option.unselected}
                </AssetContainer>
                <TextContainer direction="column" justify="center">
                  <Status>{option.text}</Status>
                  <Count>
                    {countObj[option.label]} <span>{type}</span>
                  </Count>
                </TextContainer>
                {isChecked && <Check />}
              </FlexContainer>
            </DropdownOption>
          </Link>
        </DropdownOptionContainer>
      );
    });
  };

  return (
    <Container
      ref={dropdownRef}
      data-testid="type-toggle"
      onClick={() => setDropdownState(!dropdownState)}
    >
      <FlexContainer align="center" className="pointer">
        <DropdownHeader>{selected}</DropdownHeader>
        <Container>{dropdownState ? upArrowIcon : downArrow}</Container>
      </FlexContainer>
      {dropdownState && (
        <DropdownContainer>{renderOptions()}</DropdownContainer>
      )}
    </Container>
  );
};

TypeToggle.propTypes = {
  type: PropTypes.oneOf(Object.values(listTypes)).isRequired,
  countObj: PropTypes.object,
  setStateList: PropTypes.func,
  setSelectedFilter: PropTypes.func,
  selectedFilter: PropTypes.string
};

TypeToggle.defaultProps = {
  countObj: {},
  setStateList: () => null,
  setSelectedFilter: () => null,
  selectedFilter: ''
};

export default TypeToggle;

const AssetContainer = styled(FlexContainer)`
  border-radius: 100%;
`;

const Check = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.tenantAccent};
  border-right: 2px solid ${({ theme }) => theme.tenantAccent};
  height: 20px;
  margin-left: 5px;
  transform: rotate(45deg);
  width: 10px;
`;

const Count = styled(Body3)`
  color: #a3a3a3;
  span {
    text-transform: capitalize;
  }
`;

const DropdownContainer = styled(PositionContainer)`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  box-shadow: 1px 2px 6px 0px rgba(0, 0, 0, 0.28);
  left: 0;
  padding: 8px;
  top: 120%;
  z-index: 10;
`;

const DropdownHeader = styled(H4)`
  color: ${({ theme }) => theme.colors.white};
  margin-right: 12px;
`;

const DropdownOption = styled(FlexContainer)`
  padding: 8px;
`;

const DropdownOptionContainer = styled(Container)`
  border-radius: 4px;
  padding: 0 4px 0 0;
  &:hover {
    transition: 200ms ease-out;
    background: #f3f2f2;
  }
  a {
    text-decoration: none;
  }
`;

const Status = styled(Body2)`
  display: contents;
  color: ${({ theme }) => theme.colors.black};
`;

const TextContainer = styled(FlexContainer)`
  margin: 0 12px;
`;
