import React from 'react';
import { Svg } from 'components/Blocks/Styled/Media';

const tooltipIcon = (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    dynamic
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-354 -569)">
        <g transform="translate(354 569)">
          <circle cx="7" cy="7" r="6.5" stroke="currentColor"></circle>
          <path
            fill="currentColor"
            fillRule="nonzero"
            d="M7.504 8l.406-4.5a.914.914 0 10-1.82 0L6.496 8h1.008zM7 11.352c.533 0 1-.467 1-1 0-.533-.45-1-1-1s-1 .45-1 1c0 .533.483 1 1 1z"
          ></path>
        </g>
      </g>
    </g>
  </Svg>
);

export default tooltipIcon;
