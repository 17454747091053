import React from 'react';

const switchIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <defs>
      <path
        id="path-1"
        d="M4.667 3.5a3.5 3.5 0 100 7h4.666a3.5 3.5 0 100-7H4.667zm4.666-1.167a4.667 4.667 0 110 9.334H4.667a4.667 4.667 0 010-9.334h4.666zM4.667 4.667a2.333 2.333 0 100 4.666 2.333 2.333 0 000-4.666zm0 1.166a1.167 1.167 0 110 2.334 1.167 1.167 0 010-2.334z"
      ></path>
    </defs>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-133 -44)">
        <g transform="translate(133 44)">
          <path d="M0 0H14V14H0z"></path>
          <mask fill="#fff">
            <use xlinkHref="#path-1"></use>
          </mask>
          <use
            fill="#939393"
            fillRule="nonzero"
            transform="matrix(-1 0 0 1 14 0)"
            xlinkHref="#path-1"
          ></use>
        </g>
      </g>
    </g>
  </svg>
);

export default switchIcon;
