import React from 'react';
import styled from 'styled-components';
import { renderToString } from 'react-dom/server';
import { decode } from 'html-entities';

const wrapWithWhiteSpace = html =>
  decode(renderToString(<WhiteSpaceWrapper>{html}</WhiteSpaceWrapper>));

export default wrapWithWhiteSpace;

const WhiteSpaceWrapper = styled.div`
  white-space: pre-wrap;
  word-break: break-word;
`;
