import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import pluralize from 'pluralize';
import { FlexContainer } from 'components/Atoms/Containers';
import { H3, TruncateTextByLines } from 'components/Atoms/Text';
import switchIcon from 'assets/switchIcon';
import clockIcon from 'assets/clockIcon';
import personIcon from 'assets/personIcon';
import checkIcon from 'assets/checkIcon';

const COLUMN_TYPES = Object.freeze({
  LIVE_ASSESSMENTS: 'LIVE_ASSESSMENTS',
  PAST_ASSESSMENTS: 'PAST_ASSESSMENTS',
  HISTORICAL_DATA: 'HISTORICAL_DATA'
});

const QuestionAdditionalInformation = ({ information }) => {
  const {
    live_assessments: liveAssessments = [],
    past_assessments: pastAssessments = [],
    historical_response_data: historicalResponseData = {}
  } = information;
  const [showMoreLiveAssessments, setShowMoreLiveAssessments] = useState(false);
  const [showMorePastAssessments, setShowMorePastAssessments] = useState(false);
  const correctnessPercentage = (
    historicalResponseData?.correctness_percentage * 100
  ).toFixed(2);

  const getTimeDelta = dateFrom => {
    const dateTo = new Date();
    const diff = new Date(
      dateTo.getFullYear() - dateFrom.getFullYear(),
      dateTo.getMonth() - dateFrom.getMonth(),
      dateTo.getDate() - dateFrom.getDate()
    );

    const years = diff.getYear();
    const months = diff.getMonth();
    const days = diff.getDate() || 1;

    if (years) {
      return `${years} yr ago`;
    }
    if (months) {
      return `${months} mo ago`;
    }
    return `${days} d ago`;
  };

  const renderList = (list, columnType) => {
    if (columnType === COLUMN_TYPES.LIVE_ASSESSMENTS) {
      return renderCorrectList(
        list,
        showMoreLiveAssessments,
        setShowMoreLiveAssessments
      );
    }
    if (columnType === COLUMN_TYPES.PAST_ASSESSMENTS) {
      return renderCorrectList(
        list,
        showMorePastAssessments,
        setShowMorePastAssessments
      );
    }
  };

  const renderCorrectList = (list, showMoreItems, setShowMoreItems) => {
    const allListItems = [...list];
    const truncatedListItems = allListItems.splice(0, 3);

    return (
      <>
        <InformationList>
          {truncatedListItems.map(({ tenant, info }, idx) => (
            <InformationListItem key={idx}>
              <TruncateTextByLines lines={1}>
                <TenantName>{tenant}</TenantName>, {info}
              </TruncateTextByLines>
            </InformationListItem>
          ))}
          {showMoreItems &&
            allListItems.map(({ tenant, info }, idx) => (
              <InformationListItem key={idx}>
                <TruncateTextByLines lines={1}>
                  <TenantName>{tenant}</TenantName>, {info}
                </TruncateTextByLines>
              </InformationListItem>
            ))}
        </InformationList>
        {!showMoreItems && Boolean(allListItems?.length) && (
          <ShowMoreButton onClick={() => setShowMoreItems(true)}>
            +{allListItems.length} more
          </ShowMoreButton>
        )}
      </>
    );
  };

  return (
    <>
      <Separator />
      <AdditionalInfoContainer justify="space-between">
        <ItemContainer>
          <ItemContainer align="center">
            {switchIcon}
            <section>
              <StyledH3>
                {liveAssessments.length
                  ? 'On Live assessments'
                  : 'Not on Live Assessments'}
              </StyledH3>
              {renderList(
                liveAssessments.map(({ name, tenant }) => ({
                  info: name,
                  tenant: tenant.name
                })),
                COLUMN_TYPES.LIVE_ASSESSMENTS
              )}
            </section>
          </ItemContainer>
        </ItemContainer>

        <ItemContainer isPastAssessments>
          {clockIcon}
          <section>
            <StyledH3>
              {pastAssessments.length
                ? 'On Past assessments'
                : 'Not on Past Assessments'}
            </StyledH3>
            {renderList(
              pastAssessments.map(({ date_used, tenant }) => ({
                info: getTimeDelta(new Date(date_used)),
                tenant: tenant.name
              })),
              COLUMN_TYPES.PAST_ASSESSMENTS
            )}
          </section>
        </ItemContainer>

        <ItemContainer isHistoricalResponseData>
          <section>
            <HistoricalResponseItem align="center">
              {personIcon}
              <StyledH3>
                {historicalResponseData?.total_usage > 0
                  ? `${pluralize(
                      'Candidate',
                      historicalResponseData.total_usage,
                      true
                    )} Answered`
                  : 'No Candidates Answered'}
              </StyledH3>
            </HistoricalResponseItem>

            <HistoricalResponseItem align="center">
              {checkIcon}
              <StyledH3>
                {historicalResponseData?.correctness_percentage > 0
                  ? `${correctnessPercentage}% Got Correct`
                  : 'No % Correct Recorded'}
              </StyledH3>
            </HistoricalResponseItem>
          </section>
        </ItemContainer>
      </AdditionalInfoContainer>
    </>
  );
};

export default QuestionAdditionalInformation;

QuestionAdditionalInformation.propTypes = {
  information: PropTypes.object.isRequired
};

const Separator = styled.div`
  background: #e5e1e1;
  height: 1px;
  margin: 0 -24px;
`;

const AdditionalInfoContainer = styled(FlexContainer)`
  padding: 24px 48px;
`;

const ItemContainer = styled.div`
  flex-basis: calc(33.33% - 15px);
  max-width: ${({ isPastAssessments, isHistoricalResponseData }) => {
    if (isPastAssessments) return '250px';
    if (isHistoricalResponseData) return '200px';
  }};
  position: relative;
  svg {
    position: absolute;
    left: -25px;
  }
`;

const StyledH3 = styled(H3)`
  color: ${({ theme }) => theme.colors.steel};
  margin: 0 0 8px 0;
`;

const InformationList = styled.ul`
  margin: 0;
  padding: 0 0 0 18px;
`;

const InformationListItem = styled.li`
  color: ${({ theme }) => theme.colors.steel};
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 20px;
  margin-bottom: 4px;

  &::marker {
    color: ${({ theme }) => theme.colors.steel};
  }
`;

const TenantName = styled.span`
  color: #373440;
  font-size: 14px;
`;

const HistoricalResponseItem = styled(FlexContainer)`
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 20px;
  margin-bottom: 4px;
  h3 {
    margin: 0;
  }
  svg {
    bottom: 1px;
    left: -15px;
    position: relative;
  }
`;

const ShowMoreButton = styled.button`
  background: 0;
  border: 0;
  border-bottom: 1px dashed #373440;
  color: #373440;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 14px;
  margin-left: 5px;
  outline: 0;
  padding: 0;
`;
