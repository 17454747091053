import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FlexContainer } from 'components/Atoms/Containers';
import QuestionInformationItem from 'components/Blocks/QuestionInformationItem';
import CategoriesDropdown from 'pages/ManagementPage/CategoriesDropdown';
import gridIcon from 'assets/gridIcon';
import { MAP_BACKEND_QUESTION_TYPES } from 'pages/QuestionPage/Constants';
import { QuestionInformationItemTypes } from 'utils/constants/constants';
import createPreview from 'pages/QuestionPage/helpers/createPreview';
import { questionTypes } from 'pages/App/helpers/questionTypes';

const QuestionItemBody = ({
  category,
  handleUpdateQuestions,
  content,
  questionType,
  isSlotDisabled,
  errors
}) => (
  <section>
    <QuestionPreview>{createPreview(content, 3)}</QuestionPreview>
    <InfoContainer align="center" direction="row">
      {questionType && !isSlotDisabled && (
        <QuestionInformationItem
          content={MAP_BACKEND_QUESTION_TYPES[questionType]}
          type={QuestionInformationItemTypes.QUESTION_TYPE}
        />
      )}
      {questionType !== questionTypes.STOCK && (
        <>
          <GridIcon>{gridIcon}</GridIcon>
          <StyledCategoriesDropdown
            defaultCategory={category}
            updateCategory={({ id, value }) =>
              handleUpdateQuestions('default_category', {
                id: parseInt(id, 10),
                name: value
              })
            }
            isValid={!errors?.CATEGORY}
          />
        </>
      )}
    </InfoContainer>
  </section>
);

export default QuestionItemBody;

QuestionItemBody.propTypes = {
  category: PropTypes.string.isRequired,
  handleUpdateQuestions: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  questionType: PropTypes.string.isRequired
};

const QuestionPreview = styled.div`
  color: rgb(89, 89, 89);
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 16px;
  margin-bottom: 16px;
  text-align: left;
`;

const InfoContainer = styled(FlexContainer)`
  span {
    font-family: ${({ theme }) => theme.fonts.avenirProMedium};
    font-size: 14px;
    position: relative;
    top: 2px;
  }
`;

const GridIcon = styled.span`
  margin-right: 8px;
`;

const StyledCategoriesDropdown = styled(CategoriesDropdown)`
  background: #f8f9fa;
  border-radius: 4px;
  border: 1px solid #d7d7d7;
  height: 100%;
  & ~ div:last-child {
    border-radius: 4px;
    max-height: 200px;
    margin-top: 4px;
    overflow-y: auto;
    width: 310px;
    z-index: 100;
  }
`;
