import React, { useEffect, useState } from 'react';
import {
  Redirect,
  Switch,
  Route,
  useParams,
  useLocation,
  useHistory
} from 'react-router-dom';
import AccessControl from 'components/Systems/AccessControl';
import AssessmentInvite from 'pages/PanelPage/components/selectedAssessment/assessmentInvite/AssessmentInvite';
import AssessmentsLoader from 'pages/PanelPage/AssessmentsLoader';
import AssessmentResults from 'pages/PanelPage/components/selectedAssessment/assessmentResults/AssessmentResults';
import AssessmentSettings from 'pages/PanelPage/components/selectedAssessment/AssessmentSettings/AssessmentSettings';
import Navbar from 'pages/PanelPage/components/Navbar';
import { paths } from 'pages/App/helpers/paths';
import { permissionTypes } from 'pages/App/helpers/permissionTypes';
import { useAssessmentContext } from 'state/assessmentContext/useAssessmentContext';
import {
  MaxWidthContainer,
  PageContentContainer
} from 'components/Atoms/Containers';
import { getAssessmentBySlugMW } from 'middleware';

const SelectedAssessment = () => {
  const {
    assessmentState: { selectedAssessment },
    assessmentDispatch
  } = useAssessmentContext();
  const assessmentPath = `${paths.ADMIN}/:assessment`;
  const history = useHistory();
  const { assessment } = useParams();
  const { pathname } = useLocation();
  const [isInvitePage, setIsInvitePage] = useState(false);

  useEffect(() => {
    const fetchAssessment = async () => {
      let result = await getAssessmentBySlugMW({ urlParams: [assessment] });
      if (!result.data) {
        history.push(paths.ADMIN);
      }
      assessmentDispatch.setSelectedAssessment(result.data);
    };
    fetchAssessment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isPathEndInvite = pathname.split('/').pop() === 'invite';
    setIsInvitePage(isPathEndInvite);
  }, [pathname]);

  return selectedAssessment ? (
    <>
      <Navbar />
      <PageContentContainer isInvitePage={isInvitePage}>
        <MaxWidthContainer>
          <Switch>
            <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
            <Route path={`${assessmentPath}/results`}>
              <AssessmentResults />
            </Route>
            <Route exact path={`${assessmentPath}/settings`}>
              <AssessmentSettings />
            </Route>
            <Route exact path={`${assessmentPath}/invite`}>
              <AccessControl
                allowedPermissions={[permissionTypes.CAN_CREATE_TAKER_INVITE]}
              >
                <AssessmentInvite selectedAssessment={selectedAssessment} />
              </AccessControl>
            </Route>
            <Redirect to={`${assessmentPath}/results`} />
          </Switch>
        </MaxWidthContainer>
      </PageContentContainer>
    </>
  ) : (
    <AssessmentsLoader withNav />
  );
};

export default SelectedAssessment;
