import 'react-calendar/dist/Calendar.css';
import PropTypes from 'prop-types';
import React from 'react';
import { useForm, FormContext } from 'react-hook-form';
import styled from 'styled-components';
import Button from 'components/Blocks/Forms/Button';
import CalendarPicker from 'components/Blocks/CalendarPicker';
import { FormContainer } from 'components/Atoms/Containers';
import { FlexContainer } from 'components/Atoms/Containers';

const DateFilterWindow = ({
  closeAllFilterWindows,
  dateFilter,
  setDateFilter,
  setStartDate,
  setEndDate
}) => {
  const methods = useForm();

  const formatDay = date =>
    date
      .getDate()
      .toString()
      .padStart(2, 0);

  const formatMonth = date =>
    (date.getMonth() + 1)
      .toString()
      .toString()
      .padStart(2, 0);

  const applyFilters = () => {
    const startDay = formatDay(dateFilter.startDate);
    const startMonth = formatMonth(dateFilter.startDate);
    const startYear = dateFilter.startDate.getFullYear();
    const endDay = formatDay(dateFilter.endDate);
    const endMonth = formatMonth(dateFilter.endDate);
    const endYear = dateFilter.endDate.getFullYear();

    setStartDate(`${startYear}-${startMonth}-${startDay}`);
    setEndDate(`${endYear}-${endMonth}-${endDay}`);
    closeAllFilterWindows();
  };

  const handleDatePicker = dateRange => {
    setDateFilter({
      ...dateFilter,
      startDate: dateRange[0],
      endDate: dateRange[1]
    });
  };

  return (
    <FormContext {...methods}>
      <FilterWindow direction="column">
        <FormContainer onSubmit={methods.handleSubmit(applyFilters)}>
          <CalendarPicker
            dateFilter={dateFilter}
            handleDatePicker={handleDatePicker}
            isRangeSelection
          />
          <ButtonContainer justify="space-between" align="center">
            <Button
              type="submit"
              text="Apply"
              disabled={!dateFilter.startDate && !dateFilter.endDate}
              className={'silent'}
            />
          </ButtonContainer>
        </FormContainer>
      </FilterWindow>
    </FormContext>
  );
};

DateFilterWindow.propTypes = {
  closeAllFilterWindows: PropTypes.func.isRequired,
  dateFilter: PropTypes.object.isRequired,
  setDateFilter: PropTypes.func.isRequired,
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired
};

export default DateFilterWindow;

const ButtonContainer = styled(FlexContainer)`
  border-top: ${({ theme }) => `1px solid ${theme.colors.iron}`};
  padding: 17px 25px;
`;

const FilterWindow = styled(FlexContainer)`
  background: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.iron}`};
  border-radius: 4px;
  position: absolute;
  right: 8px;
  top: 38px;
  width: 318px;
  z-index: 1;
`;
