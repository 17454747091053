import React from 'react';

export const messageIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    x="0"
    y="0"
    version="1.1"
    viewBox="0 0 16 16"
    xmlSpace="preserve"
  >
    <image
      width="16"
      height="16"
      x="0"
      y="0"
      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAqCAQAAABfRhDxAAAABGdBTUEAALGPC/xhBQAAACBjSFJN AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElN RQflAgUTDBxhl42pAAABz0lEQVRIx7WWzStEURjGnzMXk3yUBRrSCN1SSjaiLCQyxcLK1oKNP4CN svIXKFZYkNWklI0pCx8biY0FQhMrmtmYnebrsbiDwdzTceacZ3W7bz2/9zz3nvccgYIYRD9G0Y5K lKcM4jjChcj8eMs2bjJPU8pynaFi+zDPjZl/6uQLQYcbxu1JcpUBIADAxXiZuZfWBMIeoAutVgBh uB6gDsIKwEGNBwhYsQcAYdO8oApJ7RGnSg3kMYwOHcCZmFXrkrt6AIeqATp6EY0hphQR0aMHCCGE siUDEDlFF8d/J8kAh1hGUCGgHFYwqgN4FZdqC2BCL6Im9iquoFEPEMGI0pQiqvQADqrVIpJJBkjg TnFUdPuHJAPEMKfY5g6mdQAZkVbzZ8a/JgMMcU02Zb79MaAHcOEqRiSR9QMnAChPnP+LHuANeSv2 WaQ8wC3iVgD3uPEAz9i3AtgTL4UnNvDA+MUxytoiGBu4xKQx8xcusN5zLpqWdNGHCGZKTtAsorhT mK0CacRxJR586hxnrkRHT5ynmeslIyUA2zSwo/0ASS7SwKngBzjmoEHzX4AUlz//BBuAK44Y+qx/ AOQ7t9hswRwAOMlrTlnpvQBoYadZxw/pjmGCudgUYgAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMS0w Mi0wNVQxOToxMjoyOCswMzowMKnH20IAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjEtMDItMDVUMTk6 MTI6MjgrMDM6MDDYmmP+AAAAAElFTkSuQmCC"
    ></image>
  </svg>
);

export default messageIcon;
