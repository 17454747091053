import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import equal from 'fast-deep-equal';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, FormContext } from 'react-hook-form';
import { PageWrapper } from 'components/Blocks/Styled/Wrappers';
import {
  Container,
  FlexContainer,
  FormContainer
} from 'components/Atoms/Containers';
import Header from 'pages/ManagementPage/Header';
import AssessmentsLoader from 'pages/PanelPage/AssessmentsLoader';
import AssessmentPreview from 'pages/ManagementPage/AssessmentPreview';
import AssessmentCreationForm from 'pages/ManagementPage/AssessmentCreationForm';
import SettingsModal from 'pages/ManagementPage/SettingsModal';
import CategoryWeightsWarningModal from 'pages/ManagementPage/CategoryWeightsWarningModal';
import PopupNotification from 'components/Blocks/PopupNotification';
import LeaveRoutePrompt from 'pages/ManagementPage/LeaveRoutePrompt';
import FormErrors from 'pages/ManagementPage/FormErrors';
import Modal from 'components/Systems/Modal';
import { StyledButton } from 'components/Atoms/Buttons';
import validateForm from 'pages/ManagementPage/validations/ValidateForm';
import usePreventLeaveRoute from 'pages/ManagementPage/hooks/usePreventLeaveRoute';
import useFetchCategories from 'pages/QuestionPage/hooks/useFetchCategories';
import { useThemeContext } from 'state/themeContext/useThemeContext';
import { questionTypes } from 'pages/App/helpers/questionTypes';
import validateQuestionPoolSameScore from 'pages/ManagementPage/utils/validateQuestionPoolSameScore';
import getQuestionMaxScore from 'pages/ManagementPage/utils/getQuestionMaxScore';
import mapTakerFields from 'utils/helpers/mapTakerFields';
import getHourStringFromNumber from 'utils/helpers/getHourStringFromNumber';
import { paths } from 'pages/App/helpers/paths';
import {
  ASSESSMENT_TYPES,
  FORM_ID,
  EXPIRATION_UNITS,
  EACH_QUESTION,
  WHOLE_SECTION,
  HAS_EMPTY_CATEGORIES,
  INVALID_POOL_SCORES,
  MAJOR_EDITING_MODE,
  SAVE_AND_REPLACE,
  DEFAULT_INSTRUCTIONS,
  EXPIRATION_TYPES
} from 'pages/ManagementPage/Constants';
import { getAssessmentBySlugMW } from 'middleware';
import {
  getPoolsByAssessmentMW,
  createAssessmentMW,
  getQuestionsListMW,
  uploadLogoMW,
  updateAssessmentConfigurationMW,
  updateAssessmentMW,
  updateAssessmentMetadataMW
} from 'pages/ManagementPage/middleware';
const DEFAULT_ASSESSMENT_DATA = {
  formTitle: '',
  headerTitle: '',
  title: '',
  instructions: '',
  defaultLogo: '',
  logoFile: { file: null, url: '' },
  fields: [],
  messageHeader: '',
  messageParagraph: ''
};

const DEFAULT_SECTION_DATA = {
  sectionId: 0,
  sectionTitle: '',
  sectionDuration: WHOLE_SECTION,
  sectionSeconds: null,
  sectionDescription: '',
  sectionIsLinear: true
};

const ONE_DAY_IN_SECONDS = 86400;
const ONE_WEEK_IN_SECONDS = 604800;
const TWO_WEEKS_WEEKS_IN_MS = 12096e5;
const TWO_WEEKS_FROM_NOW = new Date(Date.now() + TWO_WEEKS_WEEKS_IN_MS);

const DEFAULT_SETTINGS = {
  assessmentName: '',
  assessmentSlug: '',
  selectedType: ASSESSMENT_TYPES.PUBLIC,
  emailSender: 'assessments',
  categoriesWeight: {},
  expirationNumber: 2,
  expirationUnit: EXPIRATION_UNITS.WEEKS,
  notificationSender: '',
  assessmentColorTheme: '',
  isQuestionPoolEnabled: false,
  expirationType: EXPIRATION_TYPES.DURATION,
  expirationDate: TWO_WEEKS_FROM_NOW,
  expirationHour: getHourStringFromNumber(TWO_WEEKS_FROM_NOW.getHours() + 1),
  attemptLimit: null
};

const AssessmentCreation = ({ tenantInfo }) => {
  const { tenantAccent } = useThemeContext();
  const { name: tenantName, id: tenantId, logo_url: logoUrl } = tenantInfo;
  const { assessmentSlug } = useParams();
  const history = useHistory();
  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit'
  });

  const [assessmentData, setAssessmentData] = useState({
    ...DEFAULT_ASSESSMENT_DATA,
    defaultLogo: logoUrl
  });

  const [sectionsData, setSectionsData] = useState([DEFAULT_SECTION_DATA]);
  const [assessmentSettings, setAssessmentSettings] = useState({
    ...DEFAULT_SETTINGS,
    assessmentColorTheme: tenantAccent
  });
  const [selectedOption, setSelectedOption] = useState('');
  const [showEditSettingsModal, setShowEditSettingsModal] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [assessmentId, setAssessmentId] = useState(null);
  const [isLoading, setIsLoading] = useState(Boolean(assessmentSlug));
  const [categories, setCategories] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [createdCards, setCreatedCards] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isMajorChange, setisMajorChange] = useState(false);
  const [majorEditingMode, setMajorEditingMode] = useState(null);
  const [showMajorEditingModal, setShowMajorEditingModal] = useState(false);
  const [questionPools, setQuestionPools] = useState([]);
  const [isRequestBeingSent, setIsRequestBeingSent] = useState(false);
  const [customWeightingValues, setCustomWeightingValues] = useState(null);
  const [confirmCustomWeighting, setConfirmCustomWeighting] = useState(false);
  const [fetchedWeighting, setFetchedWeighting] = useState(null);
  const [areWeightsAutoCalc, setAreWeightsAutoCalc] = useState(null);
  const { headerTitle, formTitle, title } = assessmentData;

  usePreventLeaveRoute();
  useFetchCategories(setCategories);

  const modifyAssessmentData = newData => {
    setAssessmentData(prevData => ({
      ...prevData,
      ...newData
    }));
  };

  const handleAddQuestionPool = () => {
    const newPools = [...questionPools];
    newPools.push({
      title: `Untitled Bank ${newPools.length + 1}`,
      questions: []
    });
    setQuestionPools(newPools);
  };

  const handleUpdateQuestionPool = (
    editedPool,
    title = null,
    questions = null
  ) => {
    const newPools = [...questionPools];
    const newPool = newPools.find(({ title }) => editedPool.title === title);
    if (title) {
      newPool.title = title;
    }
    if (questions) {
      newPool.questions = questions;
    }
    setQuestionPools(newPools);
  };

  const handleRemoveQuestionPool = removedPool => {
    const newPools = questionPools.filter(
      ({ title }) => title !== removedPool.title
    );
    setQuestionPools(newPools);
  };

  const mapInstructions = instructions => {
    const isNoWrappedList =
      instructions.includes('<li>') &&
      !(instructions.includes('<ol>') || instructions.includes('<ul>'));
    if (isNoWrappedList) return `<ol>${instructions}<ol>`;
    else return instructions;
  };

  const getSlotSchema = useCallback(
    (questions = [], isQuestionTimed) =>
      questions.map(
        (
          {
            slotId = null,
            id,
            default_category,
            duration,
            weight,
            slot_type = 'slot_question',
            category_functions = null,
            isQuestionPool = false,
            selectedPool = ''
          },
          idx
        ) => {
          const selectedCategories = Array.isArray(default_category)
            ? default_category
            : [default_category];

          const commonFields = {
            ...(isEditing && slotId && { id: slotId }),
            compile_index: idx,
            sort_index: idx,
            weight: weight && !isNaN(weight) ? weight / 100 : 1
          };

          if (isQuestionPool) {
            const poolCategory = categories.find(
              ({ name }) => name === selectedPool
            );

            return {
              slot_type: 'slot_random',
              category_id: poolCategory.id,
              category: poolCategory,
              ...commonFields
            };
          } else {
            return {
              slot_type,
              question_id: id,
              categories:
                selectedCategories?.length > 0 ? selectedCategories : undefined,
              ...commonFields,
              // TODO: actually support category_functions #4819
              ...(category_functions && { category_functions }),
              time_allowed_seconds: isQuestionTimed ? duration : null
            };
          }
        }
      ),
    [isEditing, categories]
  );

  const onSaveSettings = settings => {
    const {
      'assessment-settings-name': assessmentName = '',
      'assessment-settings-slug': assessmentSlug,
      'assessment-settings-email-sender': emailSender,
      'assessment-settings-type': selectedType,
      'assessment-settings-expiration-number': expirationNumber,
      'assessment-settings-expiration-unit': expirationUnit,
      'assessment-settings-complete-notification-sender': notificationSender,
      'assessment-settings-color-theme': assessmentColorTheme,
      'assessment-settings-question-pool-toggle': isQuestionPoolEnabled,
      'assessment-settings-expiration-date': expirationDate,
      'assessment-settings-expiration-hour': expirationHour,
      'assessment-settings-attempt-limit': attemptLimit
    } = settings;

    const expirationData = {};
    if (expirationDate && expirationHour) {
      expirationData.expirationType = EXPIRATION_TYPES.DATE;
      expirationData.expirationDate = new Date(expirationDate);
      expirationData.expirationHour = expirationHour;
    } else {
      expirationData.expirationType = EXPIRATION_TYPES.DURATION;
      expirationData.expirationNumber = expirationNumber;
      expirationData.expirationUnit = expirationUnit;
    }

    const weights = Object.keys(settings).filter(value =>
      /^weight__/i.test(value)
    );

    const categoriesWeight = {};
    for (const weightObjId of weights) {
      const [, catWeightId, catId, name] = weightObjId.split('__');
      categoriesWeight[catId] = {
        catWeightId,
        id: weightObjId,
        name,
        weight: settings[weightObjId]
      };
    }

    const mapSettings = {
      // Include previous settings for settings not yet configurable like
      // email_invite_template_id
      ...assessmentSettings,
      assessmentName,
      assessmentSlug,
      selectedType,
      emailSender,
      categoriesWeight,
      notificationSender,
      assessmentColorTheme,
      isQuestionPoolEnabled,
      attemptLimit: selectedType === ASSESSMENT_TYPES.PUBLIC && attemptLimit,
      ...expirationData
    };

    setAssessmentSettings(mapSettings);
    modifyAssessmentData({
      title: assessmentName,
      formTitle: assessmentName,
      headerTitle: assessmentName
    });
    setShowEditSettingsModal(false);
  };

  const handleKeyPress = event => {
    const code = event.keyCode ? event.keyCode : event.which;
    if (code === 13) {
      event.preventDefault();
    }
  };

  const formData = {
    data: {
      ...assessmentData,
      title: formTitle,
      defaultTitle: title
    },
    setData: modifyAssessmentData
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const requestHandlers = {
    handleSuccess: (wasEdited = false) =>
      history.push({
        pathname: `${paths.ADMIN}/active`,
        createdAssessment: headerTitle || title || formTitle || 'Assessment',
        wasEdited
      }),
    handleError: () => {
      setRequestError(true);
      setConfirmCustomWeighting(false);
    }
  };

  const updateLogo = useCallback(async file => {
    const formData = new FormData();
    formData.append('file', file);
    const uploadLogoData = await uploadLogoMW({ data: formData });
    return uploadLogoData;
  }, []);

  const updateAssessmentConfigs = useCallback(
    async assessmentId => {
      const { file = null, url = '' } = assessmentData.logoFile;
      const shouldUpdateLogo = url && logoUrl !== url && file;
      let newLogoUrl = { data: logoUrl };
      if (shouldUpdateLogo) {
        newLogoUrl = await updateLogo(file);
      }

      return await updateAssessmentConfigurationMW({
        data: {
          default_theme_color: assessmentSettings.assessmentColorTheme,
          show_nav_logo: null,
          logo_url: newLogoUrl?.data || '',
          assessment_id: assessmentId
        }
      });
    },
    [
      assessmentData.logoFile,
      assessmentSettings.assessmentColorTheme,
      logoUrl,
      updateLogo
    ]
  );

  const getSlug = useCallback(
    assessmentName => {
      const nonAlphaNumericalOrHypen = /[^\w\d-]/g;
      const slug = `${tenantName} ${assessmentName}`
        .replace(/\s+/g, '-')
        .replace(nonAlphaNumericalOrHypen, '')
        .toLowerCase();

      setAssessmentSettings(prevSettings => ({
        ...prevSettings,
        assessmentSlug: slug
      }));
    },
    [tenantName]
  );

  const getSectionsSchema = useCallback(
    () =>
      sectionsData.map(
        (
          {
            sectionModelId = null,
            sectionTitle,
            sectionDescription,
            sectionIsLinear,
            sectionSeconds,
            sectionDuration,
            questions
          },
          idx
        ) => {
          const isSectionTimed = !(sectionDuration === EACH_QUESTION);
          return {
            ...(isEditing && sectionModelId && { id: sectionModelId }),
            title: sectionTitle,
            description: sectionDescription,
            // Question level timing sections must be linear
            is_linear: sectionIsLinear || !isSectionTimed,
            time_allowed_seconds: isSectionTimed ? sectionSeconds : null,
            sort_index: idx,
            is_timed: isSectionTimed,
            section_type: 'section',
            slots: getSlotSchema(questions, !isSectionTimed)
          };
        }
      ),
    [sectionsData, getSlotSchema, isEditing]
  );

  const getAutoCalculateCategoryWeights = useCallback(() => {
    const defaultCategoriesWeight = {};
    for (const { questions = [] } of sectionsData) {
      for (const question of questions) {
        const {
          options,
          bands,
          default_category,
          question_type,
          isQuestionPool,
          selectedPool
        } = question;
        let questionScore = 0;
        // Handles talenstat edge cases with multiple categories per question
        let defaultCategories = Array.isArray(default_category)
          ? default_category
          : [default_category];

        if (isQuestionPool && selectedPool) {
          const currentPool = questionPools.find(
            ({ title }) => title === selectedPool
          );

          const possibleScore =
            currentPool && validateQuestionPoolSameScore(currentPool);

          if (possibleScore) {
            questionScore = possibleScore;
          } else {
            defaultCategoriesWeight[INVALID_POOL_SCORES] = true;
          }
          defaultCategories = [
            categories.find(({ name }) => name === selectedPool)
          ];
        } else if (
          question_type !== questionTypes.STOCK &&
          (options || bands)
        ) {
          questionScore = getQuestionMaxScore(question);
        }
        // If question doesn't have correct bands or options the questionScore is -Infinity
        if (questionScore < 0) {
          questionScore = 0;
        }

        for (const default_category of defaultCategories) {
          if (!default_category?.id) {
            defaultCategoriesWeight[HAS_EMPTY_CATEGORIES] = true;
            break;
          }

          if (!defaultCategoriesWeight?.[default_category.id]) {
            const weightId =
              assessmentSettings.categoriesWeight?.[default_category.id]
                ?.catWeightId || 0;
            defaultCategoriesWeight[default_category.id] = {
              id: `weight__${weightId}__${default_category.id}__${default_category.name}`,
              name: default_category.name,
              weight: questionScore
            };
          } else {
            defaultCategoriesWeight[
              default_category.id
            ].weight += questionScore;
          }
        }
      }
    }

    return defaultCategoriesWeight;
  }, [
    assessmentSettings.categoriesWeight,
    categories,
    questionPools,
    sectionsData
  ]);

  const getDefaultCategoriesWeight = useCallback(() => {
    const defaultCategoriesWeight = getAutoCalculateCategoryWeights();

    const reconciledWeights = {
      ...defaultCategoriesWeight,
      ...assessmentSettings.categoriesWeight,
      ...(areWeightsAutoCalc && defaultCategoriesWeight)
    };

    // Filter removed categories
    if (!Object.keys(defaultCategoriesWeight)?.length)
      return { reconciledWeights: {}, defaultCategoriesWeight };

    Object.keys(reconciledWeights).forEach(id => {
      if (!defaultCategoriesWeight.hasOwnProperty(id)) {
        delete reconciledWeights[id];
      }
    });
    return { defaultCategoriesWeight, reconciledWeights };
  }, [
    assessmentSettings.categoriesWeight,
    getAutoCalculateCategoryWeights,
    areWeightsAutoCalc
  ]);

  const getUsedCategoryWeights = useCallback(() => {
    const {
      reconciledWeights,
      defaultCategoriesWeight
    } = getDefaultCategoriesWeight();

    const categoryWeights = Object.keys(assessmentSettings?.categoriesWeight)
      .length
      ? reconciledWeights
      : defaultCategoriesWeight;

    return categoryWeights;
  }, [assessmentSettings.categoriesWeight, getDefaultCategoriesWeight]);

  const getCategoriesWeightSchema = useCallback(() => {
    const categoryWeights = getUsedCategoryWeights();
    return Object.keys(categoryWeights).map(key => {
      const catWeight = {
        category_id: parseInt(key),
        weight: categoryWeights[key].weight
      };
      // If adding a new category to the assessment, its weightId will be null
      // because anything new will have a null id cause its not created in db yet.
      // So we should check for that, and if its null don't define id in the object
      const weightId = parseInt(categoryWeights[key].catWeightId);
      if (isEditing && weightId) {
        catWeight.id = weightId;
      }
      return catWeight;
    });
  }, [isEditing, getUsedCategoryWeights]);

  const getDropdownFieldSchema = (isDropdown, configuration, isEditing) =>
    isDropdown &&
    configuration.fieldOptions.map(({ id = null, value }, idx) => ({
      ...(isEditing && id && { id }),
      sort_index: idx,
      content: [{ language_id: 1, text_display: value }]
    }));

  const getTakerFieldsSchema = useCallback(
    () =>
      assessmentData.fields
        .filter(({ isChecked, isDisabled }) => isChecked && !isDisabled)
        .map(({ id, configuration, itemName, isMandatory }) => {
          const isDropdown = !!configuration?.fieldOptions?.length;
          const takerFields = {
            ...(isEditing && { id }),
            field_type: isDropdown ? 'taker_field_select' : 'taker_field_text',
            // Language_id is currently supporting only 1 which is english
            content: [{ title: itemName, language_id: 1 }],
            is_required: isMandatory
          };
          if (isDropdown) {
            takerFields.options = getDropdownFieldSchema(
              isDropdown,
              configuration,
              isEditing
            );
          }
          return takerFields;
        }),
    [assessmentData.fields, isEditing]
  );

  const mapCategoriesWeight = useCallback(
    categoryWeights => {
      const getCategoryName = categoryId =>
        categories.find(({ id }) => id === categoryId).name;
      const weights = {};
      for (const categoryWeight of categoryWeights) {
        weights[categoryWeight.category_id] = {
          catWeightId: categoryWeight.id,
          id: `weight__${categoryWeight.id}__${
            categoryWeight.category_id
          }__${getCategoryName(categoryWeight.category_id)}`,
          name: getCategoryName(categoryWeight.category_id),
          weight: categoryWeight.weight
        };
      }
      return weights;
    },
    [categories]
  );

  const getQuestions = (slots, questions) =>
    slots
      .map((slot, idx) => {
        const fetchedQuestionData = questions.find(
          ({ id }) => slot.question_id === id
        );

        const isSlotRandom = slot.slot_type === questionTypes.SLOT_RANDOM;
        const categoriesKey = isSlotRandom ? 'category' : 'categories';

        const defaultCategories =
          slot?.[categoriesKey]?.length === 1
            ? slot[categoriesKey][0]
            : slot[categoriesKey];

        let questionData = {};

        if (isSlotRandom) {
          questionData = {
            id: `question-pool-${idx}`,
            isQuestionPool: true,
            weight: slot.weight * 100,
            duration: slot.time_allowed_seconds,
            slotId: slot.id,
            selectedPool: defaultCategories.name,
            slot_type: slot.slot_type,
            category_functions: slot.category_functions
          };
        } else {
          questionData = {
            id: slot.question_id,
            weight: slot.weight * 100,
            duration: slot.time_allowed_seconds,
            slotId: slot.id,
            default_category: defaultCategories,
            question_type: fetchedQuestionData?.question_type || slot.slot_type,
            slot_type: slot.slot_type,
            category_functions: slot.category_functions
          };
        }

        if (fetchedQuestionData) {
          questionData = {
            ...fetchedQuestionData,
            ...questionData
          };
        }

        return questionData;
      })
      .filter(data => data);

  const getSections = useCallback(
    (questions, sections) =>
      sections.map(
        (
          {
            id,
            title,
            is_timed,
            time_allowed_seconds,
            description,
            is_linear,
            slots,
            section_type
          },
          idx
        ) => ({
          sectionModelId: id,
          sectionId: idx,
          sectionTitle: title,
          sectionDuration: is_timed ? WHOLE_SECTION : EACH_QUESTION,
          sectionSeconds: time_allowed_seconds,
          sectionDescription: description,
          sectionIsLinear: is_linear,
          sectionType: section_type,
          questions: getQuestions(slots, questions)
        })
      ),
    []
  );

  const fillPoolsData = useCallback(
    poolsData => {
      const mappedPools = [];
      for (const poolDataKey of Object.keys(poolsData)) {
        const categoryId = parseInt(poolDataKey);
        const category = categories.find(({ id }) => id === categoryId);
        const questions = poolsData[poolDataKey];
        mappedPools.push({
          title: category.name,
          questions
        });
      }
      setQuestionPools(mappedPools);
    },
    [categories]
  );

  const getExpirationData = useCallback((timeToExp, expiresOn) => {
    const expirationData = {};

    if (timeToExp) {
      const possibleDays = timeToExp / ONE_DAY_IN_SECONDS;
      expirationData.expirationType = EXPIRATION_TYPES.DURATION;
      expirationData.expirationNumber = possibleDays;
      expirationData.expirationUnit = EXPIRATION_UNITS.DAYS;
    } else if (expiresOn) {
      const expirationDate = new Date(expiresOn);
      const expirationHour = expirationDate.getHours();
      expirationDate.setHours(0, 0, 0);
      expirationDate.setMilliseconds(0, 0, 0);
      expirationData.expirationType = EXPIRATION_TYPES.DATE;
      expirationData.expirationDate = expirationDate;
      expirationData.expirationHour = getHourStringFromNumber(expirationHour);
    }

    return expirationData;
  }, []);

  const fillAssessmentData = useCallback(
    async assessmentData => {
      if (!categories) return;
      const {
        id,
        name,
        assessment_detail: {
          assessment_detail_content: { instructions }
        },
        taker_fields,
        slug,
        is_public,
        time_to_expiration,
        expires_on,
        email_invite_template_id,
        email_sender,
        email_to_notify,
        category_weights,
        sections,
        configuration: { default_theme_color, logo_url },
        question_pool,
        attempt_limit
      } = assessmentData;

      setAssessmentData(prevData => ({
        ...prevData,
        id,
        formTitle: name,
        headerTitle: name,
        title: name,
        fields: mapTakerFields(taker_fields),
        instructions: mapInstructions(instructions),
        messageHeader: '',
        messageParagraph: '',
        logoFile: { file: null, url: logo_url }
      }));

      const expirationData = getExpirationData(time_to_expiration, expires_on);

      setAssessmentSettings(prevSettings => ({
        ...prevSettings,
        assessmentName: name,
        assessmentSlug: slug,
        selectedType: is_public
          ? ASSESSMENT_TYPES.PUBLIC
          : ASSESSMENT_TYPES.PRIVATE,
        emailSender: `${email_sender}`,
        email_invite_template_id,
        notificationSender: email_to_notify,
        categoriesWeight: mapCategoriesWeight(category_weights),
        ...expirationData,
        assessmentColorTheme: default_theme_color,
        isQuestionPoolEnabled: Boolean(question_pool.length),
        attemptLimit: attempt_limit
      }));

      setFetchedWeighting(mapCategoriesWeight(category_weights));

      const questionIds = assessmentData.sections
        .map(({ slots }) => slots)
        .flat()
        .map(({ question_id }) => question_id)
        .filter(id => id);

      const params = new URLSearchParams();
      questionIds.forEach(id => params.append('question_ids', id));

      const questions = await getQuestionsListMW({ queryParams: params });

      setSectionsData(getSections(questions.data.paginated_data, sections));
      setIsEditing(true);
      setAssessmentId(assessmentData.id);
    },
    [categories, mapCategoriesWeight, getSections, getExpirationData]
  );

  const getAttachedPools = useCallback(() => {
    const attachedPools = [];

    for (const pool of questionPools) {
      if (
        !pool.title.startsWith('Untitled Bank') &&
        pool?.questions.length > 0
      ) {
        attachedPools.push({
          category_id: categories.find(({ name }) => name === pool.title).id,
          questions: pool.questions.map(({ id }) => id)
        });
      }
    }
    return attachedPools;
  }, [questionPools, categories]);

  const getTimeToExpirationInSeconds = useCallback(() => {
    return assessmentSettings.expirationUnit === EXPIRATION_UNITS.WEEKS
      ? parseInt(assessmentSettings.expirationNumber * ONE_WEEK_IN_SECONDS)
      : parseInt(assessmentSettings.expirationNumber * ONE_DAY_IN_SECONDS);
  }, [assessmentSettings]);

  const getTimeToExpiration = useCallback(() => {
    const expTimeData = {
      time_to_expiration: null,
      expires_on: null
    };
    if (assessmentSettings.expirationType === EXPIRATION_TYPES.DURATION) {
      expTimeData.time_to_expiration = getTimeToExpirationInSeconds();
    } else if (assessmentSettings.expirationType === EXPIRATION_TYPES.DATE) {
      const expDate = new Date(assessmentSettings.expirationDate);
      const hours = assessmentSettings.expirationHour.split(':')[0];
      expDate.setHours(hours);
      expDate.setMilliseconds(0, 0, 0);
      expTimeData.expires_on = expDate;
    }
    return expTimeData;
  }, [
    assessmentSettings.expirationType,
    assessmentSettings.expirationDate,
    assessmentSettings.expirationHour,
    getTimeToExpirationInSeconds
  ]);

  const getAssessmentCreationSchema = useCallback(
    (shouldRemoveAttempts = false) => {
      const tenant = { id: tenantInfo.id, name: tenantInfo.name };
      const schema = {
        name: assessmentData.title,
        slug: assessmentSettings.assessmentSlug,
        assessment_detail_id: 4,
        sections: getSectionsSchema(),
        tenant_id: tenantId,
        tenant,
        is_public: assessmentSettings.selectedType === ASSESSMENT_TYPES.PUBLIC,
        taker_fields: getTakerFieldsSchema(),
        assessment_detail: {
          assessment_detail_content: {
            instructions: assessmentData.instructions,
            language_id: 1
          }
        },
        ...(isEditing && {
          email_invite_template_id: assessmentSettings.email_invite_template_id
        }),
        ...(assessmentSettings.isQuestionPoolEnabled && {
          question_pool: getAttachedPools()
        }),
        email_sender: assessmentSettings.emailSender,
        email_to_notify: assessmentSettings.notificationSender,
        category_weights: getCategoriesWeightSchema(),
        language_id: 1,
        remove_attempts: shouldRemoveAttempts,
        attempt_limit: assessmentSettings.attemptLimit || null,
        ...getTimeToExpiration()
      };

      if (!assessmentData.instructions) {
        schema.assessment_detail.assessment_detail_content.instructions = DEFAULT_INSTRUCTIONS;
      }

      return schema;
    },
    [
      assessmentData.title,
      assessmentData.instructions,
      assessmentSettings,
      getCategoriesWeightSchema,
      getSectionsSchema,
      getTakerFieldsSchema,
      tenantId,
      tenantInfo,
      isEditing,
      getAttachedPools,
      getTimeToExpiration
    ]
  );

  const createAssessmentRequest = useCallback(async () => {
    const createdAssessment = await createAssessmentMW({
      data: getAssessmentCreationSchema()
    });
    return createdAssessment.data;
  }, [getAssessmentCreationSchema]);

  const updateAssessmentRequest = useCallback(
    async (shouldRemoveAttempts = false, assessmentSchema = null) => {
      const updateAssessment = await updateAssessmentMW({
        urlParams: [assessmentId],
        data:
          assessmentSchema || getAssessmentCreationSchema(shouldRemoveAttempts)
      });

      if (updateAssessment) {
        requestHandlers.handleSuccess(true);
      } else {
        requestHandlers.handleError();
      }

      return updateAssessment;
    },
    [assessmentId, requestHandlers, getAssessmentCreationSchema]
  );

  const handleUpdateAssessment = useCallback(async () => {
    const assessmentSchema = getAssessmentCreationSchema();

    const updateMetaData = await updateAssessmentMetadataMW({
      urlParams: [assessmentId],
      data: assessmentSchema
    });

    const { is_major_change: isMajorChange } = updateMetaData.data;
    const editingProps = {
      assessmentSchema,
      isMajorChange
    };

    setisMajorChange(isMajorChange);

    if (isMajorChange) {
      setIsSubmitting(false);
    } else {
      return updateAssessmentRequest(assessmentSchema);
    }

    return editingProps;
  }, [assessmentId, getAssessmentCreationSchema, updateAssessmentRequest]);

  const compareWeightingSchemas = (weighting1, weighting2) => {
    const schema1 = {};
    const schema2 = {};

    Object.keys(weighting1 || {}).map(
      catId => (schema1[catId] = parseInt(weighting1[catId].weight))
    );

    Object.keys(weighting2 || {}).map(
      catId => (schema2[catId] = parseInt(weighting2[catId].weight))
    );

    return equal(schema1, schema2);
  };

  const onSubmit = useCallback(() => {
    const createOrUpdateAssessment = async () => {
      setIsRequestBeingSent(true);
      try {
        let id = null;
        if (isEditing) {
          id = assessmentId;
          await handleUpdateAssessment();
        } else {
          ({ id } = await createAssessmentRequest());
        }
        await updateAssessmentConfigs(id);
        if (!isEditing) {
          requestHandlers.handleSuccess();
        }
      } catch (error) {
        console.error(error);
        requestHandlers.handleError();
      } finally {
        setIsRequestBeingSent(false);
      }
    };

    createOrUpdateAssessment();
  }, [
    updateAssessmentConfigs,
    createAssessmentRequest,
    handleUpdateAssessment,
    isEditing,
    assessmentId,
    requestHandlers
  ]);

  const validate = useCallback(async () => {
    const isValidform = await validateForm(
      assessmentData,
      assessmentSettings,
      sectionsData,
      setFormErrors,
      createdCards,
      isEditing,
      questionPools
    );
    if (isValidform) {
      onSubmit();
    } else {
      setConfirmCustomWeighting(false);
    }
  }, [
    assessmentData,
    assessmentSettings,
    createdCards,
    onSubmit,
    sectionsData,
    isEditing,
    questionPools
  ]);

  const checkCategoryWeights = useCallback(() => {
    const autoCalculatedWeights = getAutoCalculateCategoryWeights();
    const currentUsedWeights = getUsedCategoryWeights();
    const areWeightsDifferentFromAutoCalculation = compareWeightingSchemas(
      autoCalculatedWeights,
      currentUsedWeights
    );
    return [areWeightsDifferentFromAutoCalculation, currentUsedWeights];
  }, [getAutoCalculateCategoryWeights, getUsedCategoryWeights]);

  useEffect(() => {
    if (isEditing && fetchedWeighting && areWeightsAutoCalc === null) {
      let isAutoCalculation = compareWeightingSchemas(
        getAutoCalculateCategoryWeights(),
        fetchedWeighting
      );

      setAreWeightsAutoCalc(isAutoCalculation);
    }
  }, [
    fetchedWeighting,
    isEditing,
    getAutoCalculateCategoryWeights,
    areWeightsAutoCalc
  ]);

  useEffect(() => {
    const fetchAssessmentData = async () => {
      setIsLoading(true);

      const assessment = await getAssessmentBySlugMW({
        urlParams: [assessmentSlug]
      });

      fillAssessmentData(assessment.data);

      const pools = await getPoolsByAssessmentMW({
        urlParams: [assessmentSlug]
      });

      fillPoolsData(pools.data || {});

      setIsLoading(false);
    };

    assessmentSlug && categories && fetchAssessmentData();
  }, [assessmentSlug, fillAssessmentData, fillPoolsData, categories]);

  useEffect(() => {
    if (isSubmitting) {
      if (isEditing) {
        const [
          areWeightsDifferentFromAutoCalculation,
          usedWeights
        ] = checkCategoryWeights();
        setConfirmCustomWeighting(areWeightsDifferentFromAutoCalculation);
        setCustomWeightingValues(usedWeights);
      } else {
        setConfirmCustomWeighting(true);
      }
    }
    setIsSubmitting(false);
  }, [isSubmitting, isEditing, checkCategoryWeights]);

  useEffect(() => {
    if (confirmCustomWeighting === true) {
      validate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmCustomWeighting]);

  useEffect(() => {
    assessmentData.title && !assessmentSlug && getSlug(assessmentData.title);
  }, [assessmentData.title, tenantName, assessmentSlug, getSlug]);

  useEffect(() => {
    if (isEditing) {
      setisMajorChange(false);
    }
  }, [assessmentData, sectionsData, assessmentSettings, isEditing]);

  const renderPopup = () =>
    requestError && (
      <PopupContainer>
        <PopupNotification
          content="Sorry, we were not able to create this assessment. Check your internet connection and try again"
          popupType="ERROR"
          title="Something went wrong"
          cleanHandler={() => setRequestError(false)}
        ></PopupNotification>
      </PopupContainer>
    );

  const renderMajorEditingModeModals = () =>
    showMajorEditingModal && (
      <Modal
        onClose={() => setShowMajorEditingModal(false)}
        title={MAJOR_EDITING_MODE[majorEditingMode].text}
      >
        <MajorEditingModal direction="column">
          {MAJOR_EDITING_MODE[majorEditingMode].message.map(piece => (
            <p key={piece}>{piece}</p>
          ))}
          <FlexContainer>
            <MajorEditingButton
              onClick={ev => {
                ev.preventDefault();
                updateAssessmentRequest(majorEditingMode === SAVE_AND_REPLACE);
              }}
            >
              Continue with change
            </MajorEditingButton>
            <MajorEditingButton
              className="cancel"
              onClick={() => setShowMajorEditingModal(false)}
            >
              Cancel
            </MajorEditingButton>
          </FlexContainer>
        </MajorEditingModal>
      </Modal>
    );

  const renderCategoriesWeightWarningModal = () => {
    const onClick = response => {
      setConfirmCustomWeighting(response);
      setCustomWeightingValues(null);
    };

    return (
      customWeightingValues &&
      !confirmCustomWeighting && (
        <CategoryWeightsWarningModal
          onClose={() => {
            onClick(false);
          }}
          onConfirm={() => {
            onClick(true);
          }}
          reconciledWeights={customWeightingValues}
        />
      )
    );
  };

  return isLoading ? (
    <AssessmentsLoader />
  ) : (
    <MainWrapper background="#f0f2f4">
      <LeaveRoutePrompt />
      <FormContext {...methods}>
        <FormContainer
          onKeyPress={ev => handleKeyPress(ev)}
          onSubmit={ev => {
            ev.preventDefault();
            setIsSubmitting(true);
          }}
          id={FORM_ID}
        >
          <Header
            onEditSettings={() => setShowEditSettingsModal(true)}
            onGoBack={() => history.push(`${paths.ADMIN}`)}
            setData={modifyAssessmentData}
            title={headerTitle}
            setIsSubmitting={() => setIsSubmitting(true)}
            isMajorChange={isMajorChange}
            setMajorEditingMode={setMajorEditingMode}
            setShowMajorEditingModal={setShowMajorEditingModal}
            isDisabled={isRequestBeingSent}
          />
          {renderPopup()}
          {renderMajorEditingModeModals()}
          <AssessmentCreationContainer>
            <AssessmentCreationSection background="transparent">
              {Object.keys(formErrors).length > 0 && (
                <FormErrors errors={formErrors} />
              )}
              <AssessmentCreationForm
                formData={formData}
                sectionsData={sectionsData}
                setSectionsData={setSectionsData}
                setSelectedOption={setSelectedOption}
                setCreatedCards={setCreatedCards}
                formErrors={formErrors}
                isSubmitting={isSubmitting}
                isEditing={isEditing}
                isQuestionPoolEnabled={assessmentSettings.isQuestionPoolEnabled}
                questionPools={questionPools}
                handleAddQuestionPool={handleAddQuestionPool}
                handleUpdateQuestionPool={handleUpdateQuestionPool}
                handleRemoveQuestionPool={handleRemoveQuestionPool}
              />
            </AssessmentCreationSection>
            <AssessmentCreationSection background="white">
              <AssessmentPreview
                assessmentData={assessmentData}
                sectionsData={sectionsData}
                selectedOption={selectedOption}
                assessmentColorTheme={assessmentSettings.assessmentColorTheme}
              />
            </AssessmentCreationSection>
          </AssessmentCreationContainer>
        </FormContainer>
      </FormContext>
      {showEditSettingsModal && (
        <SettingsModal
          defaultSettings={{
            ...assessmentSettings,
            assessmentName: title
          }}
          onCancel={() => setShowEditSettingsModal(false)}
          onSave={onSaveSettings}
          categoriesWeightData={getDefaultCategoriesWeight()}
          isEditing={isEditing}
          isValidSlug={!formErrors?.SETTINGS}
          setWasWeightsCustomized={wasWeightsCustomized =>
            setAreWeightsAutoCalc(!wasWeightsCustomized)
          }
        />
      )}
      {renderCategoriesWeightWarningModal()}
    </MainWrapper>
  );
};

export default AssessmentCreation;

AssessmentCreation.propTypes = {
  tenantInfo: PropTypes.object.isRequired
};

const MainWrapper = styled(PageWrapper)`
  display: flex;
`;

const AssessmentCreationContainer = styled(FlexContainer)`
  flex: 1;
  margin-top: 2px;
`;

const AssessmentCreationSection = styled(Container)`
  flex: 1;
  max-width: 50%;
  padding: 34px 104px 58px 112px;
`;

const PopupContainer = styled.section`
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 10;
`;

const MajorEditingModal = styled(FlexContainer)`
  text-align: left;
`;

const MajorEditingButton = styled(StyledButton)`
  margin-top: 12px;
  &:first-child {
    margin-right: 16px;
  }
  &.cancel {
    background: ${({ theme }) => theme.colors.invalidRed};
    color: ${({ theme }) => theme.colors.white};
  }
`;
