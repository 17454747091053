import makeRequest from 'utils/requests/makeRequest';
import endpointNames from 'utils/constants/endpointNames';

export const getAssessmentPreviewDataMW = async ({ urlParams }) => {
  try {
    const result = makeRequest({
      endpoint: endpointNames.getAssessmentPreviewData,
      urlParams
    });
    return result;
  } catch (err) {
    console.error(err);
  }
};
