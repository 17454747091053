import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FlexContainer } from 'components/Atoms/Containers';
import { FormInput, FormTitle } from 'components/Blocks/ManagementForm';
import { InstructionsListContainer } from 'pages/ManagementPage/ProctorComponents';
import QuillEditor from 'components/Systems/QuillEditor';
import xIcon from 'assets/xIcon';
import { ellipsis } from 'utils/mixins/Mixins';
import {
  MAX_LOGO_MB_SIZE,
  MAX_LOGO_NAME_LENGTH
} from 'pages/ManagementPage/Constants';

const LandingPageForm = ({
  defaultTitle,
  instructions,
  title,
  setData,
  errors,
  logoFileName
}) => {
  const fileInputRef = useRef(null);

  const handleUploaderClick = event => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  const handleFileChange = event => {
    const [file] = event.target.files;
    if (file) {
      const url = URL.createObjectURL(file);
      setData({ logoFile: { file, url } });
    }
  };

  const removeFile = event => {
    event.stopPropagation();
    setData({
      logoFile: { file: null, url: '' }
    });
  };

  return (
    <FormContainer direction="column">
      <FormTitle>Logo</FormTitle>
      <InformationLabel isValid={!errors?.FILE}>
        {errors?.FILE
          ? `Must be .png or .jpg smaller than ${MAX_LOGO_MB_SIZE} MB and the file name should be less than ${MAX_LOGO_NAME_LENGTH} characters long`
          : `Max file size: ${MAX_LOGO_MB_SIZE} MB`}
      </InformationLabel>

      <UploadFileButtonContainer
        align="center"
        onClick={handleUploaderClick}
        isValid={!errors?.FILE}
      >
        <UploadFileButton>Upload</UploadFileButton>
        <FileName hasFile={Boolean(logoFileName)}>
          {logoFileName || 'No file selected'}
        </FileName>
        {logoFileName && <RemoveFile onClick={removeFile}>{xIcon}</RemoveFile>}
      </UploadFileButtonContainer>
      <FakeFileInput
        ref={fileInputRef}
        type="file"
        accept="image/png, image/jpeg"
        onChange={handleFileChange}
      />
      <FormTitle>Assessment Name</FormTitle>
      <FormInput
        name="assessment-title"
        onChange={ev =>
          setData({ title: ev.target.value, headerTitle: ev.target.value })
        }
        placeholder="Name your assessment"
        type="text"
        value={title}
        defaultValue={defaultTitle}
        isValid={!errors?.TITLE}
      />
      <FormTitle>Instructions</FormTitle>

      <InstructionsListContainer>
        <QuillEditor
          initialBody={instructions}
          grayTheme
          inputName="assessment-landing-page-instructions"
          placeholder="Instructions for your assessment"
          isLatexRestricted
          onUpdatedBody={body => setData({ instructions: body })}
          validateOnSubmit
          isValidField
        />
      </InstructionsListContainer>
    </FormContainer>
  );
};

export default LandingPageForm;

LandingPageForm.propTypes = {
  defaultTitle: PropTypes.string.isRequired,
  instructions: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  setData: PropTypes.func.isRequired,
  errors: PropTypes.object,
  logoFileName: PropTypes.string,
  defaultLogoUrl: PropTypes.string
};

LandingPageForm.defaultProps = {
  errors: {},
  logoFileName: '',
  defaultLogoUrl: ''
};

const FormContainer = styled(FlexContainer)`
  width: 100%;
`;

const FakeFileInput = styled.input`
  display: none;
`;

const UploadFileButtonContainer = styled(FlexContainer)`
  background: ${({ theme }) => theme.colors.lightGrey};
  border: ${({ theme, isValid }) =>
    !isValid && `2px solid ${theme.colors.invalidRed}`};
  cursor: pointer;
  height: 32px;
  padding-right: 12px;
`;

const UploadFileButton = styled.button`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 2px;
  border: 1px solid #d0d0d0;
  color: #6a7f87;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.avenirProBlack};
  font-size: 14px;
  height: 100%;
  letter-spacing: 1.25px;
  max-width: 100px;
  outline: 0;
  text-transform: uppercase;
`;

const FileName = styled.span`
  color: ${({ hasFile, theme }) => (hasFile ? theme.colors.black : '#6a7f87')};
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  margin-left: 12px;
  ${ellipsis}
`;

const RemoveFile = styled.span`
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-left: auto;
`;

const InformationLabel = styled.label`
  color: ${({ isValid, theme }) =>
    isValid ? '#6a7f87' : theme.colors.invalidRed};
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.4px;
  line-height: 16px;
  margin: -10px 0 16px;
  text-align: left;
`;
