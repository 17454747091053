import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useForm, FormContext } from 'react-hook-form';
import * as yup from 'yup';
import AssessmentLink from 'pages/PanelPage/components/selectedAssessment/assessmentInvite/AssessmentLink';
import InviteBody from 'pages/PanelPage/components/selectedAssessment/assessmentInvite/InviteBody';
import InviteHeader from 'pages/PanelPage/components/selectedAssessment/assessmentInvite/InviteHeader';
import PopupNotification from 'components/Blocks/PopupNotification';
import { validateEmail } from 'pages/PanelPage/helpers/validateEmail';
import { FlexContainer, FormContainer } from 'components/Atoms/Containers';
import xIcon from 'assets/xIcon';
import {
  getInviteTemplateMW,
  saveDefaultTemplateMW,
  sendInvitationsMW
} from 'pages/PanelPage/middleware';

const validationSchema = yup.object().shape({
  recipients: yup.array().of(
    yup.object().shape({
      value: yup
        .string()
        .test('Valid Email', 'Please enter a valid email', value => {
          if (value === '') {
            return true;
          }
          if (value) {
            return validateEmail(value);
          }
        })
    })
  ),
  subject: yup.string().required('Email subject is required')
});

const AssessmentInvite = ({ selectedAssessment }) => {
  const methods = useForm({ validationSchema, mode: 'all' });
  const [template, setTemplate] = useState(null);
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [sending, setSending] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(null);
  const [bodyHasChanged, setBodyHasChanged] = useState(false);
  const [subjectHasChanged, setSubjectHasChanged] = useState(false);
  const [showEmailChanges, setShowEmailChanges] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const { slug, is_public } = selectedAssessment;
  const {
    formState: { isSubmitted, isValid },
    handleSubmit
  } = methods;
  const showErrors = isSubmitted && !isValid;

  const fetchTemplate = useCallback(async () => {
    const template = await getInviteTemplateMW({ urlParams: [slug] });
    setTemplate(template.data);
    setBody(template.data.formatted_body);
    setSubject(template.data.formatted_subject);
  }, [slug]);

  useEffect(() => {
    fetchTemplate();
  }, [fetchTemplate]);

  // Triggers invitation/s sending
  const postInvitations = async (list, subject) => {
    const templateBody = body !== template.formatted_body ? body : null;
    const templateSubject =
      subject !== template.formatted_subject ? subject : null;

    const invitationsData = {
      invites: list,
      template_body: templateBody,
      template_subject: templateSubject
    };

    setSending(true);
    const sendInvitations = await sendInvitationsMW({
      urlParams: [slug],
      data: invitationsData
    });

    if (sendInvitations) {
      setSendSuccess(true);
      setTimeout(() => setSendSuccess(null), 5000);
    } else {
      setSendSuccess(false);
    }
    setSending(false);
  };

  // Restructure invite data for request
  const onSubmit = ({ recipients, subject }) => {
    if (recipients) {
      // get list of value emails entered
      let inviteList = recipients
        .filter(({ value }) => Boolean(value.length))
        .map(({ value }) => value.toLowerCase());
      // remove duplicate emails
      inviteList = [...new Set(inviteList)];
      if (inviteList.length) {
        // format list for request body
        inviteList = inviteList.map(email => {
          return { email, send_email: true };
        });
        postInvitations(inviteList, subject);
      }
    }
  };

  const handleSaveDefaultTemplate = async event => {
    event.preventDefault();
    const templateData = {
      ...template,
      template_type: 'email',
      body,
      subject
    };

    await saveDefaultTemplateMW({ urlParams: [slug], data: templateData });
    setShowPopup(true);
  };

  const renderPopup = () =>
    showPopup && (
      <PopupContainer>
        <PopupNotification
          title="New invite template saved"
          content="This email template is now the default for this assessment"
          cleanHandler={() => setShowPopup(false)}
        ></PopupNotification>
      </PopupContainer>
    );

  return (
    <FormContext {...methods}>
      <FlexContainer justify="center" align="center">
        <EmailFormContainer onSubmit={handleSubmit(onSubmit)}>
          <FlexContainer margin="20px 0" align="center" justify="flex-end">
            {is_public && <AssessmentLink assessment={selectedAssessment} />}
          </FlexContainer>
          <InviteContainer direction="column" align="flex-end">
            {renderPopup()}
            <InviteHeader
              template={template}
              showErrors={showErrors}
              sendSuccess={sendSuccess}
              showSubjectChanges={subjectHasChanged && showEmailChanges}
              setSubjectHasChanged={setSubjectHasChanged}
              setSubject={setSubject}
            />
            <InviteBody
              template={template}
              setBody={setBody}
              body={body}
              sending={sending}
              setBodyHasChanged={setBodyHasChanged}
              isValidSaveDefault={bodyHasChanged || subjectHasChanged}
              setShowEmailChanges={setShowEmailChanges}
              showBodyChanges={bodyHasChanged && showEmailChanges}
              handleSaveDefaultTemplate={handleSaveDefaultTemplate}
            />
          </InviteContainer>
          {sendSuccess === true && (
            <AlertContainer
              isSuccessAlert={sendSuccess}
              justify="center"
              align="center"
            >
              <Check />
              Success!
            </AlertContainer>
          )}
          {sendSuccess === false && (
            <AlertContainer
              isSuccessAlert={sendSuccess}
              justify="center"
              align="center"
            >
              <XIconContainer>{xIcon}</XIconContainer>
              Error: unable to send email. Please try again.
            </AlertContainer>
          )}
        </EmailFormContainer>
      </FlexContainer>
    </FormContext>
  );
};

export default AssessmentInvite;

AssessmentInvite.propTypes = {
  selectedAssessment: PropTypes.object.isRequired
};

const EmailFormContainer = styled(FormContainer)`
  width: 755px;
`;

const InviteContainer = styled(FlexContainer)`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
`;

const AlertContainer = styled(FlexContainer)`
  background: ${({ isSuccessAlert }) =>
    isSuccessAlert ? '#7ebf5a' : '#da1e28'};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
  height: 40px;
  padding: 8px 4px 4px;
  width: 755px;
`;

const XIconContainer = styled.div`
  padding: none;
  svg g {
    stroke: ${({ theme }) => theme.colors.white};
  }
`;

const Check = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.colors.white};
  border-right: 2px solid ${({ theme }) => theme.colors.white};
  height: 16px;
  margin: 0 14px 7px 0;
  transform: rotate(45deg);
  width: 8px;
`;

const PopupContainer = styled.div`
  right: -12px;
  margin-left: 8px;
  position: absolute;
  top: -5px;
  z-index: 100;
`;
