import React from 'react';
import PropTypes from 'prop-types';
import { FormContainer, FormSection } from 'components/Blocks/ManagementForm';
import QuestionFields from 'pages/ManagementPage/candidateInfoForm/QuestionFields';

const CandidateInfoForm = ({ createdFields, setData }) => (
  <FormContainer direction="column">
    <FormSection data-testid="question-fields-candidate-info">
      <QuestionFields
        createdFields={createdFields}
        setFieldsData={fields => setData({ fields })}
      />
    </FormSection>
  </FormContainer>
);

export default CandidateInfoForm;

CandidateInfoForm.propTypes = {
  createdFields: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired
};
