import React from 'react';

const candidateInfoIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="17"
    viewBox="0 0 14 17"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#FFF" fillRule="nonzero" transform="translate(-315 -139)">
        <g transform="translate(164 59)">
          <g transform="translate(108 70)">
            <g transform="translate(32)">
              <g transform="translate(11 10.5)">
                <path d="M6.857 0a4.119 4.119 0 00-4.114 4.114A4.119 4.119 0 006.857 8.23a4.119 4.119 0 004.114-4.115A4.119 4.119 0 006.857 0zM11.976 10.916a5.865 5.865 0 00-4.205-1.773H5.943c-1.585 0-3.078.63-4.205 1.773A5.996 5.996 0 000 15.146c0 .253.205.458.457.458h12.8a.457.457 0 00.457-.457c0-1.59-.617-3.092-1.738-4.23z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default candidateInfoIcon;
