import { WHOLE_SECTION } from 'pages/ManagementPage/Constants';

const calculateDuration = ({ section, mode }) => {
  if (mode === 'assessment-preview') {
    if (section.sectionDuration === WHOLE_SECTION) {
      return section.sectionSeconds;
    } else {
      const questions = section?.questions || [];
      return questions
        .filter(q => q.duration)
        .reduce((acc, q) => (acc += q.duration), 0);
    }
  } else if (mode === 'preview-page') {
    // here section is actually slots
    return section
      .filter(sec => sec.time_allowed_seconds)
      .reduce((acc, sec) => (acc += sec.time_allowed_seconds), 0);
  } else return null;
};

export default calculateDuration;
