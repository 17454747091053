import React from 'react';

const dragAndDropIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="34"
    viewBox="0 0 16 34"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#B4B4B4" transform="translate(-91 -543)">
        <g transform="translate(91 90)">
          <g transform="translate(0 374)">
            <g transform="translate(0 36)">
              <g transform="rotate(-180 8 38.257)">
                <g>
                  <rect
                    width="2.004"
                    height="1.882"
                    x="0"
                    y="3.765"
                    rx="0.941"
                  ></rect>
                  <rect
                    width="2.004"
                    height="1.882"
                    x="0"
                    y="7.529"
                    rx="0.941"
                  ></rect>
                  <rect
                    width="2.004"
                    height="1.882"
                    x="0"
                    y="11.294"
                    rx="0.941"
                  ></rect>
                  <rect
                    width="2.004"
                    height="1.882"
                    x="4.008"
                    y="3.765"
                    rx="0.941"
                  ></rect>
                  <rect
                    width="2.004"
                    height="1.882"
                    x="4.008"
                    y="7.529"
                    rx="0.941"
                  ></rect>
                  <rect
                    width="2.004"
                    height="1.882"
                    x="4.008"
                    y="11.294"
                    rx="0.941"
                  ></rect>
                  <rect
                    width="2.004"
                    height="1.882"
                    x="4.008"
                    y="0"
                    rx="0.941"
                  ></rect>
                  <rect
                    width="2.004"
                    height="1.882"
                    x="0"
                    y="0"
                    rx="0.941"
                  ></rect>
                </g>
                <g transform="translate(8.016)">
                  <rect
                    width="2.004"
                    height="1.882"
                    x="0"
                    y="3.765"
                    rx="0.941"
                  ></rect>
                  <rect
                    width="2.004"
                    height="1.882"
                    x="0"
                    y="7.529"
                    rx="0.941"
                  ></rect>
                  <rect
                    width="2.004"
                    height="1.882"
                    x="0"
                    y="11.294"
                    rx="0.941"
                  ></rect>
                  <rect
                    width="2.004"
                    height="1.882"
                    x="4.008"
                    y="3.765"
                    rx="0.941"
                  ></rect>
                  <rect
                    width="2.004"
                    height="1.882"
                    x="4.008"
                    y="7.529"
                    rx="0.941"
                  ></rect>
                  <rect
                    width="2.004"
                    height="1.882"
                    x="4.008"
                    y="11.294"
                    rx="0.941"
                  ></rect>
                  <rect
                    width="2.004"
                    height="1.882"
                    x="4.008"
                    y="0"
                    rx="0.941"
                  ></rect>
                  <rect
                    width="2.004"
                    height="1.882"
                    x="0"
                    y="0"
                    rx="0.941"
                  ></rect>
                </g>
                <g transform="translate(0 15.059)">
                  <rect
                    width="2.004"
                    height="1.882"
                    x="0"
                    y="0"
                    rx="0.941"
                  ></rect>
                  <rect
                    width="2.004"
                    height="1.882"
                    x="0"
                    y="7.529"
                    rx="0.941"
                  ></rect>
                  <rect
                    width="2.004"
                    height="1.882"
                    x="0"
                    y="11.294"
                    rx="0.941"
                  ></rect>
                  <rect
                    width="2.004"
                    height="1.882"
                    x="0"
                    y="15.059"
                    rx="0.941"
                  ></rect>
                  <rect
                    width="2.004"
                    height="1.882"
                    x="4.008"
                    y="0"
                    rx="0.941"
                  ></rect>
                  <rect
                    width="2.004"
                    height="1.882"
                    x="4.008"
                    y="7.529"
                    rx="0.941"
                  ></rect>
                  <rect
                    width="2.004"
                    height="1.882"
                    x="4.008"
                    y="11.294"
                    rx="0.941"
                  ></rect>
                  <rect
                    width="2.004"
                    height="1.882"
                    x="4.008"
                    y="15.059"
                    rx="0.941"
                  ></rect>
                  <rect
                    width="2.004"
                    height="1.882"
                    x="4.008"
                    y="3.765"
                    rx="0.941"
                  ></rect>
                  <rect
                    width="2.004"
                    height="1.882"
                    x="0"
                    y="3.765"
                    rx="0.941"
                  ></rect>
                </g>
                <g transform="translate(8.016 15.059)">
                  <rect
                    width="2.004"
                    height="1.882"
                    x="0"
                    y="0"
                    rx="0.941"
                  ></rect>
                  <rect
                    width="2.004"
                    height="1.882"
                    x="0"
                    y="7.529"
                    rx="0.941"
                  ></rect>
                  <rect
                    width="2.004"
                    height="1.882"
                    x="0"
                    y="11.294"
                    rx="0.941"
                  ></rect>
                  <rect
                    width="2.004"
                    height="1.882"
                    x="0"
                    y="15.059"
                    rx="0.941"
                  ></rect>
                  <rect
                    width="2.004"
                    height="1.882"
                    x="4.008"
                    y="0"
                    rx="0.941"
                  ></rect>
                  <rect
                    width="2.004"
                    height="1.882"
                    x="4.008"
                    y="7.529"
                    rx="0.941"
                  ></rect>
                  <rect
                    width="2.004"
                    height="1.882"
                    x="4.008"
                    y="11.294"
                    rx="0.941"
                  ></rect>
                  <rect
                    width="2.004"
                    height="1.882"
                    x="4.008"
                    y="15.059"
                    rx="0.941"
                  ></rect>
                  <rect
                    width="2.004"
                    height="1.882"
                    x="4.008"
                    y="3.765"
                    rx="0.941"
                  ></rect>
                  <rect
                    width="2.004"
                    height="1.882"
                    x="0"
                    y="3.765"
                    rx="0.941"
                  ></rect>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default dragAndDropIcon;
