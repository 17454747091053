import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FlexContainer } from 'components/Atoms/Containers';
import QuestionSelectOption from 'pages/PreviewPage/QuestionSelectOption';
import { checkMarkIcon } from 'components/Atoms/Icons/checkMarkIcon';
import getCorrectAnswerOption from 'pages/PreviewPage/helpers/getCorrectAnswerOption';
import indexToLetter from 'pages/PreviewPage/helpers/indexToLetter';

const QuestionSelectOptions = ({ options }) => {
  const correctAnswerOption = getCorrectAnswerOption(options);

  return (
    <QuestionSelectWrapper data-testid="question-select-wrapper">
      {options.map((option, i) => (
        <QuestionSelectOption
          content={option.content[0].text_display}
          letter={indexToLetter(i)}
          key={i}
        />
      ))}
      {correctAnswerOption.map(({ correctLetter }) => (
        <CorrectContainer
          align="center"
          key={correctLetter}
          data-testid="question-select-correct"
        >
          {checkMarkIcon} Correct Answer:
          <BoldedLetter>{correctLetter}</BoldedLetter>
        </CorrectContainer>
      ))}
    </QuestionSelectWrapper>
  );
};

export default QuestionSelectOptions;

QuestionSelectOptions.propTypes = {
  options: PropTypes.array.isRequired
};

const QuestionSelectWrapper = styled.div`
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
`;

const BoldedLetter = styled.span`
  font-weight: 900;
  margin: 0 0 0 8px;
`;

const CorrectContainer = styled(FlexContainer)`
  background-color: ${({ theme }) => theme.colors.correctGreen};
  border-radius: 4px;
  margin: 4px 0;
  min-height: 48px;
  padding: 4px;
  > svg {
    height: 25px;
    margin: 0 16px;
    min-width: 25px;
  }
`;
