import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import equal from 'fast-deep-equal';
import AccessControl from 'components/Systems/AccessControl';
import AddAssessment from 'pages/ManagementPage/AddAssessment';
import AssessmentCard from 'pages/PanelPage/components/AssessmentCard';
import FilterList from 'components/Blocks/FilterList';
import Loader from 'components/Systems/Loader';
import Navbar from 'pages/PanelPage/components/Navbar';
import SortList from 'components/Blocks/SortList';
import { useAssessmentContext } from 'state/assessmentContext/useAssessmentContext';
import { useUserContext } from 'state/userContext/useUserContext';
import {
  Container,
  MaxWidthContainer,
  FlexContainer,
  PageContentContainer
} from 'components/Atoms/Containers';
import { PageWrapper } from 'components/Blocks/Styled/Wrappers';
import { listTypes } from 'pages/App/helpers/listTypes';
import { paths } from 'pages/App/helpers/paths';
import { permissionTypes } from 'pages/App/helpers/permissionTypes';
import { assessmentStates, tenantAccount } from 'pages/PanelPage/Constants';
import {
  VALID_STATES as VS,
  INACTIVE_ASSESSMENTS
} from 'utils/constants/stateTypes';
import NoResults from 'components/Blocks/NoResults';
import PopupNotification from 'components/Blocks/PopupNotification';
import usePrevious from 'pages/PanelPage/hooks/usePrevious';
import { getAssessmentsMW } from 'pages/PanelPage/middleware';

const AssessmentList = () => {
  const {
    pathname,
    createdAssessment = null,
    wasEdited = false
  } = useLocation();

  const {
    assessmentState: { assessmentList },
    assessmentDispatch
  } = useAssessmentContext();

  const {
    userState: { userData }
  } = useUserContext();

  const [sortByRecent, setSortByRecent] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [stateList, setStateList] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [tenantData, setTenantData] = useState(null);

  useEffect(() => {
    const handleFilters = () => {
      switch (pathname) {
        case `${paths.ADMIN}/${VS.ALL}`:
          setStateList(Object.values(assessmentStates));
          setSelectedFilter(VS.ALL);
          break;
        case `${paths.ADMIN}/${VS.ALL}/${VS.ACTIVE}`:
          setStateList([VS.ACTIVE]);
          setSelectedFilter(VS.ALL);
          break;
        case `${paths.ADMIN}/${VS.ALL}/${VS.REVIEW}`:
          setStateList([VS.REVIEW]);
          setSelectedFilter(VS.ALL);
          break;
        case `${paths.ADMIN}/${VS.ALL}/${VS.ARCHIVED}`:
          setStateList([VS.ARCHIVED]);
          setSelectedFilter(VS.ALL);
          break;
        case `${paths.ADMIN}/${VS.INACTIVE}`:
          setStateList(INACTIVE_ASSESSMENTS);
          setSelectedFilter(VS.INACTIVE);
          break;
        case `${paths.ADMIN}/${VS.INACTIVE}/${VS.REVIEW}`:
          setStateList([VS.REVIEW]);
          setSelectedFilter(VS.INACTIVE);
          break;
        case `${paths.ADMIN}/${VS.INACTIVE}/${VS.ARCHIVED}`:
          setStateList([VS.ARCHIVED]);
          setSelectedFilter(VS.INACTIVE);
          break;
        case `${paths.ADMIN}/${VS.ACTIVE}`:
          setStateList([VS.ACTIVE]);
          setSelectedFilter(VS.ACTIVE);
          break;
        default:
          break;
      }
    };
    handleFilters();
  }, [pathname]);

  const prevStateList = usePrevious(stateList);
  const prevSortByRecent = usePrevious(sortByRecent);
  const prevTenantData = usePrevious(tenantData);

  useEffect(() => {
    assessmentDispatch.setSelectedAssessment(null);
    const params = new URLSearchParams();
    stateList.forEach(state => params.append('assessment_states', state));
    params.append('sort_order', sortByRecent ? 'desc' : 'asc');
    setIsLoading(true);
    if (tenantData && tenantData.id !== tenantAccount.ALL_ACCOUNTS) {
      params.append('tenant_id', tenantData.id);
    } else if (!tenantData) {
      params.append('tenant_id', userData.tenant_id);
    }
    const fetchAssessments = async () => {
      let results = await getAssessmentsMW({ queryParams: params });
      assessmentDispatch.setAssessments(results.data);
      setIsLoading(false);
    };
    stateList.length &&
      (!equal(prevStateList, stateList) ||
        sortByRecent !== prevSortByRecent ||
        !equal(prevTenantData, tenantData)) &&
      fetchAssessments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateList, sortByRecent, tenantData]);

  const renderAssessmentList = () => {
    return assessmentList.length ? (
      assessmentList.map(assessment => (
        <AssessmentCard
          key={assessment.id}
          assessment={assessment}
          tenantId={tenantData?.id}
        />
      ))
    ) : (
      <NoResults />
    );
  };

  const renderPopup = () => {
    const action = wasEdited ? 'edited' : 'created';
    return (
      (createdAssessment || wasEdited) && (
        <PopupContainer>
          <PopupNotification
            content={`You have successfully ${action} assessment ${createdAssessment}`}
            title={`New assessment has been ${action}`}
          />
        </PopupContainer>
      )
    );
  };

  return (
    <PageWrapper background="#f0f2f4" id="admin-page">
      <Navbar
        setStateList={setStateList}
        setSelectedFilter={setSelectedFilter}
        selectedFilter={selectedFilter}
        setTenantId={setTenantData}
        tenantId={tenantData?.id}
      />
      <PageContentContainer>
        <MaxWidthContainer>
          <Container width="100%">
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <ActionsContainer align="center" justify="space-between">
                  <FilterList
                    type={listTypes.ASSESSMENTS}
                    onFilterClick={setStateList}
                    selectedFilter={selectedFilter}
                  />
                  <AccessControl
                    allowedPermissions={[
                      permissionTypes.CAN_CREATE_ASSESSMENTS,
                      permissionTypes.CAN_CREATE_ALL_ASSESSMENTS
                    ]}
                    renderNoAccess={() => null}
                  >
                    <AddNewAssessment>
                      <AddAssessment />
                    </AddNewAssessment>
                  </AccessControl>
                  <SortList
                    handleSort={() => setSortByRecent(!sortByRecent)}
                    isCaretUp={!sortByRecent}
                    text="created at"
                  />
                </ActionsContainer>

                <Container width="100%">
                  {renderPopup()}
                  {renderAssessmentList()}
                </Container>
              </>
            )}
          </Container>
        </MaxWidthContainer>
      </PageContentContainer>
    </PageWrapper>
  );
};

export default AssessmentList;

const ActionsContainer = styled(FlexContainer)`
  margin-bottom: 36px;
  width: 100%;
`;

const AddNewAssessment = styled.section`
  > button {
    position: absolute;
    right: 0;
    top: -52px;
  }
`;

const PopupContainer = styled.section`
  position: absolute;
  top: -80px;
  right: 0;
  z-index: 10;
`;
