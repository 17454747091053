import React from 'react';

const questionPoolItemIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <path
        id="path-1"
        d="M4.294 8.511h-.342v4.762H0V0h4.997c.773 0 1.447.1 2.024.3.576.2 1.048.487 1.415.862s.643.828.826 1.36c.184.53.276 1.127.276 1.79a4.524 4.524 0 01-.532 2.184 2.947 2.947 0 01-.513.703c-.19.187-.38.325-.57.412l2.147 5.662H5.719L4.294 8.51zm-.342-5.118v2.325h.342c.114 0 .24-.016.38-.047a.963.963 0 00.665-.534c.076-.15.114-.344.114-.582 0-.25-.035-.45-.105-.6a.982.982 0 00-.266-.356.797.797 0 00-.341-.168 1.644 1.644 0 00-.333-.038h-.456z"
      ></path>
    </defs>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-159 -76)">
        <g transform="translate(159 76)">
          <rect
            width="24"
            height="24"
            x="0"
            y="0"
            fill="#FFC043"
            opacity="0.1"
            rx="2"
          ></rect>
          <g fillRule="nonzero" transform="translate(6.988 5.474)">
            <g>
              <use fill="#FFF" xlinkHref="#path-1"></use>
              <use fill="#FFC043" xlinkHref="#path-1"></use>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default questionPoolItemIcon;
