import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { saveAs } from 'file-saver';
import AtAGlance from 'pages/PanelPage/components/selectedAssessment/assessmentResults/AtAGlance';
import TakersContainer from 'pages/PanelPage/components/selectedAssessment/assessmentResults/TakersContainer';
import DistributionTable from 'pages/PanelPage/components/selectedAssessment/assessmentResults/DistributionTable';
import usePrevious from 'pages/PanelPage/hooks/usePrevious';
import { Separator } from 'pages/PanelPage/styles/StyledComponents/StyledComponents';
import Search from 'pages/PanelPage/components/Search';
import NoResults from 'components/Blocks/NoResults';
import Paginator from 'components/Blocks/Paginator';
import { FlexContainer } from 'components/Atoms/Containers';
import { StyledButton } from 'components/Atoms/Buttons';
import Loader from 'components/Systems/Loader';
import { requestHelper } from 'utils/requests/requestHelper';
import { requests } from 'utils/requests/requests';
import { ATTEMPT_STATES } from 'pages/ManagementPage/Constants';
import { useAssessmentContext } from 'state/assessmentContext/useAssessmentContext';

const AllResults = ({ chartData, setChartData }) => {
  const {
    assessmentState: { selectedAssessment },
    assessmentDispatch: { setAssessmentAttempts }
  } = useAssessmentContext();
  const [pageNum, setPageNum] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [startDate, setStartDate] = useState(null);
  const [searchEmail, setSearchEmail] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [log, setLog] = useState({ rangeStart: 1, rangeEnd: 10 });
  const [isLoading, setIsLoading] = useState(true);
  const [isExporting, setIsExporting] = useState(false);
  const [generalAttemptData, setGeneralAttemptData] = useState({});
  const [assessmentData, setAssessmentData] = useState({});
  const [sortBy, setSortBy] = useState({ sortKey: 'date', order: 'desc' });
  const prevPageNum = usePrevious(pageNum);

  const fetchAssessmentAttempts = async () => {
    await requestHelper(
      requests({
        page: pageNum,
        limit: itemsPerPage,
        start_date: startDate,
        end_date: endDate,
        email: searchEmail,
        assessment_id: selectedAssessment.id,
        attempt_state: ATTEMPT_STATES.COMPLETE,
        sort_by: sortBy.sortKey,
        sort_order: sortBy.order
      }).getAssessmentAttempts,
      {},
      response => {
        const {
          paginated_data,
          chart_data,
          ...generalData
        } = response.data.data;
        generalData.pages = Math.ceil(generalData.count / itemsPerPage);
        generalData.weighted_max_score =
          paginated_data?.[0]?.weighted_max_score;
        setAssessmentAttempts(paginated_data);
        setGeneralAttemptData(generalData);
      }
    );
    await requestHelper(
      requests({
        start_date: startDate,
        end_date: endDate,
        email: searchEmail,
        assessment_id: selectedAssessment.id,
        attempt_state: ATTEMPT_STATES.COMPLETE
      }).getHistogramData,
      {},
      response => {
        const { chart_data } = response.data.data;
        setChartData(chart_data);
      }
    );
    setIsLoading(false);
  };

  const fetchAssessmentAttemptsCsv = async useParams => {
    const requestParams = useParams
      ? {
          start_date: startDate,
          end_date: endDate,
          email: searchEmail,
          assessment_id: selectedAssessment.id,
          attempt_state: ATTEMPT_STATES.COMPLETE,
          sort_by: sortBy.sortKey,
          sort_order: sortBy.order
        }
      : {
          assessment_id: selectedAssessment.id,
          attempt_state: ATTEMPT_STATES.COMPLETE,
          sort_by: sortBy.sortKey,
          sort_order: sortBy.order
        };
    await requestHelper(
      requests(requestParams).getAssessmentAttemptsCsv,
      {},
      response => {
        const contentType = response.headers['content-type'];
        const blob = new Blob([response.data], { type: contentType });
        saveAs(blob, 'assessment_results.csv');
      }
    );
  };

  const handleExportClick = async () => {
    setIsExporting(true);
    await fetchAssessmentAttemptsCsv(true);
    setIsExporting(false);
  };

  useEffect(() => {
    setAssessmentData(selectedAssessment);
  }, [selectedAssessment]);

  useEffect(() => {
    fetchAssessmentAttempts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageNum,
    itemsPerPage,
    sortBy.sortKey,
    sortBy.order,
    searchEmail,
    endDate
  ]);

  // NOTE: This use effect sets the page number back to 1 when a date filter is applied
  useEffect(() => {
    pageNum > 1 && pageNum === prevPageNum && setPageNum(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, searchEmail, pageNum]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <FlexContainer justify="space-between" align="flex-end">
        <SearchWrapper>
          <Search name="search" setSearchInputValue={setSearchEmail} />
        </SearchWrapper>
        <AtAGlance assessmentData={assessmentData} />
      </FlexContainer>
      <FlexContainer>
        {generalAttemptData.count > 0 && (
          <Export disabled={isExporting} onClick={handleExportClick}>
            EXPORT {(searchEmail || startDate || endDate) && 'Filtered'} RESULTS
          </Export>
        )}
      </FlexContainer>
      <Separator />
      <DistributionTable
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        chartData={chartData}
        searchEmail={searchEmail}
        setSearchEmail={setSearchEmail}
      />
      <TakersContainer sortBy={sortBy} setSortBy={setSortBy} />
      {!generalAttemptData.count ? <NoResults /> : null}
      {generalAttemptData.count ? (
        <Paginator
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          log={log}
          setLog={setLog}
          pageNum={pageNum}
          setPageNum={setPageNum}
          totalCount={generalAttemptData.count}
          totalPages={generalAttemptData.pages}
        />
      ) : null}
    </>
  );
};

AllResults.propTypes = {
  chartData: PropTypes.object.isRequired,
  setChartData: PropTypes.func.isRequired
};

AllResults.defaultProps = {
  selectedSection: {},
  setSelectedSection: () => {}
};

export default AllResults;

const SearchWrapper = styled.div`
  margin-bottom: 16px;
  width: auto;
`;

const Export = styled(StyledButton)`
  align-items: center;
  background: ${({ theme }) => theme.tenantAccent};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  height: 40px;
  justify-content: center;
  width: 212px;
`;
