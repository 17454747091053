import styled from 'styled-components';
import { FlexContainer } from 'components/Atoms/Containers';
import Input from 'components/Blocks/Forms/Input';

export const FormTitle = styled.p`
  color: #212629;
  font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: 28px;
  margin-bottom: 8px;
  margin-top: 0;
  text-align: left;
  text-transform: capitalize;
`;

export const FormContainer = styled(FlexContainer)`
  width: 100%;
`;

export const FormInput = styled(Input)`
  background: ${({ theme }) => theme.colors.lightGrey};
`;

export const FormSection = styled.section`
  margin-bottom: 12px;
`;
