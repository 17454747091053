import React from 'react';

const questionItemIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#037AFF" transform="translate(-115 -410)">
        <g transform="translate(84 318)">
          <g transform="translate(16 68)">
            <g transform="translate(15 24)">
              <rect
                width="24"
                height="24.324"
                x="0"
                y="0"
                opacity="0.1"
                rx="2"
              ></rect>
              <path
                fillRule="nonzero"
                d="M17.808 18.243v-3.411l-1.71.127v-.073c.108-.121.219-.3.333-.538.114-.237.213-.507.297-.812.084-.304.153-.629.207-.976.054-.346.081-.684.081-1.012 0-.985-.12-1.885-.36-2.7-.24-.815-.585-1.508-1.035-2.08a4.666 4.666 0 00-1.656-1.332c-.654-.316-1.389-.474-2.205-.474-.816 0-1.554.158-2.214.474-.66.317-1.221.77-1.683 1.36-.462.59-.816 1.301-1.062 2.134-.246.833-.369 1.767-.369 2.8 0 2.092.444 3.7 1.332 4.826.888 1.125 2.172 1.687 3.852 1.687h6.192zm-6.048-3.575a.935.935 0 01-.621-.22 1.552 1.552 0 01-.423-.61 4.01 4.01 0 01-.243-.93 7.93 7.93 0 01-.081-1.178c0-.413.027-.796.081-1.149.054-.353.135-.657.243-.912.108-.255.249-.456.423-.602a.935.935 0 01.621-.219c.468 0 .813.27 1.035.812.222.541.333 1.231.333 2.07 0 .852-.108 1.554-.324 2.107-.216.554-.564.83-1.044.83z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default questionItemIcon;
