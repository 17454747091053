import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  AnswerDescription,
  FreeResponseInput,
  QuestionBody,
  RadioInput,
  RadioLabel,
  RadioRowWrapper
} from 'pages/QuestionPage/questionPreview/ProctorComponents';
import { parseHtml } from 'pages/PanelPage/helpers/parseHtml';
import EmptyContentPlaceHolder from 'components/Blocks/EmptyContentPlaceholder';
import {
  FREE_RESPONSE,
  MULTIPLE_CHOICE,
  MAP_BACKEND_QUESTION_TYPES,
  questionLibraryPath
} from 'pages/QuestionPage/Constants';
import { FlexContainer } from 'components/Atoms/Containers';
import { requestHelper } from 'utils/requests/requestHelper';
import AddButton from 'pages/ManagementPage/AddButton';
import QuestionInformationItem from 'components/Blocks/QuestionInformationItem';
import { QuestionInformationItemTypes } from 'utils/constants/constants';
import eyeIcon from 'assets/eyeIcon';
import { requests } from 'utils/requests/requests';

const QuestionPreview = ({
  answersOptionsData,
  body,
  type,
  match,
  questionId,
  isAssessmentCreation,
  handleAddButtonClick,
  showActionBtn
}) => {
  const [fetchedData, setFetchedData] = useState(null);
  const questionPreviewId = questionId || match?.params?.questionId;

  const fetchData = useCallback(async () => {
    try {
      await requestHelper(
        requests({ questionId: questionPreviewId }).getSingleQuestionData,
        {},
        response => {
          const questionData = response.data.data;
          const questionDataContent = questionData.content[0];
          const type = MAP_BACKEND_QUESTION_TYPES[questionData.question_type];
          const fetchAnswerOptions = () => {
            switch (type) {
              case FREE_RESPONSE:
                return questionData.bands.map(
                  ({ min_value, max_value, score }) => ({
                    min: min_value,
                    max: max_value,
                    score
                  })
                );
              case MULTIPLE_CHOICE:
                return questionData.options.map(({ content, value }) => ({
                  body: content[0].text_display,
                  score: value
                }));
              default:
                return [];
            }
          };
          setFetchedData({
            answersOptionsData: {
              answerOptions: fetchAnswerOptions(),
              helperText: questionDataContent.placeholder
            },
            body: questionDataContent.text_display,
            type,
            category: questionData?.default_category?.name
          });
        },
        err => {
          console.error(err);
        }
      );
    } catch (err) {
      console.error(err);
    }
  }, [questionPreviewId]);

  useEffect(() => {
    if (questionPreviewId) {
      fetchData();
    }
  }, [questionPreviewId, fetchData]);

  const { answerOptions, helperText } = fetchedData
    ? fetchedData.answersOptionsData
    : answersOptionsData;
  const renderAnswerOptions = () => {
    let shouldShowAnswers = !!fetchedData;
    switch (fetchedData ? fetchedData.type : type) {
      case MULTIPLE_CHOICE:
        shouldShowAnswers =
          shouldShowAnswers || answerOptions.some(({ body }) => body);
        return (
          shouldShowAnswers && (
            <>
              <AnswerDescription>
                Please select a single option below
              </AnswerDescription>
              {answerOptions.map(
                ({ body }, index) =>
                  body && (
                    <RadioRowWrapper
                      key={`preview-answer-option-${index}`}
                      data-testid="preview-multiple-answer-option"
                    >
                      <RadioInput readOnly type="radio" checked={false} />
                      <RadioLabel className="quill" htmlFor={body}>
                        {parseHtml(body, true)}
                      </RadioLabel>
                    </RadioRowWrapper>
                  )
              )}
            </>
          )
        );
      case FREE_RESPONSE:
        return (
          <>
            <AnswerDescription>
              Please enter your answer below
            </AnswerDescription>
            <FreeResponseInput data-testid="preview-free-answer-option">
              {helperText}
            </FreeResponseInput>
          </>
        );
      default:
        return;
    }
  };

  const getExtraInformation = () => {
    const { category, type } = fetchedData || {};
    return (
      <ExtraInformation
        align="center"
        justify="space-between"
        data-testid="assessment-creation-extra-info"
      >
        <FlexContainer align="center">
          {category && (
            <QuestionAdditionalInfoItem
              content={category}
              type={QuestionInformationItemTypes.QUESTION_TYPE}
            />
          )}
          {type && (
            <QuestionAdditionalInfoItem
              content={type}
              type={QuestionInformationItemTypes.CATEGORY}
            />
          )}
        </FlexContainer>
        <Actions align="center">
          <div>
            <PreviewIcon
              to={`${questionLibraryPath}/question-preview/${questionId}`}
              target="_blank"
            >
              {eyeIcon}
            </PreviewIcon>
          </div>
          {showActionBtn && (
            <div>
              <StyledAddBtn onClick={handleAddButtonClick} />
            </div>
          )}
        </Actions>
      </ExtraInformation>
    );
  };

  const renderPreviewContent = () => {
    const bodyToRender = fetchedData ? fetchedData.body : body;
    const shouldShowPreview = fetchedData ? Boolean(questionPreviewId) : true;
    return (
      shouldShowPreview && (
        <>
          {bodyToRender && (
            <>
              {isAssessmentCreation && getExtraInformation()}
              <QuestionBody className="quill">
                {parseHtml(bodyToRender, true)}
              </QuestionBody>
            </>
          )}
          {renderAnswerOptions()}
        </>
      )
    );
  };

  return (
    <>
      <PreviewContainer className={!!fetchedData && 'fetched'}>
        {renderPreviewContent()}
      </PreviewContainer>
      <EmptyContentPlaceHolder
        className="placeholder"
        content="No content yet"
      />
    </>
  );
};

QuestionPreview.propTypes = {
  answersOptionsData: PropTypes.object,
  body: PropTypes.string,
  type: PropTypes.oneOf([MULTIPLE_CHOICE, FREE_RESPONSE]),
  questionId: PropTypes.number,
  isAssessmentCreation: PropTypes.bool,
  handleAddButtonClick: PropTypes.func,
  showActionBtn: PropTypes.bool
};

QuestionPreview.defaultProps = {
  answersOptionsData: {
    answerOptions: [],
    helperText: ''
  },
  body: '',
  type: MULTIPLE_CHOICE,
  questionId: null,
  isAssessmentCreation: false,
  handleAddButtonClick: () => null,
  showActionBtn: true
};

export default QuestionPreview;

const PreviewContainer = styled.div`
  margin-top: 137px;
  position: relative;
  word-break: break-word;
  .quill {
    font-size: 16px;
  }
  img {
    max-width: 100%;
  }
  * {
    white-space: pre-wrap;
  }
  &.fetched {
    margin-right: auto;
    margin-left: auto;
    padding-right: 0;
    padding-left: 0;
    max-width: 1016px;
  }
  .katex * {
    white-space: nowrap;
  }
  + .placeholder {
    display: none;
  }
  &:empty {
    + .placeholder {
      display: flex;
    }
  }
  pre {
    background-color: #23241f;
    color: #f8f8f2;
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 5px 10px;
    white-space: pre-wrap;
  }
`;

const ExtraInformation = styled(FlexContainer)`
  margin-bottom: 22px;
`;

const Actions = styled(FlexContainer)`
  > div {
    cursor: pointer;
    &:first-of-type {
      margin-right: 16px;
    }
  }
`;

const StyledAddBtn = styled(AddButton)`
  height: 32px;
  width: 32px;
`;

const QuestionAdditionalInfoItem = styled(QuestionInformationItem)`
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 14px;
  & span {
    position: relative;
    top: 2px;
  }
`;

const PreviewIcon = styled(Link)`
  position: relative;
  z-index: 10;
`;
