import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FlexContainer } from 'components/Atoms/Containers';
import { Body2, H6 } from 'components/Atoms/Text';
import tickIcon from 'assets/tickIcon';
import closeIcon from 'assets/closeIcon';
import errorIcon from 'assets/errorIcon';
import warningIcon from 'assets/warningIcon';

const POPUP_TYPE = Object.freeze({
  IS_ERROR: 'ERROR',
  IS_NORMAL: 'NORMAL',
  IS_ALERT: 'ALERT'
});

const PopupNotification = ({
  content,
  duration,
  popupType,
  title,
  cleanHandler,
  className
}) => {
  const [shouldShow, setShouldShow] = useState(true);

  const closePopup = useCallback(() => {
    setShouldShow(false);
    cleanHandler();
  }, [cleanHandler]);

  useEffect(() => {
    if (duration !== Infinity) {
      const timerId = setTimeout(closePopup, duration);
      return () => {
        clearTimeout(timerId);
      };
    }
  }, [closePopup, duration]);

  const getIcon = () => {
    switch (popupType) {
      case POPUP_TYPE.IS_ERROR:
        return <ErrorIcon data-testid="error-icon">{errorIcon}</ErrorIcon>;

      case POPUP_TYPE.IS_NORMAL:
        return <TickIcon data-testid="tick-icon">{tickIcon}</TickIcon>;

      case POPUP_TYPE.IS_ALERT:
        return (
          <WarningIcon data-testid="warning-icon">{warningIcon}</WarningIcon>
        );
      default:
        return;
    }
  };

  return (
    shouldShow && (
      <PopupNotificationContainer className={className}>
        <PopupNotificationHeader align="center">
          {getIcon()}
          <PopupNotificationTitle>{title}</PopupNotificationTitle>
          <CloseBtn onClick={closePopup}>{closeIcon}</CloseBtn>
        </PopupNotificationHeader>
        <PopupNotificationContent>{content}</PopupNotificationContent>
      </PopupNotificationContainer>
    )
  );
};

export default PopupNotification;

PopupNotification.propTypes = {
  content: PropTypes.string.isRequired,
  duration: PropTypes.number,
  popupType: PropTypes.oneOf(Object.values(POPUP_TYPE)).isRequired,
  title: PropTypes.string.isRequired,
  cleanHandler: PropTypes.func
};

PopupNotification.defaultProps = {
  duration: Infinity,
  cleanHandler: () => null,
  popupType: POPUP_TYPE.IS_NORMAL
};

const PopupNotificationContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  border: ${({ theme }) => `1px solid ${theme.colors.mercury}`};
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.07);
  max-width: 360px;
  padding: 12px 14px 16px 16px;
`;

const PopupNotificationTitle = styled(H6)`
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.1px;
  line-height: 24px;
`;

const TickIcon = styled(FlexContainer)`
  background: ${({ theme }) => theme.colors.mantisGreen};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.white};
  height: 16px;
  margin-right: 8px;
  position: relative;
  top: -1px;
  width: 16px;
`;

const ErrorIcon = styled(FlexContainer)`
  position: relative;
  right: 4px;
`;

const WarningIcon = styled(FlexContainer)`
  position: relative;
  right: 4px;
`;

const PopupNotificationHeader = styled(FlexContainer)`
  margin-bottom: 4px;
  text-align: left;
`;

const PopupNotificationContent = styled(Body2)`
  color: ${({ theme }) => theme.colors.smoky};
  margin-left: 24px;
  text-align: left;
`;

const CloseBtn = styled.button`
  background: 0;
  border: 0;
  align-self: flex-start;
  cursor: pointer;
  margin-left: auto;
  outline: 0;
  padding: 0;
  position: relative;
  top: -8px;
  svg {
    fill: ${({ theme }) => theme.colors.veryLightGrey};
  }
`;
