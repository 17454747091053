import { useContext } from 'react';
import { AssessmentContext } from 'state/assessmentContext/assessmentContext';
import { dispatchActions } from 'state/assessmentContext/dispatchActions';

export const useAssessmentContext = () => {
  const { assessmentState, assessmentDispatch } = useContext(AssessmentContext);

  return {
    assessmentState,
    assessmentDispatch: dispatchActions(assessmentDispatch)
  };
};
