const convertNumberToLetter = index => {
  let number = index + 1;
  let letter = '';
  let t = '';
  while (number > 0) {
    t = (number - 1) % 26;
    letter = String.fromCharCode(65 + t) + letter;
    number = ((number - t) / 26) | 0;
  }
  return letter;
};

export default convertNumberToLetter;
