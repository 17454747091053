import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import pluralize from 'pluralize';
import { FlexContainer } from 'components/Atoms/Containers';
import {
  TenantLogoContainer,
  TenantLogo,
  AssessmentTitle,
  InstructionContainer,
  TermsButton,
  termsAndConditions,
  InstructionsListContainer
} from 'pages/ManagementPage/ProctorComponents';
import { Table, BorderCell } from 'components/Blocks/Styled/Tables';
import { useThemeContext } from 'state/themeContext/useThemeContext';
import { parseHtml } from 'pages/PanelPage/helpers/parseHtml';
import { StyledButton } from 'components/Atoms/Buttons';
import secondsToFormattedString from 'utils/helpers/secondsToFormattedString';

const LandingPagePreview = ({
  assessmentColorTheme,
  instructions,
  title,
  logoUrl,
  sectionsData,
  isAssessmentPreview
}) => {
  const theme = useThemeContext();

  const renderSectionTable = sectionsData => (
    <Table borderCollapse="collapse">
      <tbody>
        {sectionsData.map(({ title, durationInSeconds, questionCount }, i) => {
          const durationString = secondsToFormattedString(durationInSeconds);

          return (
            <tr key={i}>
              <StyledCell>{title}</StyledCell>
              <StyledCell>
                {pluralize('question', questionCount, true)}
              </StyledCell>
              <StyledCell>{durationString}</StyledCell>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );

  return (
    (title || instructions || logoUrl) && (
      <PreviewContainer align="center" direction="column" justify="center">
        <TenantLogoContainer>
          <TenantLogo
            data-testid="tenant-logo"
            src={logoUrl || theme.domainTheme.logo}
            alt="Tenant Logo"
          />
        </TenantLogoContainer>
        {title && (
          <FlexContainer
            align="center"
            direction="column"
            data-testid="title-info"
          >
            <AssessmentTitle>{title}</AssessmentTitle>
            <InfoContainer align="center" justify="space-between">
              {renderSectionTable(sectionsData)}
            </InfoContainer>
          </FlexContainer>
        )}
        {instructions && (
          <InfoContainer
            align="center"
            direction="column"
            justify="center"
            data-testid="instructions-info"
          >
            <InstructionsListContainer>
              <InstructionContainer>
                <InstructionsTitle>Instructions:</InstructionsTitle>
                {parseHtml(instructions)}
              </InstructionContainer>
            </InstructionsListContainer>
            <TermsButton>
              {!isAssessmentPreview && (
                <ContinueBtn background={assessmentColorTheme}>
                  continue
                </ContinueBtn>
              )}
            </TermsButton>
            {termsAndConditions}
          </InfoContainer>
        )}
      </PreviewContainer>
    )
  );
};

export default LandingPagePreview;

LandingPagePreview.propTypes = {
  assessmentColorTheme: PropTypes.string.isRequired,
  instructions: PropTypes.string.isRequired,
  logoUrl: PropTypes.string,
  sectionsData: PropTypes.array,
  title: PropTypes.string.isRequired,
  isAssessmentPreview: PropTypes.bool
};

LandingPagePreview.defaultProps = {
  sectionsData: []
};

const StyledCell = styled(BorderCell)`
  padding: 12px 18px;
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
`;

const PreviewContainer = styled(FlexContainer)`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 32px 48px;
  word-break: break-word;
`;

const InfoContainer = styled(FlexContainer)`
  width: 100%;
`;

const ContinueBtn = styled(StyledButton)`
  background: ${({ background }) => background};
  pointer-events: none;
`;

const InstructionsTitle = styled.span`
  display: block;
  margin-bottom: 16px;
`;
