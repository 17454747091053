import styled from 'styled-components';

const Img = styled.img`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin: ${({ margin }) => margin};
  margin: ${({ padding }) => padding};
  &.pointer {
    cursor: pointer;
  }
`;

const Svg = styled.svg`
  color: ${({ theme, dynamic }) =>
    dynamic ? theme.tenantAccent : theme.colors.darkBlue};
`;

export { Img, Svg };
