import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import equal from 'fast-deep-equal';
import NoResults from 'components/Blocks/NoResults';
import Paginator from 'components/Blocks/Paginator';
import QuestionNav from 'pages/QuestionPage/QuestionNav';
import QuestionCard from 'pages/QuestionPage/QuestionCard';
import QuestionUpdateWarning from 'pages/QuestionPage/QuestionUpdateWarning';
import { Container, MaxWidthContainer } from 'components/Atoms/Containers';
import Loader from 'components/Systems/Loader';
import { requestHelper } from 'utils/requests/requestHelper';
import { requests } from 'utils/requests/requests';
import {
  VALID_STATES as VS,
  QUESTION_STATES
} from 'utils/constants/stateTypes';
import usePrevious from 'pages/PanelPage/hooks/usePrevious';

const QuestionList = ({
  showSelectQuestionType,
  setEditingQuestionId,
  stateList,
  setStateList,
  selectedFilter,
  isAssessmentCreation,
  onCardClick,
  activeCard,
  excludedQuestionIds,
  preserveHistory,
  shouldFetchAssessmentsByQuestion
}) => {
  const [questions, setQuestions] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [log, setLog] = useState({ rangeStart: 1, rangeEnd: 10 });
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [sortByRecent, setSortByRecent] = useState(true);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [questionAssessmentData, setQuestionAssessmentData] = useState([]);
  const [questionUpdateData, setQuestionUpdateData] = useState({});
  const [questionUpdated, setQuestionUpdated] = useState(false);
  const [questionsAdditionalInfo, setQuestionsAdditionalInfo] = useState([]);
  const [filteredCategoryParam, setFilteredCategoryParam] = useQueryParam(
    'category',
    NumberParam
  );
  const [searchInputValue, setSearchInputValue] = useQueryParam(
    'search',
    StringParam
  );
  const previousStateList = usePrevious(stateList);
  const previousSearch = usePrevious(searchInputValue);
  const previousCategorySearch = usePrevious(filteredCategoryParam);
  const previousPage = usePrevious(pageNum);
  const previousQuestions = usePrevious(questions);

  const urlUpdateType = preserveHistory ? 'pushIn' : 'replaceIn';

  const fetchQuestionList = useCallback(
    async active => {
      setIsLoading(true);
      const params = new URLSearchParams();
      stateList.forEach(state => params.append('question_states', state));
      params.append('page', pageNum);
      params.append('limit', itemsPerPage);
      params.append('sort_order', sortByRecent ? 'desc' : 'asc');
      searchInputValue && params.append('search', searchInputValue);
      excludedQuestionIds.forEach(id => params.append('excluded_ids', id));
      filteredCategoryParam &&
        params.append('category_ids', filteredCategoryParam);
      await requestHelper(requests(params).getQuestionList, {}, response => {
        if (active) {
          setQuestions(response.data.data.paginated_data);
          setTotalCount(response.data.data.count);
          setIsLoading(false);
          setTotalPages(Math.ceil(response.data.data.count / itemsPerPage));
          setQuestionUpdated(false);
        }
      });
    },
    [
      pageNum,
      itemsPerPage,
      stateList,
      sortByRecent,
      searchInputValue,
      excludedQuestionIds,
      filteredCategoryParam
    ]
  );

  useEffect(() => {
    const fetchAssessmentsByQuestion = async () => {
      const params = new URLSearchParams();
      const questionIds = questions.map(({ id }) => id);
      questionIds.forEach(id => params.append('question_ids', id));
      await requestHelper(
        requests(params).getAssessmentsByQuestion,
        {},
        ({ data }) => setQuestionsAdditionalInfo(data.data)
      );
    };

    if (
      shouldFetchAssessmentsByQuestion &&
      questions.length &&
      !equal(questions, previousQuestions)
    ) {
      fetchAssessmentsByQuestion();
    }
  }, [questions, shouldFetchAssessmentsByQuestion, previousQuestions]);

  useEffect(() => {
    let active = true;
    if (
      (stateList.length && previousStateList !== stateList) ||
      (previousSearch !== searchInputValue && pageNum === 1) ||
      (previousCategorySearch !== filteredCategoryParam && pageNum === 1) ||
      previousPage !== pageNum
    ) {
      fetchQuestionList(active);
    }
    return () => (active = false);
  }, [
    fetchQuestionList,
    stateList,
    previousStateList,
    previousSearch,
    searchInputValue,
    previousCategorySearch,
    filteredCategoryParam,
    pageNum,
    previousPage
  ]);

  useEffect(() => {
    let active = true;
    questionUpdated && fetchQuestionList(active);

    return () => (active = false);
  }, [questionUpdated, fetchQuestionList]);

  useEffect(() => {
    if (questionUpdateData.questionId) {
      questionAssessmentData.length || questionUpdateData.data === VS.DELETE
        ? setShowWarningModal(true)
        : updateQuestion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionAssessmentData]);

  useEffect(() => {
    setPageNum(1);
  }, [stateList]);

  useEffect(() => {
    setPageNum(1);
  }, [filteredCategoryParam, searchInputValue]);

  const onOptionClick = async updateData => {
    setQuestionUpdateData(updateData);
    const questionAsssessmentData = questionsAdditionalInfo.find(
      ({ question_id }) => question_id === updateData.questionId
    ).assessments;

    setQuestionAssessmentData(questionAsssessmentData);
  };

  const updateQuestion = useCallback(async () => {
    setIsLoading(true);
    const { questionId, data } = questionUpdateData;
    const question = questions.find(question => question.id === questionId);
    const { default_category, files, ...updatedQuestion } = question;
    if (data === VS.EDIT) {
      setEditingQuestionId(questionId);
    } else {
      updatedQuestion.question_state = data;
      delete updatedQuestion.created_at;
      delete updatedQuestion.updated_at;
      await requestHelper(
        requests({ questionId }).updateQuestion,
        updatedQuestion,
        () => {
          questionAssessmentData.length && setQuestionAssessmentData([]);
          questionUpdateData.questionId && setQuestionUpdateData({});
          showWarningModal && setShowWarningModal(false);
          setQuestionUpdated(true);
        }
      );
    }
  }, [
    questionUpdateData,
    questions,
    showWarningModal,
    questionAssessmentData.length,
    setEditingQuestionId
  ]);

  return (
    <Container>
      {showWarningModal && (
        <QuestionUpdateWarning
          setShowWarningModal={setShowWarningModal}
          affectedAssessments={questionAssessmentData}
          updateQuestion={updateQuestion}
          isDelete={questionUpdateData.data === VS.DELETE}
        />
      )}
      <MaxWidthContainer>
        <Container width="100%">
          <QuestionNav
            setSortByRecent={setSortByRecent}
            sortByRecent={sortByRecent}
            showSelectQuestionType={showSelectQuestionType}
            totalQuestions={totalCount}
            setStateList={setStateList}
            stateList={stateList}
            selectedFilter={selectedFilter}
            isAssessmentCreation={isAssessmentCreation}
            searchInputValue={searchInputValue}
            setSearchInputValue={value =>
              setSearchInputValue(value, urlUpdateType)
            }
            setFilteredCategory={category =>
              setFilteredCategoryParam(category, urlUpdateType)
            }
            filteredCategory={filteredCategoryParam}
          />
          {isLoading ? (
            <Loader />
          ) : (
            <Container width="100%">
              {questions.length ? (
                questions.map(question => {
                  const shouldShowCardToggle = Boolean(
                    questionsAdditionalInfo.find(
                      ({ question_id }) => question_id === question.id
                    )?.assessments?.length
                  );

                  return (
                    <QuestionCard
                      key={question.id}
                      questionProps={question}
                      onOptionClick={onOptionClick}
                      isAssessmentCreation={isAssessmentCreation}
                      onClick={() => onCardClick(question)}
                      isActiveCard={
                        isAssessmentCreation && question.id === activeCard
                      }
                      shouldShowToggle={shouldShowCardToggle}
                    />
                  );
                })
              ) : (
                <NoResults />
              )}
            </Container>
          )}
        </Container>

        <Paginator
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          log={log}
          setLog={setLog}
          pageNum={pageNum}
          setPageNum={setPageNum}
          totalCount={totalCount}
          totalPages={totalPages}
        />
      </MaxWidthContainer>
    </Container>
  );
};

export default QuestionList;

QuestionList.propTypes = {
  showSelectQuestionType: PropTypes.func,
  setEditingQuestionId: PropTypes.func,
  stateList: PropTypes.array,
  setStateList: PropTypes.func,
  selectedFilter: PropTypes.string,
  isAssessmentCreation: PropTypes.bool,
  onCardClick: PropTypes.func,
  activeCard: PropTypes.number,
  excludedQuestionIds: PropTypes.array,
  filteredCategory: PropTypes.array,
  preserveHistory: PropTypes.bool,
  shouldFetchAssessmentsByQuestion: PropTypes.bool
};

QuestionList.defaultProps = {
  showSelectQuestionType: () => null,
  setEditingQuestionId: () => null,
  stateList: QUESTION_STATES,
  setStateList: () => null,
  selectedFilter: '',
  isAssessmentCreation: false,
  onCardClick: () => null,
  activeCard: null,
  excludedQuestionIds: [],
  preserveHistory: true,
  shouldFetchAssessmentsByQuestion: false
};
