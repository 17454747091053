export const requests = (params = {}) => {
  return {
    // authentication endpoints
    login: {
      endpoint: 'authentication/login',
      method: 'post'
    },
    publicAccess: {
      endpoint: 'authentication/public_access',
      method: 'post'
    },
    // question management endpoints
    getCategories: {
      endpoint: 'category',
      method: 'get'
    },
    createQuestion: {
      endpoint: 'question',
      method: 'post'
    },
    getQuestionList: {
      endpoint: 'question',
      method: 'get',
      params
    },
    getSingleQuestionData: {
      endpoint: `question/${params.questionId}`,
      method: 'get'
    },
    updateQuestion: {
      endpoint: `question/${params.questionId}`,
      method: 'put'
    },
    // question file endpoints
    saveFile: {
      endpoint: `question/${params.questionId}/file`,
      method: 'put',
      headers: params.headers
    },
    deleteFile: {
      endpoint: `question/${params.questionId}/file/${params.questionFileId}`,
      method: 'delete'
    },
    // assessment management endpoints
    createAssessment: {
      endpoint: 'assessment',
      method: 'post'
    },
    updateAssessment: {
      endpoint: `assessment/${params.assessmentId}`,
      method: 'put'
    },
    updateAssessmentState: {
      endpoint: `assessment/${params.assessment_id}/update-state`,
      method: 'patch'
    },
    updateAssessmentMetadata: {
      endpoint: `assessment/${params.assessmentId}/update-metadata`,
      method: 'post'
    },
    deleteAssessment: {
      // TODO: add this to backend #4459
      endpoint: `assessment/${params.assessment_id}`,
      method: 'delete'
    },
    // assessment endpoints
    getAssessments: {
      endpoint: 'assessment',
      method: 'get',
      params
    },
    getAssessment: {
      endpoint: `assessment/${params.assessment_id}`,
      method: 'get'
    },
    getAssessmentPreviewUuid: {
      endpoint: `assessment/${params.assessmentId}/get-preview`,
      method: 'get'
    },
    getAssessmentPreviewData: {
      endpoint: `assessment/preview/${params.previewUuid}`,
      method: 'get'
    },
    getAssessmentBySlug: {
      endpoint: `assessment/slug/${params.slug}`,
      method: 'get'
    },
    getPoolsByAssessment: {
      endpoint: `assessment/slug/${params.slug}/pools`,
      method: 'get'
    },
    getAssessmentCategoryStatistics: {
      endpoint: `assessment/${params.assessment_id}/statistics/category`,
      method: 'get'
    },
    getHistogramData: {
      endpoint: 'reporting/attempt/histogram',
      method: 'get',
      params
    },
    getAssessmentAttempts: {
      endpoint: 'reporting/attempt',
      method: 'get',
      params
    },
    getAssessmentAttemptsCsv: {
      endpoint: 'reporting/attempt/csv',
      method: 'get',
      params
    },
    getAssessmentAttempt: {
      endpoint: `reporting/attempt/${params.uuid}`,
      method: 'get'
    },
    getAssessmentAttemptStockStatistics: {
      endpoint: `reporting/attempt/${params.uuid}/statistics/stock`,
      method: 'get'
    },
    getAssessmentTemplate: {
      endpoint: `template/${params.slug}`,
      method: 'get'
    },
    createAssessmentTemplate: {
      endpoint: `template/${params.slug}`,
      method: 'post'
    },
    createInvitations: {
      endpoint: `proctoring/invite/${params.slug}`,
      method: 'post'
    },
    createAssessmentAttemptLink: {
      endpoint: `reporting/attempt/${params.uuid}/link`,
      method: 'post'
    },
    checkAssessmentBySlug: {
      endpoint: `proctoring/${params.slug}`,
      method: 'get'
    },
    getAssessmentsCount: {
      endpoint: `assessment/count`,
      method: 'get',
      params
    },
    // user endpoints
    getUserData: {
      endpoint: 'identity/tenant-user',
      method: 'get'
    },
    requestResetPassword: {
      endpoint: 'identity/tenant-user/password/request-reset',
      method: 'post'
    },
    resetPassword: {
      endpoint: 'identity/tenant-user/password/reset',
      method: 'post'
    },
    // configuration endpoints
    updateAssessmentConfig: {
      endpoint: 'configuration/update_assessment_config',
      method: 'put'
    },
    uploadLogo: {
      endpoint: 'configuration/logo',
      method: 'post'
    },
    // question additional information endpoints
    getAssessmentsByQuestion: {
      endpoint: 'question-additional-information/used-questions',
      method: 'get',
      params
    },
    getQuestionInformation: {
      endpoint: `question-additional-information/${params.questionId}`,
      method: 'get'
    },
    // tenant endpoints
    getTenants: {
      endpoint: 'identity/tenant',
      method: 'get'
    }
  };
};
