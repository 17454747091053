import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PreviewNavPoolsItem from 'pages/PreviewPage/PreviewNavPoolsItem';
import { FlexContainer } from 'components/Atoms/Containers';
import leftArrowIcon from 'assets/leftArrowIcon';
import { VIEW_TYPES } from 'pages/PreviewPage/Constants';

const PreviewNavPools = ({
  setViewType,
  questionPoolsData,
  categories,
  handleScrollToItem,
  defaultSelectedItem
}) => {
  const [selectedItem, setSelectedItem] = useState(defaultSelectedItem);

  const renderBackBtn = () => (
    <GoBackBtn
      align="center"
      onClick={() => setViewType(VIEW_TYPES.assessment)}
    >
      {leftArrowIcon}
      <span>Back</span>
    </GoBackBtn>
  );

  const renderPoolList = () => (
    <PoolList>
      {Object.keys(questionPoolsData).map(poolId => (
        <li
          key={poolId}
          onClick={e => {
            if (+poolId === +selectedItem) {
              setSelectedItem(null);
            } else {
              handleScrollToItem(e, poolId);
              setSelectedItem(+poolId);
            }
          }}
        >
          <PreviewNavPoolsItem
            categoryName={categories.find(({ id }) => +id === +poolId).name}
            questions={questionPoolsData[poolId]}
            isExpanded={selectedItem === +poolId}
            onQuestionClick={(e, questionIdx) =>
              handleScrollToItem(e, `SR${poolId}-${questionIdx}`)
            }
          />
        </li>
      ))}
    </PoolList>
  );
  return (
    <PreviewNavWrapper>
      {
        <>
          {renderBackBtn()}
          {renderPoolList()}
        </>
      }
    </PreviewNavWrapper>
  );
};

export default PreviewNavPools;

PreviewNavPools.propTypes = {
  setViewType: PropTypes.func.isRequired,
  questionPoolsData: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  handleScrollToItem: PropTypes.func.isRequired,
  defaultSelectedItem: PropTypes.number
};

PreviewNavPools.defaultProps = {
  defaultSelectedItem: null
};

const PreviewNavWrapper = styled.section`
  height: 100vh;
  overflow-y: scroll;
  position: fixed;
  width: 350px;
`;

const GoBackBtn = styled(FlexContainer)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.mediumGrey};
  cursor: pointer;
  margin-bottom: 16px;
  padding: 15px 24px 8px;
  span {
    color: #34b7ea;
    font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 1.8px;
    text-align: left;
    text-transform: uppercase;
  }
  svg {
    bottom: 3px;
    position: relative;
    path {
      fill: #34b7ea;
    }
  }
`;

const PoolList = styled.ul`
  padding: 0 16px;
  li {
    list-style-type: none;
    margin: 0 0 10px 0;
  }
`;
