import React, { createContext, useReducer } from 'react';

const initialState = {
  isAuthenticated: false,
  userData: null,
  roles: [],
  permissions: []
};

export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_ROLES_AND_PERMISSIONS = 'SET_ROLES_AND_PERMISSIONS';

export const UserContext = createContext(initialState);

export const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_AUTHENTICATED:
      return { ...state, isAuthenticated: payload };
    case SET_USER_DATA:
      return { ...state, userData: payload };
    case SET_ROLES_AND_PERMISSIONS:
      return {
        ...state,
        roles: payload.roles,
        permissions: payload.permissions
      };
    default:
      return state;
  }
};

const UserProvider = ({ children }) => {
  const [userState, userDispatch] = useReducer(reducer, initialState);

  return (
    <UserContext.Provider value={{ userState, userDispatch }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
