import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FlexContainer } from 'components/Atoms/Containers';
import { Body1 } from 'components/Atoms/Text';

const BandedResponse = ({ bands }) => {
  const lessThanOrEqualTo = String.fromCharCode(8804);
  let maxScore = 0;
  let correctIndex = 0;
  let bandsWithOptimalAnswer = [];

  if (bands.length > 1) {
    bandsWithOptimalAnswer = bands.map((band, i) => {
      if (band.score === maxScore) {
        band.optimalAnswer = true;
      } else if (band.score > maxScore) {
        maxScore = band.score;
        correctIndex = i;
      }
      return band;
    });
    bandsWithOptimalAnswer[correctIndex].optimalAnswer = true;
  } else if (bands.length > 0) {
    bandsWithOptimalAnswer = bands.map(band => {
      band.optimalAnswer = true;
      return band;
    });
  }

  const renderAnswerContent = ({ min_value, max_value, score, correct }) => (
    <FlexContainer align="center">
      <StyledBody>Score: {score}</StyledBody>
      <Body1>
        {correct && `Optimal answer: `}
        <AnswerText>
          {min_value} {lessThanOrEqualTo} x {lessThanOrEqualTo} {max_value}
        </AnswerText>
      </Body1>
    </FlexContainer>
  );

  const renderBands = () =>
    bandsWithOptimalAnswer.map(
      ({ min_value, max_value, score, optimalAnswer }, i) =>
        optimalAnswer ? (
          <CorrectContainer align="center" justify="space-between" key={i}>
            {renderAnswerContent({
              min_value,
              max_value,
              score,
              correct: true
            })}
          </CorrectContainer>
        ) : (
          <IncorrectContainer align="center" justify="space-between" key={i}>
            {renderAnswerContent({ min_value, max_value, score })}
          </IncorrectContainer>
        )
    );

  const renderNoBands = () => (
    <IncorrectContainer align="center" justify="space-between">
      No answer data found
    </IncorrectContainer>
  );

  return (
    <>
      <BandedInputMockContainer align="center">...</BandedInputMockContainer>
      {bandsWithOptimalAnswer.length ? renderBands() : renderNoBands()}
    </>
  );
};

export default BandedResponse;

BandedResponse.propTypes = {
  bands: PropTypes.array.isRequired
};

const StyledBody = styled(Body1)`
  color: ${({ theme }) => theme.colors.brickOrange};
  margin: 0 13px 0 0;
`;

const BandedInputMockContainer = styled(FlexContainer)`
  background: ${({ theme }) => theme.colors.whiteSmoke};
  border-bottom: ${({ theme }) => `solid 2px ${theme.tenantAccent}`};
  height: 40px;
  margin: 8px 0 16px 0;
  padding: 5px 0 0 16px;
  width: 332px;
`;

const CorrectContainer = styled(FlexContainer)`
  background-color: ${({ theme }) => theme.colors.correctGreen};
  border-radius: 4px;
  height: 40px;
  margin: 4px 0;
  padding: 8px 16px;
`;

const IncorrectContainer = styled(FlexContainer)`
  background-color: ${({ theme }) => theme.colors.incorrectRed};
  border-radius: 4px;
  height: 40px;
  margin: 4px 0;
  padding: 8px 16px;
`;

const AnswerText = styled.span`
  font-weight: bold;
`;
