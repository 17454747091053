import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import EmptyContentPlaceHolder from 'components/Blocks/EmptyContentPlaceholder';
import { OPTION_TYPES } from 'pages/ManagementPage/Constants';
import { ButtonFooter } from 'pages/ManagementPage/ProctorComponents';
import LandingPagePreview from 'pages/ManagementPage/LandingPagePreview';
import MessagePreview from 'pages/ManagementPage/MessagePreview';
import CandidateInfoPreview from 'pages/ManagementPage/candiateInfoPreview/CandidateInfoPreview';
import QuestionSectionPreview from 'pages/ManagementPage/QuestionSectionPreview';
import getNumericPart from 'pages/ManagementPage/utils/getNumericPart';
import calculateDuration from 'utils/helpers/calculateDuration';

const AssessmentPreview = ({
  assessmentData,
  assessmentColorTheme,
  sectionsData,
  selectedOption
}) => {
  const getPreview = () => {
    const {
      instructions,
      title,
      fields,
      messageParagraph,
      logoFile: { url },
      defaultLogo
    } = assessmentData;

    const mapSectionsData = sectionsData => {
      const sections = sectionsData.map(section => ({
        questionCount: section.length ?? 0,
        durationInSeconds: calculateDuration({
          section,
          mode: 'assessment-preview'
        }),
        title: section.sectionTitle || 'Section 1'
      }));
      return sections;
    };

    const mappedSectionData = mapSectionsData(sectionsData);

    const previewOption = selectedOption.includes(OPTION_TYPES.QUESTION_SECTION)
      ? OPTION_TYPES.QUESTION_SECTION
      : selectedOption;
    switch (previewOption) {
      case OPTION_TYPES.LANDING_PAGE:
        return (
          <LandingPagePreview
            assessmentColorTheme={assessmentColorTheme}
            instructions={instructions}
            title={title}
            sectionsData={mappedSectionData}
            logoUrl={url || defaultLogo}
          />
        );

      case OPTION_TYPES.CANDIDATE_INFO:
        const selectedFields = fields?.filter(({ isChecked }) => isChecked);
        return (
          <CandidateInfoPreview
            assessmentColorTheme={assessmentColorTheme}
            fields={selectedFields}
          />
        );

      case OPTION_TYPES.MESSAGE:
        return <MessagePreview messageParagraph={messageParagraph} />;

      case OPTION_TYPES.QUESTION_SECTION:
        const currentSelectedSection = sectionsData.find(
          ({ sectionId }) =>
            parseInt(sectionId) === getNumericPart(selectedOption)
        );
        return (
          <QuestionSectionPreview
            assessmentColorTheme={assessmentColorTheme}
            sectionData={currentSelectedSection}
          />
        );
      default:
        return;
    }
  };

  const getFooter = () => {
    switch (selectedOption) {
      case OPTION_TYPES.CANDIDATE_INFO:
        return <ButtonFooter color={assessmentColorTheme} text="Next" />;

      case OPTION_TYPES.MESSAGE:
        return (
          <ButtonFooter color={assessmentColorTheme} text="Start Assessment" />
        );
      default:
        return;
    }
  };

  return (
    <>
      <Preview>{getPreview()}</Preview>
      <EmptyContentPlaceHolder
        className="placeholder"
        content="No page or content yet"
      />
      <FooterContainer>{getFooter()}</FooterContainer>
    </>
  );
};

export default AssessmentPreview;

AssessmentPreview.propTypes = {
  assessmentData: PropTypes.object.isRequired,
  assessmentColorTheme: PropTypes.string.isRequired,
  sectionsData: PropTypes.array.isRequired,
  selectedOption: PropTypes.string.isRequired
};

AssessmentPreview.defaultProps = {
  sectionsData: []
};

const Preview = styled.div`
  word-break: break-word;
  + .placeholder {
    display: none;
  }
  &:empty {
    + .placeholder {
      display: flex;
    }
  }
`;

const FooterContainer = styled.div`
  margin: auto -112px 0;
  position: absolute;
  top: calc(100% - 65px);
  width: 100%;
  &:empty {
    display: none;
  }
`;
