import React from 'react';

const clockIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#939393" fillRule="nonzero" transform="translate(-42 -46)">
        <path d="M48 46a6 6 0 110 12 6 6 0 010-12zm0 1.09a4.91 4.91 0 100 9.82 4.91 4.91 0 000-9.82zm0 1.092c.271 0 .496.198.538.457l.007.088v3.047l1.477 1.477a.545.545 0 01-.696.834l-.075-.063-1.637-1.636a.545.545 0 01-.153-.3L47.455 52v-3.273c0-.301.244-.545.545-.545z"></path>
      </g>
    </g>
  </svg>
);

export default clockIcon;
