import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import pluralize from 'pluralize';
import { FlexContainer } from 'components/Atoms/Containers';
import { TruncateTextByLines } from 'components/Atoms/Text';
import AddButton from 'pages/ManagementPage/AddButton';
import usePrevious from 'pages/PanelPage/hooks/usePrevious';
import { QUESTION_POOL_LIMIT } from 'pages/ManagementPage/Constants';

const QuestionPoolNavigator = ({
  handleAddQuestionPool,
  questionPools,
  selectedPool,
  setSelectedPool
}) => {
  const navigatorRef = useRef(null);
  const previousQuestionPoolsLength = usePrevious(questionPools.length);

  useEffect(() => {
    if (
      questionPools.length &&
      previousQuestionPoolsLength < questionPools.length
    ) {
      navigatorRef.current.scrollLeft = navigatorRef.current.scrollWidth;
      setSelectedPool(questionPools[questionPools.length - 1]);
    }
  }, [questionPools, previousQuestionPoolsLength, setSelectedPool]);

  const onAddPool = ev => {
    ev.preventDefault();
    handleAddQuestionPool();
  };

  const renderPoolCards = () =>
    questionPools.map(pool => (
      <QuestionPoolCard
        key={pool.title}
        align="flex-start"
        direction="column"
        onClick={() => setSelectedPool(pool)}
        selected={pool === selectedPool}
      >
        <PoolTitleLabel>
          <TruncateTextByLines lines={1}>{pool.title}</TruncateTextByLines>
        </PoolTitleLabel>
        <QuestionCountLabel hasNoQuestions={pool.questions.length < 1}>
          {pluralize('question', pool.questions.length, true)}
        </QuestionCountLabel>
      </QuestionPoolCard>
    ));

  return (
    <StickyContainer>
      <QuestionListsNavigator ref={navigatorRef}>
        {renderPoolCards()}
        {questionPools.length < QUESTION_POOL_LIMIT && (
          <StyledAddButton
            onClick={onAddPool}
            shouldBeAbsolute={questionPools.length >= 5}
          />
        )}
      </QuestionListsNavigator>
    </StickyContainer>
  );
};

export default QuestionPoolNavigator;

QuestionPoolNavigator.propTypes = {
  questionPools: PropTypes.array.isRequired,
  handleAddQuestionPool: PropTypes.func.isRequired,
  selectedPool: PropTypes.object,
  setSelectedPool: PropTypes.func.isRequired
};

QuestionPoolNavigator.defaultProps = {
  selectedPool: null
};

const StickyContainer = styled.div`
  position: sticky;
  top: -15px;
  z-index: 200;
`;

const QuestionListsNavigator = styled.nav`
  align-items: center;
  background: ${({ theme }) => theme.colors.whiteSmoke};
  display: flex;
  height: auto;
  min-height: 85px;
  margin-top: 15px;
  overflow-x: auto;
  padding: 18px 36px;
  scroll-behavior: smooth;
`;
const StyledAddButton = styled(AddButton)`
  background: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `solid 2px ${theme.tenantAccent}`};
  height: 32px;
  position: ${({ shouldBeAbsolute }) =>
    shouldBeAbsolute ? 'absolute' : 'static'};
  right: 0;
  width: 32px;
  svg g {
    fill: ${({ theme }) => theme.tenantAccent};
  }
  &:hover {
    background: ${({ theme }) => theme.tenantAccent};
    svg g {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;

const QuestionPoolCard = styled(FlexContainer)`
  background: ${({ theme, selected }) =>
    selected ? theme.colors.white : '#e2e5e8'};
  border: ${({ selected }) => selected && '1px solid #34b7ea'};
  border-radius: 4px;
  cursor: pointer;
  flex: 1 0 18%;
  margin: 0 16px;
  max-width: 270px;
  padding: 7px 16px;
  &:first-child {
    margin-left: 0;
  }
`;

const PoolTitleLabel = styled(FlexContainer)`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 20px;
`;

const QuestionCountLabel = styled(FlexContainer)`
  color: ${({ theme, hasNoQuestions }) =>
    hasNoQuestions ? '#e55531' : theme.colors.slateGrey};
  font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
  font-size: 10px;
  letter-spacing: 0.29px;
  line-height: 20px;
`;
