import React from 'react';
import { Svg } from 'components/Blocks/Styled/Media';

const cogIcon = (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    dynamic
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g
        fill="currentColor"
        fillRule="nonzero"
        transform="translate(-1208 -17)"
      >
        <g transform="translate(1208 9)">
          <g transform="translate(0 8)">
            <path d="M15.701 9.987L14.195 8.81a5.93 5.93 0 00.036-.619 5.75 5.75 0 00-.036-.618l1.507-1.178a.738.738 0 00.179-.941l-1.566-2.71a.726.726 0 00-.906-.325l-1.777.713a6.092 6.092 0 00-1.067-.62l-.27-1.884A.725.725 0 009.57 0H6.43a.723.723 0 00-.723.623l-.27 1.891a6.28 6.28 0 00-1.066.62L2.59 2.417a.74.74 0 00-.899.32L.123 5.451a.734.734 0 00.178.946l1.506 1.177c-.024.23-.036.43-.036.618s.012.388.036.619L.299 9.989a.739.739 0 00-.177.94l1.566 2.71c.167.305.53.464.906.326l1.777-.714c.34.245.697.453 1.066.62l.27 1.884c.045.359.356.629.724.629h3.14c.368 0 .68-.27.723-.623l.271-1.89a6.31 6.31 0 001.065-.62l1.782.715a.72.72 0 00.9-.32l1.572-2.723a.737.737 0 00-.183-.936zm-7.7.936A2.733 2.733 0 015.27 8.192 2.733 2.733 0 018 5.462a2.733 2.733 0 012.731 2.73 2.733 2.733 0 01-2.73 2.73z"></path>
          </g>
        </g>
      </g>
    </g>
  </Svg>
);

export default cogIcon;
