export const OPTION_TYPES = Object.freeze({
  LANDING_PAGE: 'LANDING_PAGE',
  CANDIDATE_INFO: 'CANDIDATE_INFO',
  MESSAGE: 'MESSAGE',
  QUESTION_SECTION: 'QUESTION_SECTION'
});

export const OPTION_STATES = Object.freeze({
  HIDDEN: 'HIDDEN',
  COLLAPSED: 'COLLAPSED',
  EXPANDED: 'EXPANDED'
});

export const ATTEMPT_STATES = Object.freeze({
  INITIALIZED: 'INITIALIZED',
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  PAUSED: 'PAUSED',
  SUBMITTED: 'SUBMITTED',
  COMPLETE: 'COMPLETE',
  EXPIRED: 'EXPIRED'
});

export const DEFAULT_INSTRUCTIONS = `<li>We recommend using Google Chrome, and to take the assessment using a desktop or laptop.</li><li>Please have a four-function calculator ready for the entirety of the assessment</li><li>You may take breaks between sections of the assessment. If you leave the assessment before starting a new section, you will be able to resume when you return.</li><li>Have a stable internet connection.</li><li>Please note that it is not permitted to copy and/or publish any of the test content.</li>`;

export const FORM_ID = 'create_assessment_form';

export const EACH_QUESTION = 'Each Question';
export const WHOLE_SECTION = 'Whole Section';
export const DEFAULT_DURATION = WHOLE_SECTION;

export const ASSESSMENT_TYPES = Object.freeze({
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE'
});

export const EXPIRATION_UNITS = Object.freeze({
  WEEKS: 'Weeks',
  DAYS: 'Days'
});

export const DEFAULT_EMAIL_TAKER_FIELD = Object.freeze({
  itemId: 0,
  itemName: 'Email',
  isChecked: true,
  isDisabled: true,
  isDisableEdit: true
});

export const FORM_ERRORS_MAP = Object.freeze({
  LANDING_PAGE: 'Assessment must have landing page',
  CANDIDATE_INFO: 'Assessment must have Candidate Info page',
  LANDING_PAGE_TITLE: 'Landing Page is missing required information',
  QUESTION_SECTION: 'Assessment must have at least 1 section',
  QUESTION_SECTION_MISSING_DATA:
    '%{sectionName}% is missing required information',
  QUESTION_SECTION_MISSING_QUESTIONS:
    '%{sectionName}%  must have at least 1 question',
  SETTINGS: 'Settings page is missing required information',
  SLOT_RANDOM_EMPTY:
    'Random question must be from list with at least 1 question',
  HAS_MORE_SLOT_RANDOMS_THAN_QUESTIONS_IN_LIST:
    'Cannot have more random question slots than the number of questions in its list',
  HAS_INVALID_SCORES:
    'Cannot have questions with different scores in a random question bank'
});

export const SETTINGS_ERRORS = Object.freeze({
  HAS_EMPTY_CATEGORIES:
    'This assessment currently has questions without category assignments',
  INVALID_POOL_SCORES: 'This assessment currently has an invalid question pool'
});

export const HAS_EMPTY_CATEGORIES = 'HAS_EMPTY_CATEGORIES';
export const INVALID_POOL_SCORES = 'INVALID_POOL_SCORES';

export const SECTION_STOCK = 'section_stock';

export const MAX_LOGO_MB_SIZE = 2;

export const MAX_LOGO_NAME_LENGTH = 170;
export const SAVE_AS_NEW = 'SAVE_AS_NEW';
export const SAVE_AND_REPLACE = 'SAVE_AND_REPLACE';

export const MAJOR_EDITING_MODE = {
  [SAVE_AS_NEW]: {
    type: SAVE_AS_NEW,
    text: 'Save as new assessment',
    message: [
      'When saving as new assessment, all of your old results will be preserved, but will not appear along side the new results.',
      'Any live invitation links will direct to the new version you have just created.'
    ]
  },
  [SAVE_AND_REPLACE]: {
    type: SAVE_AND_REPLACE,
    text: 'Save and replace',
    message: [
      'Choosing "Continue" will delete any results that have been recorded for this assessment.',
      'If you wish to keep the existing results, exit and choose the "Save as New Assessment" option.'
    ]
  }
};

export const QUESTION_POOL_LIMIT = 15;

export const EXPIRATION_TYPES = Object.freeze({
  DATE: 'DATE',
  DURATION: 'DURATION'
});
