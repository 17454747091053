import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import AccessControl from 'components/Systems/AccessControl';
import AuthPage from 'pages/AuthPage';
import AssessmentsLoader from 'pages/PanelPage/AssessmentsLoader';
import AssessmentList from 'pages/PanelPage/components/AssessmentList';
import QuestionLibrary from 'pages/QuestionPage/QuestionLibrary';
import QuestionCreation from 'pages/QuestionPage/questionCreation/QuestionCreation';
import QuestionPreview from 'pages/QuestionPage/questionPreview/QuestionPreview';
import PreviewPage from 'pages/PreviewPage';
import TenantInfo from 'components/Systems/TenantInfo/TenantInfo';
import SelectedAssessment from 'pages/PanelPage/components/selectedAssessment/SelectedAssessment';
import UnauthorizedView from 'pages/PanelPage/components/UnauthorizedView';
import AssessmentCreation from 'pages/ManagementPage/AssessmentCreation';
import AssessmentProvider from 'state/assessmentContext/assessmentContext';
import { formTypes } from 'pages/App/helpers/formTypes';
import { paths } from 'pages/App/helpers/paths';
import { permissionTypes } from 'pages/App/helpers/permissionTypes';
import { useAuth } from 'pages/App/hooks/useAuth';
import { questionLibraryPath } from 'pages/QuestionPage/Constants';
import { VALID_STATES as VS } from 'utils/constants/stateTypes';
import { useUserContext } from 'state/userContext/useUserContext';

const App = () => {
  const { isAuthenticated, authPending, permissions, tenantInfo } = useAuth();
  const { userState } = useUserContext();
  const {
    CAN_ACCESS_ADMIN_PANEL,
    CAN_VIEW_QUESTION_LIBRARY,
    CAN_MANAGE_QUESTION_LIBRARY,
    CAN_CREATE_ASSESSMENTS,
    CAN_CREATE_ALL_ASSESSMENTS,
    CAN_VIEW_ASSESSMENTS
  } = permissionTypes;

  useEffect(() => {
    if (userState.isAuthenticated && userState?.userData?.id) {
      Sentry.setUser({
        id: userState.userData.id
      });
    }
  }, [userState]);

  return (
    <Router>
      {/* the only public route */}
      <Switch>
        <Route path={`${paths.PREVIEW}/:previewUuid`} component={PreviewPage} />
        {authPending ? (
          <AssessmentsLoader withNav />
        ) : // the rest of the authenticated routes
        isAuthenticated && permissions.includes(CAN_ACCESS_ADMIN_PANEL) ? (
          <AssessmentProvider>
            <QueryParamProvider ReactRouterRoute={Route}>
              <TenantInfo>
                <Switch>
                  <Route exact path={`${questionLibraryPath}/${VS.ALL}`}>
                    <AccessControl
                      allowedPermissions={CAN_VIEW_QUESTION_LIBRARY}
                    >
                      <QuestionLibrary />
                    </AccessControl>
                  </Route>
                  <Route
                    exact
                    path={`${questionLibraryPath}/${VS.ALL}/${VS.ACTIVE}`}
                  >
                    <AccessControl
                      allowedPermissions={CAN_VIEW_QUESTION_LIBRARY}
                    >
                      <QuestionLibrary />
                    </AccessControl>
                  </Route>
                  <Route
                    exact
                    path={`${questionLibraryPath}/${VS.ALL}/${VS.DRAFT}`}
                  >
                    <AccessControl
                      allowedPermissions={CAN_VIEW_QUESTION_LIBRARY}
                    >
                      <QuestionLibrary />
                    </AccessControl>
                  </Route>
                  <Route
                    exact
                    path={`${questionLibraryPath}/${VS.ALL}/${VS.RETIRED}`}
                  >
                    <AccessControl
                      allowedPermissions={CAN_VIEW_QUESTION_LIBRARY}
                    >
                      <QuestionLibrary />
                    </AccessControl>
                  </Route>
                  <Route exact path={`${questionLibraryPath}/${VS.ACTIVE}`}>
                    <AccessControl
                      allowedPermissions={CAN_VIEW_QUESTION_LIBRARY}
                    >
                      <QuestionLibrary />
                    </AccessControl>
                  </Route>
                  <Route exact path={`${questionLibraryPath}/${VS.INACTIVE}`}>
                    <AccessControl
                      allowedPermissions={CAN_VIEW_QUESTION_LIBRARY}
                    >
                      <QuestionLibrary />
                    </AccessControl>
                  </Route>
                  <Route
                    exact
                    path={`${questionLibraryPath}/${VS.INACTIVE}/${VS.DRAFT}`}
                  >
                    <AccessControl
                      allowedPermissions={CAN_VIEW_QUESTION_LIBRARY}
                    >
                      <QuestionLibrary />
                    </AccessControl>
                  </Route>
                  <Route
                    exact
                    path={`${questionLibraryPath}/${VS.INACTIVE}/${VS.RETIRED}`}
                  >
                    <AccessControl
                      allowedPermissions={CAN_VIEW_QUESTION_LIBRARY}
                    >
                      <QuestionLibrary />
                    </AccessControl>
                  </Route>
                  <Route exact path={`${questionLibraryPath}/create-question`}>
                    <AccessControl
                      allowedPermissions={CAN_MANAGE_QUESTION_LIBRARY}
                    >
                      <QuestionCreation />
                    </AccessControl>
                  </Route>
                  <Route
                    exact
                    path={`${questionLibraryPath}/edit-question/:questionId`}
                  >
                    <AccessControl
                      allowedPermissions={CAN_MANAGE_QUESTION_LIBRARY}
                    >
                      <QuestionCreation />
                    </AccessControl>
                  </Route>
                  <Route
                    exact
                    path={`${questionLibraryPath}/question-preview/:questionId`}
                    render={props => (
                      <AccessControl
                        allowedPermissions={CAN_VIEW_QUESTION_LIBRARY}
                      >
                        <QuestionPreview {...props} />
                      </AccessControl>
                    )}
                  />
                  <Route path={`${paths.ADMIN}/assessment-management/create`}>
                    <AccessControl
                      allowedPermissions={[
                        CAN_CREATE_ASSESSMENTS,
                        CAN_CREATE_ALL_ASSESSMENTS
                      ]}
                    >
                      <AssessmentCreation tenantInfo={tenantInfo} />
                    </AccessControl>
                  </Route>
                  <Route
                    exact
                    path={`${paths.ADMIN}/assessment-management/edit-assessment/:assessmentSlug`}
                  >
                    <AccessControl
                      allowedPermissions={[
                        CAN_CREATE_ASSESSMENTS,
                        CAN_CREATE_ALL_ASSESSMENTS
                      ]}
                    >
                      <AssessmentCreation tenantInfo={tenantInfo} />
                    </AccessControl>
                  </Route>
                  <Route exact path={`${paths.ADMIN}/${VS.ALL}`}>
                    <AccessControl allowedPermissions={CAN_VIEW_ASSESSMENTS}>
                      <AssessmentList />
                    </AccessControl>
                  </Route>
                  <Route exact path={`${paths.ADMIN}/${VS.ALL}/${VS.ACTIVE}`}>
                    <AccessControl allowedPermissions={CAN_VIEW_ASSESSMENTS}>
                      <AssessmentList />
                    </AccessControl>
                  </Route>
                  <Route exact path={`${paths.ADMIN}/${VS.ALL}/${VS.REVIEW}`}>
                    <AccessControl allowedPermissions={CAN_VIEW_ASSESSMENTS}>
                      <AssessmentList />
                    </AccessControl>
                  </Route>
                  <Route exact path={`${paths.ADMIN}/${VS.ALL}/${VS.ARCHIVED}`}>
                    <AccessControl allowedPermissions={CAN_VIEW_ASSESSMENTS}>
                      <AssessmentList />
                    </AccessControl>
                  </Route>
                  <Route exact path={`${paths.ADMIN}/${VS.ACTIVE}`}>
                    <AccessControl allowedPermissions={CAN_VIEW_ASSESSMENTS}>
                      <AssessmentList />
                    </AccessControl>
                  </Route>
                  <Route exact path={`${paths.ADMIN}/${VS.INACTIVE}`}>
                    <AccessControl allowedPermissions={CAN_VIEW_ASSESSMENTS}>
                      <AssessmentList />
                    </AccessControl>
                  </Route>
                  <Route
                    exact
                    path={`${paths.ADMIN}/${VS.INACTIVE}/${VS.REVIEW}`}
                  >
                    <AccessControl allowedPermissions={CAN_VIEW_ASSESSMENTS}>
                      <AssessmentList />
                    </AccessControl>
                  </Route>
                  <Route
                    exact
                    path={`${paths.ADMIN}/${VS.INACTIVE}/${VS.ARCHIVED}`}
                  >
                    <AccessControl allowedPermissions={CAN_VIEW_ASSESSMENTS}>
                      <AssessmentList />
                    </AccessControl>
                  </Route>
                  <Route path={`${paths.ADMIN}/:assessment`}>
                    <SelectedAssessment />
                  </Route>
                  <Route
                    path={paths.UNAUTHORIZED}
                    component={UnauthorizedView}
                  />
                  <Redirect to={`${paths.ADMIN}/${VS.ACTIVE}`} />
                </Switch>
              </TenantInfo>
            </QueryParamProvider>
          </AssessmentProvider>
        ) : (
          <Switch>
            <Route exact path={paths.LOGIN}>
              <AuthPage formType={formTypes.LOGIN} />
            </Route>
            <Route path={paths.REQUEST_RESET_PASSWORD}>
              <AuthPage formType={formTypes.EMAIL} />
            </Route>
            <Route path={paths.RESET_PASSWORD}>
              <AuthPage formType={formTypes.PASSWORD} />
            </Route>
            <Route path={paths.CREATE_ACCOUNT}>
              <AuthPage formType={formTypes.SIGNUP} />
            </Route>
            <Redirect to={paths.LOGIN} />
          </Switch>
        )}
      </Switch>
    </Router>
  );
};

export default App;
