import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  FlexContainer,
  QuestionListContainer,
  QuestionPreviewContainer
} from 'components/Atoms/Containers';
import CategoriesDropdown from 'pages/ManagementPage/CategoriesDropdown';
import QuestionPreview from 'pages/QuestionPage/questionPreview/QuestionPreview';
import QuestionCard from 'pages/QuestionPage/QuestionCard';
import pencilIcon from 'assets/pencilIcon';
import addQuestionIcon from 'assets/addQuestionIcon';
import trashCanGrayIcon from 'assets/trashCanGrayIcon';

const QuestionPoolEditor = ({
  selectedPool,
  handleUpdateQuestionPool,
  handleRemoveQuestionPool,
  createdPools,
  goToQuestionLibrary
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  useEffect(() => {
    if (selectedPool) {
      setShowDropdown(false);
      setSelectedQuestion(null);
    }
  }, [selectedPool]);

  const handleRemoveQuestionFromPool = ev => {
    ev.stopPropagation();
    const filteredQuestions = selectedPool.questions.filter(
      ({ id }) => id !== selectedQuestion.id
    );
    setSelectedQuestion(null);
    handleUpdateQuestionPool(selectedPool, null, filteredQuestions);
  };

  const renderQuestionCards = () => (
    <FlexContainer justify="space-between">
      <QuestionListContainer>
        {selectedPool.questions.map(question => (
          <QuestionCard
            key={question.id}
            questionProps={question}
            isAssessmentCreation
            onClick={() => setSelectedQuestion(question)}
            isActiveCard={selectedQuestion === question}
            showStateTag={false}
            handleSelfRemove={handleRemoveQuestionFromPool}
          />
        ))}

        {selectedPool?.questions.length > 0 ? (
          <AddQuestionIconContainer
            onClick={goToQuestionLibrary}
            shouldBeCentered
          >
            {addQuestionIcon}
          </AddQuestionIconContainer>
        ) : (
          <AddQuestionCard
            key="add-btn"
            align="center"
            justify="center"
            onClick={goToQuestionLibrary}
          >
            <AddQuestionIconContainer>
              {addQuestionIcon}
            </AddQuestionIconContainer>
            <AddQuestionSpan>
              Add questions from the Questions Library
            </AddQuestionSpan>
          </AddQuestionCard>
        )}
      </QuestionListContainer>

      <QuestionPreviewContainer>
        <QuestionPreview
          questionId={selectedQuestion?.id}
          isAssessmentCreation
          showActionBtn={false}
        />
      </QuestionPreviewContainer>
    </FlexContainer>
  );

  const renderBody = () => {
    if (selectedPool) {
      return (
        <BodyContainer direction="column">
          <HeaderContainer align="center" justify="space-between">
            <FlexContainer align="center">
              <PoolTitle>{selectedPool.title}</PoolTitle>
              <PencilIconContainer
                align="center"
                justify="center"
                onClick={() => setShowDropdown(true)}
                data-testid="pencil-btn"
              >
                {pencilIcon}
              </PencilIconContainer>
            </FlexContainer>
            <DeleteListButton
              align="center"
              justify="center"
              onClick={handleRemoveQuestionPool}
            >
              {trashCanGrayIcon}
              <span>delete list</span>
            </DeleteListButton>
          </HeaderContainer>
          {showDropdown && (
            <DropdownContainer>
              <StyledCategoriesDropdown
                defaultCategory={selectedPool.title}
                updateCategory={cat =>
                  handleUpdateQuestionPool(selectedPool, cat.value)
                }
                excludedCategories={createdPools.map(({ title }) => title)}
              />
            </DropdownContainer>
          )}
          {renderQuestionCards()}
        </BodyContainer>
      );
    } else {
      return (
        <InformationSection align="center" direction="column" justify="center">
          <InformationHeading>
            Welcome to the Question Bank Editor!
          </InformationHeading>
          <InformationSubtitle>
            Select or add a new bank to get started
          </InformationSubtitle>
        </InformationSection>
      );
    }
  };

  return <main>{renderBody()}</main>;
};

export default QuestionPoolEditor;

QuestionPoolEditor.propTypes = {
  selectedPool: PropTypes.object,
  handleUpdateQuestionPool: PropTypes.func.isRequired,
  createdPools: PropTypes.array,
  goToQuestionLibrary: PropTypes.func.isRequired,
  handleRemoveQuestionPool: PropTypes.func.isRequired
};

QuestionPoolEditor.defaultProps = {
  selectedPool: null,
  createdPools: []
};

const InformationSection = styled(FlexContainer)`
  margin-top: 15%;
`;

const InformationHeading = styled.h3`
  color: ${({ theme }) => theme.colors.steel};
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 24px;
  margin-bottom: 10px;
  width: 400px;
`;

const InformationSubtitle = styled.h4`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 16px;
  letter-spacing: 0.53px;
  line-height: 24px;
`;

const BodyContainer = styled(FlexContainer)`
  margin: 36px 0 0 18px;
`;

const PoolTitle = styled.h4`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 24px;
  font-weight: normal;
  text-align: left;
  margin: 0 10px 0 0;
`;

const PencilIconContainer = styled(FlexContainer)`
  background: #f1f1f1;
  cursor: pointer;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  svg {
    border-radius: 50%;
    height: 32px;
    width: 32px;
    g {
      fill: ${({ theme }) => theme.tenantAccent};
    }
  }
`;

const DropdownContainer = styled.div`
  margin-top: 18px;
  width: 250px;
`;

const StyledCategoriesDropdown = styled(CategoriesDropdown)`
  background: #f8f9fa;
  border: ${({ theme }) => `1px solid ${theme.colors.lightSilver}`};
  ~ .dropdown-options {
    max-height: 300px;
    overflow-y: auto;
  }
`;

const AddQuestionCard = styled(FlexContainer)`
  border: 1px solid #e0e0e0;
  cursor: pointer;
  padding: 35px 0;
`;

const AddQuestionIconContainer = styled(FlexContainer)`
  background: #257aff;
  cursor: pointer;
  margin: ${({ shouldBeCentered }) => shouldBeCentered && '0 auto'};
  width: fit-content;
`;

const AddQuestionSpan = styled(FlexContainer)`
  color: #5a5a5a;
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 14px;
  letter-spacing: 0.4px;
  margin-left: 15px;
  line-height: 20px;
`;

const DeleteListButton = styled(FlexContainer)`
  border-radius: 2px;
  border: 1px solid #d0d0d0;
  cursor: pointer;
  padding: 9px 20px 9px 13px;
  margin: 0 20px 0 0;
  svg rect {
    stroke: ${({ theme }) => theme.colors.transparent};
  }
  span {
    color: #5a5a5a;
    cursor: pointer;
    font-family: ${({ theme }) => theme.fonts.avenirProBlack};
    font-size: 14px;
    letter-spacing: 1.25px;
    margin-left: 10px;
    position: relative;
    text-transform: uppercase;
    top: 1px;
  }
`;

const HeaderContainer = styled(FlexContainer)`
  z-index: 10;
`;
