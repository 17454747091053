import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LandingPageForm from 'pages/ManagementPage/LandingPageForm';
import { FlexContainer } from 'components/Atoms/Containers';
import CandidateInfoForm from 'pages/ManagementPage/candidateInfoForm/CandidateInfoForm';
import MessageForm from 'pages/ManagementPage/MessageForm';
import QuestionSectionForm from 'pages/ManagementPage/questionSection/QuestionSectionForm';
import ExtendedSectionCard from 'pages/ManagementPage/questionSection/ExtendedSectionCard';
import trashCanGrayIcon from 'assets/trashCanGrayIcon';
import getNumericPart from 'pages/ManagementPage/utils/getNumericPart';
import {
  EACH_QUESTION,
  OPTION_TYPES,
  SECTION_STOCK
} from 'pages/ManagementPage/Constants';

const CardItem = ({
  formData,
  handleSaveSection,
  handleUpdateSection,
  icon,
  isCardOpen,
  itemId,
  showSectionCard,
  handleAddQuestion,
  sectionsData,
  isDeletable,
  setDeleteConfirmationId,
  handleRemoveQuestion,
  setIsDragging,
  formErrors,
  isQuestionPoolEnabled,
  handleAddPool,
  handleOpenQuestionPoolEditor,
  questionPools
}) => {
  const isQuestionSection = itemId.includes(OPTION_TYPES.QUESTION_SECTION);
  let currentSection = null;
  if (isQuestionSection) {
    currentSection =
      sectionsData.find(
        ({ sectionId }) => getNumericPart(itemId) === sectionId
      ) || {};
  }
  const isSectionStock = currentSection?.sectionType === SECTION_STOCK;

  const getForm = () => {
    const {
      data: {
        title,
        defaultTitle,
        instructions,
        fields,
        messageHeader,
        messageParagraph,
        logoFile: { file }
      },
      setData
    } = formData;

    const formOption = isQuestionSection
      ? OPTION_TYPES.QUESTION_SECTION
      : itemId;

    switch (formOption) {
      case OPTION_TYPES.LANDING_PAGE:
        return (
          <LandingPageForm
            defaultTitle={defaultTitle}
            instructions={instructions}
            setData={setData}
            title={title}
            errors={formErrors?.LANDING_PAGE}
            logoFileName={file?.name}
          />
        );
      case OPTION_TYPES.CANDIDATE_INFO:
        return <CandidateInfoForm createdFields={fields} setData={setData} />;
      case OPTION_TYPES.MESSAGE:
        return (
          <MessageForm
            defaultHeader={messageHeader}
            defaultParagraph={messageParagraph}
            setData={setData}
          />
        );
      case OPTION_TYPES.QUESTION_SECTION:
        const {
          sectionTitle,
          sectionDuration,
          sectionSeconds,
          sectionDescription,
          sectionIsLinear
        } = currentSection;

        const defaultData = {
          defaultSectionTitle: sectionTitle,
          defaultSectionDuration: sectionDuration,
          defaultsectionSeconds: sectionSeconds,
          defaultSectionDescription: sectionDescription,
          defaultIsLinear: sectionIsLinear
        };

        const shouldAllowQuestionIndividualTiming = !currentSection?.questions?.some(
          ({ isQuestionPool }) => isQuestionPool
        );

        return (
          <QuestionSectionForm
            errors={formErrors?.SECTIONS?.[getNumericPart(itemId)]?.FIELDS}
            setData={setData}
            onSave={section => handleSaveSection(itemId, section)}
            handleUpdateSection={newData =>
              handleUpdateSection(itemId, newData)
            }
            {...defaultData}
            shouldAllowQuestionIndividualTiming={
              shouldAllowQuestionIndividualTiming
            }
          />
        );
      default:
        return null;
    }
  };

  const getActions = () => {
    const handleDelete = event => {
      setDeleteConfirmationId(true);
      event.stopPropagation();
    };

    return (
      <ActionsContainer>
        {isDeletable && (
          <ActionItem
            align="center"
            justify="center"
            onClick={ev => handleDelete(ev)}
          >
            {trashCanGrayIcon}
          </ActionItem>
        )}
      </ActionsContainer>
    );
  };

  const handleUpdateQuestions = (updatedAttr, updatedVal, questionId) => {
    const newSectionsData = [...sectionsData];
    const updatedSection = newSectionsData.find(
      section => section === currentSection
    );
    const newQuestions = [...updatedSection.questions];
    const updatedQuestion = newQuestions.find(({ id }) => questionId === id);
    updatedQuestion[updatedAttr] = updatedVal;
    handleUpdateSection(itemId, newSectionsData);
  };

  const handleSetNewQuestions = questions => {
    const newSectionsData = [...sectionsData];
    const updatedSection = newSectionsData.find(
      section => section === currentSection
    );
    updatedSection.questions = questions;
    handleUpdateSection(itemId, newSectionsData);
  };

  const getErrors = () =>
    formErrors?.SECTIONS?.[getNumericPart(itemId)]?.FIELDS?.QUESTIONS;

  const getIsQuestionPoolEnabled = () =>
    isQuestionPoolEnabled && currentSection?.sectionDuration !== EACH_QUESTION;

  return (
    <Card direction="column" disabled={isSectionStock}>
      {icon}
      {(!isCardOpen || isSectionStock) && getActions()}
      {(showSectionCard || isSectionStock) && (
        <ExtendedSectionCard
          selectedDuration={currentSection?.sectionDuration}
          addedQuestions={currentSection?.questions}
          onAddPool={handleAddPool}
          onAddQuestion={handleAddQuestion}
          handleRemoveQuestion={questionId =>
            handleRemoveQuestion(questionId, currentSection.sectionId)
          }
          handleUpdateQuestions={handleUpdateQuestions}
          setIsDragging={setIsDragging}
          handleSetNewQuestions={handleSetNewQuestions}
          errors={getErrors()}
          isSectionStock={isSectionStock}
          isQuestionPoolEnabled={getIsQuestionPoolEnabled()}
          handleOpenQuestionPoolEditor={handleOpenQuestionPoolEditor}
          questionPools={questionPools}
        />
      )}
      {isCardOpen && !isSectionStock && (
        <FormContainer
          onClick={ev => ev.stopPropagation()}
          data-testid="card-form"
        >
          {getForm()}
        </FormContainer>
      )}
    </Card>
  );
};

export default CardItem;

CardItem.propTypes = {
  handleSaveSection: PropTypes.func.isRequired,
  handleUpdateSection: PropTypes.func.isRequired,
  icon: PropTypes.node.isRequired,
  isCardOpen: PropTypes.bool.isRequired,
  itemId: PropTypes.string.isRequired,
  showSectionCard: PropTypes.bool,
  handleAddQuestion: PropTypes.func.isRequired,
  handleAddPool: PropTypes.func.isRequired,
  sectionsData: PropTypes.array.isRequired,
  isDeletable: PropTypes.bool,
  setDeleteConfirmationId: PropTypes.func.isRequired,
  handleRemoveQuestion: PropTypes.func.isRequired,
  setIsDragging: PropTypes.func.isRequired,
  formErrors: PropTypes.object,
  isQuestionPoolEnabled: PropTypes.bool.isRequired,
  handleOpenQuestionPoolEditor: PropTypes.func.isRequired
};

CardItem.defaultProps = {
  showSectionCard: false,
  isDeletable: false,
  formErrors: {}
};

const Card = styled(FlexContainer)`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 2px;
  border: 1px solid rgb(221, 221, 221);
  padding: 16px;

  ${({ disabled }) =>
    disabled &&
    `
    background: #ececec;
    pointer-events: none;
   
  `}
`;

const FormContainer = styled(FlexContainer)`
  background: transparent;
  margin: 16px 0 25px;
`;

const ActionsContainer = styled.div`
  height: 100%;
  position: absolute;
  margin-right: 30px;
  right: 0;
  top: 22px;
`;

const ActionItem = styled(FlexContainer)`
  background: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  pointer-events: all;
`;
