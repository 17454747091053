import { useCallback, useEffect } from 'react';
import { requestHelper } from 'utils/requests/requestHelper';
import { requests } from 'utils/requests/requests';

const cache = {};

const useFetchCategories = setCategories => {
  const fetchCategories = useCallback(async () => {
    if (cache?.data) {
      setCategories(cache.data);
      return;
    }
    await requestHelper(requests().getCategories, {}, response => {
      setCategories(response.data.data);
      cache.data = response.data.data;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);
};

export default useFetchCategories;
