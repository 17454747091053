import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Login from 'pages/AuthPage/components/Login';
import ResetPassword from 'pages/AuthPage/components/ResetPassword';
import RequestResetPassword from 'pages/AuthPage/components/RequestResetPassword';
import { FlexContainer } from 'components/Atoms/Containers';
import { Img } from 'components/Blocks/Styled/Media';
import { formTypes } from 'pages/App/helpers/formTypes';
import { paths } from 'pages/App/helpers/paths';
import { useThemeContext } from 'state/themeContext/useThemeContext';

const AuthPage = ({ formType }) => {
  const theme = useThemeContext();

  const renderForm = () => {
    switch (formType) {
      case formTypes.LOGIN:
        return <Login />;
      case formTypes.EMAIL:
        return <RequestResetPassword />;
      case formTypes.PASSWORD:
        return <ResetPassword />;
      case formTypes.SIGNUP:
        return <ResetPassword isSignup />;
      default:
        return <Login />;
    }
  };

  const renderSubFormText = () => {
    switch (formType) {
      case formTypes.EMAIL:
      case formTypes.PASSWORD:
        return (
          <LinkWrapper>
            <LoginLink to={paths.LOGIN}>Return to login</LoginLink>
          </LinkWrapper>
        );
      case formTypes.SIGNUP:
        return (
          <AgreementText>
            By creating a Correlation One Assessments account, you're agreeing
            to accept the Correlation One Privacy Policy and Terms of Service.
          </AgreementText>
        );
      default:
        return null;
    }
  };

  return (
    <PageContainer
      align="center"
      justify="center"
      direction="column"
      id="authentication-page"
    >
      <Img
        margin="0 0 100px"
        width="160px"
        src={theme.domainTheme.logo}
        alt="tenant logo"
      />
      <AuthFormContainer>{renderForm()}</AuthFormContainer>
      {renderSubFormText()}
      {/* TODO: re-add when localization is supported */}
      {/* <FlexContainer align="center"> */}
      {/* {globe}
          <Localization margin="0 0 0 8px" color={theme.colors.white}>
            English(US)
          </Localization> */}
      {/* </FlexContainer> */}
    </PageContainer>
  );
};

export default AuthPage;

AuthPage.propTypes = {
  formType: PropTypes.oneOf(Object.values(formTypes)).isRequired
};

const PageContainer = styled(FlexContainer)`
  width: 100%;
  height: 100vh;
  background: ${({ theme }) => theme.domainTheme.background};
`;

const AuthFormContainer = styled(FlexContainer)`
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 350px;
  padding: 32px 24px;
  margin: 16px;
  @media (max-width: 400px) {
    width: 300px;
  }
`;

const LoginLink = styled(Link)`
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 14px;
  letter-spacing: 0.4px;
  text-decoration: none;
`;

const LinkWrapper = styled.div`
  margin-top: 16px;
`;

const AgreementText = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 14px;
  margin-top: 16px;
  letter-spacing: 0.4px;
  text-align: center;
  width: 350px;
`;

// const Localization = styled(Overline)`
//   font-size: 14px;
// `
