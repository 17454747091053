import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import QuestionCreationNav from 'pages/QuestionPage/questionCreation/QuestionCreationNav';
import QuestionCreationForm from 'pages/QuestionPage/questionCreation/QuestionCreationForm';
import QuestionPreview from 'pages/QuestionPage/questionPreview/QuestionPreview';
import { PageWrapper } from 'components/Blocks/Styled/Wrappers';
import { Container, FlexContainer } from 'components/Atoms/Containers';
import Loader from 'pages/QuestionPage/questionCreation/Loader';
import {
  DEFAULT_ANSWER_OPTIONS,
  FREE_RESPONSE,
  MULTIPLE_CHOICE,
  QUESTION_BACKEND_TYPES,
  MAP_BACKEND_QUESTION_TYPES,
  questionLibraryPath
} from 'pages/QuestionPage/Constants';
import { VALID_STATES as VS } from 'utils/constants/stateTypes';
import { requestHelper } from 'utils/requests/requestHelper';
import { requests } from 'utils/requests/requests';
import useFetchCategories from 'pages/QuestionPage/hooks/useFetchCategories';
import convertNumberToLetter from 'pages/QuestionPage/helpers/convertNumberToLetter/convertNumberToLetter';

const QuestionCreation = () => {
  const history = useHistory();
  const initialQuestionType = useLocation()?.questionType || MULTIPLE_CHOICE;
  const { questionId } = useParams();
  const [questionType, setQuestionType] = useState(initialQuestionType);
  const [questionTypeOptions, setQuestionTypeOptions] = useState(
    Object.keys(QUESTION_BACKEND_TYPES)
  );
  const [questionState, setQuestionState] = useState(VS.DRAFT);
  const [questionBody, setQuestionBody] = useState('');
  const [categories, setCategories] = useState([]);
  const [helperText, setHelperText] = useState('');
  const [tenantsList, setTenantsList] = useState([]);
  const [selectedRestrictedTenant, setSelectedRestrictedTenant] = useState(
    null
  );
  const [title, setTitle] = useState('');
  const [legacyQuestionId, setLegacyQuestionId] = useState(null);
  const [multipleAnswerOptions, setMultipleAnswerOptions] = useState(
    Array(DEFAULT_ANSWER_OPTIONS).fill({ body: '', score: null, id: null })
  );
  const [freeResponseAnswerOptions, setFreeResponseAnswerOptions] = useState(
    Array(DEFAULT_ANSWER_OPTIONS).fill({
      id: null,
      min: null,
      max: null,
      inclusive: true,
      score: null
    })
  );
  const [questionFiles, setQuestionFiles] = useState([]);
  const [fileUploadErrors, setFileUploadErrors] = useState([]);
  const [pendingUploadedFiles, setPendingUploadedFiles] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Reset variables on if you press back from edit-question to create-question
    // Related to issue: #4545
    if (!questionId) {
      setQuestionFiles([]);
      setPendingUploadedFiles([]);
      setFileUploadErrors([]);
    }
  }, [questionId]);

  useFetchCategories(setCategories);

  const fetchQuestion = useCallback(async () => {
    await requestHelper(
      requests({ questionId }).getSingleQuestionData,
      {},
      response => {
        const {
          question_type,
          question_state,
          content,
          default_category,
          options,
          bands,
          legacy_question_id,
          files,
          tenant
        } = response.data.data;
        const questionContent = content[0];
        setQuestionType(MAP_BACKEND_QUESTION_TYPES[question_type]);
        setQuestionTypeOptions([MAP_BACKEND_QUESTION_TYPES[question_type]]);
        setQuestionState(question_state);
        setQuestionBody(questionContent.text_display);
        setTitle(questionContent.title);
        setHelperText(questionContent.placeholder);
        setSelectedCategory(default_category?.name);
        setLegacyQuestionId(legacy_question_id);
        setQuestionFiles(files);
        setSelectedRestrictedTenant({
          ...tenant,
          value: tenant?.name
        });
        if (question_type === QUESTION_BACKEND_TYPES[MULTIPLE_CHOICE]) {
          const newOptions = options.map(({ id, value, content }) => ({
            score: value,
            body: content[0].text_display,
            id: id
          }));
          setMultipleAnswerOptions(newOptions);
        } else if (question_type === QUESTION_BACKEND_TYPES[FREE_RESPONSE]) {
          const newBands = bands.map(({ id, max_value, min_value, score }) => ({
            id,
            min: min_value,
            max: max_value,
            score
          }));
          setFreeResponseAnswerOptions(newBands);
        }
        setIsLoading(false);
      }
    );
  }, [questionId]);

  useEffect(() => {
    questionId && fetchQuestion();
  }, [fetchQuestion, questionId]);

  useEffect(() => {
    questionId === undefined && setIsLoading(false);
  }, [questionId]);

  const getQuestionCreationSchema = (data, isCreation) => {
    // Note: We do not currently support language_id, default english is chosen by backend
    const questionSchema = {
      content: [
        {
          title: data['question-title'],
          text_display: questionBody
        }
      ],
      default_category_id: data['question-category']
        ? categories.find(cat => cat.name === data['question-category']).id
        : null,
      question_type: QUESTION_BACKEND_TYPES[data['question-type']],
      question_state: questionState,
      legacy_question_id: data['legacy-question-id'] || null,
      tenant_id: selectedRestrictedTenant?.id || null
    };

    const getInput = (prefix, option, suffix) =>
      data[
        `${prefix}-answer-option-${convertNumberToLetter(option)}-${suffix}`
      ];

    switch (data['question-type']) {
      case FREE_RESPONSE:
        questionSchema.content[0].placeholder =
          data['free-response-helper-text'];
        questionSchema.bands = freeResponseAnswerOptions.map(({ id }, idx) => {
          const bandSchema = {
            ...(!isCreation && { id }),
            min_value: getInput('free-response', idx, 'min'),
            max_value: getInput('free-response', idx, 'max'),
            score: getInput('free-response', idx, 'score')
          };

          return bandSchema;
        });
        break;

      case MULTIPLE_CHOICE:
        questionSchema.options = multipleAnswerOptions.map(
          ({ id, body }, idx) => {
            const optionSchema = {
              ...(!isCreation && { id }),
              content: [{ text_display: body }],
              value: getInput('multiple', idx, 'score'),
              sort_index: idx
            };
            return optionSchema;
          }
        );
        break;

      default:
        return;
    }
    return questionSchema;
  };

  const createQuestion = async data => {
    await requestHelper(
      requests().createQuestion,
      getQuestionCreationSchema(data, true),
      response => {
        if (response.status === 200) {
          saveFiles(response.data.data.id);
        } else {
          // TODO: Do something on failure to create question?
        }
      }
    );
  };

  const updateQuestion = async data => {
    await requestHelper(
      requests({ questionId }).updateQuestion,
      getQuestionCreationSchema(data, false),
      response => {
        response.status === 200 && saveFiles(response.data.data.id);
      }
    );
  };

  const handleSubmitData = data => {
    questionId ? updateQuestion(data) : createQuestion(data);
  };

  const goBack = () => history.push(`${questionLibraryPath}/${VS.ALL}`);

  const getCurrentAnswerOptionsData = () => {
    switch (questionType) {
      case MULTIPLE_CHOICE:
        return {
          answerOptions: multipleAnswerOptions
        };
      case FREE_RESPONSE:
        return {
          answerOptions: freeResponseAnswerOptions,
          helperText: helperText
        };
      default:
        break;
    }
  };

  const deleteFile = useCallback(
    async questionFileId => {
      setIsLoading(true);
      try {
        await requestHelper(
          requests({ questionId, questionFileId }).deleteFile,
          {},
          () => {
            setQuestionFiles(oldQuestionFiles =>
              oldQuestionFiles.filter(file => file.id !== questionFileId)
            );
            setIsLoading(false);
          },
          error => {
            setFileUploadErrors(oldErrors => [
              error.response
                ? `Failed to delete ${questionFileId}: ${error.response.data.message}`
                : `Failed to delete ${questionFileId}:${error}`,
              ...oldErrors
            ]);
            setIsLoading(false);
          }
        );
      } catch (err) {
        console.error(err);
      }
    },
    [questionId, setFileUploadErrors, setQuestionFiles, setIsLoading]
  );

  // This function fires a request for each file that needs to be saved.
  // It builds two arrays, one for errors and one for successes.
  // If there are errors, they all get displayed
  const saveAllFiles = useCallback(
    async _questionId => {
      const requestFile = async f => {
        const formData = new FormData();
        formData.append('file', f);
        return await requestHelper(
          requests({
            questionId: _questionId,
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).saveFile,
          formData,
          response => response.data.data,
          error =>
            error?.response
              ? `${f.name}: ${error?.response?.data?.message}`
              : `${f.name}: ${error}`
        );
      };
      let requestArray = [];
      // Can't use map because it causes issues with await
      for (let file of pendingUploadedFiles) {
        requestArray.push(await requestFile(file));
      }

      let successes = [];
      let errors = [];
      requestArray.forEach(f => {
        if (typeof f === 'string') {
          errors.push(f);
        } else {
          successes.push(f);
        }
      });
      setFileUploadErrors(oldErrors => [...errors, ...oldErrors]);
      setQuestionFiles(oldQuestionFiles => [...successes, ...oldQuestionFiles]);
      setIsLoading(false);
      setPendingUploadedFiles([]);
      // Reroute to edit question page if error occured so they can see errors
      if (errors.length) {
        history.push(`${questionLibraryPath}/edit-question/${_questionId}`);
      } else {
        history.push(`${questionLibraryPath}/${VS.ALL}`);
      }
    },
    [
      history,
      setQuestionFiles,
      setIsLoading,
      pendingUploadedFiles,
      setPendingUploadedFiles,
      setFileUploadErrors
    ]
  );

  const saveFiles = useCallback(
    async _questionId => {
      setIsLoading(true);
      setFileUploadErrors([]);
      saveAllFiles(_questionId);
    },
    [saveAllFiles, setIsLoading, setFileUploadErrors]
  );

  return (
    <PageWrapper background="#f0f2f4">
      <QuestionCreationNav
        handleGoBack={goBack}
        existingQuestion={Boolean(questionId)}
      />
      <QuestionCreationContainer>
        <QuestionCreationSection background="transparent">
          {isLoading ? (
            <Loader />
          ) : (
            <QuestionCreationForm
              freeResponseAnswerOptions={freeResponseAnswerOptions}
              helperText={helperText}
              legacyQuestionId={legacyQuestionId}
              multipleAnswerOptions={multipleAnswerOptions}
              onSubmit={handleSubmitData}
              questionBody={questionBody}
              questionType={questionType}
              questionTypeOptions={questionTypeOptions}
              selectedCategory={selectedCategory}
              setFreeResponseAnswerOptions={setFreeResponseAnswerOptions}
              setHelperText={setHelperText}
              setMultipleAnswerOptions={setMultipleAnswerOptions}
              setQuestionBody={setQuestionBody}
              setQuestionType={setQuestionType}
              setSelectedCategory={setSelectedCategory}
              setTenantsList={setTenantsList}
              selectedRestrictedTenant={selectedRestrictedTenant}
              setSelectedRestrictedTenant={setSelectedRestrictedTenant}
              tenantsList={tenantsList}
              title={title}
              questionFiles={questionFiles}
              fileUploadErrors={fileUploadErrors}
              saveFiles={saveFiles}
              deleteFile={deleteFile}
              pendingUploadedFiles={pendingUploadedFiles}
              setPendingUploadedFiles={setPendingUploadedFiles}
            />
          )}
        </QuestionCreationSection>
        <QuestionCreationSection background="white">
          {isLoading ? (
            <Loader isPreview />
          ) : (
            <QuestionPreview
              answersOptionsData={getCurrentAnswerOptionsData()}
              body={questionBody}
              type={questionType}
            />
          )}
        </QuestionCreationSection>
      </QuestionCreationContainer>
    </PageWrapper>
  );
};

export default QuestionCreation;

const QuestionCreationContainer = styled(FlexContainer)`
  margin-top: 2px;
`;

const QuestionCreationSection = styled(Container)`
  flex: 1;
  max-width: 50%;
  min-height: 100vh;
  padding: 38px 104px;
`;
