import { VALID_STATES as VS } from 'utils/constants/stateTypes';
import { requestHelper } from 'utils/requests/requestHelper';
import { requests } from 'utils/requests/requests';

const assessmentStateHelpers = {
  setArchived: assessment_id => {
    requestHelper(
      requests({ assessment_id }).updateAssessmentState,
      {
        assessment_state: VS.ARCHIVED
      },
      response => {
        response.status === 200 && window.location.reload();
      }
    );
  },
  setReview: assessment_id => {
    requestHelper(
      requests({ assessment_id }).updateAssessmentState,
      {
        assessment_state: VS.REVIEW
      },
      response => {
        response.status === 200 && window.location.reload();
      }
    );
  },
  setLive: assessment_id => {
    requestHelper(
      requests({ assessment_id }).updateAssessmentState,
      {
        // Must be active because live is not a valid state in db
        assessment_state: VS.ACTIVE
      },
      response => {
        response.status === 200 && window.location.reload();
      }
    );
  },
  delete: assessment_id => {
    requestHelper(
      requests({ assessment_id }).updateAssessmentState,
      {
        assessment_state: VS.RETIRED
      },
      response => {
        response.status === 200 && window.location.reload();
      }
    );
  }
};

export default assessmentStateHelpers;
