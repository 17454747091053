import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  FormContainer,
  FormTitle,
  FormSection,
  FormInput
} from 'components/Blocks/ManagementForm';
import Dropdown from 'components/Blocks/Dropdown';
import { StyledButton } from 'components/Atoms/Buttons';
import { FlexContainer } from 'components/Atoms/Containers';
import QuillEditor from 'components/Systems/QuillEditor';
import ToggleSwitch from 'pages/ManagementPage/questionSection/ToggleSwitch';
import {
  EACH_QUESTION,
  WHOLE_SECTION,
  DEFAULT_DURATION
} from 'pages/ManagementPage/Constants';
import { handleButtonHover } from 'utils/helpers/handleButtonHover';
import { removeNumericInputSpinners } from 'utils/mixins/Mixins';

const DURATION_VALUES = [{ id: EACH_QUESTION }, { id: WHOLE_SECTION }];

const QuestionSectionForm = ({
  defaultSectionTitle,
  defaultSectionDuration,
  defaultsectionSeconds,
  defaultSectionDescription,
  defaultIsLinear,
  onSave,
  handleUpdateSection,
  errors,
  shouldAllowQuestionIndividualTiming
}) => {
  const [sectionTitle, setSectionTitle] = useState(defaultSectionTitle || '');
  const [selectedDuration, setSelectedDuration] = useState(
    defaultSectionDuration || DEFAULT_DURATION
  );
  const [selectedSeconds, setSelectedSeconds] = useState(
    defaultsectionSeconds || null
  );

  const [isLinear, setIsLinear] = useState(defaultIsLinear && true);
  const selectedWholeSection =
    selectedDuration ===
    DURATION_VALUES.find(({ id }) => id === WHOLE_SECTION).id;

  const isButtonDisabled =
    !sectionTitle || (selectedWholeSection && selectedSeconds === null);

  const handleSaveClick = event => {
    event.stopPropagation();
    onSave(sectionTitle);
  };

  const handleSectionTitleChange = title => {
    setSectionTitle(title);
    handleUpdateSection({ sectionTitle: title });
  };

  const handleDescriptionChange = description => {
    handleUpdateSection({ sectionDescription: description });
  };

  const handleSelectedSecondsChange = seconds => {
    setSelectedSeconds(parseInt(seconds));
    handleUpdateSection({ sectionSeconds: parseFloat(seconds) });
  };

  const handleIsLinearChange = () => {
    setIsLinear(!isLinear);
    handleUpdateSection({ sectionIsLinear: !isLinear });
  };

  const handleSelectedDurationChange = selectedDuration => {
    const selected = DURATION_VALUES.find(({ id }) => id === selectedDuration)
      .id;
    setSelectedDuration(selected);
    handleUpdateSection({ sectionDuration: selected });
  };

  const getDurationOption = () => {
    if (shouldAllowQuestionIndividualTiming) {
      return DURATION_VALUES;
    } else {
      return DURATION_VALUES.filter(({ id }) => id === WHOLE_SECTION);
    }
  };

  return (
    <FormContainer direction="column">
      <FormSection data-testid="section-name">
        <FormTitle>Section name</FormTitle>
        <StyledInput
          defaultValue={defaultSectionTitle}
          type="text"
          name="question-section-name"
          required
          onChange={ev => handleSectionTitleChange(ev.target.value)}
          isValid={!errors?.TITLE}
        />
      </FormSection>
      <FormSection>
        <DurationContainer align="center">
          <DurationBy direction="column">
            <FormTitle>Setup the duration by</FormTitle>
            <StyledDropdown
              name="question-section-duration"
              selectedOption={selectedDuration}
              setSelectedOption={selected =>
                handleSelectedDurationChange(selected)
              }
              options={getDurationOption()}
            />
          </DurationBy>

          {selectedWholeSection && (
            <DurationSeconds direction="column" data-testid="seconds-input">
              <FormTitle>Time duration</FormTitle>
              <StyledInput
                type="number"
                name="question-section-duration-seconds"
                required
                placeholder="Seconds"
                onChange={ev => handleSelectedSecondsChange(ev.target.value)}
                isValid={!errors?.WHOLE_SECTION_TIMER}
                validations={{ min: 0 }}
                defaultValue={`${defaultsectionSeconds}`}
              />
            </DurationSeconds>
          )}
        </DurationContainer>
      </FormSection>
      <FormSection data-testid="description-quill">
        <FormTitle>Description</FormTitle>
        <QuillEditor
          initialBody={defaultSectionDescription}
          grayTheme
          inputName="question-section-description"
          isLatexRestricted
          onUpdatedBody={body => handleDescriptionChange(body)}
          preserveWhiteSpaces={false}
          isValidField
          validateOnSubmit
        />
      </FormSection>
      {selectedWholeSection && (
        <FormSection data-testid="toggle-switch">
          <FlexContainer align="center" justify="space-between">
            <SwitchTitle>
              Allow test taker to view/edit previously answered questions
            </SwitchTitle>
            <ToggleSwitch
              inputName="question-section-is-linear"
              isOn={!isLinear}
              handleSwitch={() => handleIsLinearChange()}
            />
          </FlexContainer>
        </FormSection>
      )}
      <SaveButton
        onClick={ev => handleSaveClick(ev)}
        disabled={isButtonDisabled}
      >
        Save & Add questions
      </SaveButton>
    </FormContainer>
  );
};

export default QuestionSectionForm;

QuestionSectionForm.propTypes = {
  handleUpdateSection: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  defaultSectionTitle: PropTypes.string,
  defaultSectionDuration: PropTypes.string,
  defaultsectionSeconds: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  defaultSectionDescription: PropTypes.string,
  defaultIsLinear: PropTypes.bool,
  errors: PropTypes.object,
  shouldAllowQuestionIndividualTiming: PropTypes.bool
};

QuestionSectionForm.defaultProps = {
  defaultSectionTitle: '',
  defaultSectionDuration: '',
  defaultsectionSeconds: '',
  defaultSectionDescription: '',
  defaultIsLinear: false,
  errors: {},
  shouldAllowQuestionIndividualTiming: true
};

const StyledInput = styled(FormInput)`
  height: 30px;
  ${removeNumericInputSpinners}
`;

const StyledDropdown = styled(Dropdown)`
  background: #f8f9fa;
  border: 1px solid #d7d7d7;
  .selected-value {
    color: ${({ theme }) => theme.colors.black};
    font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 16px;
    padding-left: 2px;
    position: relative;
    top: 2px;
  }
`;

const DurationContainer = styled(FlexContainer)`
  min-height: 78px;
`;

const DurationBy = styled(FlexContainer)`
  flex: 1 1 70%;
  margin-right: 18px;
  max-width: 70%;
`;

const DurationSeconds = styled(FlexContainer)`
  flex: 1;
  margin-left: 18px;
`;

const SwitchTitle = styled(FormTitle)`
  margin: 0;
`;

const SaveButton = styled(StyledButton)`
  background: ${({ theme }) => theme.colors.darkBlue};
  margin-right: 4px;
  &:hover {
    background: ${({ theme }) => handleButtonHover(theme.colors.darkBlue)};
  }
  &:disabled {
    background: #ccc;
    cursor: default;
  }
`;
