import React, { useState } from 'react';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import { paths } from 'pages/App/helpers/paths';
import AllResults from 'pages/PanelPage/components/selectedAssessment/assessmentResults/AllResults';
import TakerResults from 'pages/PanelPage/components/selectedAssessment/assessmentResults/takerResults/TakerResults';

const AssessmentResults = () => {
  const resultsPath = `${paths.ADMIN}/:assessment/results`;
  const [chartData, setChartData] = useState({});
  const { pathname } = useLocation();

  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      <Route exact path={resultsPath}>
        <AllResults chartData={chartData} setChartData={setChartData} />
      </Route>
      <Route path={`${resultsPath}/:taker`}>
        <TakerResults chartData={chartData} setChartData={setChartData} />
      </Route>
    </Switch>
  );
};

export default AssessmentResults;
