import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import upArrowVIcon from 'assets/upArrowVIcon';
import downArrowV from 'assets/downArrowVIcon';
import InputUnderLine from 'components/Blocks/Forms/InputUnderLine';
import Input from 'components/Blocks/Forms/Input';
import { Container, FlexContainer } from 'components/Atoms/Containers';
import { useClickedOutsideEvent } from 'hooks/useClickedOutsideEvent';

const Dropdown = ({
  className,
  name,
  placeholder,
  selectedOption,
  setSelectedOption,
  options,
  isRequired,
  isValid,
  onModifyOption,
  onModifySelectedOption,
  handleCustomOptionsFilter,
  showUnderline
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [selectedId, setSelectedId] = useState(false);
  const dropdownRef = useRef(null);

  useClickedOutsideEvent(dropdownRef, () => setShowOptions(false));

  const renderDropdownIcon = () => (
    <SelectIcon>{showOptions ? upArrowVIcon : downArrowV}</SelectIcon>
  );

  const handleSelectedOption = (option, event) => {
    event.stopPropagation();
    setSelectedOption(option);
    setSelectedId(option);
    setShowOptions(false);
  };

  const renderOptions = () => {
    const sortedOptions = handleCustomOptionsFilter(options);
    return sortedOptions.map(({ id, value = id }) => (
      <SelectItem
        active={id === selectedId && id !== 'EMPTY_OPTION'}
        data-active={id === selectedId && id !== 'EMPTY_OPTION'}
        data-testid="dropdown-option"
        key={`dropdown-option-${id}`}
        onClick={ev => handleSelectedOption(id, ev)}
      >
        {onModifyOption(value)}
      </SelectItem>
    ));
  };

  return (
    <Container
      onClick={() => setShowOptions(!showOptions)}
      ref={dropdownRef}
      tabindex
      data-testid={`${name}-dropdown-test-id`}
    >
      <Select
        className={`${className} ${showOptions && 'isOpen'}`}
        isValid={isValid}
      >
        <SelectOption
          data-testid="dropdown-selected-option"
          hasPlaceholder={!selectedOption}
        >
          {onModifySelectedOption(selectedOption) || placeholder}
        </SelectOption>
        {renderDropdownIcon()}
      </Select>
      <CustomUnderLine
        focused={showOptions}
        showUnderline={showUnderline && isValid}
      />
      <Input
        name={name}
        required={isRequired}
        value={selectedOption}
        isVisible={false}
      />
      {showOptions && (
        <SelectOptionsContainer className="dropdown-options">
          {renderOptions()}
        </SelectOptionsContainer>
      )}
    </Container>
  );
};

export default Dropdown;

Dropdown.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  selectedOption: PropTypes.string,
  setSelectedOption: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired
      ]),
      value: PropTypes.string
    })
  ).isRequired,
  isRequired: PropTypes.bool,
  isValid: PropTypes.bool,
  onModifyOption: PropTypes.func,
  onModifySelectedOption: PropTypes.func,
  handleCustomOptionsFilter: PropTypes.func,
  showUnderline: PropTypes.bool
};

Dropdown.defaultProps = {
  className: '',
  placeholder: '',
  selectedOption: '',
  isRequired: true,
  isValid: true,
  onModifyOption: val => val,
  onModifySelectedOption: val => val,
  handleCustomOptionsFilter: options => options,
  showUnderline: false
};

const Select = styled(FlexContainer)`
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 2px;
  border: ${({ theme, isValid }) =>
    !isValid && `solid 2px ${theme.colors.invalidRed}`};
  cursor: pointer;
  height: 30px;
  justify-content: space-between;
  padding: 6px;
`;

const SelectOption = styled.div`
  color: ${({ hasPlaceholder, theme }) =>
    hasPlaceholder ? `${theme.colors.steel}` : '#6a7f87'};
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.4px;
  line-height: 16px;
`;

const SelectIcon = styled(FlexContainer)`
  flex-direction: column;
  margin-left: 8px;
  & > svg {
    width: 10px;
    height: 10px;
  }
`;

const SelectOptionsContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 2px;
  border: 1px solid rgb(215, 215, 215);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  height: fit-content;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 10;
`;

const SelectItem = styled.div`
  background: ${({ active, theme }) =>
    active ? theme.tenantAccent : theme.colors.white};
  color: ${({ active, theme }) =>
    active ? theme.colors.white : theme.colors.black};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 14px;
  line-height: 24px;
  min-height: 38px;
  padding: 8px;
  text-align: left;
  &:hover {
    background: ${({ active, theme }) => !active && theme.colors.grey};
  }
`;

const CustomUnderLine = styled(InputUnderLine)`
  background: ${({ theme, showUnderline }) =>
    showUnderline ? 'rgba(156,156,156,0.7)' : theme.colors.white};
`;
