import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AssessmentTaker from 'pages/PanelPage/components/selectedAssessment/assessmentResults/AssessmentTaker';
import { FlexContainer } from 'components/Atoms/Containers';
import { Table, Row, Cell } from 'components/Blocks/Styled/Tables';
import { Body1 } from 'components/Atoms/Text';
import { sortTypes } from 'pages/App/helpers/sortTypes';
import downArrowV from 'assets/downArrowVIcon';
import { useAssessmentContext } from 'state/assessmentContext/useAssessmentContext';

const TakersContainer = ({ sortBy, setSortBy }) => {
  const {
    assessmentState: { selectedAssessmentAttempts }
  } = useAssessmentContext();
  const [selected, setSelected] = useState('date');

  const renderTakers = () => {
    return selectedAssessmentAttempts?.map(
      ({
        id,
        taker,
        time_taken_seconds,
        submitted_at,
        uuid,
        weighted_score,
        weighted_max_score,
        assessment_percentile
      }) => {
        const formattedDate = new Date(submitted_at)
          .toLocaleDateString()
          .split('/')
          .map(unitOfTime => unitOfTime.padStart(2, 0))
          .join('-');

        const takerData = {
          email: taker?.email,
          date: formattedDate,
          time: new Date(time_taken_seconds * 1000).toISOString().substr(11, 8),
          totalScore: `${weighted_score.toFixed(2)} / ${weighted_max_score}`
        };
        if (assessment_percentile) {
          takerData.candidatePercent = `${parseFloat(
            assessment_percentile * 100
          ).toFixed(2)}%`;
        }

        // TODO: Time and sectionScore need to be implemented, but both need to be discussed further
        return <AssessmentTaker key={id} takerData={takerData} uuid={uuid} />;
      }
    );
  };

  const handleSort = header => {
    if (header.sortBy) {
      if (selected === header.name) {
        setSortBy({
          ...sortBy,
          order: sortBy.order === 'asc' ? 'desc' : 'asc'
        });
      } else {
        setSelected(header.name);
        setSortBy({
          sortKey: header.sortBy,
          order: 'desc'
        });
      }
    }
  };

  const renderTableHeaders = () => {
    const headers = [
      { name: 'email address', sortBy: sortTypes.EMAIL },
      { name: 'date', sortBy: sortTypes.DATE },
      { name: 'time taken', sortBy: sortTypes.TIME_TAKEN },
      { name: 'total score', sortBy: sortTypes.SCORE },
      { name: 'details' }
    ];
    if (selectedAssessmentAttempts[0]?.assessment_percentile) {
      headers.splice(4, 0, { name: 'candidate %', sortBy: sortTypes.SCORE });
    }

    return (
      <Row height="41px">
        {headers.map((header, index) => (
          <HeaderCell
            key={index}
            onClick={() => handleSort(header)}
            selected={header.name === selected && true}
          >
            <TableHeader
              color="#6a7f87"
              fontSize="12px"
              textTransform="capitalize"
              sortable={header.sortBy}
            >
              {header.name} {header.sortBy && downArrowV}
            </TableHeader>
          </HeaderCell>
        ))}
      </Row>
    );
  };

  return (
    <FlexContainer direction="column">
      <CandidatesBody className="bold">Candidates</CandidatesBody>
      <Table borderCollapse="collapse">
        <thead>{renderTableHeaders()}</thead>
        <tbody>{renderTakers()}</tbody>
      </Table>
    </FlexContainer>
  );
};

TakersContainer.propTypes = {
  sortBy: PropTypes.object,
  setSortBy: PropTypes.func
};

TakersContainer.defaultProps = {
  sortBy: '',
  setSortBy: () => {}
};

export default TakersContainer;

const TableHeader = styled(Body1)`
  cursor: ${({ sortable }) => sortable && 'pointer'};
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
`;

const CandidatesBody = styled(Body1)`
  color: ${({ theme }) => theme.colors.smoky};
  margin: 0 0 16px;
`;

const HeaderCell = styled(Cell)`
  svg {
    fill: ${({ selected, theme }) =>
      selected ? theme.tenantAccent : '#e0e0e0'};
  }
  &:active {
    svg {
      fill: #656565;
    }
  }
  &:hover {
    svg {
      fill: ${({ selected }) => !selected && '#656565'};
    }
  }
`;
