import React from 'react';

const landingPageIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="11"
    viewBox="0 0 16 11"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#FFF" fillRule="nonzero" transform="translate(-203 -141)">
        <g transform="translate(164 59)">
          <g transform="translate(0 70)">
            <g transform="translate(28.552)">
              <path d="M12.085 19.343c1.092.11 2.567.239 3.35.305l.879.075-1.593 2.498a.376.376 0 00-.017.372c.06.117.178.19.305.19h.906a.335.335 0 00.194-.061l3.848-2.707.18.014c.44.035 1.957.149 3.042.149.268 0 .492-.007.666-.02 1.212-.097 2.603-.328 2.603-1.268 0-.964-1.618-1.81-2.597-1.81h-8.656l-2.209-4.454a.345.345 0 00-.307-.195h-.955a.34.34 0 00-.265.129.374.374 0 00-.076.295l.691 4.182-.36.175c-.504.245-1.337.734-1.26 1.266.06.425.716.772 1.63.865z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default landingPageIcon;
