import React from 'react';
import { Svg } from 'components/Blocks/Styled/Media';

const importIcon = (
  <Svg
    width="12px"
    height="12px"
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    dynamic
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-953.000000, -312.000000)"
        fill="currentColor"
        fillRule="nonzero"
      >
        <g transform="translate(381.000000, 251.000000)">
          <g transform="translate(16.000000, 54.000000)">
            <g transform="translate(556.000000, 2.000000)">
              <g transform="translate(0.000000, 5.000000)">
                <polygon
                  points="9.890625 7.078125 9.890625 9.890625 2.109375 9.890625 
                2.109375 7.078125 0 7.078125 0 12 12 12 12 7.078125"
                ></polygon>
                <polygon
                  points="6 0 3.890625 3.1640625 5.296875 3.1640625 
                5.296875 8.0859375 6.703125 8.0859375 6.703125 3.1640625 
                8.109375 3.1640625"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </Svg>
);

export default importIcon;
