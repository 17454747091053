import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { handleButtonHover } from 'utils/helpers/handleButtonHover';
import addIcon from 'assets/addIcon';

const AddButton = ({ className, onClick, testId }) => (
  <Btn className={className} data-testid={testId} onClick={onClick}>
    {addIcon}
  </Btn>
);

export default AddButton;

AddButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  testId: PropTypes.string
};

AddButton.defaultProps = {
  className: '',
  testId: ''
};

const Btn = styled.button`
  align-items: center;
  background: ${({ theme }) => theme.tenantAccent};
  border-radius: 20px;
  border: 0;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  display: flex;
  font-size: 24px;
  height: 40px;
  justify-content: center;
  outline: 0;
  width: 40px;
  &:hover {
    background: ${({ theme }) => handleButtonHover(theme.tenantAccent)};
  }
`;
