import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { paths } from 'pages/App/helpers/paths';
import Navbar from 'pages/PanelPage/components/Navbar';
import { FlexContainer } from 'components/Atoms/Containers';
import { PageWrapper } from 'components/Blocks/Styled/Wrappers';
import orangeXIcon from 'assets/orangeXIcon';
import warningIcon from 'assets/warningIcon';

const UnauthorizedView = () => {
  return (
    <PageWrapper background="#f0f2f4">
      <Navbar isUnauthorizedPage />
      <NotificationWrapper>
        <Notification data-testid="unauthorized-notification" to={paths.ADMIN}>
          <FlexContainer>
            {warningIcon}
            <TextWrapper>You do not have access to this page.</TextWrapper>
          </FlexContainer>
          <CancelWrapper to={paths.ADMIN}>{orangeXIcon}</CancelWrapper>
        </Notification>
      </NotificationWrapper>
    </PageWrapper>
  );
};

export default UnauthorizedView;

const NotificationWrapper = styled.div`
  display: flex;
  justify-content: center;
  line-height: 20px;
  margin-top: 32px;
  width: 100%;
`;

const Notification = styled.div`
  align-items: center;
  background: rgba(231, 126, 70, 0.19);
  border: 1px solid #df7c48;
  color: #df7c48;
  display: flex;
  font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
  font-size: 16px;
  justify-content: space-between;
  padding: 16px;
  width: 600px;
`;

const CancelWrapper = styled(Link)`
  cursor: pointer;
`;

const TextWrapper = styled.div`
  margin: 2px 0 0 16px;
`;
