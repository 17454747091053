import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Body2 } from 'components/Atoms/Text';
import { Container } from 'components/Atoms/Containers';
import pencilIcon from 'assets/pencilIcon';
import trashCanRedIcon from 'assets/trashCanRedIcon';
import leakedIcon from 'assets/leakedIcon';
import viewMoreIcon from 'assets/viewMoreIcon';
import Circle from 'assets/CircleIcon';
import { useClickedOutsideEvent } from 'hooks/useClickedOutsideEvent';
import {
  MAP_BACKEND_QUESTION_TYPES,
  STOCK_TRADING
} from 'pages/QuestionPage/Constants';
import {
  MAP_ASSESSMENT_STATE_TO_LABEL,
  QUESTION_STATES,
  VALID_STATES as VS,
  VALID_ASSESSMENT_STATE_CHANGES,
  VALID_QUESTION_STATE_CHANGES
} from 'utils/constants/stateTypes';

const questionDropdownOptions = Object.freeze([
  {
    id: 1,
    icon: pencilIcon,
    text: 'Edit Question',
    label: VS.EDIT,
    customClass: 'underline'
  },
  {
    id: 2,
    icon: <Circle color="#7ebf5a" />,
    text: 'Mark as Active',
    label: VS.ACTIVE
  },
  {
    id: 3,
    icon: <Circle color="#df7c48" />,
    text: 'Mark as Draft',
    label: VS.DRAFT
  },
  {
    id: 4,
    icon: <Circle color="#939393" />,
    text: 'Mark as Retired',
    label: VS.RETIRED,
    customClass: 'underline'
  },
  {
    id: 5,
    icon: leakedIcon,
    text: 'Mark as Leaked',
    label: VS.LEAKED
  },
  {
    id: 6,
    icon: trashCanRedIcon,
    text: 'Delete',
    label: VS.DELETE,
    customClass: 'warning'
  }
]);

const assessmentDropdownOptions = Object.freeze([
  {
    id: 1,
    icon: <Circle color="#7ebf5a" />,
    text: 'Mark as Live',
    label: VS.LIVE
  },
  {
    id: 2,
    icon: <Circle color="#df7c48" />,
    text: 'Mark As Review',
    label: VS.REVIEW
  },
  {
    id: 3,
    icon: <Circle color="#939393" />,
    text: 'Mark as Archived',
    label: VS.ARCHIVED
  },
  {
    id: 4,
    icon: pencilIcon,
    text: 'Edit Assessment',
    label: VS.EDIT
  },
  {
    id: 5,
    icon: trashCanRedIcon,
    text: 'Delete',
    label: VS.DELETE,
    customClass: 'warning'
  }
]);

const MoreOptionsMenu = ({ assessmentProps, questionProps }) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);
  useClickedOutsideEvent(containerRef, () => setIsOpen(false));

  const renderDropdown = () => {
    let dropdownOptions = assessmentProps
      ? assessmentDropdownOptions
      : questionDropdownOptions;

    // Builds menu option functionality
    // Including disabled state & onclick actions
    // Handles both questions and assessments
    return dropdownOptions.map((option, idx) => {
      let id = null;
      let isDisabled = false;
      let onClick = () => null;
      let state = null;
      let questionType = null;
      let validStates = {};

      if (questionProps) {
        state = questionProps.questionState;
        questionType = questionProps.questionType;
        validStates = VALID_QUESTION_STATE_CHANGES;
        id = questionProps.id;
        onClick = data =>
          questionProps.onOptionClick({
            questionId: id,
            data
          });
      } else if (assessmentProps) {
        state = MAP_ASSESSMENT_STATE_TO_LABEL[assessmentProps.assessmentState];
        validStates = VALID_ASSESSMENT_STATE_CHANGES;
        onClick = assessmentProps.onOptionClick[option.label];
      }

      const isValidState = state && !validStates[state].includes(option.label);
      const isValidToEdit =
        option.label === VS.EDIT && questionType === STOCK_TRADING;

      isDisabled = isValidState || isValidToEdit;

      return (
        <DropdownItem
          className={option.customClass}
          data-testid="dropdown-item"
          isDisabled={isDisabled}
          key={`${idx}-${id}`}
          onClick={() => onClick(option.label)}
        >
          <DropdownIcon data-testid="dropdown-item-icon">
            {option.icon}
          </DropdownIcon>
          <DropdownText>{option.text}</DropdownText>
        </DropdownItem>
      );
    });
  };

  return (
    <Container ref={containerRef}>
      <DropdownBtn
        data-testid="dropdown-btn"
        isFocused={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      >
        {viewMoreIcon}
      </DropdownBtn>
      {isOpen && (
        <DropdownModal data-testid="dropdown-modal">
          {renderDropdown()}
        </DropdownModal>
      )}
    </Container>
  );
};

MoreOptionsMenu.propTypes = {
  assessmentProps: PropTypes.shape({
    assessmentState: PropTypes.string.isRequired,
    onOptionClick: PropTypes.object.isRequired
  }),
  questionProps: PropTypes.shape({
    id: PropTypes.number.isRequired,
    questionState: PropTypes.oneOf(QUESTION_STATES).isRequired,
    questionType: PropTypes.oneOf(Object.keys(MAP_BACKEND_QUESTION_TYPES))
      .isRequired,
    onOptionClick: PropTypes.func.isRequired
  })
};

MoreOptionsMenu.defaultProps = {
  assessmentProps: null,
  questionProps: null
};

export default MoreOptionsMenu;

const DropdownModal = styled.nav`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid rgb(228, 228, 228);
  border-radius: 4px;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.07);
  margin-top: 4px;
  min-width: 165px;
  position: absolute;
  right: 0;
  z-index: 20;
`;
const DropdownBtn = styled.button`
  background: ${({ isFocused, theme }) =>
    isFocused ? '#f1f1f1' : theme.colors.transparent};
  border: 0;
  cursor: pointer;
  display: flex;
  height: 24px;
  padding: 0;
  outline: 0;
`;
const DropdownItem = styled.button`
  align-items: center;
  background: 0;
  border: 0;
  color: ${({ theme }) => theme.colors.smoky};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  display: flex;
  margin: 0;
  outline: 0;
  opacity: ${({ isDisabled }) => isDisabled && '0.3'};
  padding: 8px 8px 4px;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  user-select: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  width: 100%;
  &:hover {
    background: ${({ isDisabled }) => !isDisabled && '#f1f1f1'};
  }
  &.warning {
    color: #d64e4c;
    &:hover {
      background: ${({ isDisabled }) => !isDisabled && '#fbeded'};
    }
  }
  &.underline {
    border-bottom: ${({ theme }) => !`1px solid ${theme.colors.iron}`};
  }
`;

const DropdownIcon = styled.span`
  margin-right: 3px;
`;

const DropdownText = styled(Body2)`
  align-items: center;
  display: flex;
  height: 24px;
`;
