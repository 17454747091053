import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FlexContainer } from 'components/Atoms/Containers';

const OptionIcon = ({ background, disabled, isNavIcon, svg, text }) => (
  <MainContainer
    align="center"
    direction="column"
    isNavIcon={isNavIcon}
    disabled={disabled}
  >
    <IconContainer
      data-testid="option-icon-container"
      align="center"
      justify="center"
      background={background}
      disabled={disabled}
    >
      {svg}
    </IconContainer>
    <IconText isNavIcon={isNavIcon}>{text}</IconText>
  </MainContainer>
);

export default OptionIcon;

OptionIcon.propTypes = {
  background: PropTypes.string.isRequired,
  isNavIcon: PropTypes.bool,
  svg: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired
};

const MainContainer = styled(FlexContainer)`
  cursor: pointer;
  flex-direction: ${({ isNavIcon }) => !isNavIcon && 'row'};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`;

const IconContainer = styled(FlexContainer)`
  background: ${({ background, disabled }) =>
    disabled ? '#9c9c9c' : background};
  border-radius: 2px;
  height: 35px;
  width: 35px;
`;

const IconText = styled.span`
  color: ${({ isNavIcon, theme }) =>
    isNavIcon ? theme.colors.black : theme.colors.steel};
  font-family: ${({ theme, isNavIcon }) =>
    isNavIcon ? theme.fonts.avenirProMedium : theme.fonts.avenirProHeavy};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 20px;
  margin-left: ${({ isNavIcon }) => !isNavIcon && '10px'};
  margin-top: 5px;
  text-transform: capitalize;
`;
