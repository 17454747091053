import { DEFAULT_EMAIL_TAKER_FIELD } from 'pages/ManagementPage/Constants';
const mapTakerFields = takerFields => {
  const fields = [DEFAULT_EMAIL_TAKER_FIELD];

  takerFields.forEach(
    ({ id, content, is_required, field_type, options = [] }, idx) => {
      const fieldItem = {
        id,
        itemId: idx + 1,
        itemName: content[0].title,
        isChecked: true,
        isDisabled: false,
        isMandatory: is_required,
        isEditing: false
      };

      if (field_type === 'taker_field_select') {
        fieldItem.configuration = {
          fieldOptions: options
            .sort((a, b) => a.sort_index - b.sort_index)
            .map((field, idx) => ({
              id: field.id,
              optionId: `option-${idx + 1}`,
              value: field.content[0].text_display
            }))
        };
      }
      fields.push(fieldItem);
    }
  );

  return fields;
};

export default mapTakerFields;
