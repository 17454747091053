import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container, FlexContainer } from 'components/Atoms/Containers';

const InputUnderLine = ({ className, focused }) => (
  <UnderlineContainer className={className} justify="center">
    <Line focused={focused} />
  </UnderlineContainer>
);

export default InputUnderLine;

InputUnderLine.propTypes = {
  className: PropTypes.string,
  focused: PropTypes.bool.isRequired
};

InputUnderLine.defaultProps = {
  className: ''
};

const UnderlineContainer = styled(FlexContainer)`
  background: rgba(156, 156, 156, 0.7);
  height: 2px;
  width: 100%;
`;

const Line = styled(Container)`
  background: ${({ theme }) => theme.tenantAccent};
  height: 100%;
  transition: all 150ms ease-out;
  width: ${({ focused }) => (focused ? '100%' : '0%')};
`;
