const secondsToFormattedString = seconds => {
  const minutes = Math.floor(seconds / 60);
  const secondsRemaining = seconds % 60;
  if (minutes > 0) {
    return `${minutes}min ${secondsRemaining}sec`;
  } else {
    return `${secondsRemaining}sec`;
  }
};

export default secondsToFormattedString;
