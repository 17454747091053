import React from 'react';
import styled from 'styled-components';
import { checkMarkIcon } from 'components/Atoms/Icons/checkMarkIcon';
import { redXIcon } from 'components/Atoms/Icons/redXIcon';
import { parseHtml } from 'pages/PanelPage/helpers/parseHtml';
import { FlexContainer } from 'components/Atoms/Containers';
import { Body1 } from 'components/Atoms/Text';

const SelectOptions = ({
  attempt: {
    question: { options },
    answer
  },
  formattedTimeTaken
}) => {
  const answerLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  const renderQuestionOptions = () => {
    return options?.map(option => {
      return (
        <QuestionOption
          key={option.id}
          selected={answer?.options && answer?.options[0]?.id === option.id}
        >
          <OptionSelect />
          <Body1 margin="0 6px 0 0">{answerLetters[option.sort_index]}.</Body1>
          <Body1> {parseHtml(option.content[0].text_display, true)} </Body1>
        </QuestionOption>
      );
    });
  };

  const handleCorrectSelect = () => {
    let correctAnswer = options.reduce((currentHighValue, currentOption) => {
      return currentHighValue.value > currentOption.value
        ? currentHighValue
        : currentOption;
    });

    return (
      <CorrectContainer
        align="center"
        justify="space-between"
        background={
          correctAnswer.id === (answer?.options && answer?.options[0]?.id)
            ? '#e6f1de'
            : '#faddd6'
        }
      >
        <FlexContainer align="center">
          <Body1 color="#df7c48" margin="0 13px 0 0">
            {correctAnswer.id === (answer?.options && answer?.options[0]?.id)
              ? checkMarkIcon
              : redXIcon}
          </Body1>
          <Body1>
            Correct answer:{' '}
            <AnswerText>{answerLetters[correctAnswer.sort_index]}</AnswerText>
          </Body1>
        </FlexContainer>
        {formattedTimeTaken && (
          <Body1>
            Time spent: <AnswerText>{formattedTimeTaken}</AnswerText>
          </Body1>
        )}
      </CorrectContainer>
    );
  };

  return (
    <>
      {renderQuestionOptions()}
      {handleCorrectSelect()}
    </>
  );
};

export default SelectOptions;

const AnswerText = styled.span`
  font-weight: bold;
`;

const CorrectContainer = styled(FlexContainer)`
  border-radius: 4px;
  height: 40px;
  margin: 20px 24px 20px 0;
  padding: 5px 28px 0 32px;
`;

const OptionSelect = styled.div`
  width: 16px;
  height: 16px;
  border: 1px solid #c9c9c9;
  margin-right: 16px;
  flex-shrink: 0;
  border-radius: 14px;
`;

const QuestionOption = styled(FlexContainer)`
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  margin: 7px 0;
  padding: 12px 18px 8px 12px;
  width: 762px;
  ${({ selected, theme: { tenantAccent } }) => {
    return selected && `border: 1px solid ${tenantAccent};`;
  }}
  ${OptionSelect} {
    ${({ selected, theme: { tenantAccent } }) => {
      return (
        selected &&
        `border: 1px solid ${tenantAccent}; 
        background: ${tenantAccent};`
      );
    }}
  }
`;
