import React from 'react';
import { Svg } from 'components/Blocks/Styled/Media';

const allFocusIcon = (
  <Svg
    width="26px"
    height="26px"
    viewBox="0 0 26 26"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-141.000000, -176.000000)">
        <g transform="translate(140.000000, 13.000000)">
          <g transform="translate(0.000000, 7.000000)">
            <g transform="translate(0.000000, 48.000000)">
              <g transform="translate(1.000000, 108.000000)">
                <circle fill="currentColor" cx="13" cy="13" r="13"></circle>
                <g
                  transform="translate(7.000000, 9.000000)"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                >
                  <path d="M11.6484375,0.005859375 L0.3515625,0.005859375 C0.15740625,0.005859375 0,0.163265625 0,0.357421875 L0,2.47558594 C0,2.66974219 0.15740625,2.82714844 0.3515625,2.82714844 L0.706054688,2.82714844 L0.706054688,8.83007812 C0.706054688,9.02423437 0.863460937,9.18164062 1.05761719,9.18164062 L10.9423828,9.18164062 C11.1365391,9.18164062 11.2939453,9.02423437 11.2939453,8.83007812 L11.2939453,2.82714844 L11.6484375,2.82714844 C11.8425937,2.82714844 12,2.66974219 12,2.47558594 L12,0.357421875 C12,0.163265625 11.8425937,0.005859375 11.6484375,0.005859375 Z M10.5908203,8.47851562 L1.40917969,8.47851562 L1.40917969,2.82714844 L10.5908203,2.82714844 L10.5908203,8.47851562 Z M11.296875,2.12402344 L0.703125,2.12402344 L0.703125,0.708984375 L11.296875,0.708984375 L11.296875,2.12402344 Z"></path>
                  <path d="M5.29394531,5.65136719 L6.70605469,5.65136719 C7.28922656,5.65136719 7.76367188,5.17692187 7.76367188,4.59375 C7.76367188,4.01057812 7.28922656,3.53613281 6.70605469,3.53613281 L5.29394531,3.53613281 C4.71077344,3.53613281 4.23632812,4.01057812 4.23632812,4.59375 C4.23632812,5.17692187 4.71077344,5.65136719 5.29394531,5.65136719 Z M5.29394531,4.23925781 L6.70605469,4.23925781 C6.90152344,4.23925781 7.06054688,4.39828125 7.06054688,4.59375 C7.06054688,4.78921875 6.90152344,4.94824219 6.70605469,4.94824219 L5.29394531,4.94824219 C5.09847656,4.94824219 4.93945312,4.78921875 4.93945312,4.59375 C4.93945312,4.39828125 5.09847656,4.23925781 5.29394531,4.23925781 Z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </Svg>
);

export default allFocusIcon;
