import React from 'react';

const addIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#FFF" fillRule="nonzero" transform="translate(-1205 -113)">
        <g transform="translate(208 100)">
          <g transform="translate(984)">
            <path d="M20.865 25.2v-4.536H25.4a.936.936 0 100-1.872h-4.536v-4.536a.936.936 0 10-1.872 0v4.536h-4.536a.936.936 0 000 1.872h4.536V25.2a.936.936 0 001.872 0z"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default addIcon;
