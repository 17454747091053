import React from 'react';
import PropTypes from 'prop-types';
import { ResponsivePie } from '@nivo/pie';
import styled from 'styled-components';
import { FlexContainer } from 'components/Atoms/Containers';
import { Subtitle2 } from 'components/Atoms/Text';
import { useThemeContext } from 'state/themeContext/useThemeContext';

const TakerSummaryPie = ({
  weightMaxScore,
  unanswered,
  obtained,
  vertical
}) => {
  const theme = useThemeContext();

  const data = [
    {
      id: 'missed',
      label: `Missed: ${(weightMaxScore - obtained - unanswered).toFixed(2)}`,
      value: weightMaxScore - obtained - unanswered,
      color: '#e55531',
      last: false
    },
    {
      id: 'unanswered',
      label: `Unanswered: ${unanswered.toFixed(2)}`,
      value: unanswered,
      color: '#cbcbcb',
      last: false
    },
    {
      id: 'obtained',
      label: `Obtained: ${obtained.toFixed(2)}`,
      value: obtained,
      color: theme.colors.mantisGreen,
      last: true
    }
  ];

  const CustomSymbolShape = ({
    x,
    y,
    size,
    fill,
    borderWidth,
    borderColor,
    id
  }) => {
    return (
      <>
        <circle
          r={size / 2}
          cx={x + size / 2}
          cy={y + size / 2}
          fill={fill}
          strokeWidth={borderWidth}
          stroke={borderColor}
          style={{
            pointerEvents: 'none'
          }}
        />
        {id !== 'correct' && (
          <rect
            x={x + x + size - 10}
            y={y + y + size}
            width={172}
            height={1}
            fill={theme.colors.iron}
          />
        )}
      </>
    );
  };

  const MyResponsivePie = () => (
    <ResponsivePie
      animate={true}
      colors={{ datum: 'color' }}
      data={data}
      enableRadialLabels={false}
      enableSlicesLabels={false}
      innerRadius={0.95}
      isInteractive={false}
      theme={{
        legends: {
          text: { fontSize: 14 }
        }
      }}
      legends={[
        {
          anchor: vertical ? 'bottom' : 'right',
          direction: 'column',
          itemHeight: 40,
          itemWidth: 100,
          symbolShape: vertical ? 'circle' : CustomSymbolShape,
          symbolSize: 10,
          translateX: vertical ? 0 : -60,
          translateY: 0
        }
      ]}
      margin={
        vertical
          ? { top: -80, right: 80, bottom: 80, left: 80 }
          : { top: 0, right: 0, bottom: 0, left: -140 }
      }
      motionDamping={15}
      motionStiffness={90}
      padAngle={2}
      cornerRadius={4}
      sortByValue={true}
    />
  );

  return (
    <>
      {MyResponsivePie()}
      <PieFilling
        align="center"
        direction="column"
        justify="center"
        className={vertical && 'vertical'}
      >
        <Subtitle2>
          {obtained.toFixed(2)}/{weightMaxScore.toFixed(2)}
        </Subtitle2>
        <SmallText>score</SmallText>
      </PieFilling>
    </>
  );
};

TakerSummaryPie.propTypes = {
  weightMaxScore: PropTypes.number.isRequired,
  unanswered: PropTypes.number.isRequired,
  obtained: PropTypes.number.isRequired
};

TakerSummaryPie.defaultProps = {
  weightMaxScore: 0,
  unanswered: 0,
  obtained: 0
};

export default TakerSummaryPie;

const PieFilling = styled(FlexContainer)`
  bottom: 0;
  left: 0;
  pointer-events: 'none';
  position: absolute;
  right: 135px;
  top: 5px;

  &.vertical {
    bottom: 147px;
    right: 0;
    top: 0;
  }
`;

const SmallText = styled(Subtitle2)`
  color: #6a7f87;
  font-size: 12px;
`;
