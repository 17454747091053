import React from 'react';

const calendarGreyIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g
        fill="#939393"
        fillRule="nonzero"
        stroke="#939393"
        strokeWidth="0.3"
        transform="translate(-901 -282)"
      >
        <g transform="translate(208 80)">
          <g transform="translate(0 86)">
            <g transform="translate(694 63)">
              <g transform="translate(0 48)">
                <g transform="translate(0 6)">
                  <path d="M8.854 10H1.146A1.147 1.147 0 010 8.854V1.98C0 1.347.514.833 1.146.833h7.708C9.486.833 10 1.347 10 1.98v6.875C10 9.486 9.486 10 8.854 10zM1.146 1.458a.522.522 0 00-.521.521v6.875c0 .287.234.521.52.521h7.71c.286 0 .52-.234.52-.52V1.978a.522.522 0 00-.52-.52h-7.71z"></path>
                  <path d="M9.688 3.958H.312a.313.313 0 010-.625h9.376a.313.313 0 010 .625zM2.396 2.5a.313.313 0 01-.313-.313V.313a.313.313 0 01.625 0v1.875c0 .172-.14.312-.312.312zM7.604 2.5a.313.313 0 01-.312-.313V.313a.313.313 0 01.625 0v1.875c0 .172-.14.312-.313.312z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default calendarGreyIcon;
