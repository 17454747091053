import getNumericPart from 'pages/ManagementPage/utils/getNumericPart';
import validateSlugUniqueness from 'pages/ManagementPage/utils/validateSlugUniqueness';
import {
  FORM_ERRORS_MAP,
  OPTION_TYPES,
  WHOLE_SECTION,
  EACH_QUESTION,
  SECTION_STOCK,
  MAX_LOGO_MB_SIZE,
  MAX_LOGO_NAME_LENGTH
} from 'pages/ManagementPage/Constants';
import { questionTypes } from 'pages/App/helpers/questionTypes';
import validateQuestionPoolSameScore from 'pages/ManagementPage/utils/validateQuestionPoolSameScore';

const validateForm = async (
  assessmentData,
  assessmentSettings,
  sectionsData,
  setFormErrors,
  createdCards,
  isEditing,
  questionPools
) => {
  const errors = {
    CARDS: {},
    SECTIONS: { INVALID: {} },
    SETTINGS: '',
    SLOT_RANDOM: {}
  };

  const replaceLabel = (error, errorPrefix) =>
    error.replace('%{sectionName}%', errorPrefix);

  const validateCardsCreated = () => {
    const landingPage = createdCards?.LANDING_PAGE;
    const candiateInfo = createdCards?.CANDIDATE_INFO;
    const questionSections = Object.keys(createdCards).filter(
      card => createdCards[card] && card.includes(OPTION_TYPES.QUESTION_SECTION)
    );

    if (!landingPage) {
      errors.CARDS.LANDING_PAGE = FORM_ERRORS_MAP.LANDING_PAGE;
    }

    if (!candiateInfo) {
      errors.CARDS.CANDIDATE_INFO = FORM_ERRORS_MAP.CANDIDATE_INFO;
    }

    if (!questionSections.length) {
      errors.CARDS.QUESTION_SECTION = FORM_ERRORS_MAP.QUESTION_SECTION;
    }
  };

  const validateLandingPageFields = () => {
    const assessmentTitle =
      assessmentData.formTitle ||
      assessmentData.headerTitle ||
      assessmentData.title;

    const validateFile = () => {
      const file = assessmentData?.logoFile?.file;
      if (!file) {
        return true;
      }

      const sizeMb = (file.size / (1024 * 1024)).toFixed(2);

      if (
        file.name.length <= MAX_LOGO_NAME_LENGTH &&
        sizeMb <= MAX_LOGO_MB_SIZE &&
        (file.type === 'image/png' || file.type === 'image/jpeg')
      ) {
        return true;
      }
      return false;
    };

    if (createdCards?.LANDING_PAGE) {
      errors.LANDING_PAGE = {};
      if (!assessmentTitle) {
        errors.LANDING_PAGE.TITLE = FORM_ERRORS_MAP.LANDING_PAGE_TITLE;
      }
      if (!validateFile()) {
        errors.LANDING_PAGE.FILE = FORM_ERRORS_MAP.LANDING_PAGE_TITLE;
      }
    }
  };

  const validateQuestionPools = () => {
    const usedPools = [];
    let hasMoreSlotRandomsThanQuestionsInList = false;
    let validPoolScores = true;

    const questionSections = Object.keys(createdCards).filter(
      card => createdCards[card] && card.includes(OPTION_TYPES.QUESTION_SECTION)
    );

    for (const section of questionSections) {
      const sectionData = sectionsData.find(
        ({ sectionId }) => sectionId === getNumericPart(section)
      );

      const hasSlotRandom = Boolean(
        sectionData?.questions?.find(({ isQuestionPool }) => isQuestionPool)
      );

      if (hasSlotRandom) {
        usedPools.push(
          sectionData.questions.filter(({ selectedPool = '' }) => selectedPool)
        );
      }
    }

    const poolCategories = usedPools
      .flat()
      .map(({ selectedPool }) => selectedPool);

    for (const poolCategory of poolCategories) {
      const poolData = questionPools.find(
        ({ title }) => title === poolCategory
      );
      const questionListLimit = poolData?.questions?.length || 0;
      const numberOfUsedPools =
        poolCategories.filter(pool => pool === poolCategory)?.length || 0;

      validPoolScores = validateQuestionPoolSameScore(poolData);
      if (!validPoolScores) {
        break;
      }
      if (
        questionListLimit !== 0 &&
        numberOfUsedPools !== 0 &&
        numberOfUsedPools > questionListLimit
      ) {
        hasMoreSlotRandomsThanQuestionsInList = true;
        break;
      }
    }

    if (hasMoreSlotRandomsThanQuestionsInList) {
      errors.SLOT_RANDOM.HAS_MORE_SLOT_RANDOMS_THAN_QUESTIONS_IN_LIST =
        FORM_ERRORS_MAP.HAS_MORE_SLOT_RANDOMS_THAN_QUESTIONS_IN_LIST;
    }
    if (!validPoolScores) {
      errors.SLOT_RANDOM.HAS_INVALID_SCORES =
        FORM_ERRORS_MAP.HAS_INVALID_SCORES;
    }
  };

  const validateQuestionSections = () => {
    const questionSections = Object.keys(createdCards).filter(
      card => createdCards[card] && card.includes(OPTION_TYPES.QUESTION_SECTION)
    );
    for (const section of questionSections) {
      const sectionData = sectionsData.find(
        ({ sectionId }) => sectionId === getNumericPart(section)
      );
      const {
        sectionTitle,
        sectionId,
        sectionDuration,
        sectionSeconds,
        questions = [],
        sectionType
      } = sectionData;

      const isWeightValid = weight =>
        !isNaN(weight) && weight % 1 === 0 && weight >= 0;

      const isDurationValid = duration =>
        !!(duration && duration % 1 === 0 && duration > 0);

      const hasQuestions = questions.length > 0;
      const validWholeSectionTimer =
        sectionDuration !== WHOLE_SECTION
          ? null
          : isDurationValid(sectionSeconds);

      const validEachQuestionSectionTimer =
        sectionDuration !== EACH_QUESTION || !hasQuestions
          ? null
          : !questions.find(({ duration = 0 }) => !isDurationValid(duration));

      const validWeights = !questions.find(
        ({ weight = 100 }) => !isWeightValid(weight)
      );

      const hasSlotRandom = Boolean(
        questions.find(({ isQuestionPool }) => isQuestionPool)
      );

      const validCategories =
        hasSlotRandom ||
        !questions.find(({ default_category }) => !default_category);

      const errorPrefix = sectionTitle
        ? `Section ${sectionTitle}`
        : 'A section';

      let emptySlotRandom = false;

      if (hasSlotRandom) {
        const poolsInSection = questions.filter(
          ({ isQuestionPool }) => isQuestionPool
        );

        for (const { selectedPool = null } of poolsInSection) {
          const currentPool = questionPools.find(
            ({ title }) => selectedPool === title
          );

          emptySlotRandom = !currentPool?.questions?.length;
          if (emptySlotRandom) {
            break;
          }
        }
      }

      if (
        sectionType !== SECTION_STOCK &&
        (!sectionTitle ||
          validWholeSectionTimer === false ||
          validEachQuestionSectionTimer === false ||
          !validWeights ||
          !validCategories ||
          emptySlotRandom)
      ) {
        errors.SECTIONS[sectionId] = { FIELDS: { QUESTIONS: {} } };

        errors.SECTIONS.INVALID[`MISSING_DATA-${sectionId}`] = replaceLabel(
          FORM_ERRORS_MAP.QUESTION_SECTION_MISSING_DATA,
          errorPrefix
        );
        if (!sectionTitle) {
          errors.SECTIONS[sectionId].FIELDS.TITLE = true;
        }
        if (validWholeSectionTimer === false) {
          errors.SECTIONS[sectionId].FIELDS.WHOLE_SECTION_TIMER = true;
        }
        if (emptySlotRandom) {
          errors.SLOT_RANDOM.SLOT_RANDOM_EMPTY =
            FORM_ERRORS_MAP.SLOT_RANDOM_EMPTY;
        }

        if (
          validEachQuestionSectionTimer === false ||
          !validWeights ||
          !validCategories
        ) {
          for (const {
            duration = 0,
            weight = 100,
            id,
            default_category,
            question_type
          } of questions) {
            errors.SECTIONS[sectionId].FIELDS.QUESTIONS[id] = {};
            if (
              !isDurationValid(duration) &&
              sectionDuration === EACH_QUESTION
            ) {
              errors.SECTIONS[sectionId].FIELDS.QUESTIONS[id].TIMER = true;
            }
            if (question_type === questionTypes.SLOT_RANDOM) {
              if (!isWeightValid(weight)) {
                errors.SECTIONS[sectionId].FIELDS.QUESTIONS[id].WEIGHT = true;
              }
              if (!default_category) {
                errors.SECTIONS[sectionId].FIELDS.QUESTIONS[id].CATEGORY = true;
              }
            }
          }
        }
      }
      if (!hasQuestions) {
        errors.SECTIONS.INVALID[
          `MISSING_QUESTIONS-${sectionId}`
        ] = replaceLabel(
          FORM_ERRORS_MAP.QUESTION_SECTION_MISSING_QUESTIONS,
          errorPrefix
        );
      }
    }
  };

  const validateSettings = async () => {
    const assessmentTitle =
      assessmentData.formTitle ||
      assessmentData.headerTitle ||
      assessmentData.title;
    const { assessmentSlug } = assessmentSettings;
    const validSettings = !!(
      assessmentTitle &&
      assessmentSlug &&
      (isEditing || (await validateSlugUniqueness(assessmentSlug)))
    );

    if (!validSettings) {
      errors.SETTINGS = FORM_ERRORS_MAP.SETTINGS;
    }
  };

  const cleanErrors = () => {
    const cleanedErrors = { ...errors };
    if (!Object.keys(cleanedErrors.CARDS).length) {
      delete cleanedErrors.CARDS;
    }
    if (
      cleanedErrors.SECTIONS?.INVALID &&
      !Object.keys(cleanedErrors.SECTIONS.INVALID).length
    ) {
      delete cleanedErrors.SECTIONS.INVALID;
    }
    if (
      cleanedErrors?.LANDING_PAGE &&
      !Object.keys(cleanedErrors.LANDING_PAGE).length
    ) {
      delete cleanedErrors.LANDING_PAGE;
    }
    if (!Object.keys(cleanedErrors.SECTIONS).length) {
      delete cleanedErrors.SECTIONS;
    }

    if (!cleanedErrors.SETTINGS) {
      delete cleanedErrors.SETTINGS;
    }
    if (!Object.keys(cleanedErrors.SLOT_RANDOM).length) {
      delete cleanedErrors.SLOT_RANDOM;
    }

    return cleanedErrors;
  };

  const confirmValidations = () => {
    const errors = cleanErrors();
    setFormErrors(errors);
    return !Object.keys(errors).length;
  };

  validateCardsCreated();
  validateLandingPageFields();
  validateQuestionPools();
  validateQuestionSections();
  await validateSettings();
  return confirmValidations();
};

export default validateForm;
