import styled from 'styled-components';
import { removeNumericInputSpinners } from 'utils/mixins/Mixins';

export const Input = styled.input`
  appearance: none;
  background: #f5f5f5;
  border-bottom: 0;
  border-top: 0;
  border: 2px solid #f5f5f5;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  letter-spacing: 0.78px;
  line-height: 16px;
  margin: 0;
  outline: none;
  width: 100%;
  ${removeNumericInputSpinners}
  &::placeholder {
    color: #bfbfbf;
    text-transform: capitalize;
  }
`;

export const InputLabel = styled.div`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.44px;
  line-height: 28px;
  margin-bottom: 16px;
  color: ${props => props.theme.black};
`;

export const InputDescriptor = styled.span`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.67px;
  line-height: 16px;
  margin-bottom: 8px;
  color: #858585;
`;

export const InputUnderlineOuter = styled.div`
  background: #d3d3d3;
  height: 2px;
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 2px;
  margin-bottom: 6px;
`;

export const InputUnderlineInner = styled.div`
  background: ${({ theme }) => theme.tenantAccent};
  height: 100%;
  border-radius: 2px;
  transition: all 150ms ease-out;
  &.focus {
    width: 100%;
  }
  &.unfocus {
    width: 0%;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  max-width: 300px;
  position: relative;
  label {
    color: #484848;
  }
  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
    input,
    label {
      cursor: not-allowed;
    }
  }
`;

export const InputHolder = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ height }) => height && height};
  justify-content: center;
  width: ${({ width }) => width && width};
`;

export const SearchImage = styled.div`
  left: 10px;
  position: absolute;
  top: 3px;
`;

export const Separator = styled.div`
  margin: 32px 0;
  border-bottom: 1px solid #e0e0e0;
`;
