import React from 'react';

const freeResponseQuestionIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="114"
    height="36"
    viewBox="0 0 114 36"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g stroke="#6A7F87" strokeWidth="2" transform="translate(-797 -311)">
        <g transform="translate(430 208)">
          <g transform="translate(302 74)">
            <g transform="translate(65 29)">
              <rect
                width="112"
                height="34"
                x="1"
                y="1"
                fill="#6A7F87"
                fillOpacity="0.16"
                rx="2"
              ></rect>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M110.5 30.5L108.5 32.5"
              ></path>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M110 26L104 32"
              ></path>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.5 6L8.5 16"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default freeResponseQuestionIcon;
