import styled from 'styled-components';

const PageWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  background: ${({ background }) => background};
`;

// Export Wrappers
export { PageWrapper };
