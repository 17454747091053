import React from 'react';

const leftArrowIcon = (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1170.000000, -1278.000000)">
        <g transform="translate(1.000000, 159.000000)">
          <g transform="translate(206.000000, 546.000000)">
            <g transform="translate(1.000000, 573.000000)">
              <g transform="translate(978.000000, 16.000000) rotate(-270.000000) translate(-978.000000, -16.000000) translate(962.000000, 0.000000)">
                <rect x="0" y="0" width="32" height="32"></rect>
                <path
                  d="M12.0485281,12.3653052 C11.579899,11.8782316 10.820101,11.8782316 10.3514719,12.3653052 C9.88284271,12.8523789 9.88284271,13.6420813 10.3514719,14.1291549 L16,20 L21.6485281,14.1291549 C22.1171573,13.6420813 22.1171573,12.8523789 21.6485281,12.3653052 C21.179899,11.8782316 20.420101,11.8782316 19.9514719,12.3653052 L16,16.4723007 L12.0485281,12.3653052 Z"
                  fill="#656565"
                  fillRule="nonzero"
                  transform="translate(16.000000, 16.000000) rotate(-360.000000) translate(-16.000000, -16.000000) "
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default leftArrowIcon;
