import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Input from 'components/Blocks/Forms/Input';
import { FlexContainer } from 'components/Atoms/Containers';
import { removeNumericInputSpinners } from 'utils/mixins/Mixins';

const ScoreInput = ({
  autoFocus,
  disabled,
  inputName,
  score,
  min,
  ...inputProps
}) => (
  <ScoreContainer>
    <Score
      autoFocus={autoFocus}
      disabled={disabled}
      name={inputName}
      type="number"
      value={score?.toString() || 0}
      validations={{ min }}
      showUnderLine={false}
      {...inputProps}
    />
    <ScoreLabel>Pt</ScoreLabel>
  </ScoreContainer>
);

export default ScoreInput;

ScoreInput.propTypes = {
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  inputName: PropTypes.string.isRequired,
  score: PropTypes.number,
  min: PropTypes.number
};

ScoreInput.defaultProps = {
  autoFocus: false,
  disabled: false,
  score: null,
  min: 0
};

const ScoreContainer = styled(FlexContainer)`
  align-items: center;
  margin-left: 8px;
`;

const Score = styled(Input)`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid rgb(220, 220, 220);
  border-radius: 2px;
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 12px;
  line-height: 24px;
  max-height: 24px;
  width: 57px;
  padding: 6px 4px 4px;
  ${removeNumericInputSpinners}
  &:disabled {
    cursor: pointer;
    opacity: 1;
    pointer-events: none;
  }
`;

const ScoreLabel = styled.span`
  color: ${({ theme }) => theme.colors.steel};
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 12px;
  margin: 2px 0 0 6px;
`;
