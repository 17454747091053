import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FlexContainer } from 'components/Atoms/Containers';
import QuestionHeader from 'pages/ManagementPage/questionSection/questionItemCard/QuestionItemHeader';
import QuestionItemBody from 'pages/ManagementPage/questionSection/questionItemCard/QuestionItemBody';
import QuestionItemPoolBody from 'pages/ManagementPage/questionSection/questionItemCard/QuestionItemPoolBody';

const QuestionItemCard = ({
  category,
  content,
  questionNumber,
  questionType,
  handleRemoveQuestion,
  showDuration,
  handleUpdateQuestions,
  weight,
  duration,
  errors,
  isSlotDisabled,
  isQuestionPool,
  handleOpenQuestionPoolEditor,
  questionPools,
  selectedPool
}) => {
  const questionHeader = useMemo(
    () => (
      <QuestionHeader
        questionNumber={questionNumber}
        questionType={questionType}
        handleRemoveQuestion={handleRemoveQuestion}
        showDuration={showDuration}
        handleUpdateQuestions={handleUpdateQuestions}
        duration={duration}
        weight={weight}
        errors={errors}
        isSlotDisabled={isSlotDisabled}
        isQuestionPool={isQuestionPool}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      weight,
      duration,
      questionNumber,
      handleRemoveQuestion,
      handleUpdateQuestions,
      errors,
      isSlotDisabled
    ]
  );

  const questionBody = useMemo(
    () => (
      <QuestionItemBody
        category={category}
        handleUpdateQuestions={handleUpdateQuestions}
        content={content}
        questionType={questionType}
        isSlotDisabled={isSlotDisabled}
        errors={errors}
      />
    ),
    [
      category,
      content,
      questionType,
      handleUpdateQuestions,
      isSlotDisabled,
      errors
    ]
  );

  const questionPoolBody = useMemo(
    () => (
      <QuestionItemPoolBody
        handleOpenQuestionPoolEditor={handleOpenQuestionPoolEditor}
        questionPools={questionPools}
        selectedPool={selectedPool}
        handleUpdateQuestions={handleUpdateQuestions}
      />
    ),
    [
      handleOpenQuestionPoolEditor,
      questionPools,
      selectedPool,
      handleUpdateQuestions
    ]
  );

  return (
    <MainContainer direction="column" disabled={isSlotDisabled}>
      {questionHeader}
      {isQuestionPool ? questionPoolBody : questionBody}
    </MainContainer>
  );
};

export default QuestionItemCard;

QuestionItemCard.propTypes = {
  category: PropTypes.string,
  content: PropTypes.string,
  questionNumber: PropTypes.number.isRequired,
  questionType: PropTypes.string,
  handleRemoveQuestion: PropTypes.func.isRequired,
  showDuration: PropTypes.bool.isRequired,
  handleUpdateQuestions: PropTypes.func.isRequired,
  weight: PropTypes.number,
  duration: PropTypes.number,
  isQuestionPool: PropTypes.bool.isRequired,
  handleOpenQuestionPoolEditor: PropTypes.func.isRequired,
  questionPools: PropTypes.array
};

QuestionItemCard.defaultProps = {
  content: '',
  category: '',
  questionType: '',
  weight: 100,
  duration: undefined,
  questionPools: []
};

const MainContainer = styled(FlexContainer)`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid rgb(208, 208, 208);
  border-radius: 2px;
  padding: 24px 16px;

  ${({ disabled }) =>
    disabled &&
    `
    background: #ececec;
    pointer-events: none;
    div {
      background: #ececec;
    }
  `}
`;
