import React from 'react';

export const checkMarkIcon = (
  <svg
    width="17px"
    height="12px"
    viewBox="0 0 17 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-311.000000, -3529.000000)"
        fill="#7EBF5A"
        fillRule="nonzero"
        stroke="#E5F2DE"
        strokeWidth="0.6"
      >
        <g transform="translate(0.000000, 160.000000)">
          <g transform="translate(209.000000, 1319.000000)">
            <g transform="translate(0.000000, 704.000000)">
              <g transform="translate(24.000000, 749.000000)">
                <g transform="translate(3.018109, 51.247173)">
                  <g transform="translate(54.325956, 532.568659)">
                    <g transform="translate(20.265594, 4.019386)">
                      <g transform="translate(0.390342, 9.164782)">
                        <path d="M14.8409839,0.3 C15.1866782,0.3 15.532368,0.431858023 15.7959931,0.695383207 C16.0596065,0.959046542 16.1914108,1.30459756 16.191447,1.65018544 C16.1914831,1.99584441 16.0597022,2.3415424 15.7962648,2.60527953 L15.7962648,2.60527953 L7.0963171,11.3046168 C6.83276413,11.5682197 6.48721998,11.7 6.14162426,11.7 C5.79595582,11.7 5.45022658,11.56817 5.18646121,11.3046044 L5.18646121,11.3046044 L0.695474747,6.8132924 C0.431815762,6.54968336 0.3,6.20408314 0.3,5.85847747 C0.3,5.51285726 0.431825712,5.16722003 0.695494841,4.9035509 C0.9592087,4.63983704 1.30481559,4.50797014 1.65039907,4.50793398 C1.99597606,4.50789783 2.34153248,4.63968822 2.60513306,4.90330545 L2.60513306,4.90330545 L6.14174052,8.4399562 L13.8862516,0.6954033 C14.1499109,0.431743944 14.4954549,0.3 14.8409839,0.3 Z"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
