import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { StyledButton } from 'components/Atoms/Buttons';
import { Container } from 'components/Atoms/Containers';

const Button = ({
  background,
  className,
  disabled,
  isFullWidth,
  loading,
  onClick,
  text,
  textTransform,
  type
}) => {
  return (
    <StyledButton
      background={background}
      disabled={disabled}
      className={className}
      isFullWidth={isFullWidth}
      onClick={onClick}
      type={type}
      textTransform={textTransform}
    >
      {loading && (
        <Container margin="2px 8px 0 -16px">
          <SpinnerSvg viewBox="0 0 50 50">
            <path
              fill="#ffffff"
              d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,
               8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,
               0,14.615,6.543,14.615,14.615H43.935z"
            ></path>
          </SpinnerSvg>
        </Container>
      )}
      {text}
    </StyledButton>
  );
};

Button.propTypes = {
  background: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
  textTransform: PropTypes.oneOf([
    'capitalize',
    'uppercase',
    'lowercase',
    'none'
  ]),
  type: PropTypes.oneOf(['text', 'submit'])
};

Button.defaultProps = {
  background: '',
  className: '',
  disabled: false,
  isFullWidth: false,
  loading: false,
  onClick: null,
  text: 'button',
  textTransform: 'none',
  type: 'text'
};

export default Button;

const SpinnerSvg = styled.svg`
  animation: spin 700ms infinite linear;
  height: 20px;
  width: 20px;
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
