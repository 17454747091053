import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import 'react-quill/dist/quill.snow.css';
import styled, { css } from 'styled-components';
import Input from 'components/Blocks/Forms/Input';
import { Container } from 'components/Atoms/Containers';
import { useInputFocus } from 'pages/PanelPage/hooks/useInputFocus';
import OptionTooltip from 'components/Blocks/OptionTooltip';
import InputUnderLine from 'components/Blocks/Forms/InputUnderLine';

const COLORS_WHITE_LIST = [
  '#000000',
  '#808080',
  '#FFFFFF',
  '#FF0000',
  '#00FF00',
  '#0000FF',
  '#FFCC00'
];
const Size = Quill.import('attributors/style/size');
const AlignStyle = Quill.import('attributors/style/align');
Size.whitelist = ['12px', '14px', false, '18px', '24px', '32px'];
Quill.register('modules/imageResize', ImageResize);
Quill.register(AlignStyle, true);
Quill.register(Size, true);

const QuillEditor = ({
  autoFocus,
  initialBody,
  inputName,
  onUpdatedBody,
  isSmall,
  onValidChange,
  placeholder,
  grayTheme,
  isLatexRestricted,
  isRequired,
  validateOnSubmit,
  isValidField
}) => {
  const [body, setBody] = useState(initialBody);
  const { focus, onFocus, onBlur } = useInputFocus();
  const [isValid, setIsValid] = useState(
    !validateOnSubmit ? body != null : isValidField
  );

  let quillBodyRef = useRef(null);
  const modules = {
    // Prevent quill to add undesired HTML
    clipboard: { matchVisual: false },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize']
    },
    toolbar: [
      [
        'bold',
        'italic',
        'underline',
        'strike',
        { list: 'ordered' },
        { list: 'bullet' },
        'link',
        'image',
        { size: Size.whitelist },
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' },
        'code-block',
        {
          color: COLORS_WHITE_LIST
        }
      ]
    ]
  };

  useEffect(() => {
    onValidChange(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  useEffect(() => {
    autoFocus && quillBodyRef.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoFocus]);

  useEffect(() => {
    const isEmptyBody =
      !!!body?.replace(/(<\/?(?:img)[^>]*>)|<[^>]+>/gi, '$1').trim() &&
      body !== '';
    let validation = isValid;

    if (!isEmptyBody || !isRequired) {
      validation = true;
      onUpdatedBody(body.replace(/[\r\n]/g, '<br>'));
    } else {
      validation = false;
      onUpdatedBody('');
    }
    !validateOnSubmit && setIsValid(validation);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [body]);

  useEffect(() => {
    initialBody && updateBody();
  }, [initialBody]);
  const updateBody = () => {
    if (!quillBodyRef?.getEditor) return;
    const editor = quillBodyRef.getEditor();
    const html = quillBodyRef.makeUnprivilegedEditor(editor).getHTML();
    setBody(html);
  };

  return (
    <BodyContainer
      isFocus={focus}
      className={isSmall && 'small'}
      isValid={isValid}
      grayTheme={grayTheme}
    >
      <ReactQuill
        onFocus={onFocus}
        onChange={updateBody}
        onBlur={() => {
          updateBody();
          onBlur();
        }}
        theme="snow"
        modules={modules}
        ref={element => (quillBodyRef = element)}
        value={body || initialBody}
        placeholder={placeholder}
      />
      <Input
        name={inputName}
        value={body}
        required={isRequired}
        isVisible={false}
      />
      {isValid && <InputUnderLine focused={focus} />}
      {!isLatexRestricted && (
        <Tooltip>
          <OptionTooltip content="When inputing mathematical notation, use the following format: \[Formula Here\]" />
        </Tooltip>
      )}
    </BodyContainer>
  );
};

export default QuillEditor;

QuillEditor.propTypes = {
  autoFocus: PropTypes.bool,
  initialBody: PropTypes.string,
  inputName: PropTypes.string,
  onUpdatedBody: PropTypes.func,
  isSmall: PropTypes.bool,
  onValidChange: PropTypes.func,
  placehoder: PropTypes.string,
  grayTheme: PropTypes.bool,
  isLatexRestricted: PropTypes.bool,
  isRequired: PropTypes.bool,
  validateOnSubmit: PropTypes.bool,
  isValidField: PropTypes.bool
};

QuillEditor.defaultProps = {
  autoFocus: false,
  initialBody: '',
  inputName: '',
  onUpdatedBody: () => {},
  isSmall: false,
  onValidChange: () => {},
  placeholder: '',
  grayTheme: false,
  isLatexRestricted: false,
  isRequired: true,
  validateOnSubmit: false,
  isValidField: null
};

const createFontSizeStyles = () => {
  let styles = '';

  Size.whitelist.forEach(fontSize => {
    styles += `
      & span[data-value='${fontSize}']::before {
        content: '${fontSize}';
      }
     `;
  });
  return css`
    ${styles}
  `;
};

const BodyContainer = styled(Container)`
  width: 100%;
  .quill {
    background: ${({ theme }) => theme.colors.white};
    border: ${({ isValid, theme }) =>
      `${isValid ? '0px' : '2px'} solid ${theme.colors.invalidRed}`};
    text-align: left;
    .ql-toolbar.ql-snow {
      border: 0;
      border-bottom: 1px solid #f0f0f0;
      .ql-formats {
        .ql-picker {
          ${createFontSizeStyles()}
        }
      }
    }
    .ql-container.ql-snow {
      border: 0;
    }
  }
  .ql-editor {
    background: ${({ grayTheme, theme }) =>
      grayTheme ? theme.colors.lightGrey : 'transparent'};
    font-size: 16px;
    min-height: 116px;
    &.ql-blank::before {
      color: #6a7f87;
      font-family: ${({ theme }) => theme.fonts.avenirNextMedium};
      font-size: 14px;
      font-style: normal;
      letter-spacing: 0.4px;
      line-height: 16px;
    }
    ul {
      margin: revert;
    }
  }
  &.small {
    .quill {
      .ql-toolbar.ql-snow {
        border: 0;
        max-width: 80%;
        @media (min-width: 1200px) {
          max-width: 90%;
        }
      }
    }
  }
`;

const Tooltip = styled.div`
  position: absolute;
  right: 8px;
  bottom: 8px;
`;
