import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Dropdown from 'components/Blocks/Dropdown';
import getHourStringFromNumber from 'utils/helpers/getHourStringFromNumber';

// Creates an array with the 24 hours of the day in militar format and as strings
const HOURS = Object.freeze(
  [...Array(24)].map((_, idx) => ({
    id: getHourStringFromNumber(idx)
  }))
);

const HoursDropdown = ({
  className,
  onSelect,
  selectedHour,
  inputName,
  isValid
}) => (
  <StyledDropdown
    className={className}
    options={HOURS}
    selectedOption={selectedHour}
    setSelectedOption={hour => onSelect(hour)}
    showUnderline
    name={inputName}
    isValid={isValid}
  />
);

export default HoursDropdown;

HoursDropdown.propTypes = {
  className: PropTypes.string,
  onSelect: PropTypes.func,
  selectedHour: PropTypes.string,
  inputName: PropTypes.string,
  isValid: PropTypes.bool
};

HoursDropdown.defaultProps = {
  className: '',
  onSelect: () => null,
  selectedHour: '',
  inputName: 'hours-dropdown',
  isValid: true
};

const StyledDropdown = styled(Dropdown)`
  background: ${({ theme }) => theme.colors.whiteSmoke};
  width: 100px;
  ~ .dropdown-options {
    max-height: 300px;
    overflow-y: scroll;
  }
  > div {
    color: ${({ theme }) => theme.colors.black};
    font-size: 14px;
  }
`;
